
import React, { useState, useEffect, useCallback } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import * as Constants from "../../constants";
import * as MyForms from "./MyForms";
import Modal from "react-modal";
import * as MyButtons from "./MyButtons";
import * as Utils from "./Utils";

export default function Referenti(props) {
	const [state, setState] = useState({});
	const [nomeCliente, setNomeCliente] = useState();
	const [listReferenti, setlistReferenti] = useState([]);
	const [ReferentiAssociati, setAssociaRef] = useState({});
	const [labelGestioneProgetto, setLabel] = useState(false);

	useEffect(() => {
		if(props.partner) fetchReferentiPartner();
	}, [props.partner, state]);

	useEffect(() => {
	}, [props.progetto]);

	const fetchReferentiPartner = async () => {
		var api_path = Constants.Compose([Constants.PATH_CLIENTI, props.partner.clienteId, 'persone']);
		api_path += "?filter=" + JSON.stringify({'order':['cognome']});
		const referenti = await Constants.getData(api_path);
		setlistReferenti(referenti)

		setNomeCliente(props.partner.cliente.acronimo);
	};

	const ChangeCheckBox = function(data) {
		let checked = data.target.checked;
		let personaId = data.target.name;
		if(checked) {
			ReferentiAssociati[personaId] = 1;
		} else {
			delete ReferentiAssociati[personaId]
		}
		setAssociaRef(ReferentiAssociati)
	}

	const Conferma = async () => {
		var api_path = Constants.Compose([Constants.PATH_PARTNERS, props.partner.partnerId, 'partnerReferentiConfig']);
		await Constants.postData(api_path, JSON.stringify(Object.keys(ReferentiAssociati)));
		props.forceUpdate();
		props.onRequestClose();
	}

	return (
		<>
			<Modal isOpen={props.isModalReferentiOpen}
				onRequestClose={props.onRequestClose}
				style={{ overlay: { width: "35", }, content: { top: "5%", left: "20%", right: "20%", bottom: "auto", }, width: "30px", }} >
				<div className="modal-content">
					<div className="modal-header">
						<h5>{nomeCliente} - Referenti progetto</h5>
						<h6>{labelGestioneProgetto} </h6>
						<MyButtons.MyClose handleClick={props.onRequestClose}/>
					</div>
					<div className="modal-body">
						<div class="form-group row">
							<table className="table">
								<thead>
									{listReferenti.map((ref) => {
										const found = props.partner.referenti.find(element => element.personaId == ref.personaId);
										let checked = found ? 1 : 0;

										return (
											<tr>
												<th width="20%">{ref.cognome} {ref.nome}</th>
												<th><input type="checkbox" name={ref.personaId} defaultChecked={checked} value="1" onChange={ChangeCheckBox}/></th>
											</tr>
										)
									})}
								</thead>
							</table>
						</div>
						<div>
							<MyButtons.MySimple label="Salva" handleClick={Conferma}/>&nbsp;
							<MyButtons.MyAnnulla handleClick={props.onRequestClose} />
						</div>
					</div>
				</div>
			</Modal>
		</>
	)
}