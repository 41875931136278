import React, { useState, useEffect, useCallback } from "react";
import * as Constants from "../../constants";
import { useHistory, withRouter, useRouteMatch } from "react-router-dom";
import ContentTable from "./ContentTable";
import * as MyButtons from "../Forms/MyButtons";
import ModalConfirm from "../Forms/ModalConfirm";

function Consorziati(props) {
  const { url, path } = useRouteMatch();
  const history = useHistory();

  const [state, setState] = useState({});
  const [listConsorziati, setConsorziati] = useState([]);
  const [listClienti, setClienti] = useState([]);
  const [listClientiFiltrata, setClientiFiltrati] = useState([]);
  const [readOnlyConsorziato, setReadOnlyConsorziato] = useState(true);
  const [isModalConfirmOpen, setModalConfirm] = useState(false);
  const [selectedConsorzio, setSelectedConsorzio] = useState();

  useEffect(() => {
    fetch_clienti();
  }, [props.clienteId]);

  useEffect(() => {
    fetch_consorziati();
  }, [listClienti, state]);

  const forceUpdate = React.useCallback(() => {
    setState({ key: Math.random() });
  }, []);

  const changeRoute = useCallback(
    (row) => {
      console.log("ROW ", row);
      // history.push({pathname: "/"});
      history.push({ pathname: "/Clienti/" + row.nome, state: row });
    },
    [url, history]
  );

  const changeState = useCallback(
    (row) => {
      console.log("CHANGE STATE ", row);
      history.push({ pathname: "/" });
      history.replace({ pathname: url + "/" + row.nome, state: row });
    },
    [url, history]
  );

  const fetch_clienti = async () => {
    var api_path = Constants.Compose([Constants.PATH_CLIENTI]);
    api_path +=
      "?filter=" +
      JSON.stringify({ where: { tipoClienteId: [7] }, order: ["acronimo"] });
    const response_data = await Constants.getData(api_path);
    setClienti(response_data);
  };

  const fetch_consorziati = async () => {
    var api_path = Constants.Compose([
      Constants.PATH_CLIENTI,
      props.clienteId,
      "inConsorzi",
    ]);
    api_path +=
      "?filter=" +
      JSON.stringify({
        include: [
          {
            relation: "consorzio",
            scope: { order: ["acronimo"], include: "tipoCliente" },
          },
        ],
      });
    const response_data = await Constants.getData(api_path);
    var list = [];
    for (var i in response_data) {
      var cons = response_data[i];
      list.push({
        acronimo: cons.consorzio.acronimo,
        nome: cons.consorzio.nome,
        clienteId: cons.consorzio.clienteId,
        tipoCliente: cons.consorzio.tipoCliente.tipoCliente,
        consorziatoId: cons.consorziatoId,
        data_cons: cons.consorzio,
      });
      console.log("LIST:", list);
    }

    var listClientiFiltrata = [];
    for (var i in listClienti) {
      var cli = listClienti[i];
      var gia_associato = list.find((obj) => {
        return obj.clienteId == cli.clienteId;
      });
      if (!gia_associato) listClientiFiltrata.push(cli);
    }
    setConsorziati(list);
    setClientiFiltrati(listClientiFiltrata);
  };

  const handleChangeConsorzio = (e) => {
    setSelectedConsorzio(e.target.value);
  };

  const closeModalConfirm = () => {
    setModalConfirm(false);
  };
  const openModalConfirm = (e) => {
    setState({ consorziatoToDelete: e.consorziatoId });
    setModalConfirm(true);
  };

  const handleEditClickConsorziato = () => {
    setReadOnlyConsorziato(!readOnlyConsorziato);
  };
  const handlePostConsorziato = async () => {
    if (!selectedConsorzio) return;
    var api_path = Constants.Compose([
      Constants.PATH_CLIENTI,
      props.clienteId,
      "inConsorzi",
    ]);
    const response_data = await Constants.postData(
      api_path,
      JSON.stringify({ clienteId: selectedConsorzio })
    );

    forceUpdate();
  };
  const handleDeleteConsorziato = async () => {
    var api_path = Constants.Compose([
      Constants.PATH_CLIENTI,
      props.clienteId,
      "inConsorzi",
      state.consorziatoToDelete,
    ]);
    const response_data = await Constants.deleteData(api_path);
    forceUpdate();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Acronimo",
        accessor: "acronimo",
      },
      {
        Header: "Nome",
        accessor: "nome",
      },
      {
        Header: "Cancella",
        delete: 1,
      },
    ],
    []
  );

  return (
    <>
      <div class="border-0 top_commandi" style={{ textAlign: "right" }}>
        {readOnlyConsorziato ? (
          <MyButtons.MyModifica handleClick={handleEditClickConsorziato} />
        ) : (
          <MyButtons.MyAnnulla handleClick={handleEditClickConsorziato} />
        )}
      </div>
      {!readOnlyConsorziato && (
        <div class="form-group row">
          <div class="col-lg-10">
            <select
              name="clienteId"
              class="form-control"
              onChange={handleChangeConsorzio}
            >
              <option value="" selected="selected">
                Seleziona consorzio
              </option>
              {listClientiFiltrata.map((obj) => (
                <option value={obj["clienteId"]}>{obj["acronimo"]}</option>
              ))}
            </select>
          </div>
          <div class="col-lg-2">
            <MyButtons.MyAggiungi
              handleClick={handlePostConsorziato}
              label=" "
            />
          </div>
        </div>
      )}
      <ContentTable
        key={state.key}
        columns={columns}
        title="Consorziati"
        dataToDisplay={listConsorziati}
        execToDelete={openModalConfirm}
        changeRoute={changeRoute}
        consorziatiData={true}
        defaultOrderBy={"acronimo"}
        easy={1}
        readOnly={readOnlyConsorziato}
      />
      <ModalConfirm
        isModalConfirmOpen={isModalConfirmOpen}
        message="Il cliente sta per essere rimosso da questo consorzio."
        closeModalConfirm={closeModalConfirm}
        handleConfirm={handleDeleteConsorziato}
      />
    </>
  );
}

export default withRouter(Consorziati);
