import "./App.css";
import React, { useContext, createContext, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Header, HeaderMobile, Content, Footer } from "./components";
import Login from "./Login";

function logout() {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("roles");
  sessionStorage.removeItem("nome_utente");
}

function setRoles(roles) {
  sessionStorage.setItem("roles", JSON.stringify(roles));
}

function setToken(token) {
  sessionStorage.setItem("token", token);
}
function setUtente(nome_utente) {
  sessionStorage.setItem("nome_utente", nome_utente);
}

function getRoles() {
  const rolesString = sessionStorage.getItem("roles");
  const userRoles = JSON.parse(rolesString);
  return userRoles?.roles;
}

export function getToken() {
  const tokenString = sessionStorage.getItem("token");
  return tokenString;
}

export function getUtente() {
  const nome_utente = sessionStorage.getItem("nome_utente");
  return nome_utente;
}

export function PrivateRoute({ children, ...rest }) {
  const isAuthenticated = getToken();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isAuthenticated ? (
          <Redirect to={{ pathname: "/Login", state: { from: location }, }} />
        ) : (
          children
        )
      }
    />
  );
}

function App() {
  const NotFound = () => <h1>Page Not Found</h1>;
  // const [actualToken, setActualToken] = useState(getToken());
  const actualToken = '';
  const nome_utente = getUtente();

  return (
    <>
      <Router>
        <Switch>
          <PrivateRoute exact path="/test1">
            <HeaderMobile />
            <div class="d-flex flex-column flex-root">
              <Header user={"admin"} logout={logout} />
              <Content actualPath="test1" />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute exact path="/test2">
            <HeaderMobile />
            <div class="d-flex flex-column flex-root">
              <Header user={"admin"} logout={logout} />
              <Content actualPath="test2" />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute exact path="/">
            <HeaderMobile />
            <div class="d-flex flex-column flex-root">
              <Header user={nome_utente} logout={logout} />
              <Content actualPath="Dashboard" />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute exact path="/Dashboard">
            <HeaderMobile />
            <div class="d-flex flex-column flex-root">
              <Header user={nome_utente} logout={logout} />
              <Content actualPath="Dashboard" />
              <Footer />
            </div>
          </PrivateRoute>
          <Route exact path="/Login">
            <Login setToken={setToken} setUtente={setUtente} setRoles={setRoles} />
          </Route>
          <PrivateRoute path="/Banche">
            <HeaderMobile />
            <div class="d-flex flex-column flex-root">
              <Header user={nome_utente} logout={logout} />
              <Content actualPath="Banche" token={actualToken} />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute path="/Bandi">
            <HeaderMobile />
            <div class="d-flex flex-column flex-root">
              <Header user={nome_utente} logout={logout} />
              <Content actualPath="Bandi" token={actualToken} />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute path="/Enti Erogatori">
            <HeaderMobile />
            <div class="d-flex flex-column flex-root">
              <Header user={nome_utente} logout={logout} />
              <Content actualPath="Enti Erogatori" token={actualToken} />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute path="/ETS">
            <HeaderMobile />
            <div class="d-flex flex-column flex-root">
              <Header user={nome_utente} logout={logout} />
              <Content actualPath="ETS" token={actualToken} />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute path="/Clienti">
            <HeaderMobile />
            <div class="d-flex flex-column flex-root">
              <Header user={nome_utente} logout={logout} />
              <Content actualPath="Clienti" />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute path="/Responsabili Commessa">
            <HeaderMobile />
            <div class="d-flex flex-column flex-root">
              <Header user={nome_utente} logout={logout} />
              <Content actualPath="Responsabili Commessa" token={actualToken} />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute path="/Progetti">
            <HeaderMobile />
            <div class="d-flex flex-column flex-root">
              <Header user={nome_utente} logout={logout} />
              <Content actualPath="Progetti" />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute path="/Utenti">
            <HeaderMobile />
            <div class="d-flex flex-column flex-root">
              <Header user={nome_utente} logout={logout} />
              <Content actualPath="Utenti" />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute path="*">
            <NotFound />
          </PrivateRoute>
        </Switch>
      </Router>

      {/* <!--begin::Global Theme Bundle(used by all pages)--> */}
      <script src="./assets/plugins/global/plugins.bundle.js?v=7.0.6"></script>
      <script src="./assets/plugins/custom/prismjs/prismjs.bundle.js?v=7.0.6"></script>
      <script src="%PUBLIC_URL%/assets/js/scripts.bundle.js?v=7.0.6"></script>
      {/* <!--end::Global Theme Bundle--> */}

      {/* <!--begin::Page Vendors(used by this page)--> */}
      <script src="./assets/plugins/custom/fullcalendar/fullcalendar.bundle.js?v=7.0.6"></script>
      {/* <!--end::Page Vendors--> */}

      {/* <!--begin::Page Scripts(used by this page)--> */}
      <script src="./assets/js/pages/widgets.js?v=7.0.6"></script>
      {/* <!--end::Page Scripts--> */}
    </>
  );
}

export default App;
