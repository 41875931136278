import React, { useState, useEffect, useCallback } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as MyForms from "./MyForms";
import * as MyButtons from "./MyButtons";
import Modal from "react-modal";
import * as Constants from "../../constants";
import ContentTable from "../Tables/ContentTable";
import SchedaAttivita from "./SchedaAttivita";
import ModalConfirm from "./ModalConfirm";

Modal.setAppElement("#root");

export default function OrAttivita(props) {
	const [fields, updateFields] = useState({}); //I campi verranno inizializzati sull'apertura del ModalAttivita
	const [state, setState] = useState({});

	const [readOnly, setReadOnly] = useState(props.readOnly);
	const [nuovaAttivita, setNuovaAttivita] = useState(false);
	const [isModalConfirmOpen, setModalConfirm] = useState(false);
	const [isModalAttivitaOpen, setModalAttivitaOpen] = useState(false);
	const [dataToDisplay, setDataToDisplay] = useState([]);
	const [progettoOrId, setProgettoOrId] = useState();
	const [selectedAttivita, setDataAttivita] = useState({});

	useEffect(() => {
		if(!props.nuovaTab) {
			setProgettoOrId(props.progettoOr.progettoOrId);
			var api_path_attivita = Constants.Compose([Constants.PATH_PROGETTOOR, props.progettoOr.progettoOrId, Constants.PATH_ATTIVITA]);
			api_path_attivita += "?filter=" + JSON.stringify({include:['tipoAttivita', {'partners':'cliente'}]});
			fetchdata(api_path_attivita);
		}
	}, [props.progettoOr, state]);

	const fetchdata = async (fetchUrl) => {
		const response_data = await Constants.getData(fetchUrl);
		var dTd = [];
		for(var i in response_data) {
			var res = response_data[i];
			var list_partners = "";
			for(var j in res.partners) {
				list_partners += res.partners[j].cliente.acronimo + " ";
			}
			dTd.push({
				"attivitaId": res.attivitaId,
				"numero": res.numero,
		  		"tipoAttivita": res.tipoAttivita.tipoAttivita,
		  		"tipoAttivitaId": res.tipoAttivitaId,
		  		"descrizione": res.descrizione,
		  		"list_partners": list_partners,
		  		"meseInizio": res.meseInizio,
		  		"meseFine": res.meseFine,
		  		"durata": res.durata,
			});
		}
		setDataToDisplay(dTd);
	};

	const [divStyle, setDivStyle] = useState(
		props.nuovaTab ? "collapse show" : "collapse"
	);

	const forceUpdate = React.useCallback(() => {
		setState({ key: Math.random() });
	}, []);

	const handleEditClick = () => {
		setReadOnly(!readOnly);
	};

	const openModalConfirm = (e) => {
		setModalConfirm(true);
	};
	const closeModalConfirm = () => {
		setModalConfirm(false);
	};

	const closeNewTab = () => {
		props.setNewTab(false);
	};

	const openModalAttivita = (row_original) => {
		if(row_original.attivitaId) {
			setDataAttivita(row_original)
			setNuovaAttivita(false);
		} else {
			setNuovaAttivita(true);
		}
		setModalAttivitaOpen(true);
	};

	const closeModalAttivita = () => {
		setModalAttivitaOpen(false);
	};


	const columns = React.useMemo(
	  () => [
		{
		  Header: "Numero attivita'",
		  accessor: "numero",
		},
		{
		  Header: "Tipo",
		  accessor: "tipoAttivita",
		},
		{
		  Header: "Descrizione",
		  accessor: "descrizione",
		},
		{
		  Header: "Partners",
		  accessor: "list_partners",
		},
		{
		  Header: "Mese Inizio",
		  accessor: "meseInizio",
		},
		{
		  Header: "Mese Fine",
		  accessor: "meseFine",
		},
		{
		  Header: "Durata",
		  accessor: "durata",
		},
	  ],
	  []
	);



	return (
		<>
			<div class="card">
				<div class="card-header">
					{props.nuovaTab ? (
						<div class="card-title" data-toggle="collapse" data-target={"#collapseprogettoor" + props.dataId} >
							<i class=" flaticon2-start-up"></i> Nuovo OR
						</div>
					) : (
						<div class="card-title collapsed" data-toggle="collapse" data-target={"#collapseprogettoor" + props.dataId} >
							<i class=" flaticon2-start-up"></i> OR {props.progettoOr.numero} - {props.progettoOr.descrizione}
						</div>
					)}
				</div>
				<div id={"collapseprogettoor" + props.dataId} class={divStyle} data-parent="#accordionProgettoOr" >
					<div class="card-body">
						<Formik
							enableReinitialize={true}
							initialValues={
								!props.nuovaTab ? {
									numero: props.progettoOr.numero,
									descrizione: props.progettoOr.descrizione,
								} : {
									numero: "",
									descrizione: "",
								}
							}
							validationSchema={Yup.object({
								numero: Yup.number(),
								descrizione: Yup.string(),
							})}
							onSubmit={(values, { setSubmitting }) => {
								!props.nuovaTab
									? props.handleEdit(
											props.progettoOr.progettoOrId,
											values
										)
									: props.handlePost(values);

								setReadOnly(true);
							}}
							onReset={() => {
								setReadOnly(true);
							}}
						>
							<Form class="form">
								<div class="border-0 top_commandi bp-5" style={{ textAlign: "right" }} >
									{readOnly ? (
										<div>
											<MyButtons.MyModifica handleClick={handleEditClick} label="Modifica OR"/>
											&nbsp;&nbsp;
											<MyButtons.MyAggiungi handleClick={openModalAttivita} label="Nuova Attivita"/>
										</div>
									) : (
										<div>
											{/* Se si sta inserendo una nuova scheda il tasto Cancella è invisibile ed il tasto
										annulla riporta all'elenco. Se invece siamo in modifica il tasto cancella è visibile
										ed il tasto annulla disabilita l'editing rimanendo sulla scheda*/}
											{!props.nuovaTab ? (
												<>
													<MyButtons.MyAnnulla/>
													&nbsp;&nbsp;
													<MyButtons.MyCancella handleClick={openModalConfirm}/>
												</>
											) : (
												<MyButtons.MyAnnulla handleClick={closeNewTab}/>
											)}
											&nbsp;&nbsp;
											<MyButtons.MySalva/>
										</div>
									)}
								</div>
								{!readOnly && (
								<div class="form-group row">
									<div class="col-lg-4">
										<MyForms.MyTextInput
											label="Numero"
											name="numero"
											class="form-control"
											disabled={readOnly}
										/>
										<span class="form-text text-muted"></span>
									</div>
									<div class="col-lg-4">
										<MyForms.MyTextInput
											label="Descrizione"
											name="descrizione"
											class="form-control"
											disabled={readOnly}
										/>
										<span class="form-text text-muted"></span>
									</div>
								</div>

								)}
							</Form>
						</Formik>
						<div>
						{!props.nuovaTab && (
							<ContentTable
								key={state.key}
								columns={columns}
								title="Attivita"
								easy = "1"
								// fetchUrl={api_path_attivita}
								dataToDisplay={dataToDisplay}
								openModal={openModalAttivita}
								defaultOrderBy={"numero"}
							/>
						)}
						</div>
					</div>
				</div>
			</div>
			{!props.nuovaTab && (
				<SchedaAttivita 
					isModalAttivitaOpen={isModalAttivitaOpen}
					attivita={selectedAttivita} 
					{...props}
					forceUpdate={forceUpdate}
					nuovaAttivita={nuovaAttivita}
					onRequestClose={closeModalAttivita}
					setNuovaAttivita={setNuovaAttivita}
				/>
			)}
			<ModalConfirm
                isModalConfirmOpen={isModalConfirmOpen}
                closeModalConfirm={closeModalConfirm}
                handleConfirm={props.handleDelete}
				idToDelete={progettoOrId}
            />
		</>
	);
}
