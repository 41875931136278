import React, { useState, useEffect, useCallback } from "react";
import Modal from "react-modal";
import ContentTable from "../Tables/ContentTable";
import * as Constants from "../../constants";

Modal.setAppElement("#root");

export default function ModalContatti(props) {
	const [dataToDisplay, setData] = useState([]);
	const [progetto, setProgetto] = useState({});
	useEffect(() => {
		if(props.progetto) {
			setProgetto(props.progetto);
			fetchReferenti();
		}
	}, [props.isModalContattiOpen]);

    const fetchReferenti = async () => {
		const response_data = await Constants.getData(Constants.Compose([Constants.PATH_PROGETTI, props.progetto.progettoId, 'contatti']));
		let list = [];
		for(var i in response_data) {
			var ref = response_data[i];
			for(var j in ref.persona) {
				let persona = ref.persona[j]
				list.push({
					'acronimo': ref.acronimo,
					'nome': persona.nome,
					'cognome': persona.cognome,
					'email': persona.email,
					'email2': persona.email2,
					'telefono': persona.telefono,
					'telefono2': persona.telefono2,
				});
			}
		}
		setData(list);
    };
	const columns = React.useMemo( () => [
		{
			Header: "Partner",
			accessor: "acronimo",
		},
		{
			Header: "Cognome",
			accessor: "cognome",
		},
		{
			Header: "Nome",
			accessor: "nome",
		},
		{
			Header: "Telefono",
			accessor: "telefono",
		},
		{
			Header: "Telefono 2",
			accessor: "telefono2",
		},
		{
			Header: "Email 2",
			accessor: "email2",
		},
		{
			Header: "Email",
			accessor: "email",
		},
	], []);

    return (
		<Modal
			isOpen={props.isModalContattiOpen}
			onRequestClose={props.closeModalContatti}
			style={{
				// overlay: {
				// 	width: "150",
				// },
				content: {
					top: "10%",
					left: "10%",
					right: "10%",
					bottom: "auto",
				},
			}}
		>
			<div class="modal-content">
                <div class="modal-header">
					<h5>Contatti del progetto {progetto.acronimo}</h5>
                    <button type="button" class="close" onClick={props.closeModalContatti} >
                        <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>
                <div class="modal-body">
				<ContentTable
					columns={columns}
					dataToDisplay={dataToDisplay}
					defaultOrderBy={"acronimo"}
				/>
                </div>
            </div>
		</Modal>
    )
}