import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import * as MyForms from "./MyForms";
import Modal from "react-modal";
import ModalConfirm from "./ModalConfirm";
import * as MyButtons from "./MyButtons";
import AttachedFiles from "./AttachedFiles";
import * as Constants from "../../constants";
import * as Utils from "./Utils";

/* Funzione per il render del tab */
export default function FormAllegato(props) {
	const [readOnly, setReadOnly] = useState(props.readOnly);
	const [state, setState] = useState({});
	const [selectedFile, setSelectedFile] = useState(null);
	const [isModalConfirmOpen, setModalConfirm] = useState(false);
	const [allegato, setAllegato] = useState({});

	useEffect(() => {
		setState({'altro':0})
		if(props.allegato) {
			setAllegato(props.allegato.fileName);
			onChangeAllegatoHandler(props.allegato.tipoAllegatoId)
		}
	}, [props.allegato, props.nuovoAllegato]);

	const openModalConfirm = (e) => {
		setModalConfirm(true);
	};
	const closeModalConfirm = () => {
		setModalConfirm(false);
	};

	const onChangeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const onChangeAllegatoHandler = (tipoAllegatoId) => {
		if(tipoAllegatoId == 17) {
			setState({'altro': 1})
		} else {
			setState({'altro': 0})
		}
	};

	const handleEdit = async (allegatoId, dataToSend) => {
		var api_path = Constants.Compose([Constants.PATH_PROGETTI, props.progettoId, Constants.PATH_ALLEGATI, allegatoId]);
		const response_data = await Constants.putData(api_path, dataToSend);
		props.onRequestClose();
		props.forceUpdate();
	};

	const handleDelete = async (idToDelete) => {
		var api_path = Constants.Compose([Constants.PATH_PROGETTI, props.progettoId, Constants.PATH_ALLEGATI, idToDelete]);
		var response_data = await Constants.deleteData(api_path);
		setModalConfirm(false);
		props.onRequestClose();
		props.forceUpdate();
	}

	const handlePost = async (dataToSend) => {
		// Upload del file
		const data = new FormData();
		data.append("allegato", selectedFile);
		data.append("tipoAllegatoId", dataToSend.tipoAllegatoId);
		data.append("data", dataToSend.data);
		data.append("note", dataToSend.note);
		var api_path = Constants.Compose([Constants.PATH_UPLOADALLEGATO]);
		var response_data = await Constants.postData(api_path, data);
		var allegatoId = response_data.allegatoId;

		// Creazione del link col Progetto
		var api_path = Constants.Compose([Constants.PATH_PROGETTIALLEGATI]);
		var response_data = await Constants.postData(api_path, JSON.stringify({allegatoId: response_data.allegatoId, progettoId: props.progettoId}));

		props.onRequestClose();
		props.forceUpdate();
	};

	return (
		<>
			<Modal
				isOpen={props.isModalOpen}
				onRequestClose={props.closeModal}
				style={{
					overlay: {
						width: "150",
					},
					content: {
						color: "lightsteelblue",
						top: "5%",
						left: "30%",
						right: "30%",
						bottom: "10%",
					},
				}}
			>
				<div className="modal-content">
					<div className="modal-header">
						{props.nuovoAllegato ? 
							<h5>Nuovo Documento </h5>
						: 
							<h5>{allegato} </h5>
						}
						<MyButtons.MyClose handleClick={props.onRequestClose}/>
					</div>
					<div className="modal-body">
						<Formik
							enableReinitialize={true}
							initialValues={
								!props.nuovoAllegato
									? {
										tipoAllegatoId: props.allegato.tipoAllegatoId,
										data: props.allegato.data,
										note: props.allegato.note
									} : {
										tipoAllegatoId: "",
										note: "",
										data: ""
									}
							}
							validationSchema={Yup.object({
								tipoAllegatoId: Yup.string().required("Campo obbligatorio"),
								data: Yup.date()
							})}
							onSubmit={(values, { setSubmitting }) => {
								!props.nuovoAllegato
									? handleEdit(
											props.allegato.allegatoId,
											JSON.stringify(values, null, 2)
										)
									: handlePost(values);
								props.nuovoAllegato = false;
								setReadOnly(false);
							}}
							onReset={() => {
								setReadOnly(true);
							}}
						>
							<Form class="form">
								<div class="border-0 top_commandi bp-5" style={{ textAlign: "right" }} >
									<div>
										<MyButtons.MyAnnulla handleClick={props.onRequestClose}/>
										{!props.nuovoAllegato && (
											<>
												&nbsp;&nbsp;
												<MyButtons.MyCancella handleClick={openModalConfirm}/>
											</>
										)}
										&nbsp;&nbsp;
										<MyButtons.MySalva/>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-lg-6">
										<MyForms.MySelect
											label="Tipo Documento"
											name="tipoAllegatoId"
											class="form-control"
											disabled={readOnly}
											list={props.tipiAllegato}
											nameLabel="tipoAllegato"
											onClick={onChangeAllegatoHandler}
										/>
										<span class="form-text text-muted"></span>
									</div>
									<div class="col-lg-6">
										<label><strong>Data</strong></label>
										<div class="input-group date">
											<MyForms.MyDatePickerField
												name="data"
												class="form-control"
												disabled={readOnly}
											/>
										</div>
										<span class="form-text text-muted"></span>
									</div>
								</div>
								{(state.altro == 1) && (
									<div class="form-group row">
										<div class="col-lg-8">
											<MyForms.MyTextInput
												label="Note"
												name="note"
												width="100%"
												class="form-control"
												disabled={readOnly}
											/>
											<span class="form-text text-muted"></span>
										</div>
									</div>
								)}
							</Form>
						</Formik>
						<div class="form-group row">
							<div class="col-lg-6">
								{props.nuovoAllegato ? (
									<div class="form-group files">
										<label><strong>Carica nuovo allegato</strong></label>
										<div class="row">
											<div class="col-lg-8">
												<form method="post" action="#" id="#">
													<input type="file" class="form-control" multiple="" onChange={onChangeHandler} />
												</form>
											</div>
										</div>
									</div>
								) : (
									<>
      		                  		<label>Allegato:</label>
      		                  		<div class="dati" modalita="view">
					                	<span className="link" onClick={() => {Utils.downloadAllegato(props.allegato.allegatoId, allegato)}}>{allegato}</span>
      		                  		</div>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<ModalConfirm
				isModalConfirmOpen={isModalConfirmOpen}
				closeModalConfirm={closeModalConfirm}
				handleConfirm={handleDelete}
				idToDelete={props.allegato.allegatoId}
			/>
		</>
	);
}
