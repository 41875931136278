import React, { useCallback } from "react";
import * as Constants from "../../constants";
import {
  useHistory,
  withRouter,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import ContentTable from "./ContentTable";
import SchedaETS from "../Forms/SchedaETS";
import { PrivateRoute } from "../../../App";

function ETS(props) {
  const { url, path } = useRouteMatch();

  const history = useHistory();

  const changeRoute = useCallback(
    (row) => {
      console.log(row.nome);
      history.push({ pathname: url + "/" + row.nome, state: row });
    },
    [url, history]
  );
  const changeState = useCallback(
    (row) => {
      history.push({pathname: "/"});
      history.replace({ pathname: url + "/" + row.nome, state: row });
    },
    [url, history]
   );

  const backFromScheda = useCallback(() => {
    history.push({ pathname: "/ETS" });
  }, [history]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "personaId", // accessor è la chiave in data
      },
      {
        Header: "Nome",
        accessor: "nome",
      },
      {
        Header: "Cognome",
        accessor: "cognome",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Pec",
        accessor: "pec",
      },
    ],
    []
  );

  return (
    <Switch>
      <PrivateRoute exact path="/ETS">
        <ContentTable
          columns={columns}
          title="ETS"
          fetchUrl={Constants.GETETSURL}
          changeRoute={changeRoute}
          defaultOrderBy={"nome"}
        />
      </PrivateRoute>
      <PrivateRoute exact path={"/ETS/Nuovo ETS"}>
        <SchedaETS
          backFromScheda={backFromScheda}
          nuovaScheda={true}
          changeState={changeState}
        />
      </PrivateRoute>
      <PrivateRoute exact path={"/ETS/:nome"}>
        <SchedaETS
          backFromScheda={backFromScheda}
          nuovaScheda={false}
        />
      </PrivateRoute>
    </Switch>
  );
}

export default withRouter(ETS);
