import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as MyForms from "./MyForms";
import * as Constants from "../../constants";
import ContentTable from "../Tables/ContentTable";
import RendicontazionePartner from "./RendicontazionePartner";
import ModalConfirm from "./ModalConfirm";
import * as MyButtons from "./MyButtons";

/* Funzione per il render del tab */
export default function RendicontazioneTab(props) {
	const [state, setState] = useState({});
	const [readOnly, setReadOnly] = useState(props.readOnly);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isModalConfirmSal, setModalConfirmSal] = useState(false);
    const [isModalConfirmApprovazioneOpen, setModalConfirmApprovazioneOpen] = useState(false);
    const [isModalConfirmRiaperturaOpen, setModalConfirmRiaperturaOpen] = useState(false);
    const [isModalConfirmApprovataOpen, setModalConfirmApprovataOpen] = useState(false);
	const [SalReadOnly, setSalReadOnly] = useState(false);
	const [listPartner, setListPartner] = useState([]);
	const [mySal, setSal] = useState([]);
	const [tipoStatoRendicontazione, setStatoRendicontazione] = useState();
	const [partner, setPartner] = useState({});
	const [divStyle, setDivStyle] = useState(
		props.nuovaTab ? "collapse show" : "collapse"
	);

	useEffect(() => {
		if(props.sal) {
			fetchSal();
		}
	}, [props.sal, state]);

	useEffect(() => {
		if(mySal.progettoId) fetchPartners();
	}, [mySal, state]);

	const forceUpdate = React.useCallback(() => {
		setState({ key: Math.random() });
	}, []);

	const fetchSal = async () => {
		var api_path = Constants.Compose([Constants.PATH_SAL, props.sal.salId]);
		api_path += "?filter=" + JSON.stringify({include: ['tipoStatoRendicontazione'], order:['numero']});
		const sal = await Constants.getData(api_path);
		setSal(sal);
		setStatoRendicontazione(sal.tipoStatoRendicontazione.tipoStatoRendicontazione);
		if(sal.tipoStatoRendicontazioneId == 5) setSalReadOnly(true);
	}
	const fetchPartners = async () => {
		var api_path = Constants.Compose([Constants.PATH_PROGETTI, mySal.progettoId, Constants.PATH_PARTNERS_INREL]);
		api_path += "?filter=" + JSON.stringify({'include':['cliente', 'partnerDipendenti', {'relation': 'rendicontazioni', 'scope': {'where': {'salId': mySal.salId}, 'include': 'tipoStatoRendicontazione'}}]});
		const response_data = await Constants.getData(api_path);
		response_data.map(ele => {
			ele.rendicontazioneId = undefined;
			if(ele.rendicontazioni[0]) ele.rendicontazioneId = ele.rendicontazioni[0].rendicontazioneId;
		});
		setListPartner(response_data);
	};

	const handleEditClick = () => {
		setReadOnly(!readOnly);
	};
	const closeNewTab = () => {
		// props.setNewTab(false);
	};
	const handleConfermaRendicontazioneRiaperta = async () => {
			var api_path = Constants.Compose([Constants.PATH_SAL, mySal.salId, 'riapri']);
			const response_data = await Constants.postData(api_path);
			forceUpdate();
	}
	const handleConfermaRendicontazioneApprovata = async () => {
		var api_path = Constants.Compose([Constants.PATH_SAL, mySal.salId, 'approvato']);
		const response_data = await Constants.postData(api_path);
		forceUpdate();
	}
	const handleConfermaRendicontazioneApprovazione = async () => {
		var api_path = Constants.Compose([Constants.PATH_SAL, mySal.salId, 'inapprovazione']);
		const response_data = await Constants.postData(api_path);
		forceUpdate();
	}
	const openModalConfirmRiapertura = () => {
		setModalConfirmRiaperturaOpen(true);
	};
	const openModalConfirmApprovazione = () => {
		setModalConfirmApprovazioneOpen(true);
	};
	const openModalConfirmApprovata = () => {
		setModalConfirmApprovataOpen(true);
	};
	const openModalConfirmSal = () => {
		setModalConfirmSal(true);
	};

	const handleDeleteSal = async (salId) => {
		var api_path = Constants.Compose([Constants.PATH_SAL, salId]);
		const response_data = await Constants.deleteData(api_path);
		props.forceUpdate();
	};
	const closeModalConfirmRiapertura = () => {
		setModalConfirmRiaperturaOpen(false);
	};
	const closeModalConfirmApprovazione = () => {
		setModalConfirmApprovazioneOpen(false);
	};
	const closeModalConfirmApprovata = () => {
		setModalConfirmApprovataOpen(false);
	};
	const closeModalConfirmSal = () => {
		setModalConfirmSal(false);
	};

	const openModal = (row_original) => {
		setPartner(row_original);
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
		forceUpdate();
	};

	const columns = React.useMemo(
		() => [
		{
			Header: "Partner",
			accessor: "cliente.acronimo",
		},
		{
		  	Header: "Numero di dipendenti nel progetto",
		  	accessor: "partnerDipendenti",
		  	Cell: row => {
				return row.value.length
		  	}
			},
			{
		  	Header: "Rendicontazione",
		  	accessor: "rendicontazioni",
		  	Cell: row => {
				var rendicontazione = row.value;
				if(!rendicontazione[0]) 
					return  <span class="label label-lg label-light-warning label-inline"> Da rendicontare </span>
				else if(rendicontazione[0].tipoStatoRendicontazioneId < 2) 
					return  <span class="label label-lg label-light-warning label-inline"> {rendicontazione[0].tipoStatoRendicontazione.tipoStatoRendicontazione} </span>
				else 
					return  <span class="label label-lg label-light-success label-inline"> {rendicontazione[0].tipoStatoRendicontazione.tipoStatoRendicontazione} </span>
		  	}
			},
	  	],
	  	[]
		);
	return (
		<>
			<div class="card">
				<div class="card-header">
					{props.nuovaTab ? (
						<div class="card-title" data-toggle="collapse" data-target={"#collapsesal" + props.dataId} >
							<i class=" flaticon2-start-up"></i> Nuovo SAL
						</div>
					) : (
						<div class="card-title collapsed" data-toggle="collapse" data-target={"#collapsesal" + props.dataId} >
							<i class=" flaticon2-start-up"></i> SAL {mySal.numero} (durata {mySal.durata} mesi ) - {tipoStatoRendicontazione}
						</div>
					)}
				</div>
				<div id={"collapsesal" + props.dataId} class={divStyle} data-parent="#accordionRendicontazione" >
					<div class="card-body">
						<Formik
							enableReinitialize={true}
							initialValues={
								!props.nuovaTab ? {
									numero: mySal.numero,
									durata: mySal.durata,
								} : {
									numero: "",
									durata: "",
								}
							}
							validationSchema={Yup.object({
								numero: Yup.number(),
								durata: Yup.number(),
							})}
							onSubmit={(values, { setSubmitting }) => {
								!props.nuovaTab
									? props.handleEdit(
											mySal.salId,
											values
										)
									: props.handlePost(values);

								setReadOnly(true);
							}}
							onReset={() => {
								setReadOnly(true);
							}}
						>
							<Form class="form">
								<div class="border-0 top_commandi pb-5" style={{ textAlign: "right" }} >
									{readOnly ? (
										<div>
										{mySal.tipoStatoRendicontazioneId == 3 && (
											<>
												<MyButtons.MySimple label="Rendicontazione SAL inviata per approvazione" handleClick={openModalConfirmApprovazione}/> &nbsp;
											</>
										)}
										{mySal.tipoStatoRendicontazioneId == 4 && (
											<>
												<MyButtons.MySimple label="Rendicontazione SAL approvata" handleClick={openModalConfirmApprovata}/> &nbsp;
											</>
										)}
										{mySal.tipoStatoRendicontazioneId <= 3 && (
											<MyButtons.MyModifica label="Modifica SAL" handleClick={handleEditClick}/>
										)}
										{mySal.tipoStatoRendicontazioneId > 4 && (
											<MyButtons.MyModifica label="Riapri rendicontazione" handleClick={openModalConfirmRiapertura}/>
										)}
										</div>
									) : (
										<div>
											{/* Se si sta inserendo una nuova scheda il tasto Cancella è invisibile ed il tasto
										annulla riporta all'elenco. Se invece siamo in modifica il tasto cancella è visibile
										ed il tasto annulla disabilita l'editing rimanendo sulla scheda*/}
											{!props.nuovaTab ? (
												<>
													<MyButtons.MyAnnulla handleClick={handleEditClick}/>
													&nbsp;&nbsp;
													<MyButtons.MyCancella handleClick={openModalConfirmSal} />
												</>
											) : (
												<MyButtons.MyAnnulla handleClick={closeNewTab}/>
											)}
											&nbsp;&nbsp;
											<MyButtons.MySalva/>
										</div>
									)}
								</div>
								{!readOnly && (
									<div class="form-group row">
										<div class="col-lg-4">
											<MyForms.MyTextInput
												label="Numero"
												name="numero"
												class="form-control"
												disabled={readOnly}
											/>
											<span class="form-text text-muted"></span>
										</div>
										<div class="col-lg-4">
											<MyForms.MyTextInput
												label="Durata"
												name="durata"
												class="form-control"
												disabled={readOnly}
											/>
											<span class="form-text text-muted"></span>
										</div>
									</div>

								)}
							</Form>
						</Formik>
						{readOnly && (
							<ContentTable
								key={state.key}
								columns={columns}
								easy = "1"
								dataToDisplay={listPartner}
								openModal={openModal}
								defaultOrderBy={"attivitaNumero"}
								message_nodata="Nessun partner è stato assegnato a questo sal"
							/>

						)}
					</div>
				</div>
			</div>
			<RendicontazionePartner
				key={state.key}
                isModalOpen={isModalOpen}
				sal={mySal}
				SalReadOnly={SalReadOnly}
				vocicosto={props.vocicosto}
				partner={partner}
				forceUpdate={forceUpdate}
                onRequestClose={closeModal}
            />
			<ModalConfirm
				isModalConfirmOpen={isModalConfirmApprovazioneOpen}
				closeModalConfirm={closeModalConfirmApprovazione}
				message={"Confermi che la rendicontazione del SAL " + mySal.numero + " è stata inviata per l'approvazione ?"}
				handleConfirm={handleConfermaRendicontazioneApprovazione}
			/>
			<ModalConfirm
				isModalConfirmOpen={isModalConfirmApprovataOpen}
				closeModalConfirm={closeModalConfirmApprovata}
				message={"Confermi che la rendicontazione del SAL " + mySal.numero + " è stata approvata ? I dati della rendicontazione non potranno essere più modificati"}
				handleConfirm={handleConfermaRendicontazioneApprovata}
			/>
			<ModalConfirm
				isModalConfirmOpen={isModalConfirmRiaperturaOpen}
				closeModalConfirm={closeModalConfirmRiapertura}
				message={"Confermi la riapertuta della rendicontazione del sal " + mySal.numero + " ?"}
				handleConfirm={handleConfermaRendicontazioneRiaperta}
			/>
			{!props.nuovaTab && (
				<ModalConfirm
					isModalConfirmOpen={isModalConfirmSal}
					closeModalConfirm={closeModalConfirmSal}
					handleConfirm={handleDeleteSal}
					idToDelete={props.sal.salId}
				/>
			)}
		</>
	);
}
