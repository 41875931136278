import React, { useState, useEffect, useCallback } from "react";
import Partner from "./BudgetStack";
import MonitoraggioModal from "./MonitoraggioModal";
import * as Constants from "../../constants";
import * as MyButtons from "./MyButtons";
import * as Utils from "./Utils";

export default function Monitoraggio(props) {
  const [selectedPartner, setSelectedPartner] = useState(0);
  const [selectedPartnerName, setSelectedPartnerName] = useState();
  const [isModalOpen, setModalOpen] = useState(false);
  const [listPartners, setListPartners] = useState([]);
  const [listExcel, setListExcel] = useState([]);
  const [fileExcelName, setFileExcelName] = useState();
  const [myGrid, setGrid] = useState([]);
  const [fileExcel, setFileExcel] = useState();

  useEffect(() => {
    if(props.displayTab == 2) {
      fetchPartners();
      fetchExcel();
    }
  }, [props.displayTab]);

  useEffect(() => {
    getExcelFileForPartnerId();
  }, [listExcel]);

  useEffect(() => {
    getMonitoraggio();
    getExcelFileForPartnerId();
  }, [selectedPartner]);

  const getExcelFileForPartnerId = () => {
    const result = listExcel.filter((partner) => {
      if (partner.partnerId == selectedPartner) {
        return partner.file;
      }
    });
    if (result.length > 0) setFileExcelName(result[0]);
    else setFileExcelName();
  };

  const openModal = (row_original) => {
    if (selectedPartner > 0) setModalOpen(true);
    else
      alert(
        "Funzionalita' da implementare per la visualizzazione dei dati dell'intero progetto. Seleziona un partner"
      );
  };

  const closeModal = (row_original) => {
    setModalOpen(false);
  };

  const getMonitoraggio = async () => {
    var grid = await Utils.GetMonitoraggio(props.progettoId, selectedPartner);
    if (grid[0]) setGrid(grid[0].sheet);
  };

  const fetchPartners = async () => {
    var api_path = Constants.Compose([
      Constants.PATH_PROGETTI,
      props.progettoId,
      Constants.PATH_PARTNERS_INREL,
    ]);
    api_path +=
      "?filter=" + JSON.stringify({ include: ["cliente", "partnerLivelli"] });
    const PARTNERS = await Constants.getData(api_path);
    var list = [];
    PARTNERS.map((partner) => {
      list.push({
        partnerId: partner.partnerId,
        partnerName: partner.cliente.acronimo,
      });
    });
    setListPartners(list);
  };

  const fetchExcel = async () => {
    var api_path = Constants.Compose([
      Constants.PATH_PROGETTI,
      props.progettoId,
      "listExcel",
    ]);
    const FILES = await Constants.getData(api_path);
    var list_excel_partners = [];
    list_excel_partners.push({
      partnerId: 0,
      file: FILES.progetto.file,
      date: FILES.progetto.strdate,
    });
    FILES.partners.map((partner) => {
      list_excel_partners.push({
        partnerId: partner.partnerId,
        file: partner.file,
        date: partner.strdate,
      });
    });
    // console.log("LISTA EXCEL PARTNER:", list_excel_partners);
    setListExcel(list_excel_partners);
  };

  const downloadFileExcel = async () => {
    // console.log("FETCH FILE EXCEL:", fileExcelName);
    if (fileExcelName.partnerId == 0) {
      // console.log("PROGETTOID:", props.progettoId);
      var api_path = Constants.Compose([
        Constants.PATH_PROGETTI,
        props.progettoId,
        "getExport",
      ]);
    } else {
      var api_path = Constants.Compose([
        Constants.PATH_PARTNERS,
        fileExcelName.partnerId,
        "getExport",
      ]);
    }
    const FILE_EXCEL_DATA = await Constants.getDownload(api_path);
    const url = window.URL.createObjectURL(new Blob([FILE_EXCEL_DATA]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileExcelName.file);

    // Si accoda il link al body e si avvia il download
    document.body.appendChild(link);
    link.click();

    // Si cancella il link
    link.parentNode.removeChild(link);
  };

  const handleExport = async () => {
    var excel = await Utils.Export(props.progettoId, selectedPartner);

    var api_path;
    if (selectedPartner > 0) {
      api_path = Constants.Compose([
        Constants.PATH_PARTNERS,
        selectedPartner,
        "export",
      ]);
    } else {
      api_path = Constants.Compose([
        Constants.PATH_PROGETTI,
        props.progettoId,
        "export",
      ]);
    }
    var response_data = await Constants.postDownload(api_path, excel);
    alert("L'excel è in corso di aggiornamento");
  };

  const handleChange = (e) => {
    var partnerId = e.target.value;
    setSelectedPartner(partnerId);
    var test = listPartners.find((obj) => {
      return obj.partnerId == e.target.value;
    });
    var partnerName = test ? test.partnerName : "Intero progetto";
    setSelectedPartnerName(partnerName);
  };

  return (
    <>
      <div className="modal-content">
        <div className="modal-body">
          <div class="row">
            <div class="col-lg-6">
              <label>
                <strong>Seleziona i dati per il Monitoraggio</strong>&nbsp;{" "}
              </label>
              <select
                name="partnerId"
                onChange={handleChange}
                style={{ width: "90%" }}
              >
                <option value="0">Intero progetto</option>
                {listPartners.map((obj) => (
                  <option value={obj.partnerId}>{obj.partnerName}</option>
                ))}
              </select>
            </div>
            <div class="col">
              <label>
                <strong>Download File Excel</strong>&nbsp;{" "}
              </label>

              {fileExcelName ? (
                <div
                  onClick={() => {
                    downloadFileExcel();
                  }}
                >
                  <a role="button" tabIndex="0" class="navi-link">
                    <span class="navi-icon">
                      <i class="la la-file-excel-o"></i>
                    </span>
                    <span class="navi-text">{fileExcelName.file}</span>
                  </a>
                </div>
              ) : (
                <div>
                  <span class="navi-icon"></span>
                  <span class="navi-text">No File Excel Available</span>
                </div>
              )}
            </div>
          </div>
          <div class="border-0 top_commandi" style={{ textAlign: "right" }}>
            <MyButtons.MySimple
              label="Visualizza dati"
              handleClick={openModal}
            />
            &nbsp;
            <MyButtons.MySimple
              label="Aggiorna excel"
              handleClick={handleExport}
            />
          </div>
          <div>DASHBOARD DI PROGETTO [Da implementare]</div>
          <div
            class="border-0 top_commandi bp-5"
            style={{ textAlign: "center" }}
          >
            <MonitoraggioModal
              selectedPartner={selectedPartner}
              selectedPartnerName={selectedPartnerName}
              myGrid={myGrid}
              progettoId={props.progettoId}
              isModalOpen={isModalOpen}
              onRequestClose={closeModal}
            />
          </div>
        </div>
      </div>
    </>
  );
}
