import React, { useState, useEffect } from "react";
import * as MyButtons from "./MyButtons";
import OrAttivitaTab from "./OrAttivitaTab";
import * as Constants from "../../constants";

export default function OrAttivitaStack(props) {
	const [state, setState] = useState();
	const [listOrAttivita, setlistOrAttivita] = useState([]);
	const [tipiContratti, setTipiContratti] = useState([]);
	const [newTab, setNewTab] = useState(false);
	const [idObject, setIdObject] = useState(null);

	const forceUpdate = React.useCallback(() => {
		setState({});
	}, []);

	useEffect(() => {
		if(props.displayTab == 3) {
			const fetchOrAttivita = async () => {
				var api_path = Constants.Compose([Constants.PATH_PROGETTI, props.progettoId, Constants.PATH_PROGETTOOR]);
				api_path += "?filter=" + JSON.stringify({order:['numero']});
				const response_data = await Constants.getData(api_path);
				setlistOrAttivita(response_data);
			};

			fetchOrAttivita();
			setIdObject(props.progettoId);
		}
	}, [props.clienteId, props.displayTab]);

	const handleDelete = async (idProgettoOr) => {
		var api_path = Constants.Compose([Constants.PATH_PROGETTOOR, idProgettoOr]);
		const response_data = await Constants.deleteData(api_path);
		forceUpdate();
	};

	const handleEdit = async (idProgettoOr, dataToSend) => {
		var api_path = Constants.Compose([Constants.PATH_PROGETTOOR, idProgettoOr]);
		const response_data = await Constants.putData(api_path, JSON.stringify(dataToSend));
		forceUpdate();
	};

	const handlePostProgettoOr = async (dataToSend) => {
		var api_path = Constants.Compose([Constants.PATH_PROGETTI, props.progettoId, Constants.PATH_PROGETTOOR]);
		const response_data = await Constants.postData(api_path, dataToSend);

		forceUpdate();
		setNewTab(false);
	};

	const handleNewClick = () => {
		console.log("ENTOR")
		setNewTab(true);
	};

	return (
		<div
			//class="tab-pane fade"
			//id="tab_pane_6"
			// role="tabpanel"
			// aria-labelledby="tab_pane_6"
		>
			<div class="border-0 top_commandi" style={{ textAlign: "right" }}>
				<MyButtons.MyAggiungi handleClick={handleNewClick} label="Nuovo OR"/>
			</div>
			<div class="accordion accordion-solid accordion-toggle-plus" id="accordionProgettoOr" >
				{newTab && (
					<OrAttivitaTab
						key='new'
						setNewTab={setNewTab}
						handleEdit={handleEdit}
						handlePost={handlePostProgettoOr}
						handleDelete={handleDelete}
						nuovaTab={true}
						dataId={listOrAttivita.length + 1}
						readOnly={false}
					/>
				)}
				{listOrAttivita.map((progettoOr, i) => (
					<OrAttivitaTab
						key={i}
						setNewTab={setNewTab}
						handleEdit={handleEdit}
						handlePost={handlePostProgettoOr}
						handleDelete={handleDelete}
						progettoOr={progettoOr}
						nuovaTab={false}
						dataId={i}
						readOnly={true}
					/>
				))}
			</div>
		</div>
	);
}
