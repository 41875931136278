import React, { useState, useEffect, useCallback } from "react";
import * as MyButtons from "./MyButtons";
import Modal from "react-modal";

Modal.setAppElement("#root");

export default function ModalConfirm(props) {
    return (
		<Modal
			isOpen={props.isModalConfirmOpen}
			onRequestClose={props.closeModalConfirm}
			style={{
				// overlay: {
				// 	width: "150",
				// },
				content: {
					top: "10%",
					left: "30%",
					right: "30%",
					bottom: "auto",
				},
			}}
		>
			<div class="modal-content">
                <div class="modal-header">
					<h5>Attenzione</h5>
                    <button type="button" class="close" onClick={props.closeModalConfirm} >
                        <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="modal-title">{props.message ? props.message : "L'oggetto sta per essere eliminato"}</div>
                </div>
                <div class="modal-footer">
					<MyButtons.MyAnnulla handleClick={props.closeModalConfirm} />
					<button type="button" class="btn btn-primary font-size-sm font-weight-bolder"
						onClick={() => {
							props.handleConfirm(props.idToDelete);
							props.closeModalConfirm();
						}}
					>
						Conferma
					</button>
                </div>
            </div>
		</Modal>
    )
}