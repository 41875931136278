import React, { useCallback } from "react";
import * as Constants from "../../constants";
import {
  useHistory,
  withRouter,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import ContentTable from "./ContentTable";
import SchedaBando from "../Forms/SchedaBando";
import { PrivateRoute } from "../../../App";

function Bandi(props) {
  const { url, path } = useRouteMatch();

  const history = useHistory();

  const changeRoute = useCallback(
    (row) => {
      history.push({ pathname: url + "/" + row.titolo, state: row });
    },
    [url, history]
  );

  const changeState = useCallback(
    (row) => {
      history.push({pathname: "/"});
      history.replace({ pathname: url + "/" + row.titolo, state: row });
    },
    [url, history]
   );

  const backFromScheda = useCallback(() => {
    history.push({ pathname: "/Bandi" });
  }, [history]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "bandoId", // accessor è la chiave in data
      },
      {
        Header: "Titolo",
        accessor: "titolo",
      },
      {
        Header: "Ente Erogatore",
        accessor: "ente.nome",
      },
    ],
    []
  );

  return (
    <Switch>
      <PrivateRoute exact path="/Bandi">
        <ContentTable
          columns={columns}
          title="Bandi"
          fetchUrl={Constants.GETBANDIURL + '?filter=' + JSON.stringify({ include: ["ente"]})}
          changeRoute={changeRoute}
          defaultOrderBy={"nome"}
        />
      </PrivateRoute>
      <PrivateRoute exact path={"/Bandi/Nuovo Bando"}>
        <SchedaBando
          backFromScheda={backFromScheda}
          nuovaScheda={true}
          changeState={changeState}
        />
      </PrivateRoute>
      <PrivateRoute exact path={"/Bandi/:nome"}>
        <SchedaBando
          backFromScheda={backFromScheda}
          nuovaScheda={false}
        />
      </PrivateRoute>
    </Switch>
  );
}

export default withRouter(Bandi);
