import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useLocation, withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as MyForms from "./MyForms";
import Stack from "./Stack";
import * as Constants from "../../constants";

Modal.setAppElement("#root");

function FormBanca(props) {
  const [fields, updateFields] = useState(useLocation().state); //I campi vengono inizializzati con l'oggetto state passato fra le props del router
  const [readOnly, setReadOnly] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [tipiBanche, setTipiBanche] = useState([]);

  useEffect(() => {
    const fetchBanche = async () => {
      const url = Constants.GETTIPOBANCHEURL;
      const response_data = await Constants.getData(url);
      setTipiBanche(response_data);
    };

    if (props.nuovaScheda) {
      updateFields(null);
      setReadOnly(false);
    }
    fetchBanche();
  }, [props.nuovaScheda]);

  const handleEditClick = () => {
    setReadOnly(!readOnly);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const backToList = () => {
    props.backFromScheda();
  };

  const handleDelete = async (id) => {
    const deleteUrl =
      Constants.PUTBANCHEURL + id;
    await Constants.deleteData(deleteUrl);
    props.backFromScheda();
  };

  const handleEdit = async (id, dataToSend) => {
    const fetchUrl =
      Constants.PUTBANCHEURL + id;
    await Constants.putData(fetchUrl, dataToSend);
    setReadOnly(true);
  };

  const handlePost = async (dataToSend) => {
    const postNewBanca = async () => {
      const fetchurl = Constants.POSTBANCHEURL;
      const response_data = await Constants.postData(fetchurl, dataToSend);
      console.log(response_data);
      setReadOnly(true);
      props.changeState(response_data)
    };
    postNewBanca();
  };

  return (
    <>
      <div
        class="tab-pane fade show active"
        id="tab_pane_1"
        role="tabpanel"
        aria-labelledby="tab_pane_2"
      >
        <Formik
          enableReinitialize={true}
          initialValues={
            !props.nuovaScheda
              ? {
                  nome: fields["nome"],
                  tipoBancaId: fields["tipoBancaId"],
                  link: fields["link"],
                  pec: fields["pec"],
                }
              : {
                  nome: "",
                  tipoBancaId: "",
                  link: "",
                  pec: "",
                }
          }
          validationSchema={Yup.object({
            nome: Yup.string().required("Nome Obbilgatorio"),
            tipoBancaId: Yup.string().required("Tipo Banca Obbligatorio"),
            pec: Yup.string().email("Invalid email address").nullable(),
          })}
          onSubmit={(values, { setSubmitting }) => {
            !props.nuovaScheda
              ? handleEdit(fields["bancaId"], JSON.stringify(values, null, 2))
              : handlePost(JSON.stringify(values, null, 2));
          }}
          onReset={() => {
            setReadOnly(true);
          }}
        >
          <Form class="form pt-5">
            <div class="border-0 top_commandi" style={{ textAlign: "right" }}>
              {readOnly ? (
                <div>
                  <button
                    class="btn btn-outline-primary font-size-sm"
                    onClick={backToList}
                  >
                    <i class="icon fas fa-arrow-left"></i>
                    Indietro
                  </button>
                  &nbsp;&nbsp;
                  <div
                    class="btn btn-primary font-weight-bolder"
                    onClick={handleEditClick}
                  >
                    <span class="svg-icon svg-icon-md">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24" />
                          <circle fill="#000000" cx="9" cy="15" r="6" />
                          <path
                            d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z"
                            fill="#000000"
                            opacity="0.3"
                          />
                        </g>
                      </svg>
                    </span>
                    Edit
                  </div>
                </div>
              ) : (
                <div>
                  {/* Se si sta inserendo una nuova scheda il tasto Cancella è invisibile ed il tasto
                    annulla riporta all'elenco. Se invece siamo in modifica il tasto cancella è visibile
                    ed il tasto annulla disabilita l'editing rimanendo sulla scheda*/}
                  {!props.nuovaScheda ? (
                    <>
                      <button
                        type="reset"
                        class="btn btn-outline-primary font-size-sm"
                      >
                        <i class="icon fas fa-arrow-left"></i>
                        Annulla
                      </button>
                      &nbsp;&nbsp;
                      <div
                        class="btn btn-danger font-size-sm"
                        onClick={openModal}
                      >
                        <i class="icon far fa-trash-alt"></i>
                        Cancella
                      </div>
                    </>
                  ) : (
                    <button
                      onClick={backToList}
                      class="btn btn-outline-primary font-size-sm"
                    >
                      <i class="icon fas fa-arrow-left"></i>
                      Annulla
                    </button>
                  )}
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary font-weight-bolder"
                    type="submit"
                  >
                    <i class="icon far fa-save"></i> Salva
                  </button>
                </div>
              )}
            </div>
            {/* Si istanziano le nostre Form. */}
            <div class="form-group row">
              <div class="col-lg-6">
                <MyForms.MyTextInput
                  label="Nome"
                  name="nome"
                  //type="text"
                  class="form-control"
                  disabled={readOnly}
                />
                <span class="form-text text-muted"></span>
              </div>
              <div class="col-lg-6">
                <MyForms.MySelect
                  label="Tipo Banca"
                  name="tipoBancaId"
                  class="form-control"
                  disabled={readOnly}
                  list={tipiBanche}
                  nameLabel="tipoBanca"
                >
                </MyForms.MySelect>
                <span class="form-text text-muted"></span>
              </div>
              <div class="col-lg-6">
                <MyForms.MyTextInput
                  label="Link"
                  name="link"
                  type="text"
                  link="1"
                  class="form-control"
                  disabled={readOnly}
                />
                <span class="form-text text-muted"></span>
              </div>
              <div class="col-lg-6">
                <MyForms.MyTextInput
                  label="Pec"
                  name="pec"
                  type="email"
                  class="form-control"
                  disabled={readOnly}
                />
                <span class="form-text text-muted"></span>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
      {!props.nuovaScheda && (
        <Stack
          idPane={"tab_pane_2"}
          readOnly={readOnly}
          getAssociatedUrl={props.getReferentiUrl}
          postAssociatedUrl={props.postReferentiUrl}
          putSubjectUrl={props.putPersoneUrl}
          putObjectUrl={Constants.PUTBANCHEURL}
          id={fields["bancaId"]}
        />
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            width: "150",
          },
          content: {
            color: "lightsteelblue",
            top: "5%",
            left: "30%",
            right: "30%",
            bottom: "auto",
          },
        }}
      >
        <h2 align="center">L'oggetto sta per essere eliminato</h2>
        <br />
        <br />
        <br />
        <div align="center">
          <div
            class="btn btn-outline-primary font-size-sm"
            onClick={closeModal}
            align="center"
          >
            Annulla
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div
            class="btn btn-outline-primary font-size-sm"
            onClick={() => {
              handleDelete(fields["bancaId"]);
            }}
            align="center"
          >
            Conferma
          </div>
        </div>
      </Modal>
    </>
  );
}

export default withRouter(FormBanca);
