import React, { useState, useEffect } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import { useExportData } from "react-table-plugins";
import styled from "styled-components";
import TableToolbar from "./TableToolbar";
import Papa from "papaparse";
import XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { withRouter } from "react-router-dom";

import * as Constants from "../../constants";

// Definizione della funzione di filtro globale per la ricerca nella tabella
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <label>
      Search:
      <input
        type="search"
        class="form-control form-control-sm"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={``}
        aria-controls="RFtable1"
      />
    </label>
  );
}

//Definizione della funzione di esportazione (CSV, EXCEL, PDF)
function getExportFileBlob({ columns, data, fileType, fileName }) {
  if (fileType === "csv") {
    // CSV
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: "text/csv" });
  } else if (fileType === "xlsx") {
    // XLSX
    const header = columns.map((c) => c.exportValue);
    const compatibleData = data.map((row) => {
      const obj = {};
      header.forEach((col, index) => {
        obj[col] = row[index];
      });
      return obj;
    });

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
      header,
    });
    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
    return false;
  }

  //PDF
  if (fileType === "pdf") {
    const headerNames = columns.map((column) => column.exportValue);
    const doc = new JsPDF();
    doc.autoTable({
      head: [headerNames],
      body: data,
      margin: { top: 20 },
      styles: {
        minCellHeight: 9,
        halign: "left",
        valign: "center",
        fontSize: 11,
      },
    });
    doc.save(`${fileName}.pdf`);

    return false;
  }

  return false;
}

//FUNZIONE CONTENTTABLE
function ContentTable(props) {
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(30);

  const columns = props.columns;

let CustomTr;
  if(props.noLink) {
    CustomTr = styled.tr``;
  } else {
    CustomTr = styled.tr`
      :hover {
        background-color: #eef0f8;
        cursor: pointer;
      }
    `;
  }

  const handleOnRowClick = (row_original) => {
    if (props.changeRoute) {
      if (props.consorziatiData) {
        props.changeRoute(row_original.data_cons);
        window.location.reload();
      } else {
        props.changeRoute(row_original);
      }
    }
    if (props.openModal) props.openModal(row_original);
    if (props.exec) props.exec(row_original);
  };
  const handleDelete = (row_original) => {
    if (props.execToDelete) props.execToDelete(row_original);
  };

  useEffect(() => {
    if (props.fetchUrl) {
      fetchdata();
    } else if (props.dataToDisplay) {
      setData(props.dataToDisplay);
      var pSize =
        props.dataToDisplay.length > 10 && !props.easy
          ? 10
          : props.dataToDisplay.length;
      setPageSize(pSize);
    }
  }, [props.fetchUrl, props.dataToDisplay]);

  const fetchdata = async () => {
    const response_data = await Constants.getData(props.fetchUrl);
    setData(response_data);
    if(response_data) {
      var pSize =
        response_data.length > 10 && !props.easy ? 10 : response_data.length;
      setPageSize(pSize);
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    exportData,
    gotoPage,
    pageCount,
  } = useTable(
    {
      columns,
      data,
      getExportFileBlob,
      initialState: {
        sortBy: [
          {
            id: props.defaultOrderBy,
            desc: false,
          },
        ],
        defaultPageSize: data.length,
        pageSize: pageSize, // da rendere dinamico lo state pageSize. Ora non lo vede
      },
    },
    useGlobalFilter,
    useSortBy,
    useExportData,
    usePagination
  );
  const { pageIndex } = state;

  return (
    <div class="card-body">
      {!props.easy && (
        <>
          <div style={{ float: "left" }} class="card-title">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </div>
          <div style={{ float: "right" }}>
            <TableToolbar exportfunction={exportData} title={props.title} />
          </div>
        </>
      )}

      <table
        {...getTableProps()}
        class="table table-separate table-head-custom table-checkable"
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(
                (column) =>
                  (props.editMode || (!props.editMode && !column.delete)) && (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  )
              )}
            </tr>
          ))}
        </thead>
        {!props.editMode ? (
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
                return (
                  <CustomTr
                    {...row.getRowProps()}
                    onClick={() => handleOnRowClick(row.original)}
                  >
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}> {cell.render("Cell")}</td>
                    ))}
                  </CustomTr>
                );
            })}
            {!page.length ? (
              props.message_nodata ? (
                <tr>{props.message_nodata}</tr>
              ) : (
                <tr>Nessun dato inserito</tr>
              )
            ) : (
              <></>
            )}
          </tbody>
        ) : (
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <CustomTr {...row.getRowProps()}>
                  {row.cells.map((cell) =>
                    cell.column.delete ? (
                      <td
                        {...cell.getCellProps()}
                        onClick={() => handleDelete(row.original)}
                      >
                        <i class="la la-trash-o"></i>
                      </td>
                    ) : (
                      <td {...cell.getCellProps()}> {cell.render("Cell")}</td>
                    )
                  )}
                </CustomTr>
              );
            })}
            {!page.length ? (
              props.message_nodata ? (
                <tr>{props.message_nodata}</tr>
              ) : (
                <tr>Nessun dato inserito</tr>
              )
            ) : (
              <></>
            )}
          </tbody>
        )}
      </table>
      {!props.easy && (
        <>
          <div id="RFtable1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer" >
            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="dataTables_info" id="RFtable1_info" role="status" aria-live="polite" >
                  Page{" "}
                  <strong>
                    {" "}
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </div>
              </div>
              <div class="col-sm-12 col-md-7">
                <div class="dataTables_paginate paging_full_numbers" id="RFtable1_paginate" >
                  <ul class="pagination">
                    {canPreviousPage ? (
                      <>
                        <li class="paginate_button page-item first" id="RFtable1_first" >
                          <button
                            aria-controls="RFtable1"
                            data-dt-idx="0"
                            tabindex="0"
                            class="page-link"
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                          >
                            <i class="ki ki-double-arrow-back"></i>
                          </button>
                        </li>
                        <li class="paginate_button page-item previous" id="RFtable1_previous" >
                          <button
                            aria-controls="RFtable1"
                            data-dt-idx="0"
                            tabIndex="0"
                            class="page-link"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                          >
                            <i class="ki ki-arrow-back"></i>
                          </button>
                        </li>
                      </>
                    ) : (
                      <>
                        <li
                          class="paginate_button page-item first disabled"
                          id="RFtable1_first"
                        >
                          <button
                            aria-controls="RFtable1"
                            data-dt-idx="0"
                            tabIndex="0"
                            class="page-link"
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                          >
                            <i class="ki ki-double-arrow-back"></i>
                          </button>
                        </li>
                        <li
                          class="paginate_button page-item previous disabled"
                          id="RFtable1_previous"
                        >
                          <button
                            aria-controls="RFtable1"
                            data-dt-idx="0"
                            tabIndex="0"
                            class="page-link"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                          >
                            <i class="ki ki-arrow-back"></i>
                          </button>
                        </li>
                      </>
                    )}

                    <li class="paginate_button page-item active">
                      <div aria-controls="RFtable1" data-dt-idx="2" tabIndex="0" class="page-link" >
                        {pageIndex + 1}
                      </div>
                    </li>
                    {canNextPage ? (
                      <>
                        <li class="paginate_button page-item next" id="RFtable1_next" >
                          <button
                            aria-controls="RFtable1"
                            data-dt-idx="0"
                            tabIndex="0"
                            class="page-link"
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                          >
                            <i class="ki ki-arrow-next"></i>
                          </button>
                        </li>
                        <li class="paginate_button page-item last " id="RFtable1_last" >
                          <button
                            aria-controls="RFtable1"
                            data-dt-idx="0"
                            tabIndex="0"
                            class="page-link"
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                          >
                            <i class="ki ki-double-arrow-next"></i>
                          </button>
                        </li>
                      </>
                    ) : (
                      <>
                        <li class="paginate_button page-item next disabled" id="RFtable1_next" >
                          <button
                            aria-controls="RFtable1"
                            data-dt-idx="0"
                            tabIndex="0"
                            class="page-link"
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                          >
                            <i class="ki ki-arrow-next"></i>
                          </button>
                        </li>
                        <li class="paginate_button page-item last disabled" id="RFtable1_last" >
                          <button
                            aria-controls="RFtable1"
                            data-dt-idx="0"
                            tabIndex="0"
                            class="page-link"
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                          >
                            <i class="ki ki-double-arrow-next"></i>
                          </button>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default withRouter(ContentTable);
