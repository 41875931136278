import React from "react";

import FormBando from "./FormBando";
import { withRouter } from "react-router-dom";

function SchedaBando(props) {
  return (
    <div class="card-body">
      <ul class="nav nav-tabs nav-tabs-line mb-5">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#tab_pane_1">
            <span class="nav-icon">
              <i class="flaticon2-chat-1"></i>
            </span>
            <span class="nav-text">Dettagli</span>
          </a>
        </li>
      </ul>
      <div class="tab-content" id="TabContent">
        <FormBando
          backFromScheda={props.backFromScheda}
          nuovaScheda={props.nuovaScheda}
          changeState={props.changeState}
        />
      </div>
    </div>
  );
}

export default withRouter(SchedaBando);
