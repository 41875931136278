import React, { useState, useEffect, useCallback } from "react";
import { CalendarContainer } from "react-datepicker";
import Modal from "react-modal";
import * as MyButtons from "./MyButtons";
import * as Constants from "../../constants";
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import '../../../custom-datasheet.css';
import * as Utils from './Utils';

Modal.setAppElement("#root");

export default function PDipendenteModal(props) {
	const [myGrid, setGrid] = useState([]);
	const [RTotaleEffort, setRTotaleEffort] = useState();
	const [RTotaleCosto, setRTotaleCosto] = useState();
	const [RDifferenza, setRDifferenza] = useState();
	const [CAttivita, setCountAttivita] = useState();
	const [AlertNoPLivello, setAlertPLivello] = useState();
	const [visCalcolato, setVisCalcolato] = useState(false);
	const [readOnly, setReadOnly] = useState(true);
	const [isNew, setNew] = useState(false);

	useEffect(() => {
		if(props.selectedPDipendente.sommaEffortConfermato > 0) {
			setVisCalcolato(false);
			setReadOnly(true);
			setNew(false);
		} else {
			setVisCalcolato(true);
			setReadOnly(false);
			setNew(true);
		}
	}, [props.selectedPDipendente]);

	useEffect(() => {
		PreparePDipendente(props.selectedPDipendente);
	}, [readOnly, visCalcolato, props.selectedPDipendente]);

	useEffect(() => {
		setGrid(props.selectedPLivello);
	}, [props.selectedPLivello]);

	const handleEditClick = () => {
		if(!readOnly) {
			setVisCalcolato(false);
		}
		setReadOnly(!readOnly);
	}

	const handleChangeView = () => {
		setVisCalcolato(!visCalcolato);
	}

	const calc = function(grid, RowTotaleEffort, RowTotaleCosto, RowDifferenza, CountAttivita) {
		if(!RowTotaleEffort) RowTotaleEffort = RTotaleEffort;
		if(!RowTotaleCosto) RowTotaleCosto = RTotaleCosto;
		if(!RowDifferenza) RowDifferenza = RDifferenza;
		if(!CountAttivita) CountAttivita = CAttivita;

		var sumOreAtt = {};
		var sumCostoAtt = {};
		let start = props.progetto.costiReali ? 4 : 6;
		for(var i=start;i<=grid.length-5; i++) {
			var row = grid[i];
			var sumOreDip = 0;
			var sumCostoDip = 0;
			for(var j=2;j<=CountAttivita+1;j++) {
				sumOreDip += parseInt(row[j].value);
				if(!sumOreAtt[j]) sumOreAtt[j] = 0;
				if(!sumCostoAtt[j]) sumCostoAtt[j] = 0;
				sumOreAtt[j] += parseInt(row[j].value);
				var costoConfermato = parseFloat(row[j].value * row[j].costoMedioOrario);
				row[j]['costoConfermato'] = costoConfermato;

				sumCostoAtt[j] += costoConfermato;
			}
			row[CountAttivita+2]['value'] = sumOreDip;
			sumCostoDip = parseFloat(sumOreDip * row[CountAttivita+1]['costoMedioOrario']);
			if(row[CountAttivita+4]) {
				row[CountAttivita+4]['valuePulito'] = sumCostoDip;
				row[CountAttivita+4]['value'] = Utils.Format(sumCostoDip);
			}
		}
		for (var j in sumCostoAtt) {
			grid[RowTotaleEffort][j-1]['value'] = sumOreAtt[j];
			grid[RowTotaleCosto][j-1]['value'] = Utils.Format(sumCostoAtt[j]);
			// var differenza = grid[RowDifferenza-1][j-1]['valuePulito'] - sumCostoAtt[j];
			var differenza = sumCostoAtt[j] - grid[RowDifferenza-1][j-1]['valuePulito'];
			grid[RowDifferenza][j-1]['className'] = differenza == 0 ? 'cifra' : 'cifra red';
			grid[RowDifferenza][j-1]['value'] = Utils.Format(differenza);
		}

		setGrid(grid);
	}
	const PreparePDipendente = function (data) {
		var grid = [];
		if(!data.attivita) return;
		var alertNoPLivello = 1;

		if(props.progetto.costiReali == 1) {
			var TIPOLIVELLO = {
				"5": {},
				"4": {}
			};

		} else {
			var TIPOLIVELLO = {
				"1": {},
				"2": {},
				"3": {},
				"4": {}
			};
		}

		// INTESTAZIONE
		var rowGrid = [{"value": "GDL", "readOnly": true, className:"thcell"}, { value: "LIVELLO", readOnly: true, className:"thcell" }];
		data.attivita.map(att => {
			rowGrid.push(
				{ value: "Att. " + props.selectedPDipendente.numero + "." + att.numero , readOnly: true , className:"thcell"},
			);
			att.livelli.map(liv => {
				if(liv.effortMedio > 0) alertNoPLivello = 0;
				if(!TIPOLIVELLO[liv.tipoLivelloId]) return;
				
				TIPOLIVELLO[liv.tipoLivelloId][att.attivitaId] = {
					"tipoLivello": liv.tipoLivello, 
					"costoMedioOrario": liv.costoMedioOrario, 
					"effortMedio": parseFloat(liv.effortMedio).toFixed(2), 
					"effort": {}
				};
			});
		});
		setAlertPLivello(alertNoPLivello);

		rowGrid.push(
			{ value: "TOTALE EFFORT", readOnly: true, className:"thcell", width: "20px"},
			{ value: "COSTO MEDIO ORARIO", readOnly: true, className:"thcell", width: "20px"},
			{ value: "TOTALE COSTI", readOnly: true, className:"thcell", width: "20px" },
		);
		grid.push(rowGrid);

		var rowGrid = [{ value: "", readOnly: true }, { value: "", readOnly: true }];
		data.attivita.map(att => {
			rowGrid.push( { value: "Stima effort medio per livello", readOnly: true });
		});
		rowGrid.push({ value: "", readOnly: true },{ value: "", readOnly: true }, { value: "", readOnly: true });
		grid.push(rowGrid);

		for (const tipoLivelloId in TIPOLIVELLO) {
			var rowGrid = [{ value: "", readOnly: true }, { value: "", readOnly: true }];
			data.attivita.map(att => {
				var TL = TIPOLIVELLO[tipoLivelloId][att.attivitaId];
				if(TL) rowGrid.push( { value: TL.tipoLivello + ": " + TL.effortMedio, readOnly: true, className:"cifra" });
			});
			rowGrid.push({ value: "", readOnly: true },{ value: "", readOnly: true }, { value: "", readOnly: true });
			grid.push(rowGrid);
		};

		// DIPENDENTI
		data.dipendenti.map((dip) => {
			let etichetta_dip = dip.dipendente
			if(!dip.presenzaPiena) etichetta_dip += ' *'
			var rowGrid = [ { value: etichetta_dip, readOnly: true }, { value: dip.tipoLivello, readOnly: true }];
			data.attivita.map((attivita) => {
				var find = dip.attivita.find(obj => { return obj.attivitaId == attivita.attivitaId });
				var effortConfermato = find ? find.effortConfermato : undefined;
				var effortCalcolato = find.effortCalcolato;
				var costoMedioOrario = dip.costoMedioOrario;

				// var value = effortConfermato ? effortConfermato : effortCalcolato;
				var value = visCalcolato ? effortCalcolato : effortConfermato;

				rowGrid.push({
					value: value, 
					attivitaId: attivita.attivitaId,
					partnerDipendenteId: dip.partnerDipendenteId,
					effortCalcolato: effortCalcolato,
					costoCalcolato: parseFloat(value*costoMedioOrario),
					costoConfermato: parseFloat(value*costoMedioOrario),
					className:"cifra", 
					costoMedioOrario: costoMedioOrario,
					readOnly: readOnly
				});
			});
			rowGrid.push(
				{value: "", readOnly: true, className: "cifra"},
				{value: Utils.Format(dip.costoMedioOrario), readOnly: true, className:"cifra"},
				{value: "", readOnly: true, costoMedioOrario: dip.costoMedioOrario, className:"cifra"}
			);
			grid.push(rowGrid);
		});

		//TOTALE
		var rowGrid = [ { value: "TOTALE EFFORT ATTIVITA", readOnly: true, colSpan:2, className:"thcell" }];
		data.attivita.map((attivitadipAtt) => {
			rowGrid.push({value: "", readOnly: true, className:"cifra"});
		});
		rowGrid.push({value: "", readOnly: true},{value: "", readOnly: true},{value: "", readOnly: true});
		grid.push(rowGrid);
		const RowTotaleEffort = grid.length-1;

		var rowGrid = [ { value: "TOTALE COSTO ATTIVITA", readOnly: true, colSpan:2, className:"thcell" }];
		data.attivita.map((attivitadipAtt) => {
			rowGrid.push({value: "", readOnly: true, className:"cifra thcell"});
		});
		rowGrid.push({value: "", readOnly: true},{value: "", readOnly: true},{value: "", readOnly: true});
		grid.push(rowGrid);
		const RowTotaleCosto = grid.length-1;

		// STIMA COSTO ATTIVITA
		var rowGrid = [ { value: "Costo personale per l'attività", readOnly: true, colSpan:2, className:"thcell" }];
		data.attivita.map((attivita) => {
			// rowGrid.push({value: Utils.Format(attivita.costoConfermato),valuePulito: attivita.costoConfermato, readOnly: true, className:"cifra"});
			rowGrid.push({value: Utils.Format(attivita.costoPers),valuePulito: attivita.costoPers, readOnly: true, className:"cifra"});
		});
		rowGrid.push({value: "", readOnly: true},{value: "", readOnly: true},{value: "", readOnly: true});
		grid.push(rowGrid);

		// DIFFERENZA
		var rowGrid = [ { value: "Differenza", readOnly: true, colSpan:2, className:"thcell" }];
		data.attivita.map((attivitadipAtt) => {
			rowGrid.push({value: "", readOnly: true});
		});
		rowGrid.push({value: "", readOnly: true},{value: "", readOnly: true},{value: "", readOnly: true});
		grid.push(rowGrid);

		setRTotaleEffort(RowTotaleEffort);
		setRTotaleCosto(RowTotaleCosto);
		setRDifferenza(RowTotaleCosto+2);
		setCountAttivita(data.attivita.length);
		
		// Passo RowTotale e RowDiffenza perche il setRTotale e setRDifferenza e' asincrono
		calc(grid, RowTotaleEffort, RowTotaleCosto, RowTotaleCosto+2, data.attivita.length);
	}

	const handlePost = async () =>{
		var dataToSend = [];
		myGrid.map(row => {
			row.map(cell => {
				if(cell.attivitaId && cell.partnerDipendenteId) {
					dataToSend.push({
						attivitaId: cell.attivitaId,
						partnerDipendenteId: cell.partnerDipendenteId,
						effortCalcolato: cell.effortCalcolato,
						costoCalcolato: cell.costoCalcolato,
						effortConfermato: cell.value,
						costoConfermato: cell.costoConfermato
					});
				}
			});
		});
		var api_path = Constants.Compose([Constants.PATH_PARTNERS, props.partnerId, 'pianificazioneDipendente']);
		const response_data = await Constants.postData(api_path, JSON.stringify(dataToSend));
		props.forceUpdate();
		props.onRequestClose();
	};
	return (
		<>
			<Modal
				isOpen={props.isModalOpen}
				onRequestClose={props.onRequestClose}
				 style={{
					content: {
						top: "5%",
						left: "7%",
						right: "7%",
						bottom: "5%",
					},
				 	width: "1200px",
				}}
			>
				<div className="modal-content">
					<div className="modal-header">
						<h5>OR {props.selectedPDipendente.numero} - {props.selectedPDipendente.descrizione}</h5>
						<h6>Pianificazione per Dipendente &nbsp;
							{props.selectedPDipendente.sommaEffortConfermato == 0 ? ( 
								<span class="label label-lg label-light-warning label-inline"> Da Calcolare </span>
							) : (
								<span class="label label-lg label-light-success label-inline"> Calcolato </span>
							)}
						</h6>
						<MyButtons.MyClose handleClick={props.onRequestClose}/>
					</div>
					<div className="modal-body">
						<div class="border-0 top_commandi" style={{ textAlign: "right" }} >
						{readOnly ? (
							<MyButtons.MyModifica handleClick={handleEditClick}/>
						) : (
							<>
								<MyButtons.MyAnnulla handleClick={handleEditClick}/>
								&nbsp;&nbsp;
								<MyButtons.MyModifica label="Salva" handleClick={handlePost}/>
							</>
						)}
						</div>
						{AlertNoPLivello == 1 && (
							<div style={{color:'red', padding:'30px'}}><strong>ATTENZIONE: non è stata ancora effettuata la pianificazione per Livello</strong></div>
						)}
						{! readOnly && (
							visCalcolato ? (
								!isNew && (
									<h6 class="text-center">Stai visualizzando gli effort suggeriti dall'applicazione &nbsp; 
										<span class="pointer label label-lg label-light-primary label-inline" onClick={handleChangeView}> Visualizza Pianificazione Salvata</span>
									</h6>
								)
							) : (
								<h6 class="text-center">Stai visualizzando gli effort dell'ultimo salvataggio &nbsp;
									<span class="pointer label label-lg label-light-primary label-inline" onClick={handleChangeView}> Visualizza effort suggeriti</span>
								</h6>
							)
						)}
						<div style={{textAlign:"center"}}>
							<ReactDataSheet
								data={myGrid}
								valueRenderer={cell => cell.value}
								className='custom-sheet'
								onCellsChanged={changes => {
									const grid = myGrid.map(row => [...row]);
									changes.forEach(({ cell, row, col, value }) => {
										if(!value) value = 0;
										value = isNaN(value) ? 0 : value;
										value = parseFloat(value);
										grid[row][col] = { ...grid[row][col], value };
									});
									calc(grid);
								}}
							/>
						</div>
						<div><br/>* dipendente non rendicontato per l'intera durata del progetto</div>
					</div>
				</div>
			</Modal>
        </>
    )
}
