import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import * as MyForms from "./MyForms";
import Modal from "react-modal";
import ModalConfirm from "./ModalConfirm";
import * as MyButtons from "./MyButtons";
import * as Constants from "../../constants";
import * as Utils from "./Utils";

export default function FormFattura(props) {
	const [readOnly, setReadOnly] = useState(props.readOnly);
	const [selectedFile, setSelectedFile] = useState(null);
	const [isModalConfirmOpen, setModalConfirm] = useState(false);
	const [allegatoFattura, setAllegatoFattura] = useState({});

	useEffect(() => {
		if(props.fattura && props.fattura) {
			setAllegatoFattura(props.fattura.fileName);
		}
	}, [props.fattura]);

	const onChangeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const openModalConfirm = (e) => {
		setModalConfirm(true);
	};
	const closeModalConfirm = () => {
		setModalConfirm(false);
	};

	const handleDelete = async (fatturaId) => {
		var api_path = Constants.Compose([Constants.PATH_FATTURA, fatturaId]);
		setModalConfirm(false)
		const response_data = await Constants.deleteData(api_path);
		props.onRequestClose();
		props.forceUpdate();
	};

	const handleEdit = async (dataToSend) => {
		// Upload del file
		if(selectedFile) {
			const data = new FormData();
			data.append("allegato", selectedFile);
			data.append("data", dataToSend.dataFattura);
			var api_path = Constants.Compose([Constants.PATH_UPLOADALLEGATO]);
			var response_data = await Constants.postData(api_path, data);
			dataToSend.allegatoId = response_data.allegatoId;
		}
		dataToSend.importo = Utils.pulisciImporto(dataToSend.importo);

		dataToSend.anticipo = null;
		dataToSend.acconto = null;
		if(dataToSend.salId == 'anticipo') {
			dataToSend.salId = null;
			dataToSend.anticipo = 1
		}
		if(dataToSend.salId == 'acconto') {
			dataToSend.salId = null;
			dataToSend.acconto = 1
		}

		// Creazione del link con la fattura
		if(dataToSend.fatturaId) {
			var api_path = Constants.Compose([Constants.PATH_FATTURA, props.fattura.fatturaId]);
			var response_data = await Constants.putData(api_path, JSON.stringify(dataToSend));
		} else {
			var api_path = Constants.Compose([Constants.PATH_FATTURA]);
			var response_data = await Constants.postData(api_path, JSON.stringify(dataToSend));
		}

		props.onRequestClose();
		props.forceUpdate();
	};

	return (
		<>
			<Modal
				isOpen={props.isModalOpen}
				onRequestClose={props.closeModal}
				style={{
					overlay: {
						width: "150",
					},
					content: {
						color: "lightsteelblue",
						top: "5%",
						left: "30%",
						right: "30%",
						bottom: "10%",
					},
				}}
			>
				<div className="modal-content">
					<div className="modal-header">
						<h5>Fattura {props.fattura.numero} - {props.fattura.partner}</h5>
						<MyButtons.MyClose handleClick={props.onRequestClose}/>
					</div>
					<div className="modal-body">
						<Formik
							enableReinitialize={true}
							initialValues={
								{
									dataFattura: props.fattura.dataFattura,
									fatturaId: props.fattura.fatturaId,
									importo: props.fattura.importo,
									tipoStatoFatturaId: props.fattura.tipoStatoFatturaId,
									partnerId: props.fattura.partnerId,
									salId: props.fattura.salId,
									numero: props.fattura.numero,
								}
							}
							validationSchema={Yup.object({
								numero: Yup.string().required("Campo Obbligatorio"),
								tipoStatoFatturaId: Yup.number().required("Campo Obbligatorio"),
								partnerId: Yup.number().required("Campo Obbligatorio"),
								salId: Yup.string().required("Campo Obbligatorio"),
								dataFattura: Yup.date().required("Campo obbligatorio"),
							})}
							onSubmit={(values, { setSubmitting }) => {
								handleEdit(values);
								setReadOnly(false);
							}}
							onReset={() => {
								// setReadOnly(true);
							}}
						>
							<Form class="form">
								<div class="border-0 top_commandi bp-5" style={{ textAlign: "right" }} >
									<div>
										<MyButtons.MyAnnulla handleClick={props.onRequestClose}/>
										&nbsp;&nbsp;
										<MyButtons.MySalva/>
										&nbsp;&nbsp;
										<MyButtons.MyCancella handleClick={openModalConfirm}/>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-lg-6">
                						<MyForms.MySelect
                  							label="Partner"
                  							name="partnerId"
                  							class="form-control"
                  							disabled={readOnly}
                  							list={props.listPartners}
                  							nameLabel="partner"
                						/>
										<span class="form-text text-muted"></span>
									</div>
									<div class="col-lg-6">
                						<MyForms.MySelect
                  							label="Sal"
                  							name="salId"
                  							class="form-control"
                  							disabled={readOnly}
                  							list={props.listSals}
                  							nameLabel="sal"
                						/>
										<span class="form-text text-muted"></span>
									</div>
									<div class="col-lg-6">
										<MyForms.MyTextInput
											label="Numero"
											name="numero"
											class="form-control"
											disabled={readOnly}
										/>
										<span class="form-text text-muted"></span>
									</div>
									<div class="col-lg-6">
										<label><strong>Data</strong></label>
										<div class="input-group date">
											<MyForms.MyDatePickerField
												name="dataFattura"
												class="form-control"
												disabled={readOnly}
											/>
										</div>
										<span class="form-text text-muted"></span>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-lg-6">
                    					<label><strong>Importo (&euro;):</strong></label>
										<MyForms.MyCurrencyInput
											name="importo"
											class="form-control"
											disabled={readOnly}
										/>
										<span class="form-text text-muted"></span>
									</div>
									<div class="col-lg-6">
                						<MyForms.MySelect
                  							label="Stato Fattura"
                  							name="tipoStatoFatturaId"
                  							class="form-control"
                  							disabled={readOnly}
                  							list={props.tipiStatoFattura}
                  							nameLabel="tipoStatoFattura"
                						/>
										<span class="form-text text-muted"></span>
									</div>
								</div>
							</Form>
						</Formik>
						<div class="form-group row">
							<div class="col-lg-6">
								<label><strong>Allegato:</strong></label>
								<div class="dati" modalita="view">
									<a role="button" onClick={() => Utils.downloadAllegato(props.fattura.allegatoId, props.fattura.fileName)}>
									{allegatoFattura}
									</a>
								</div>
								<span class="form-text text-muted"></span>
							</div>
							<div class="form-group files">
								<label><strong>Carica nuovo allegato</strong></label>
								<div class="row">
									<div class="col-lg-8">
										<form method="post" action="#" id="#">
											<input type="file" class="form-control" multiple="" onChange={onChangeHandler} />
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<ModalConfirm
                isModalConfirmOpen={isModalConfirmOpen}
                closeModalConfirm={closeModalConfirm}
                handleConfirm={handleDelete}
				idToDelete={props.fattura.fatturaId}
            />
		</>
	);
}
