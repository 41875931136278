import React, { useState, useEffect, useCallback } from "react";
import * as Constants from "../../constants";
import {
  useHistory,
  withRouter,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import ContentTable from "./ContentTable";
import SchedaProgetto from "../Forms/SchedaProgetto";
import { PrivateRoute } from "../../../App";

function Progetti(props) {
  const { url, path } = useRouteMatch();

  const history = useHistory();

  const changeRoute = useCallback(
    (row) => {
      history.push({ pathname: url + "/" + row.acronimo + " - " + row.titolo , state: row });
    },
    [url, history]
  );

  const changeState = useCallback(
    (row) => {
      history.push({ pathname: "/" });
      history.replace({ pathname: url + "/" + row.acronimo, state: row });
    },
    [url, history]
  );

  const backFromScheda = useCallback(() => {
    history.push({ pathname: "/Progetti" });
  }, [history]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Acronimo",
        accessor: "acronimo",
      },
      {
        Header: "Titolo",
        accessor: "titolo",
      },
    ],
    []
  );
  return (
    <Switch>
      <PrivateRoute exact path="/Progetti">
        <ContentTable
          columns={columns}
          title="Progetti"
          fetchUrl={Constants.GETPROGETTIURL}
          changeRoute={changeRoute}
          defaultOrderBy={"acronimo"}
        />
      </PrivateRoute>
      <PrivateRoute exact path={"/Progetti/Nuovo Progetto"}>
        <SchedaProgetto
          backFromScheda={backFromScheda}
          nuovaScheda={true}
          changeState={changeState}
        />
      </PrivateRoute>
      <PrivateRoute exact path={"/Progetti/:nome"}>
        <SchedaProgetto
          backFromScheda={backFromScheda}
          // getReferentiUrl={Constants.GETREFERENTIURL}
          // postReferentiUrl={Constants.POSTREFERENTIURL}
          // putPersoneUrl={Constants.PUTPERSONEURL}
          nuovaScheda={false}
        />
      </PrivateRoute>
    </Switch>
  );
}

export default withRouter(Progetti);
