import React, { useCallback } from "react";
import * as Constants from "../../constants";
import {
  useHistory,
  withRouter,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import ContentTable from "./ContentTable";
import SchedaEnte from "../Forms/SchedaEnte";
import { PrivateRoute } from "../../../App";

function EntiErogatori(props) {
  const { url, path } = useRouteMatch();

  const history = useHistory();

  const changeRoute = useCallback(
    (row) => {
      console.log(row.nome);
      history.push({ pathname: url + "/" + row.nome, state: row });
    },
    [url, history]
  );

  const changeState = useCallback(
    (row) => {
      history.push({pathname: "/"});
      history.replace({ pathname: url + "/" + row.nome, state: row });
    },
    [url, history]
   );

  const backFromScheda = useCallback(() => {
    history.push({ pathname: "/Enti Erogatori" });
  }, [history]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "enteId", // accessor è la chiave in data
      },
      {
        Header: "Nome",
        accessor: "nome",
      },
      {
        Header: "Tipologia",
        accessor: "tipoEnte.tipoEnte",
      },
      {
        Header: "Pec",
        accessor: "pec",
      },
    ],
    []
  );

  return (
    <Switch>
      <PrivateRoute exact path="/Enti Erogatori">
        <ContentTable
          columns={columns}
          title="Enti Erogatori"
          fetchUrl={Constants.GETENTIURL + '?filter=' + JSON.stringify({ include: ["tipoEnte"]})}
          changeRoute={changeRoute}
          defaultOrderBy={"nome"}
        />
      </PrivateRoute>
      <PrivateRoute exact path={"/Enti Erogatori/Nuovo Ente"}>
        <SchedaEnte
          backFromScheda={backFromScheda}
          nuovaScheda={true}
          changeState={changeState}
        />
      </PrivateRoute>
      <PrivateRoute exact path={"/Enti Erogatori/:nome"}>
        <SchedaEnte
          backFromScheda={backFromScheda}
          getReferentiUrl={Constants.GETREFERENTIENTIURL}
          postReferentiUrl={Constants.POSTREFERENTIENTIURL}
          putPersoneUrl={Constants.PUTPERSONEURL}
          nuovaScheda={false}
        />
      </PrivateRoute>
    </Switch>
  );
}

export default withRouter(EntiErogatori);
