import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as MyForms from "./MyForms";
import axios from "axios";
import Modal from "react-modal";
import DipendentiTab from "./DipendentiTab";
import * as Constants from "../../constants";

export default function DipendentiStack(props) {
  const [dipendenti, setDipendenti] = useState([]);
  const [tipiContratti, setTipiContratti] = useState([]);
  const [newTab, setNewTab] = useState(false);
  const [idObject, setIdObject] = useState(null);
  const [state, setState] = useState();

  const forceUpdate = React.useCallback(() => {
    setState({});
  }, []);

  useEffect(() => {
    const fetchDipendenti = async () => {
      const url =
        Constants.GETDIPENDENTIURL +
        JSON.stringify({
          include: [{'relation': 'persona', 'scope': {order: ['cognome']}}, 'tipoContratto'],
          where: { clienteId: props.clienteId },
        });
      const response_data = await Constants.getData(url);
      setDipendenti(response_data);
    };

    const fetchTipiContratti = async () => {
      const url = Constants.GETTIPICONTRATTI;
      const response_data = await Constants.getData(url);
      setTipiContratti(response_data);
    };

    fetchDipendenti();
    fetchTipiContratti();
    setIdObject(props.clienteId);
  }, [props.clienteId, state]);

  const handleDelete = async (idPersona, idDipendente) => {
    const deletePersonaUrl =
      Constants.PUTPERSONEURL + idPersona;
    const personaDeleteResponse = await Constants.deleteData(deletePersonaUrl);

    const deleteDipendenteUrl =
      Constants.PUTDIPENDENTIURL +
      idDipendente;
    const dipendenteDeleteResponse = await Constants.deleteData(
      deleteDipendenteUrl
    );
    forceUpdate();
  };

  const handleEdit = async (idPersona, idDipendente, dataToSend) => {
    //I dati vanno splittati in 2 sottoinsiemi: uno verso la persona ed uno verso il dipendente
    const dataToPersone = {
      personaId: idPersona,
      nome: dataToSend.nome,
      cognome: dataToSend.cognome,
      codicefiscale: dataToSend.codicefiscale,
    };
    const dataToDipendenti = {
      dipendenteId: idDipendente,
      tipoLivelloId: dataToSend.tipoLivelloId,
      maxOreMese: dataToSend.maxOreMese,
      maxOreAnno: dataToSend.maxOreAnno,
      tipoContrattoId: dataToSend.tipoContrattoId,
    };

    const editPersoneUrl =
      Constants.PUTPERSONEURL + idPersona;
    await Constants.putData(
      editPersoneUrl,
      JSON.stringify(dataToPersone, null, 2)
    );

    const editDipendentiUrl =
      Constants.PUTDIPENDENTIURL +
      idDipendente;
    await Constants.putData(
      editDipendentiUrl,
      JSON.stringify(dataToDipendenti, null, 2)
    );
    forceUpdate();
  };

  const handlePostDipendente = async (dataToSend) => {
    const dataToPersone = {
      nome: dataToSend.nome,
      cognome: dataToSend.cognome,
      codicefiscale: dataToSend.codicefiscale,
    };

    const postPersoneUrl = Constants.POSTPERSONEURL;
    const response_data1 = await Constants.postData(
      postPersoneUrl,
      JSON.stringify(dataToPersone, null, 2)
    );
    console.log(response_data1.personaId);

    const dataToDipendenti = {
      livelloId: dataToSend.livelloId,
      maxOreMese: dataToSend.maxOreMese,
      maxOreAnno: dataToSend.maxOreAnno,
      tipoContrattoId: dataToSend.tipoContrattoId,
      tipoLivelloId: dataToSend.tipoLivelloId,
      personaId: response_data1.personaId,
      clienteId: props.clienteId,
    };

    const postDipendentiUrl = Constants.POSTDIPENDENTIURL;
    const response_data2 = await Constants.postData(
      postDipendentiUrl,
      JSON.stringify(dataToDipendenti, null, 2)
    );
    console.log(response_data2);

    forceUpdate();
    setNewTab(false);
  };

  const handleNewClick = () => {
    setNewTab(true);
  };

  return (
    <>
      <div class="border-0 top_commandi" style={{ textAlign: "right" }}>
        <div class="btn btn-outline-primary font-size-sm mb-5" onClick={handleNewClick} >
          <i class="icon fa fa-plus"></i> Nuovo
        </div>
      </div>
      <div class="accordion accordion-solid accordion-toggle-plus" id="accordionDipendenti" >
        {dipendenti.map((dipendente, i) => (
          <DipendentiTab
            setNewTab={setNewTab}
            handleEdit={handleEdit}
            handlePost={handlePostDipendente}
            handleDelete={handleDelete}
            dipendente={dipendente}
            tipiContratti={tipiContratti}
            nuovaTab={false}
            dataId={i}
            readOnly={true}
          />
        ))}
        {newTab && (
          <DipendentiTab
            setNewTab={setNewTab}
            handleEdit={handleEdit}
            handlePost={handlePostDipendente}
            handleDelete={handleDelete}
            sede={null}
            nuovaTab={true}
            dataId={dipendenti.length + 1}
            readOnly={false}
            tipiContratti={tipiContratti}
          />
        )}
      </div>
    </>
  );
}
