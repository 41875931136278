import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import * as MyForms from "./MyForms";
import Modal from "react-modal";
import * as MyButtons from "./MyButtons";
import AttachedFiles from "./AttachedFiles";
import * as Constants from "../../constants";
import * as Utils from "./Utils";

/* Funzione per il render del tab */
export default function ContrattiTab(props) {
  const [readOnly, setReadOnly] = useState(props.readOnly);
  const [isModalOpen, setModalOpen] = useState(false);
  const [allegato, setAllegato] = useState(null);
  const [api_path_toupdate, setApiPath] = useState(null);
  const [divStyle, setDivStyle] = useState(
    props.nuovaTab ? "collapse show" : "collapse"
  );

  useEffect(() => {
    if (!props.nuovaTab) {
      var api_path_toupdate = Constants.Compose([Constants.PATH_CONTRATTI, props.contratto.contrattoId]);
      setApiPath(api_path_toupdate);
      if(props.contratto.allegato) setAllegato(props.contratto.allegato.fileName);
    }
  }, [props.contratto, props.nuovaTab]);

  const handleEditClick = () => {
    setReadOnly(!readOnly);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const closeNewTab = () => {
    props.setNewTab(false);
  };
  return (
    <>
      <div class="card">
        <div class="card-header">
          {props.nuovaTab ? (
            <div class="card-title" data-toggle="collapse" data-target={"#collapsecontratti" + props.dataId} >
              <i class=" flaticon-avatar"></i>
            </div>
          ) : (
            <div class="card-title collapsed" data-toggle="collapse" data-target={"#collapsecontratti" + props.dataId} >
              <i class=" flaticon-avatar"></i>
              Contratto -&nbsp;  
              {props.progettoId ? (
                <>
                  {props.contratto.cliente.acronimo}
                </>
              )
              :
                <>
                  {props.contratto.progetto.acronimo}
                </>
              } 
            </div>
          )}
        </div>
        <div id={"collapsecontratti" + props.dataId} class={divStyle} data-parent="#accordionContratti" >
          <div class="card-body">
            <Formik
              enableReinitialize={true}
              initialValues={
                !props.nuovaTab
                  ? {
                      importo: props.contratto.importo,
                      previstoAcconto: String(props.contratto.previstoAcconto),
                      importoAcconto: props.contratto.importoAcconto,
                      accontoPagato: String(props.contratto.accontoPagato),
                      allegatoId: props.contratto.allegatoId,
                      clienteId: props.contratto.clienteId,
                      progettoId: props.contratto.progettoId
                    }
                  : {
                      importo: "",
                      previstoAcconto: "",
                      importoAcconto: "",
                      accontoPagato: "",
                      allegatoId: "",
                      clienteId: props.clienteId,
                      progettoId: props.progettoId
                    }
              }
              validationSchema={Yup.object({
                indirizzo: Yup.string(),
                civico: Yup.string(),
                citta: Yup.string(),
                importo: Yup.string().required("Campo obbligatorio"),
                clienteId: Yup.number().required("Campo obbligatorio"),
                progettoId: Yup.number().required("Campo obbligatorio")
              })}
              onSubmit={(values, { setSubmitting }) => {
                !props.nuovaTab
                  ? props.handleEdit(
                      props.contratto.contrattoId,
                      JSON.stringify(values, null, 2)
                    )
                  : props.handlePost(values);
                setReadOnly(true);
                props.nuovaTab = false;
              }}
              onReset={() => {
                setReadOnly(true);
              }}
            >
              <Form class="form">
                <div class="border-0 top_commandi bp-5" style={{ textAlign: "right" }} >
                  {readOnly ? (
                    <div>
											<MyButtons.MyModifica handleClick={handleEditClick}/>
                    </div>
                  ) : (
                    <div>
											{!props.nuovaTab ? (
												<>
													<MyButtons.MyAnnulla/>
													&nbsp;&nbsp;
													<MyButtons.MyCancella handleClick={openModal}/>
												</>
											) : (
												<MyButtons.MyAnnulla handleClick={closeNewTab}/>
											)}
											&nbsp;&nbsp;
											<MyButtons.MySalva/>
                    </div>
                  )}
                </div>

                <div class="form-group row">
                    {props.progettoId ? (
                      <div class="col-lg-6">
                        <MyForms.MySelect
                          label="Cliente"
                          name="clienteId"
                          class="form-control"
                          disabled={readOnly}
                          list={props.listClienti}
                          nameLabel="acronimo"
                        />
                      </div>
                    ) : (
                      <div class="col-lg-8">
                        <MyForms.MySelect
                          label="Progetto"
                          name="progettoId"
                          class="form-control"
                          disabled={readOnly}
                          list={props.listProgetti}
                          nameLabel="acronimo_titolo"
                        />
                      </div>
                    )}
                    <span class="form-text text-muted"></span>
                </div>
                <div class="form-group row">
                  <div class="col-lg-4">
                    <MyForms.MyTextInput
                      label="Importo"
                      name="importo"
                      type="number"
                      min="0.00" 
                      step="0.01" 
                      currency="1"
                      class="form-control"
                      disabled={readOnly}
                    />
                    <span class="form-text text-muted"></span>
                  </div>
                  <div class="col-lg-2">
                    <label>Previsto acconto:</label>
                    <div class="radio-inline">
                      <label class="radio radio-solid">
                        <Field
                          type="radio"
                          name="previstoAcconto"
                          value="true"
                          disabled={readOnly}
                        />
                        <span></span>
                        Sì
                      </label>
                      <label class="radio radio-solid">
                        <Field
                          type="radio"
                          name="previstoAcconto"
                          value="false"
                          disabled={readOnly}
                        />
                        <span></span>
                        No
                      </label>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <MyForms.MyTextInput
                      label="Importo acconto"
                      name="importoAcconto"
                      type="number"
                      min="0.00" 
                      step="0.01" 
                      currency="1"
                      class="form-control"
                      disabled={readOnly}
                    />
                    <span class="form-text text-muted"></span>
                  </div>
                  <div class="col-lg-2">
                    <label>Acconto Pagato:</label>
                    <div class="radio-inline">
                      <label class="radio radio-solid">
                        <Field
                          type="radio"
                          name="accontoPagato"
                          value="true"
                          disabled={readOnly}
                        />
                        <span></span>
                        Sì
                      </label>
                      <label class="radio radio-solid">
                        <Field
                          type="radio"
                          name="accontoPagato"
                          value="false"
                          disabled={readOnly}
                        />
                        <span></span>
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-4">
                    {!props.nuovaTab && (
                      <>
                        <label><strong>Allegato:</strong></label>
                        <div class="dati" modalita="view">
					                <span className="link" onClick={() => {Utils.downloadAllegato(props.contratto.allegatoId, allegato)}}>{allegato}</span>
                        </div>
                      </>
                    )}
                    <span class="form-text text-muted"></span>
                  </div>

                  {!readOnly && !props.nuovaTab && (
                    <div class="col-lg-6">
                      <AttachedFiles
                        contratto={props.contratto}
                        api_path_toupdate = {api_path_toupdate}
                        setAllegato={setAllegato}
                        doUpload={false}
                      />
                    </div>
                  )}
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            width: "150",
          },
          content: {
            color: "lightsteelblue",
            top: "5%",
            left: "30%",
            right: "30%",
            bottom: "auto",
          },
        }}
      >
        <h2 align="center">L'oggetto sta per essere eliminato</h2>
        <br />
        <br />
        <br />
        <div align="center">
          <div
            class="btn btn-outline-primary font-size-sm"
            onClick={closeModal}
            align="center"
          >
            Annulla
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div
            class="btn btn-outline-primary font-size-sm"
            onClick={() => {
              props.handleDelete(props.contratto.contrattoId);
              closeModal();
            }}
            align="center"
          >
            Conferma
          </div>
        </div>
      </Modal>
    </>
  );
}
