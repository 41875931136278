import React, { useState, useEffect, useCallback } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import * as MyForms from "./MyForms";
import * as MyButtons from "./MyButtons";
import * as Constants from "../../constants";
import ModalConfirm from "./ModalConfirm";
import BudgetTab from './BudgetTab';

export default function Partner(props) {
	const [fields, updateFields] = useState({});
	const [state, setState] = useState({});
	const [listVociCosto, setListVociCosto] = useState([]);
	const [listTipoAttivita, setListTipoAttivita] = useState([]);
	const [listBudgets, setlistBudgets] = useState([]);
	const [listTipoBudget, setlistTipoBudget] = useState([]);
	const [readOnly, setReadOnly] = useState(true);

	useEffect(() => {
		fetchBudgets();
		fetchVociCosto();
		fetchTipoAttivita();
		fetchTipoBudget();
	}, [props.partnerId, state]);


	const forceUpdate = React.useCallback(() => {
		setState({ key: Math.random() });
	}, []);

	const fetchBudgets = async () => {
		var api_path = Constants.Compose([Constants.PATH_PARTNERS, props.partnerId, Constants.PATH_BUDGET_INREL]);
		api_path += "?filter=" + JSON.stringify({'include':['tipoBudget', 'partnerCosti'], 'order': ['tipoBudgetId DESC']});
		const response_data = await Constants.getData(api_path);
		setlistBudgets(response_data);
	};

	const fetchTipoBudget = async () => {
		var api_path = Constants.Compose([Constants.PATH_TIPOBUDGET]);
		const response_data = await Constants.getData(api_path);
		setlistTipoBudget(response_data);
	};
	const fetchVociCosto = async () => {
		var api_path = Constants.Compose([Constants.PATH_BANDI, props.bandoId, Constants.PATH_COSTIBANDO_INREL]);
		api_path += "?filter=" + JSON.stringify({include:['voceCosto']});
		const response_data = await Constants.getData(api_path);
		var list = [];
		response_data.map((vocecosto) => {
			list.push(vocecosto.voceCosto)
		});
		setListVociCosto(list);
	};
	const fetchTipoAttivita = async () => {
		var api_path = Constants.Compose([Constants.PATH_TIPOATTIVITA]);
		const response_data = await Constants.getData(api_path);
		setListTipoAttivita(response_data);
	};

	const handleEdit = async (dataToSend) => {
		var api_path = Constants.Compose([Constants.PATH_PARTNERBUDGET]);
		const response_data = await Constants.putData(api_path, JSON.stringify(dataToSend));
		forceUpdate();
		props.forceUpdatePartner();
	};

	const handleDelete = async (partnerBudgetId) => {
		var api_path = Constants.Compose([Constants.PATH_PARTNERBUDGET, partnerBudgetId]);
		const response_data = await Constants.deleteData(api_path);
		forceUpdate();
	};

	const handlePost = async (dataToSend) => {
		var api_path = Constants.Compose([Constants.PATH_PARTNERBUDGET]);
		const response_data = await Constants.postData(api_path, dataToSend);

		forceUpdate();
		props.setNuovoBudget(false);
	};
	return (
		<>
			<ul class="nav nav-tabs nav-tabs-line mb-5">	
				{props.nuovoBudget && (
					<li class="nav-item">
						<a class={"nav-link active"} data-toggle="tab" href={"#tab_pane_budget_nuovo"}>
							<span class="nav-text">Nuovo Budget</span>
						</a>
					</li>
				)}
				{listBudgets.length > 0 && (
					listBudgets.map((budget, i) => {
						var active = (i === 0 && !props.nuovoBudget) ? 'active' : '';
						return (
							<li class="nav-item">
								<a class={"nav-link " + active} data-toggle="tab" href={"#tab_pane_budget_" + i}>
									<span class="nav-text">{budget.tipoBudget.tipoBudget}</span>
								</a>
							</li>
						)
					})
				)}
			</ul>
			<div class="tab-content" id="TabContent">
				{props.nuovoBudget && (
					<BudgetTab 
						active="active show" 
						id="nuovo"
						listVociCosto={listVociCosto}
						listTipoAttivita={listTipoAttivita}
						listTipoBudget={listTipoBudget}
						handlePost={handlePost}
						handleCloseNuovoBudget={props.handleCloseNuovoBudget}
						nuovoBudget={props.nuovoBudget}
						partnerId={props.partnerId}
						readOnly={false}
					/>
				)}
				{listBudgets.length > 0 && (
					listBudgets.map((budget, i) => {
						var active = (i === 0 && !props.nuovoBudget) ? 'active show' : '';
						return (
							<BudgetTab 
								budget={budget} 
								active={active} 
								id={i}
								listVociCosto={listVociCosto}
								listTipoAttivita={listTipoAttivita}
								listTipoBudget={listTipoBudget}
								handleEdit={handleEdit}
								handleDelete={handleDelete}
								readOnly={true}
							/>
						)
					})
				)}
			</div>
		</>
	)
}
