import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useLocation, withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as MyForms from "./MyForms";
import * as Constants from "../../constants";

export default function SedeLegale(props) {
  const [readOnly, setReadOnly] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleEditClick = () => {
    setReadOnly(!readOnly);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const handleDelete = async (id) => {
    const deleteUrl = Constants.PUTSEDIURL + id;
    await Constants.deleteData(deleteUrl);
    props.sedeLegale["indirizzo"] = "";
    props.sedeLegale["sedeId"] = "";
    props.sedeLegale["civico"] = "";
    props.sedeLegale["citta"] = "";
    setReadOnly(true);
    closeModal();
  };

  const handleEdit = async (id, dataToSend) => {
    const fetchUrl = Constants.PUTSEDIURL + id;
    await Constants.putData(fetchUrl, dataToSend);
    setReadOnly(true);
  };

  const handlePostSede = async (dataToSend) => {
    const postNewSede = async () => {
      const fetchurl = Constants.POSTSEDIURL;
      const response_data = await Constants.postData(fetchurl, dataToSend);
      // props.sedeLegale["sedeId"] = response_data.sedeId;
      props.updateSedeLegale(response_data);
      setReadOnly(true);
    };

    //La sede legale è una sede di tipo 1 legata al cliente chiamante
    dataToSend = { ...dataToSend, tipoSedeId: "1", clienteId: props.clienteId };

    postNewSede(JSON.stringify(dataToSend, null, 2));
  };

  return (
    <>
        <Formik
          enableReinitialize={true}
          initialValues={{
            indirizzo: props.sedeLegale ? props.sedeLegale["indirizzo"] : '',
            civico: props.sedeLegale ? props.sedeLegale["civico"] : '',
            citta: props.sedeLegale ? props.sedeLegale["citta"] : '',
          }}
          validationSchema={Yup.object({
            indirizzo: Yup.string(),
            civico: Yup.string(),
            citta: Yup.string(),
          })}
          onSubmit={(values, { setSubmitting }) => {
            !props.sedeLegale.sedeId
              ? handlePostSede(values)
              : handleEdit(
                  props.sedeLegale["sedeId"],
                  JSON.stringify(values, null, 2)
                );
          }}
          onReset={() => {
            setReadOnly(true);
          }}
        >
          <Form class="form pt-5">
            <div class="border-0 top_commandi" style={{ textAlign: "right" }}>
              {readOnly ? (
                <div>
                  <div
                    class="btn btn-primary font-weight-bolder"
                    onClick={handleEditClick}
                  >
                    <span class="svg-icon svg-icon-md">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24" />
                          <circle fill="#000000" cx="9" cy="15" r="6" />
                          <path
                            d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z"
                            fill="#000000"
                            opacity="0.3"
                          />
                        </g>
                      </svg>
                    </span>
                    Edit
                  </div>
                </div>
              ) : (
                <div>
                  {/* Se si sta inserendo una nuova scheda il tasto Cancella è invisibile ed il tasto
                    annulla riporta all'elenco. Se invece siamo in modifica il tasto cancella è visibile
                    ed il tasto annulla disabilita l'editing rimanendo sulla scheda*/}
                  <>
                    <button
                      type="reset"
                      class="btn btn-outline-primary font-size-sm"
                    >
                      <i class="icon fas fa-arrow-left"></i>
                      Annulla
                    </button>
                    &nbsp;&nbsp;
                    <div
                      class="btn btn-danger font-size-sm"
                      onClick={openModal}
                    >
                      <i class="icon far fa-trash-alt"></i>
                      Cancella
                    </div>
                  </>
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary font-weight-bolder"
                    type="submit"
                  >
                    <i class="icon far fa-save"></i> Salva
                  </button>
                </div>
              )}
            </div>
            <div class="form-group row">
              <div class="col-lg-6">
                <MyForms.MyTextInput
                  label="Via"
                  name="indirizzo"
                  type="text"
                  class="form-control"
                  disabled={readOnly}
                />
                <span class="form-text text-muted"></span>
              </div>
              <div class="col-lg-2">
                <MyForms.MyTextInput
                  label="Civico"
                  name="civico"
                  type="text"
                  class="form-control"
                  disabled={readOnly}
                />
                <span class="form-text text-muted"></span>
              </div>
              <div class="col-lg-4">
                <MyForms.MyTextInput
                  label="Città"
                  name="citta"
                  type="text"
                  class="form-control"
                  disabled={readOnly}
                />
                <span class="form-text text-muted"></span>
              </div>
            </div>
          </Form>
        </Formik>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            width: "150",
          },
          content: {
            color: "lightsteelblue",
            top: "5%",
            left: "30%",
            right: "30%",
            bottom: "auto",
          },
        }}
      >
        <h2 align="center">L'oggetto sta per essere eliminato</h2>
        <br />
        <br />
        <br />
        <div align="center">
          <div
            class="btn btn-outline-primary font-size-sm"
            onClick={closeModal}
            align="center"
          >
            Annulla
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div
            class="btn btn-outline-primary font-size-sm"
            onClick={() => {
              handleDelete(props.sedeLegale["sedeId"]);
            }}
            align="center"
          >
            Conferma
          </div>
        </div>
      </Modal>
    </>
  );
}
