import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as MyForms from "./MyForms";
import Modal from "react-modal";

/* Funzione per il render del tab */
export default function Dipendenti(props) {
  const [readOnly, setReadOnly] = useState(props.readOnly);
  const [isModalOpen, setModalOpen] = useState(false);
  const [divStyle, setDivStyle] = useState( props.nuovaTab ? "collapse show" : "collapse");

  const [livelli, setLivelli] = useState([
    { "tipoLivelloId": 1, "tipoLivello": "Alto" },
    { "tipoLivelloId": 2, "tipoLivello": "Medio" },
    { "tipoLivelloId": 3, "tipoLivello": "Basso" },
    { "tipoLivelloId": 4, "tipoLivello": "Non dipendente" },
  ]);

  const handleEditClick = () => {
    setReadOnly(!readOnly);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const closeNewTab = () => {
    console.log("Tab");
    props.setNewTab(false);
  };
  return (
    <>
      <div class="card">
        <div class="card-header">
          {props.nuovaTab ? (
            <div class="card-title" data-toggle="collapse" data-target={"#collapsedipendenti" + props.dataId} >
              <i class=" flaticon-avatar"></i>
            </div>
          ) : (
            <div class="card-title collapsed" data-toggle="collapse" data-target={"#collapsedipendenti" + props.dataId} >
              <i class=" flaticon-avatar"></i>
              {props.dipendente.persona.cognome + " " + props.dipendente.persona.nome}
            </div>
          )}
        </div>
        <div id={"collapsedipendenti" + props.dataId} class={divStyle} data-parent="#accordionDipendenti" >
          <div class="card-body">
            <Formik
              enableReinitialize={true}
              initialValues={
                !props.nuovaTab
                  ? {
                      nome: props.dipendente.persona.nome,
                      cognome: props.dipendente.persona.cognome,
                      tipoLivelloId: props.dipendente.tipoLivelloId,
                      maxOreMese: props.dipendente.maxOreMese,
                      maxOreAnno: props.dipendente.maxOreAnno,
                      tipoContrattoId: props.dipendente.tipoContratto.tipoContrattoId,
                      codicefiscale: props.dipendente.persona.codicefiscale,
                    }
                  : {
                      nome: "",
                      cognome: "",
                      tipoLivelloId: "",
                      maxOreMese: "",
                      maxOreAnno: "",
                      tipoContrattoId: "",
                      codicefiscale: "",
                    }
              }
              validationSchema={Yup.object({
                nome: Yup.string(),
                cognome: Yup.string(),
                codicefiscale: Yup.string(),
                tipoContrattoId: Yup.number().required("Campo obbligatorio"),
                tipoLivelloId: Yup.number().required("Campo obbligatorio"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                // alert(JSON.stringify(values, null, 2));
                console.log(values);
                !props.nuovaTab
                  ? props.handleEdit(
                      props.dipendente.persona.personaId,
                      props.dipendente.dipendenteId,
                      values
                    )
                  : props.handlePost(values);

                setReadOnly(true);
              }}
              onReset={() => {
                setReadOnly(true);
              }}
            >
              <Form class="form">
                <div
                  class="border-0 top_commandi bp-5"
                  style={{ textAlign: "right" }}
                >
                  {readOnly ? (
                    <div>
                      <div
                        class="btn btn-primary font-weight-bolder"
                        onClick={handleEditClick}
                      >
                        <span class="svg-icon svg-icon-md">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle fill="#000000" cx="9" cy="15" r="6" />
                              <path
                                d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z"
                                fill="#000000"
                                opacity="0.3"
                              />
                            </g>
                          </svg>
                        </span>
                        Edit
                      </div>
                    </div>
                  ) : (
                    <div>
                      {/* Se si sta inserendo una nuova scheda il tasto Cancella è invisibile ed il tasto
                    annulla riporta all'elenco. Se invece siamo in modifica il tasto cancella è visibile
                    ed il tasto annulla disabilita l'editing rimanendo sulla scheda*/}
                      {!props.nuovaTab ? (
                        <>
                          <button
                            type="reset"
                            class="btn btn-outline-primary font-size-sm"
                          >
                            <i class="icon fas fa-arrow-left"></i>
                            Annulla
                          </button>
                          &nbsp;&nbsp;
                          <div
                            class="btn btn-danger font-size-sm"
                            onClick={openModal}
                          >
                            <i class="icon far fa-trash-alt"></i>
                            Cancella
                          </div>
                        </>
                      ) : (
                        <div
                          onClick={closeNewTab}
                          class="btn btn-outline-primary font-size-sm"
                        >
                          <i class="icon fas fa-arrow-left"></i>
                          Annulla
                        </div>
                      )}
                      &nbsp;&nbsp;
                      <button
                        class="btn btn-primary font-weight-bolder"
                        type="submit"
                      >
                        <i class="icon far fa-save"></i> Salva
                      </button>
                    </div>
                  )}
                </div>

                <div class="form-group row">
                  <div class="col-lg-4">
                    <MyForms.MyTextInput
                      label="Nome"
                      name="nome"
                      //type="text"
                      class="form-control"
                      disabled={readOnly}
                    />
                    <span class="form-text text-muted"></span>
                  </div>
                  <div class="col-lg-4">
                    <MyForms.MyTextInput
                      label="Cognome"
                      name="cognome"
                      //type="text"
                      class="form-control"
                      disabled={readOnly}
                    />
                    <span class="form-text text-muted"></span>
                  </div>
                  <div class="col-lg-4">
                    <MyForms.MySelect
                      label="Livello"
                      name="tipoLivelloId"
                      class="form-control"
                      disabled={readOnly}
                      list={livelli}
                      nameLabel="tipoLivello"
                    >
                    </MyForms.MySelect>
                    <span class="form-text text-muted"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-lg-2">
                    <MyForms.MyTextInput
                      label="Max Ore Mensili"
                      name="maxOreMese"
                      //type="text"
                      class="form-control"
                      disabled={readOnly}
                    />
                    <span class="form-text text-muted"></span>
                  </div>
                  <div class="col-lg-2">
                    <MyForms.MyTextInput
                      label="Max Ore Annuali"
                      name="maxOreAnno"
                      //type="text"
                      class="form-control"
                      disabled={readOnly}
                    />
                    <span class="form-text text-muted"></span>
                  </div>
                  <div class="col-lg-4">
                    <MyForms.MySelect
                      label="Tipo Contratto"
                      name="tipoContrattoId"
                      class="form-control"
                      disabled={readOnly}
                      list={props.tipiContratti}
                      nameLabel="tipoContratto"
                    >
                    </MyForms.MySelect>
                    <span class="form-text text-muted"></span>
                  </div>
                  <div class="col-lg-4">
                    <MyForms.MyTextInput
                      label="Codice Fiscale"
                      name="codicefiscale"
                      //type="text"
                      class="form-control"
                      disabled={readOnly}
                    />
                    <span class="form-text text-muted"></span>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            width: "150",
          },
          content: {
            color: "lightsteelblue",
            top: "5%",
            left: "30%",
            right: "30%",
            bottom: "auto",
          },
        }}
      >
        <h2 align="center">L'oggetto sta per essere eliminato</h2>
        <br />
        <br />
        <br />
        <div align="center">
          <div
            class="btn btn-outline-primary font-size-sm"
            onClick={closeModal}
            align="center"
          >
            Annulla
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div
            class="btn btn-outline-primary font-size-sm"
            onClick={() => {
              props.handleDelete(
                props.dipendente.persona.personaId,
                props.dipendente.dipendenteId
              );
              closeModal();
            }}
            align="center"
          >
            Conferma
          </div>
        </div>
      </Modal>
    </>
  );
}
