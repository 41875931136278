import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as MyButtons from "./MyButtons";
import axios from "axios";
import Modal from "react-modal";
import RendicontazioneTab from "./RendicontazioneTab";
import * as Constants from "../../constants";

export default function RendicontazioneStack(props) {
	const [listSal, setlistSal] = useState([]);
	const [listVociCosto, setListVociCosto] = useState([]);
	const [state, setState] = useState();
	const [newTab, setNewTab] = useState(false);

	const forceUpdate = React.useCallback(() => {
		setState({});
	}, []);

	useEffect(() => {
		if(props.displayTab == 9) {
			fetchSal();
		}
	}, [props.displayTab]);

	const fetchSal = async () => {
		var api_path = Constants.Compose([Constants.PATH_PROGETTI, props.progettoId, Constants.PATH_SAL]);
		api_path += "?filter=" + JSON.stringify({include: ['tipoStatoRendicontazione'], order:['numero']});
		const response_data = await Constants.getData(api_path);

		setlistSal(response_data);

		// Voci di costo del bando
		var api_path = Constants.Compose([Constants.PATH_BANDI, props.bandoId, Constants.PATH_COSTIBANDO_INREL]);
		api_path += "?filter=" + JSON.stringify({include:['voceCosto']});
		const vocicosto = await Constants.getData(api_path);
		var list = [];
		vocicosto.map((vocecosto) => {
			list.push(vocecosto.voceCosto)
		});
		setListVociCosto(list);
	};

	const handleDeleteSal = async (idSal) => {
		var api_path = Constants.Compose([Constants.PATH_SAL, idSal]);
		const response_data = await Constants.deleteData(api_path);
		forceUpdate();
	};

	const handleEditSal = async (idSal, dataToSend) => {
		var api_path = Constants.Compose([Constants.PATH_SAL, idSal]);
		const response_data = await Constants.putData(api_path, JSON.stringify(dataToSend));

		forceUpdate();
	};

	const handlePostSal = async (dataToSend) => {
		dataToSend.progettoId = props.progettoId;
		var api_path = Constants.Compose([Constants.PATH_SAL], props.progettoId, [Constants.PATH_SAL_INREL]);
		const response_data = await Constants.postData(api_path, dataToSend);

		forceUpdate();
		setNewTab(false);
	};

	const handleNewClick = () => {
		setNewTab(true);
	};

	return (
		<div>
			<div class="border-0 top_commandi" style={{ textAlign: "right" }}>
				<MyButtons.MyAggiungi label="Nuovo SAL" handleClick={handleNewClick} />
			</div>
			<div class="accordion accordion-solid accordion-toggle-plus" id="accordionRendicontazione" >
				{newTab && (
					<RendicontazioneTab
						key='new'
						setNewTab={setNewTab}
						handleEdit={handleEditSal}
						handlePost={handlePostSal}
						handleDelete={handleDeleteSal}
						nuovaTab={true}
						dataId={listSal.length + 1}
						readOnly={false}
					/>
				)}
				{listSal.length > 0 && (
					listSal.map((mySal, i) => (
						<RendicontazioneTab
							key={i}
							sal={mySal}
							vocicosto={listVociCosto}
							dataId={i}
							handleEdit={handleEditSal}
							handlePost={handlePostSal}
							handleDelete={handleDeleteSal}
							readOnly={true}
							forceUpdate={forceUpdate}
						/>
					))
				)}
			</div>
		</div>
	);
}
