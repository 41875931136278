import React from "react";
import Breadcrumb from "./Breadcrumb";
import {
  Banche,
  Bandi,
  EntiErogatori,
  ETS,
  Clienti,
  ResponsabiliCommessa,
  Progetti,
  Utenti,
} from "./Tables";
import Dashboard from "./Dashboard";
import { withRouter } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { PrivateRoute } from "../../App";
function Content(props) {
  const breadcrumbs = useBreadcrumbs();

  return (
    <div className="content  d-flex flex-column flex-column-fluid" id="kt_content">
      <div className="subheader py-2 py-lg-12  subheader-transparent " id="kt_subheader">
        <div className=" container  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          <div className="d-flex align-items-center flex-wrap mr-1">
            <div className="d-flex flex-column">
              <h2 className="text-white font-weight-bold my-2 mr-5">
                {breadcrumbs.pop().breadcrumb.props.children}
              </h2>
              <Breadcrumb actualPage={props.actualPath} />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column-fluid">
        <div className=" container ">
          <PrivateRoute exact path="/">
            <Dashboard actualPath="Dashboard" />
          </PrivateRoute>
          <PrivateRoute exact path="/Dashboard">
            <Dashboard actualPath="Dashboard" />
          </PrivateRoute>
          <PrivateRoute path="/Banche">
            <div className="card card-custom">
              <Banche />
            </div>
          </PrivateRoute>
          <PrivateRoute path="/Bandi">
            <div className="card card-custom">
              <Bandi />
            </div>
          </PrivateRoute>
          <PrivateRoute path="/Enti Erogatori">
            <div className="card card-custom">
              <EntiErogatori />
            </div>
          </PrivateRoute>
          <PrivateRoute path="/ETS">
            <div className="card card-custom">
              <ETS />
            </div>
          </PrivateRoute>
          <PrivateRoute path="/Clienti">
            <div className="card card-custom">
              <Clienti />
            </div>
          </PrivateRoute>
          <PrivateRoute path="/Responsabili Commessa">
            <div className="card card-custom">
              <ResponsabiliCommessa />
            </div>
          </PrivateRoute>
          <PrivateRoute path="/Progetti">
            <div className="card card-custom">
              <Progetti />
            </div>
          </PrivateRoute>
          <PrivateRoute path="/Utenti">
            <div className="card card-custom">
              <Utenti />
            </div>
          </PrivateRoute>
        </div>
      </div>
    </div>
    /* <!--end::Content--> */
  );
}

export default withRouter(Content);
