import React, { useState, useEffect, useCallback } from "react";
import Modal from "react-modal";
import * as Constants from "../../constants";
import * as MyButtons from "./MyButtons";
import ReactDataSheet from 'react-datasheet';
import ModalGdL from "./GdL";
import 'react-datasheet/lib/react-datasheet.css';
import '../../../custom-datasheet.css';
import * as Utils from './Utils';

Modal.setAppElement("#root");

export default function PLivelloModal(props) {
	const [state, setState] = useState();
	const [myGrid, setGrid] = useState([]);
	const [selectedPLivello, setDataPLivello] = useState({});
	const [AlertNoBudget, setAlertNoBudget] = useState(false);
	const [TipoBudget, setTipoBudget] = useState();
	const [ImportoBudget, setImportoBudget] = useState();
	const [isModalGdLOpen, setModalGdl] = useState(false);
	const [RTotale, setRTotale] = useState();
	const [RDifferenza, setRDifferenza] = useState();

	useEffect(() => {
		if(props.partnerAttivitaId) fetchPLivello();
	}, [props.partnerAttivitaId, state]);

	useEffect(() => {
		PreparePLivello(selectedPLivello);
		var importoBudget = 0;
		if(props.lastBudget) {
			setTipoBudget(props.lastBudget.tipoBudget.tipoBudget);
			var rowBudgetDip = props.lastBudget.partnerCosti.find(obj => {return obj.voceCostoId == 1 && obj.tipoAttivitaId == selectedPLivello.tipoAttivitaId}) || 0;
			var rowBudgetNonDip = props.lastBudget.partnerCosti.find(obj => {return obj.voceCostoId == 2 && obj.tipoAttivitaId == selectedPLivello.tipoAttivitaId}) || 0;
			importoBudget = parseFloat(rowBudgetDip.importo) + parseFloat(rowBudgetNonDip.importo)
			setAlertNoBudget(false);
		} else {
			setAlertNoBudget(true);
		}
		setImportoBudget(Utils.Format(importoBudget));
	}, [selectedPLivello]);

	const fetchPLivello = async () => {
		var api_path = Constants.Compose([Constants.PATH_PARTNERSATTIVITA, props.partnerAttivitaId, 'pianificazioneLivelli']);
		const response_data = await Constants.getData(api_path);
		setDataPLivello(response_data)
	};


	const calc = function(grid, RowTotale, RowDifferenza) {
		if(!RowTotale) RowTotale = RTotale;
		if(!RowDifferenza) RowDifferenza = RDifferenza;

		if(grid.length) {
			// STIMA EFFORT E COSTO CALCOLATO
			for(var i=1;i<=grid.length-4; i++) {
				var row = grid[i];
				// var A = 1; // Peso in % attribuito al livello ... DA DOVE SI PRENDE?
				var D = parseInt(row[1].value); // Costo medio orario
				var E = parseInt(row[2].value); // Num dip assegnati al progetto
				var F = parseInt(row[3].value); // Monte ore mensili
				var G = parseFloat(row[4].value); // % Impegno sul progetto
				var B = parseInt(selectedPLivello.durata);

				var stimaEffortConfermato = parseInt(row[7].value);
				var stimaEffortCalcolato = parseFloat(B*E*F*G/100).toFixed(2);

				var stimaCostoCalcolato = stimaEffortCalcolato*D;
				grid[i][6]['valuePulito'] = stimaCostoCalcolato;
				grid[i][6]['value'] = Utils.Format(stimaCostoCalcolato);
				var stimaCostoConfermato = Utils.Format(stimaEffortConfermato*D);

				grid[i][5]['valuePulito'] = stimaEffortCalcolato;
				grid[i][5]['value'] = Utils.FormatNumber(stimaEffortCalcolato);

				var stimaCostoConfermato = stimaEffortConfermato*D;
				grid[i][8]['valuePulito'] = stimaCostoConfermato;
				grid[i][8]['value'] = Utils.Format(stimaCostoConfermato);
			}

			// TOTALI 
			var numRows = grid.length-2;
			var sumStimaEffortCalcolato = 0;
			var sumStimaCostoCalcolato = 0.0;
			var sumStimaEffortConfermato = 0;
			var sumStimaCostoConfermato = 0.0;

			for(var i=1;i<=grid.length-4; i++) {
			  	sumStimaEffortCalcolato += parseFloat(grid[i][5].valuePulito);
			  	sumStimaCostoCalcolato += parseFloat(grid[i][6].valuePulito);
			  	sumStimaEffortConfermato += parseFloat(grid[i][7].value);
			  	sumStimaCostoConfermato += parseFloat(grid[i][8].valuePulito);
			}
			grid[RowTotale][5]['value'] = Utils.FormatNumber(sumStimaEffortCalcolato.toFixed(2));
			grid[RowTotale][6]['valuePulito'] = sumStimaCostoCalcolato;
			grid[RowTotale][6]['value'] = Utils.Format(sumStimaCostoCalcolato);
			grid[RowTotale][7]['value'] = sumStimaEffortConfermato;
			grid[RowTotale][8]['valuePulito'] = sumStimaCostoConfermato;
			grid[RowTotale][8]['value'] = Utils.Format(sumStimaCostoConfermato);
			// grid[rowTotale][i]['className'] = sum < 0 ? '' : '';
		
			// DIFFERENZA
			var differenza_calc = grid[RowTotale][6].valuePulito - grid[RowTotale+1][1].valuePulito;
			grid[RowDifferenza][1]['className'] = differenza_calc == 0 ? 'cifra' : 'cifra red';
			grid[RowDifferenza][1]['value'] = Utils.Format(differenza_calc);

			var differenza_conf = grid[RowTotale][8].valuePulito - grid[RowTotale+1][3].valuePulito;
			grid[RowDifferenza][3]['className'] = differenza_conf == 0 ? 'cifra' : 'cifra red';
			grid[RowDifferenza][3]['value'] = Utils.Format(differenza_conf);

			setGrid(grid);
		}
	}
	const PreparePLivello = function (data) {
		if (!data.livelli) return;
		var grid = [];

		// INTESTAZIONE
		var rowGrid = [];
		rowGrid.push(
			{ value: "", readOnly: true, className: "thcell"},
			{ value: "Costo medio orario (D)", readOnly: true, className: "thcell"},
			{ value: "Num dipendenti assegnati al progetto (E)", readOnly: true, className: "thcell"},
			{ value: "Monte ore mensili (F)", readOnly: true, className: "thcell"},
			{ value: "% Impegno sul progetto (G)", readOnly: true, className: "thcell"},
			{ value: "Stima effort calcolato (H=B*E*F*G)", readOnly: true, className: "thcell"},
			{ value: "Stima costo calcolato (H*D)", readOnly: true, className: "thcell"},
			{ value: "Stima effort confermato (I)", readOnly: true, className: "thcell"},
			{ value: "Stima costo confermato (I*D)", readOnly: true, className: "thcell"}
		);
		grid.push(rowGrid);

		// LIVELLI
		if(data.livelli) {
			data.livelli.map(liv => {
				if(!liv.numDip) return true;
				var rowGrid = [];
				rowGrid.push(
					{"value": liv.tipoLivello, "tipoLivelloId": liv.tipoLivelloId, "readOnly": true, "className": "thcell"},
					{"value": Utils.Format(liv.costoMedioOrario), "tipoLivelloId": liv.tipoLivelloId, "readOnly": true},
					{"value": liv.numDip, "tipoLivelloId": liv.tipoLivelloId, "readOnly": true},
					{"value": liv.oreMensili, "tipoLivelloId": liv.tipoLivelloId, "readOnly": true},
					{"value": liv.impegno, "tipoLivelloId": liv.tipoLivelloId, className:"cifra"},
					{"value": 0, "tipoLivelloId": liv.tipoLivelloId, readOnly:true, className:"cifra"},
					{"value": 0, "tipoLivelloId": liv.tipoLivelloId, readOnly:true, className:"cifra"},
					{"value": liv.effortConfermato, "tipoLivelloId": liv.tipoLivelloId, className:"cifra"},
					{"value": liv.costoConfermato, "tipoLivelloId": liv.tipoLivelloId, readOnly:true, className:"cifra"}
				);
				grid.push(rowGrid);
			});
		}

		// TOTALE
		var rowGrid = [
			{"value": "TOTALE", "readOnly": true, className:"thcell"},
			{"value": "", "readOnly": true, className:"thcell"},
			{"value": "", "readOnly": true, className:"thcell"},
			{"value": "", "readOnly": true, className:"thcell"},
			{"value": "", "readOnly": true, className:"thcell"},
			{"value": 0, readOnly:true, className:"cifra"},
			{"value": 0, readOnly:true, className:"cifra"},
			{"value": 0, readOnly:true, className:"cifra"},
			{"value": 0, readOnly:true, className:"cifra thcell"},
		];
		grid.push(rowGrid);
		const RowTotale = grid.length-1;

		// FOOTER
		var rowGrid = [
			{"value": "Stima costo personale per l'attività (A*C)", "readOnly": true, colSpan:6, className:"thcell"},
			{"value": Utils.Format(data.costoPers), "valuePulito": data.costoPers, "readOnly": true, className:"cifra"},
			{"value": "", "readOnly": true, className:"thcell"},
			{"value": Utils.Format(data.costoPers), "valuePulito": data.costoPers, "readOnly": true, className:"cifra"},
		];
		grid.push(rowGrid);
		var rowGrid = [
			{"value": "Differenza", "readOnly": true, colSpan:6, className:"thcell"},
			{"value": 0, "readOnly": true, className:"cifra"},
			{"value": "", "readOnly": true, className:"thcell"},
			{"value": "", "readOnly": true, className:"thcell"},
		];
		grid.push(rowGrid);
		const RowDifferenza = grid.length-1;

		setRTotale(RowTotale);
		setRDifferenza(RowDifferenza);

		// Passo RowTotale e RowDiffenza perche il setRTotale e setRDifferenza e' asincrono
		calc(grid, RowTotale, RowDifferenza);
	}

	const handlePost = async () => {
		var dataToSend = {"pianificazioneLivelli":[]};
		var api_path = Constants.Compose([Constants.PATH_PARTNERSATTIVITA, props.partnerAttivitaId, 'pianificazioneLivelli']);
		var dataToSend = [];
		myGrid.map(row => {
			if(row[0].tipoLivelloId) {
				dataToSend.push({
					"partnerAttivitaId": props.partnerAttivitaId, 
					"tipoLivelloId": row[0].tipoLivelloId, 
					"impegno": row[4].value, 
					"effortCalcolato": row[5].valuePulito, 
					"costoCalcolato": row[6].valuePulito, 
					"effortConfermato": row[7].value, 
					"costoConfermato": row[8].valuePulito, 
				});
			}
		});
		const response_data = await Constants.postData(api_path, dataToSend);
		props.forceUpdate();
		props.onRequestClose();
	};


	const openModalGdL = () => {
		setModalGdl(true);
	};
	const closeModalGdL = () => {
		setModalGdl(false);
		fetchPLivello()
	};
	return (
		<>
			<Modal
				isOpen={props.isModalOpen}
				onRequestClose={props.onRequestClose}
				style={{
					overlay: {
						width: "350",
					},
					content: {
						top: "5%",
						left: "7%",
						right: "7%",
						bottom: "auto",
					},
				}}
			>
				<div className="modal-content">
					<div className="modal-header">
						<h5>Attività {selectedPLivello.progettoOrNumero}.{selectedPLivello.attivitaNumero} ({selectedPLivello.tipoAttivita})- {selectedPLivello.attivitaDescrizione}</h5>
						<h6>Pianificazione livello &nbsp;
						{! props.statoPLivello ? ( 
							<span class="label label-lg label-light-warning label-inline"> Da calcolare </span>
						) : (
							<span class="label label-lg label-light-success label-inline"> Calcolato </span>
						)}
						</h6>
						<MyButtons.MyClose handleClick={props.onRequestClose} />
					</div>
					<div className="modal-body">
						<div class="border-0 top_commandi" style={{ textAlign: "right" }} >
							<MyButtons.MyModifica handleClick={openModalGdL} label="Modifica GdL"/>
							&nbsp;&nbsp;
							<MyButtons.MyModifica label="Salva" handleClick={handlePost} />
						</div>
						<div class="border-0 top_commandi" style={{ textAlign: "right" }} >
							<table class="table-borderless" style={{width:"30%",border:"1px"}}>
								{AlertNoBudget === true ? (
									<tr>
										<td colspan="2" style={{color:'red'}}><strong>ATTENZIONE: non è stato inserito nessun budget per questo partner</strong></td>
									</tr>
								) : (
									<tr>
										<td><strong>Costo personale da {TipoBudget} per {selectedPLivello.tipoAttivita} (A)</strong></td>
										<td>{ImportoBudget}</td>
									</tr>
								)}
								<tr>
									<td><strong>Durata attività (B)</strong></td>
									<td>{selectedPLivello.durata}</td>
								</tr>
								<tr>
									<td><strong>Peso attività (C)</strong></td>
									<td>{selectedPLivello.peso} %</td>
								</tr>
							</table>
						</div>
						<ReactDataSheet
							data={myGrid}
							valueRenderer={cell => cell.value}
							className='custom-sheet'
							onCellsChanged={changes => {
								const grid = myGrid.map(row => [...row]);
								changes.forEach(({ cell, row, col, value }) => {
									if(!value) value = 0;
									value = isNaN(value) ? 0 : value;
									value = parseFloat(value);
									grid[row][col] = { ...grid[row][col], value };
								});
								calc(grid);
							}}
						/>
					</div>
				</div>
			</Modal>
			<ModalGdL
				isModalGdLOpen={isModalGdLOpen}
				onRequestClose={closeModalGdL}
				partnerId={props.partnerId}
			/>
		</>
	)
}