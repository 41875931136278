import React, { useState, useEffect } from "react";
import * as MyButtons from "./MyButtons";
import PianificazioneTab from "./PianificazioneTab";
import * as Constants from "../../constants";

export default function PartnerStack(props) {
	const [state, setState] = useState();
	const [listPartner, setlistPartner] = useState([]);

	const forceUpdate = React.useCallback(() => {
		setState({ key: Math.random() });
	}, []);

	useEffect(() => {
		if(props.displayTab == 8) {
			fetchPartners();
		}
	}, [props.displayTab]);

	const fetchPartners = async () => {
		var api_path = Constants.Compose([Constants.PATH_PROGETTI, props.progettoId, 'pianificazione']);
		const response_data = await Constants.getData(api_path);
		response_data.map((partner) => {
			partner.pLivello.map((liv) => {
				liv.attivitaNumero = liv.progettoOrNumero + "." + liv.attivitaNumero;
			});
		});
		setlistPartner(response_data);
	};

	const handleEdit = async (idProgettoOr, dataToSend) => {
		var api_path = Constants.Compose([Constants.PATH_PARTNERS]);
		const response_data = await Constants.putData(api_path, JSON.stringify(dataToSend));
		forceUpdate();
	};
	return (
		<div>
			<div class="accordion accordion-solid accordion-toggle-plus" id="accordionPianificazione" >
				{listPartner.map((partner, i) => (
					<PianificazioneTab
              			key={i}
						handleEdit={handleEdit}
						partner={partner}
						progetto={props.progetto}
						progettoId={props.progettoId}
						bandoId={props.bandoId}
						forceUpdate={forceUpdate}
					/>
				))}
			</div>
		</div>
	);
}
