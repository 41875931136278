import React, { useState, useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import * as MyForms from "./MyForms";
import * as MyButtons from "./MyButtons";
import * as Constants from "../../constants";
import ModalConfirm from "./ModalConfirm";
import ModalContatti from "./ModalContatti";

function FormProgetto(props) {
	const [fields, updateFields] = useState(useLocation().state); //I campi vengono inizializzati con l'oggetto state passato fra le props del router
	const [readOnly, setReadOnly] = useState(true);
	const [isModalConfirmOpen, setModalConfirm] = useState(false);
	const [isModalContattiOpen, setModalContatti] = useState(false);
	const [bandi, setBandi] = useState([]);
	const [ets, setEts] = useState([]);
	const [banche, setBanche] = useState([]);
	const [respCommessa, setRespCommessa] = useState([]);
	const [statoIstruttoria, setStatoIstruttoria] = useState([]);
	const [proroghe, setProroghe] = useState([]);

	useEffect(() => {
		if (props.nuovaScheda) {
			updateFields(null);
			setReadOnly(false);
		}

		fetchBanche();
		fetchBandi();
		fetchEts();
		fetchRespCommessa();
		fetchStatoIstruttoria();
	}, [props.nuovaScheda]);

	const fetchBandi = async () => {
		const url = Constants.GETBANDIURL;
		const response_data = await Constants.getData(url);
		setBandi(response_data);
	};

	const fetchEts = async () => {
		const url = Constants.GETETSURL;
		const response_data = await Constants.getData(url);
		var list = [];
		response_data.map((myEle) => {
			list.push({"etsPersonaId": myEle.personaId, "nome": myEle.nome + ' ' + myEle.cognome});
		});
		setEts(list);
	};

	const fetchRespCommessa = async () => {
		const url = Constants.GETRESPCOMMURL;
		const response_data = await Constants.getData(url);
		var list = [];
		response_data.map((myEle) => {
			list.push({"rcPersonaId": myEle.personaId, "nome": myEle.nome + ' ' + myEle.cognome});
		});
		setRespCommessa(list);
	};

	const fetchBanche = async () => {
		const url = Constants.GETBANCHEURL;
		const response_data = await Constants.getData(url);
		setBanche(response_data);
	};

	const fetchStatoIstruttoria = async () => {
		const api_path = Constants.Compose([Constants.PATH_TIPOISTRUTTORIA]);
		const response_data = await Constants.getData(api_path);
		setStatoIstruttoria(response_data);
	};

	const fetchProroghe = async () => {};


	const handleEditClick = () => {
		setReadOnly(!readOnly);
	};

	const openModalConfirm = () => {
		setModalConfirm(true);
	};
	const closeModalConfirm = () => {
		setModalConfirm(false);
	};

	const openModalContatti = () => {
		setModalContatti(true);
	};
	const closeModalContatti = () => {
		setModalContatti(false);
	};

	const backToList = () => {
		props.backFromScheda();
	};

	const handleDelete = async () => {
		const url = Constants.Compose([Constants.PATH_PROGETTI, fields["progettoId"]]);
		await Constants.deleteData(url);
		props.backFromScheda();
	};

	const handleEdit = async (dataToSend) => {
		const api_path = Constants.Compose([Constants.PATH_PROGETTI, fields["progettoId"]]);
		var tmp = await Constants.putData(api_path, dataToSend);
		props.updateProjectData(tmp)
		setReadOnly(true);
	};

	const handlePost = async (dataToSend) => {
		const postNewProgetto = async () => {
			const fetchurl = Constants.POSTPROGETTIURL;
			const response_data = await Constants.postData(fetchurl, dataToSend);
			setReadOnly(true);
			props.changeState(response_data);
		};
		postNewProgetto();
	};

	return (
		<>
			<div class="tab-pane fade show active" id="tab_pane_1" role="tabpanel" >
				<div>
					<Formik
						enableReinitialize={true}
						initialValues={
							!props.nuovaScheda ? {
								titolo: fields["titolo"],
								acronimo: fields["acronimo"],
								cup: fields["cup"],
								bandoId: fields["bandoId"],
								etsPersonaId: fields["etsPersonaId"],
								bancaId: fields["bancaId"],
								rcPersonaId: fields["rcPersonaId"],
								ammessoValutazione: String(fields["ammessoValutazione"]),
								costiReali: String(fields["costiReali"]),
								tipoStatoIstruttoriaId: fields["tipoStatoIstruttoriaId"],
								ammissioneProtocollo: fields["ammissioneProtocollo"],
								ammissioneNumero: fields["ammissioneNumero"],
								concessioneProtocollo: fields["concessioneProtocollo"],
								concessioneNumero: fields["concessioneNumero"],
								dataPresentazione: fields["dataPresentazione"],
								dataInizioPrev: fields["dataInizioPrev"],
								dataInizioEff: fields["dataInizioEff"],
								dataFinePrev: fields["dataFinePrev"],
								dataFineEff: fields["dataFineEff"],
								descrizione: fields["descrizione"],
								note: fields["note"],
							} : {
								titolo: "",
								acronimo: "",
								cup: "",
								bandoId: "",
								etsPersonaId: "",
								bancaId: "",
								rcPersonaId: "",
								ammessoValutazione: "",
								costiReali: "",
								tipoStatoIstruttoriaId: [],
								ammissioneProtocollo: "",
								ammissioneNumero: "",
								concessioneProtocollo: "",
								concessioneNumero: "",
								dataPresentazione: "",
								dataInizioPrev: "",
								dataInizioEff: "",
								dataFinePrev: "",
								dataFineEff: "",
								descrizione: "",
								note: "",
							}
						}
						validationSchema={Yup.object({
							titolo: Yup.string().required("Campo Obbligatorio"),
							acronimo: Yup.string().required("Campo Obbligatorio"),
							cup: Yup.string(),
							rcPersonaId: Yup.number().required("Campo Obbligatorio"),
							etsPersonaId: Yup.number().required("Campo Obbligatorio"),
							bancaId: Yup.number().required("Campo Obbligatorio"),
							bandoId: Yup.number().required("Campo Obbligatorio"),
							tipoStatoIstruttoriaId: Yup.number().required("Campo Obbligatorio"),
							dataInizioPrev: Yup.date().required("Campo Obbligatorio"),
						})}
						onSubmit={(values, { setSubmitting }) => {
							if(values['dataPresentazione'] == "") delete values['dataPresentazione'];
							if(values['dataInizioPrev'] == "") delete values['dataInizioPrev'];
							if(values['dataInizioEff'] == "") delete values['dataInizioEff'];
							if(values['dataFinePrev'] == "") delete values['dataFinePrev'];
							if(values['dataFineEff'] == "") delete values['dataFineEff'];
							!props.nuovaScheda
								? handleEdit(JSON.stringify(values))
								: handlePost(JSON.stringify(values));
						}}
						onReset={() => {
							setReadOnly(true);
						}}
					>
						<Form class="form">
							<div class="border-0 top_commandi bp-5" style={{ textAlign: "right" }}>
								{readOnly ? (
									<div>
										<MyButtons.MyAnnulla label="Torna alla lista" handleClick={backToList}/>
										&nbsp;&nbsp;
										<MyButtons.MyAnnulla label="Visualizza contatti" handleClick={openModalContatti} />
										&nbsp;&nbsp;
										<MyButtons.MyModifica handleClick={handleEditClick} />
									</div>
								) : (
									<div>
										{/* Se si sta inserendo una nuova scheda il tasto Cancella è invisibile ed il tasto
											annulla riporta all'elenco. Se invece siamo in modifica il tasto cancella è visibile
											ed il tasto annulla disabilita l'editing rimanendo sulla scheda*/}
										{!props.nuovaScheda ? (
											<>
												<MyButtons.MyAnnulla/>
												&nbsp;&nbsp;
												<MyButtons.MyCancella handleClick={openModalConfirm}/>
											</>
										) : (
											<MyButtons.MyAnnulla handleClick={backToList}/>
										)}
										&nbsp;&nbsp;
										<MyButtons.MySalva/>
									</div>
								)}
							</div>
							{/* Si istanziano le nostre Form. */}
							<div class="form-group row">
								<div class="col-lg-4">
									<MyForms.MyTextInput
										label="Titolo"
										name="titolo"
										class="form-control"
										disabled={readOnly}
									/>
									<span class="form-text text-muted"></span>
								</div>
								<div class="col-lg-4">
									<MyForms.MyTextInput
										label="Acronimo"
										name="acronimo"
										class="form-control"
										disabled={readOnly}
									/>
									<span class="form-text text-muted"></span>
								</div>
								<div class="col-lg-4">
									<MyForms.MyTextInput
										label="CUP"
										name="cup"
										class="form-control"
										disabled={readOnly}
									/>
									<span class="form-text text-muted"></span>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-lg-4">
									<MyForms.MySelect
										label="Bando"
										name="bandoId"
										class="form-control"
										disabled={readOnly}
										list={bandi}
										nameLabel="titolo"
									></MyForms.MySelect>
									<span class="form-text text-muted"></span>
								</div>
								<div class="col-lg-4">
									<MyForms.MySelect
										label="ETS"
										name="etsPersonaId"
										class="form-control"
										disabled={readOnly}
										list={ets}
										nameLabel="nome"
									></MyForms.MySelect>
									<span class="form-text text-muted"></span>
								</div>
								<div class="col-lg-4">
									<MyForms.MySelect
										label="Banca"
										name="bancaId"
										class="form-control"
										disabled={readOnly}
										list={banche}
										nameLabel="nome"
									></MyForms.MySelect>
									<span class="form-text text-muted"></span>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-lg-4">
									<MyForms.MySelect
										label="Responsabile Commessa"
										name="rcPersonaId"
										class="form-control"
										disabled={readOnly}
										list={respCommessa}
										nameLabel="nome"
									></MyForms.MySelect>
									<span class="form-text text-muted"></span>
								</div>
								<div class="col-lg-4">
									<label><strong>Ammesso a valutazione:</strong></label>
									<div class="radio-inline">
										<label class="radio radio-solid">
											<Field
												type="radio"
												name="ammessoValutazione"
												value="true"
												disabled={readOnly}
											/>
											<span></span>
											Sì
										</label>
										<label class="radio radio-solid">
											<Field
												type="radio"
												name="ammessoValutazione"
												value="false"
												disabled={readOnly}
											/>
											<span></span>
											No
										</label>
									</div>
									<span class="form-text text-muted"></span>
								</div>
								<div class="col-lg-4">
									<MyForms.MySelect
										label="Istruttoria"
										name="tipoStatoIstruttoriaId"
										class="form-control"
										disabled={readOnly}
										list={statoIstruttoria}
										nameLabel="tipoStatoIstruttoria"
									></MyForms.MySelect>
									<span class="form-text text-muted"></span>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-lg-4">
									<label><strong>Comunicazione di ammissione</strong></label>
									<div class="input-group">
										<MyForms.MyTextInput
											name="ammissioneProtocollo"
											class="form-control mr-3"
											placeholder="protocollo"
											disabled={readOnly}
										/>
										&nbsp;
										<MyForms.MyTextInput
											name="ammissioneNumero"
											class="form-control"
											placeholder="numero"
											disabled={readOnly}
										/>
									</div>
								</div>
								<div class="col-lg-4">
									<label><strong>Decreto di concessione</strong></label>
									<div class="input-group">
										<MyForms.MyTextInput
											name="concessioneProtocollo"
											class="form-control mr-3"
											placeholder="protocollo"
											disabled={readOnly}
										/>
										&nbsp;
										<MyForms.MyTextInput
											name="concessioneNumero"
											class="form-control"
											placeholder="numero"
											disabled={readOnly}
										/>
									</div>
								</div>
								<div class="col-lg-4">
									<label><strong>Rendicontazione a Costi Reali:</strong></label>
									<div class="radio-inline">
										<label class="radio radio-solid">
											<Field
												type="radio"
												name="costiReali"
												value="true"
												disabled={readOnly}
											/>
											<span></span>
											Sì
										</label>
										<label class="radio radio-solid">
											<Field
												type="radio"
												name="costiReali"
												value="false"
												disabled={readOnly}
											/>
											<span></span>
											No
										</label>
									</div>
									<span class="form-text text-muted"></span>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-lg-4">
									<label><strong>Data presentazione</strong></label>
									<div class="input-group date">
										<MyForms.MyDatePickerField
											name="dataPresentazione"
											//type="text"
											class="form-control"
											disabled={readOnly}
										/>
									</div>
									<span class="form-text text-muted"></span>
								</div>
								<div class="col-lg-4">
									<label><strong>Data inizio prevista</strong></label>
									<div class="input-group date">
										<MyForms.MyDatePickerField
											name="dataInizioPrev"
											class="form-control"
											disabled={readOnly}
										/>
									</div>
									<span class="form-text text-muted"></span>
								</div>
								<div class="col-lg-4">
									<label><strong>Data inizio effettiva</strong></label>
									<div class="input-group date">
										<MyForms.MyDatePickerField
											name="dataInizioEff"
											//type="text"
											class="form-control"
											disabled={readOnly}
										/>
									</div>
									<span class="form-text text-muted"></span>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-lg-4">
									<label><strong>Data fine prevista</strong></label>
									<div class="input-group date">
										<MyForms.MyDatePickerField
											name="dataFinePrev"
											//type="text"
											class="form-control"
											disabled={readOnly}
										/>
									</div>
									<span class="form-text text-muted"></span>
								</div>
								<div class="col-lg-4">
									<label><strong>Data fine effettiva</strong></label>
									<div class="input-group date">
										<MyForms.MyDatePickerField
											name="dataFineEff"
											//type="text"
											class="form-control"
											disabled={readOnly}
										/>
									</div>
									<span class="form-text text-muted"></span>
								</div>
								{process.env.REACT_APP_TYPE == 'utilizzatore' && (
									<div class="col-lg-4">
										<label><strong>Overhead</strong></label>
										<div class="input-group date">
											<MyForms.MyTextInput
												name="overhead"
												class="form-control"
												currency="1"
												disabled={readOnly}
											/>
										</div>
										<span class="form-text text-muted"></span>
									</div>
								)}
							</div>
							<div class="form-group row">
								<div class="col-lg-6">
									<MyForms.MyTextarea
										label="Descrizione"
										class="form-control"
										name="descrizione"
										disabled={readOnly}
									/>
								</div>
								<div class="col-lg-6">
									<MyForms.MyTextarea
										label="Note"
										class="form-control"
										name="note"
										disabled={readOnly}
									/>
								</div>
							</div>
						</Form>
					</Formik>
				</div>
			</div>
			<ModalConfirm
				isModalConfirmOpen={isModalConfirmOpen}
				message="L'oggetto sta per essere eliminato"
				closeModalConfirm={closeModalConfirm}
				handleConfirm={handleDelete}
			/>
			<ModalContatti
				isModalContattiOpen={isModalContattiOpen}
				progetto={fields}
				closeModalContatti={closeModalContatti}
			/>
		</>
	);
}

export default withRouter(FormProgetto);
