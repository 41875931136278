import React, { useState, useEffect, useCallback } from "react";
import PLivello from "./PLivello";
import PDipendente from "./PDipendente";
import PMese from "./PMese";
import PMonitoraggio from "./PMonitoraggio";

export default function PianificazioneTab(props) {
	const [state, setState] = useState({});

	const [readOnly, setReadOnly] = useState(props.readOnly);
	const [CountBudget, setCountBudget] = useState();
	const [divStyle, setDivStyle] = useState(
		props.nuovaTab ? "collapse show" : "collapse"
	);

	/*
	useEffect(() => {
		var api_path = Constants.Compose([Constants.PATH_PARTNERS, props.partnerId, Constants.PATH_BUDGET_INREL]);
		const response_data = await Constants.getData(api_path);
		setCountBudget(response_data.length)
	}, [props.partner]);
	*/

	const displayTab  = (tab) => {
		setState({ displayTab: tab });
	};

	const handleEditClick = () => {
		setReadOnly(!readOnly);
	};

	return (
		<>
			<div class="card">
				<div class="card-header">
					<div class="card-title collapsed" data-toggle="collapse" data-target={"#collapsepianificazione" + props.partner.partnerId} >
						<i class=" flaticon2-start-up"></i>{props.partner.partnerNome}
					</div>
				</div>
				<div id={"collapsepianificazione" + props.partner.partnerId} class={divStyle} data-parent="#accordionPianificazione" >
					<ul class="nav nav-tabs nav-tabs-line mb-5">	
						<li class="nav-item" onClick={() => displayTab('plivello')}>
							<a class={"nav-link active"} data-toggle="tab" href={"#tab_pane_plivello_"+props.partner.partnerId}>
								<span class="nav-text">Pianificazione personale per livello e attività</span>
							</a>
						</li>
						<li class="nav-item" onClick={() => displayTab('pdip')}>
							<a class={"nav-link"} data-toggle="tab" href={"#tab_pane_pdipendente_" + props.partner.partnerId}>
								<span class="nav-text">Pianificazione personale per dipendente e attività</span>
							</a>
						</li>
						<li class="nav-item" onClick={() => displayTab('pmese')}>
							<a class={"nav-link"} data-toggle="tab" href={"#tab_pane_pmese_"+props.partner.partnerId}>
								<span class="nav-text">Pianificazione personale per mese</span>
							</a>
						</li>
						<li class="nav-item" onClick={() => displayTab('monitoraggio')}>
							<a class={"nav-link"} data-toggle="tab" href={"#tab_pane_monitoraggio_"+props.partner.partnerId}>
								<span class="nav-text">Monitoraggio Pianificazione</span>
							</a>
						</li>
					</ul>
					<div class="tab-content" id="TabContentL">
						<div id={"tab_pane_plivello_" + props.partner.partnerId} class="tab-pane fade active show">
							<PLivello 
								displayTab={state.displayTab}
								pLivello={props.partner.pLivello} 
								partnerId={props.partner.partnerId} 
								forceUpdate={props.forceUpdate}
								countBudget={CountBudget}
							/>
						</div>
						<div id={"tab_pane_pdipendente_" + props.partner.partnerId} class="tab-pane fade">
							<PDipendente 
								displayTab={state.displayTab}
								PDipendente={props.partner.pDipendente} 
								partnerId={props.partner.partnerId} 
								progetto={props.progetto}
								forceUpdate={props.forceUpdate}
							/>
						</div>
						<div id={"tab_pane_pmese_"+props.partner.partnerId} class="tab-pane fade">
							<PMese 
								displayTab={state.displayTab}
								PMese={props.partner.pMese} 
								partnerNome={props.partner.partnerNome} 
								partnerId={props.partner.partnerId} 
								progetto={props.progetto} 
								forceUpdate={props.forceUpdate}
							/>
						</div>
						<div id={"tab_pane_monitoraggio_"+props.partner.partnerId} class="tab-pane fade">
							<PMonitoraggio 
								displayTab={state.displayTab}
								partnerId={props.partner.partnerId} 
								progettoId={props.progettoId} 
								forceUpdate={props.forceUpdate}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
