import React, { useState, useEffect, useCallback } from "react";
import * as MyButtons from "../Content/Forms/MyButtons";
import Modal from "react-modal";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as MyForms from "../Content/Forms/MyForms";
import * as Constants from "../constants";

Modal.setAppElement("#root");

export default function ModalChangePassword(props) {
  	const [passwordChanged, setPasswordChanged] = useState(props.passwordChanged);

    const handlePost = async (dataToSend) => {
		if(dataToSend.newPassword.length < 8) {
			alert("La password deve essere di almeno 8 caratteri")
			return;
		}
		if(dataToSend.newPassword != dataToSend.newPassword2) {
			alert("Le due password inserite non corrispondono")
			return;
		}
		const fetchurl = Constants.Compose(['Utenti','change-password']);
      	const response_data = await Constants.postData(fetchurl, dataToSend);
		if(response_data && response_data.error) {
		} else {
			setPasswordChanged(true)
		}
    };

	const handleClose = async () => {
		setPasswordChanged(false)
		props.closeModal();
	}
    return (
		<Modal
			isOpen={props.isModalOpen}
			onRequestClose={props.closeModal}
			style={{
				content: {
					top: "10%",
					left: "30%",
					right: "30%",
					bottom: "auto",
				},
			}}
		>
			<div class="modal-content">
                <div class="modal-header">
					<h5>Modifica password</h5>
                    <button type="button" class="close" onClick={props.closeModalConfirm} >
                        <i aria-hidden="true" class="ki ki-close"></i>
                    </button>
                </div>
                <div class="modal-body">
				<Formik
				enableReinitialize={true}
				initialValues={
					{
						oldPassword: "",
						newPassword: "",
						newPassword2: ""
					}	
				}
				validationSchema={Yup.object({
					oldPassword: Yup.string().required("Campo Obbilgatorio"),
					newPassword: Yup.string().required("Campo Obbilgatorio"),
					newPassword2: Yup.string().required("Campo obbligatorio"),
				})}
				onSubmit={(values, { setSubmitting }) => { handlePost(values); }}
				>
          		<Form class="form pt-5">
      			<div class="tab-pane fade show active" id="tab_pane_1" role="tabpanel" aria-labelledby="tab_pane_2" >
					{!passwordChanged ? (
						<>
						<div class="form-group row">
							<div class="col-lg-6">
								<MyForms.MyTextInput
									label="Vecchia password"
									type="password"
									name="oldPassword"
									class="form-control"
									disabled={false}
								/>
								<span class="form-text text-muted"></span>
							</div>
						</div>
						<div class="form-group row">
							<div class="col-lg-6">
								<MyForms.MyTextInput
									label="Nuova password"
									type="password"
									name="newPassword"
									class="form-control"
									disabled={false}
								/>
								<span class="form-text text-muted"></span>
							</div>
						</div>
						<div class="form-group row">
							<div class="col-lg-6">
								<MyForms.MyTextInput
									label="Ripeti password"
									type="password"
									name="newPassword2"
									class="form-control"
									disabled={false}
								/>
								<span class="form-text text-muted"></span>
							</div>
						</div>
						</>
					) : (
						<>
						<div align="center"><h3>Password aggiornata correttamente</h3></div>
						</>
					)}
            	</div>
                <div class="modal-footer">
					{!passwordChanged ? (
						<>
							<MyButtons.MyAnnulla handleClick={props.closeModal} />
							<button type="submit" class="btn btn-primary font-size-sm font-weight-bolder" > Conferma </button>
						</>
					) : (
						<MyButtons.MyAnnulla handleClick={handleClose} label="Chiudi"/>
					)}
                </div>
				</Form>
				</Formik>
                </div>
            </div>
		</Modal>
    )
}