import React, { useState, useEffect } from "react";

function Footer() {
	return (
		<div className="footer bg-white py-4 d-flex flex-lg-column " id="kt_footer">
			<div className=" container  d-flex flex-column flex-md-row align-items-center justify-content-between">
				<div className="text-dark order-2 order-md-1">
					<span className="text-muted font-weight-bold mr-2">2021&copy;</span>
					<a href="http://www.riformed.it" target="_blank" rel="noreferrer" className="text-dark-75 text-hover-primary" > Ri.For.Med. S.r.l.{" "} </a>
				</div>
				<div className="nav nav-dark order-1 order-md-3">
					<img src="/assets/images/loghi_footer.jpg" style={{height:"70px"}}></img>
				</div>
				<div className="nav nav-dark order-1 order-md-2">
					<strong>CONCESSIONE DI CONTRIBUTI A FAVORE DELLE MICRO, PICCOLE E MEDIE IMPRESE PER L’ATTUAZIONE DI PROCESSI DI INNOVAZIONE</strong>
				</div>
			</div>
		</div>
	);
}

export default Footer;
