import React, { useState, useEffect, useCallback } from "react";
import * as Constants from "../constants";
import Moment from "react-moment";
import * as Utils from "./Forms/Utils.js";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useHistory, useRouteMatch, } from "react-router-dom";
import ReactApexCharts from 'react-apexcharts'

export default function Dashboard(props) {
	const { url, path } = useRouteMatch();
	const history = useHistory();
	const [state, setState] = useState({});
	const [chart_options, setChartOption] = useState({});
	const [chart_series, setChartSeries] = useState({});
	useEffect(() => {
		fetchDashboard();
	}, []);
	
	const fetchProgetto = async (progettoId) => {
    	var api_path = Constants.Compose([Constants.PATH_PROGETTI, progettoId]);
    	const response_data = await Constants.getData(api_path);
      	changeRoute(response_data);
    };

	const changeRoute = useCallback( (row) => {
		history.push({ pathname: "Progetti/" + row.acronimo + " - " + row.titolo , state: row });
	}, [url, history]);

	const fetchDashboard = async () => {
		var api_path = Constants.Compose([Constants.PATH_DASHBOARD]);
		const dashboard_data = await Constants.getData(api_path);
		var progetti_attivi = 0;
		var tot_contributo = 0;
		var tot_onorario = 0;
		var tot_overhead = 0;
		var list_project = [];
		var chart_series = [
			{ name: 'Contributo RI', data: [] }, 
			{ name: 'Contributo SS', data: [] }, 
		]
		let progetti = dashboard_data.progetti.sort((a,b) => (a.acronimo > b.acronimo) ? 1 : ((b.acronimo > a.acronimo) ? -1 : 0))

		for(var i in progetti) {
			var progetto = progetti[i];
			var diff = (Math.abs(new Date(progetto.dataFine) - new Date())/ (1000 * 60 * 60 * 24));
			if(diff > 0) progetti_attivi++;

			tot_contributo += progetto.contributo;
			tot_onorario += progetto.onorario;
			tot_overhead += progetto.overhead;

			list_project.push(progetto.acronimo);
			var contributoRI = progetto.contributoRI;
			var contributoSS = progetto.contributoSS;
			chart_series[0].data.push(contributoRI);
			chart_series[1].data.push(contributoSS);
		}
		dashboard_data['progetti_attivi'] = progetti_attivi;
		dashboard_data['tot_contributo'] = Utils.Format(tot_contributo);
		dashboard_data['tot_onorario'] = Utils.Format(tot_onorario);
		dashboard_data['tot_overhead'] = Utils.Format(tot_overhead);
		setState(dashboard_data);

		var options = {
			chart: {
				type: 'bar',
				height: 350,
				stacked: true,
				toolbar: {
					show: true
				},
				zoom: {
					enabled: true
				}
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '55%',
					endingShape: 'rounded',
					borderRadius: 10
				},
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				show: true,
				width: 2,
				colors: ['transparent']
			},
			xaxis: {
				categories: list_project
			},
			yaxis: {
				title: {
					text: 'Euro'
				},
				labels: {
					formatter: (value) => {return Utils.Format(value)}
				}
			},
			legend: {
				position: 'right',
				offsetY: 40
			},
			fill: {
				opacity: 1
			},
			tooltip: {
				y: {
					formatter: function (val) {
						return Utils.Format(val)
					}
				}
			},
			colors: ["#009fe1", "#FFA800"]

		}
		setChartSeries(chart_series);
		setChartOption(options);
	}

	return (
		<div>
			<div className="row">
				<div className="col-lg-6 col-xxl-3">
					<div className="row">
						<div className="col-lg-12 ">
							<div className="card card-custom bg-danger gutter-b" style={{height: "100px"}}>
								<div className="card-body">
									<div className="text-inverse-primary font-weight-bolder font-size-h2 mt-3"> {state.progetti? state.progetti.length : 0}</div>
									<a href="#" className="text-inverse-primary font-weight-bold font-size-lg mt-1">Progetti caricati</a>
								</div>
							</div>
						</div>
						<div className="col-lg-12 ">
							<div className="card card-custom bg-warning gutter-b" style={{height: "100px"}}>
								<div className="card-body">
									<div className="text-inverse-primary font-weight-bolder font-size-h2 mt-3">{state.progetti_attivi}</div>
									<a href="#" className="text-inverse-primary font-weight-bold font-size-lg mt-1">Progetti attivi</a>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12 ">
							<div className="card card-custom bg-success gutter-b" style={{height: "100px"}}>
								<div className="card-body">
									<div className="text-inverse-primary font-weight-bolder font-size-h4 mt-3"> {state.tot_contributo}</div>
									<a href="#" className="text-inverse-primary font-weight-bold font-size-lg mt-1">Contributo su progetti attivi</a>
								</div>
							</div>
						</div>
						{process.env.REACT_APP_TYPE == 'advisor' ? (
							<div className="col-lg-12 ">
								<div className="card card-custom bg-primary gutter-b" style={{height: "100px"}}>
									<div className="card-body">
										<div className="text-inverse-primary font-weight-bolder font-size-h4 mt-3"> {state.tot_onorario}</div>
										<a href="#" className="text-inverse-primary font-weight-bold font-size-lg mt-1">Onorario atteso su progetti attivi</a>
									</div>
								</div>
							</div>
						) : (
							<div className="col-lg-12 ">
								<div className="card card-custom bg-primary gutter-b" style={{height: "100px"}}>
									<div className="card-body">
										<div className="text-inverse-primary font-weight-bolder font-size-h4 mt-3"> {state.tot_overhead}</div>
										<a href="#" className="text-inverse-primary font-weight-bold font-size-lg mt-1">Overhead atteso su progetti attivi</a>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="col-lg-6 col-xxl-9">
					<div className="card card-custom card-stretch gutter-b">
						<div className="card-header border-0 py-5">
							<h3 className="card-title align-items-start flex-column">
								<span className="card-label font-weight-bolder text-dark">
									Progetti
								</span>
							</h3>
						</div>
						<div className="card-body py-0">
							<div className="table-responsive">
								<table className="table table-head-custom table-vertical-center table-sm" id="kt_advance_table_widget_1" >
									<thead>
										<tr className="text-left">
											<th style={{ width: "50px" }}> Acronimo </th>
											<th style={{ minwidth: "200px" }}>Data Inizio</th>
											<th style={{ minwidth: "200px" }}>Data Fine</th>
											<th style={{ minwidth: "150px" }}>Importo</th>
											<th style={{ minwidth: "150px" }}>Contributo</th>
											<th style={{ minwidth: "150px" }}>Onorario atteso</th>
											<th style={{ minwidth: "150px" }}>% avanzamento</th>
											<th style={{ minwidth: "150px" }}>contributo maturato</th>
											{ 1 == 0 && 
												<th className="pr-0 text-right" style={{ minwidth: "150px" }}>
													azione
												</th>
											}
										</tr>
									</thead>
									<tbody>
										{state.progetti && state.progetti.map((progetto) => {
											var progress = progetto.perc_avanzamento;
											return (
												<tr key={progetto.progettoId} className="pointer" onClick={() => fetchProgetto(progetto.progettoId)}>
													<td>{progetto.acronimo}</td>
													<td>{progetto.dataInizio ? <Moment format="DD/MM/YYYY">{progetto.dataInizio}</Moment> : ''}</td>
													<td>{progetto.dataFine ? <Moment format="DD/MM/YYYY">{progetto.dataFine}</Moment> : ''}</td>
													<td style={{textAlign: "right"}}>{Utils.Format(progetto.importo)}</td>
													<td style={{textAlign: "right"}}>{Utils.Format(progetto.contributo)}</td>
													<td style={{textAlign: "right"}}>{Utils.Format(progetto.onorario)}</td>
													<td style={{textAlign: "right"}}>{Utils.FormatPerc(progress)} <ProgressBar variant="warning" now={progress} /></td>
													<td style={{textAlign: "right"}}>{Utils.Format(progetto.contributo_maturato)}</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-6">
					<div className="mb-6" id="chart">
		 				<ReactApexCharts options={chart_options} series={chart_series} type="bar" />
					</div>
				</div>
				<div className="col-lg-6">
					<div className="card card-custom  card-stretch gutter-b">
						<div className="card-header border-0">
							<h3 className="card-title font-weight-bolder text-dark">Sal in scadenza</h3>
						</div>
						{state.sals && state.sals.map((sal) => {
							return (
								<div key={sal.id} className="d-flex flsx-column align-items-cente p-4 w-100">
									<a href="#" className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"> Daily Standup Meeting </a>
									<span className="text-muted font-weight-bold"> Due in 2 Days </span>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</div>
	);
}