import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as MyForms from "./MyForms";
import Modal from "react-modal";

/* Funzione per il render del tab */
export default function RefClienti(props) {
  const [readOnly, setReadOnly] = useState(props.readOnly);
  const [isModalOpen, setModalOpen] = useState(false);
  const [divStyle, setDivStyle] = useState(
    props.nuovaTab ? "collapse show" : "collapse"
  );

  const handleEditClick = () => {
    setReadOnly(!readOnly);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const closeNewTab = () => {
    console.log("Tab");
    props.setNewTab(false);
  };
  return (
    <>
      <div class="card">
        <div class="card-header">
          {props.nuovaTab ? (
            <div class="card-title" data-toggle="collapse" data-target={"#collapserefclienti" + props.dataId} >
              <i class=" flaticon-avatar"></i>
            </div>
          ) : (
            <div class="card-title collapsed" data-toggle="collapse" data-target={"#collapserefclienti" + props.dataId} >
              <i class=" flaticon-avatar"></i>
              {props.referente.persona.cognome +
                "  " +
                props.referente.persona.nome}
            </div>
          )}
        </div>
        <div id={"collapserefclienti" + props.dataId} class={divStyle} data-parent="#accordionRefClienti" >
          <div class="card-body">
            <Formik
              enableReinitialize={true}
              initialValues={
                !props.nuovaTab
                  ? {
                      nome: props.referente.persona.nome,
                      cognome: props.referente.persona.cognome,
                      telefono: props.referente.persona.telefono,
                      telefono2: props.referente.persona.telefono2,
                      email: props.referente.persona.email,
                      email2: props.referente.persona.email2,
                    }
                  : {
                      nome: "",
                      cognome: "",
                      telefono: "",
                      telefono2: "",
                      email: "",
                      email2: "",
                    }
              }
              validationSchema={Yup.object({
                nome: Yup.string(),
                cognome: Yup.string(),
                email: Yup.string().email("Invalid email address").nullable(),
                email2: Yup.string().email("Invalid email address").nullable(),
              })}
              onSubmit={(values, { setSubmitting }) => {
                !props.nuovaTab
                  ? props.handleEdit(
                      props.referente.persona.personaId,
                      props.referente.refClienteId,
                      values
                    )
                  : props.handlePost(values);

                setReadOnly(true);
              }}
              onReset={() => {
                setReadOnly(true);
              }}
            >
              <Form class="form pt-5">
                <div class="border-0 top_commandi" style={{ textAlign: "right" }} >
                  {readOnly ? (
                    <div>
                      <div
                        class="btn btn-primary font-weight-bolder"
                        onClick={handleEditClick}
                      >
                        <span class="svg-icon svg-icon-md">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle fill="#000000" cx="9" cy="15" r="6" />
                              <path
                                d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z"
                                fill="#000000"
                                opacity="0.3"
                              />
                            </g>
                          </svg>
                        </span>
                        Edit
                      </div>
                    </div>
                  ) : (
                    <div>
                      {/* Se si sta inserendo una nuova scheda il tasto Cancella è invisibile ed il tasto
                    annulla riporta all'elenco. Se invece siamo in modifica il tasto cancella è visibile
                    ed il tasto annulla disabilita l'editing rimanendo sulla scheda*/}
                      {!props.nuovaTab ? (
                        <>
                          <button
                            type="reset"
                            class="btn btn-outline-primary font-size-sm"
                          >
                            <i class="icon fas fa-arrow-left"></i>
                            Annulla
                          </button>
                          &nbsp;&nbsp;
                          <div
                            class="btn btn-danger font-size-sm"
                            onClick={openModal}
                          >
                            <i class="icon far fa-trash-alt"></i>
                            Cancella
                          </div>
                        </>
                      ) : (
                        <div
                          onClick={closeNewTab}
                          class="btn btn-outline-primary font-size-sm"
                        >
                          <i class="icon fas fa-arrow-left"></i>
                          Annulla
                        </div>
                      )}
                      &nbsp;&nbsp;
                      <button
                        class="btn btn-primary font-weight-bolder"
                        type="submit"
                      >
                        <i class="icon far fa-save"></i> Salva
                      </button>
                    </div>
                  )}
                </div>

                <div class="form-group row">
                  <div class="col-lg-4">
                    <MyForms.MyTextInput
                      label="Nome"
                      name="nome"
                      //type="text"
                      class="form-control"
                      disabled={readOnly}
                    />
                    <span class="form-text text-muted"></span>
                  </div>
                  <div class="col-lg-4">
                    <MyForms.MyTextInput
                      label="Cognome"
                      name="cognome"
                      //type="text"
                      class="form-control"
                      disabled={readOnly}
                    />
                    <span class="form-text text-muted"></span>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-4">
                    <MyForms.MyTextInput
                      label="Telefono"
                      name="telefono"
                      //type="text"
                      class="form-control"
                      disabled={readOnly}
                    />
                    <span class="form-text text-muted"></span>
                  </div>
                  <div class="col-lg-4">
                    <MyForms.MyTextInput
                      label="Telefono 2"
                      name="telefono2"
                      //type="text"
                      class="form-control"
                      disabled={readOnly}
                    />
                    <span class="form-text text-muted"></span>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-4">
                    <MyForms.MyTextInput
                      label="Email"
                      name="email"
                      type="email"
                      class="form-control"
                      disabled={readOnly}
                    />
                    <span class="form-text text-muted"></span>
                  </div>
                  <div class="col-lg-4">
                    <MyForms.MyTextInput
                      label="Email 2"
                      name="email2"
                      type="email"
                      class="form-control"
                      disabled={readOnly}
                    />
                    <span class="form-text text-muted"></span>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            width: "150",
          },
          content: {
            color: "lightsteelblue",
            top: "5%",
            left: "30%",
            right: "30%",
            bottom: "auto",
          },
        }}
      >
        <h2 align="center">L'oggetto sta per essere eliminato</h2>
        <br />
        <br />
        <br />
        <div align="center">
          <div
            class="btn btn-outline-primary font-size-sm"
            onClick={closeModal}
            align="center"
          >
            Annulla
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div
            class="btn btn-outline-primary font-size-sm"
            onClick={() => {
              props.handleDelete(
                props.referente.persona.personaId,
                props.referente.refClienteId
              );
              closeModal();
            }}
            align="center"
          >
            Conferma
          </div>
        </div>
      </Modal>
    </>
  );
}
