import React, { useState, useEffect, useCallback } from "react";
import * as Constants from "../../constants";
import { useHistory, withRouter, useRouteMatch } from "react-router-dom";
import ContentTable from "./ContentTable";
import * as MyButtons from "../Forms/MyButtons";
import ModalConfirm from "../Forms/ModalConfirm";

function Consorziati(props) {
  const [state, setState] = useState({});
  const [listConsorziati, setConsorziati] = useState([]);
  const [listClienti, setClienti] = useState([]);
  const [listClientiFiltrata, setClientiFiltrati] = useState([]);
  const [readOnly, setReadOnlyConsorziato] = useState(true);
  const [isModalConfirmOpen, setModalConfirm] = useState(false);
  const [selectedConsorziato, setSelectedConsorziato] = useState();

  useEffect(() => {
    fetch_clienti();
  }, [props.clienteId]);

  useEffect(() => {
    fetch_consorziati();
  }, [listClienti, state]);

  const forceUpdate = React.useCallback(() => {
    setState({ key: Math.random() });
  }, []);

  const fetch_clienti = async () => {
    var api_path = Constants.Compose([Constants.PATH_CLIENTI]);
    api_path +=
      "?filter=" +
      JSON.stringify({
        where: { tipoClienteId: { nin: [7] } },
        order: ["acronimo"],
      });
    const response_data = await Constants.getData(api_path);
    setClienti(response_data);
  };

  const fetch_consorziati = async () => {
    var api_path = Constants.Compose([
      Constants.PATH_CLIENTI,
      props.clienteId,
      "consorzio",
    ]);
    api_path +=
      "?filter=" +
      JSON.stringify({
        include: [
          {
            relation: "consorziato",
            scope: { order: ["acronimo"], include: "tipoCliente" },
          },
        ],
      });
    const response_data = await Constants.getData(api_path);
    var list = [];
    for (var i in response_data) {
      var cons = response_data[i];
      list.push({
        acronimo: cons.consorziato.acronimo,
        nome: cons.consorziato.nome,
        clienteId: cons.consorziato.clienteId,
        tipoCliente: cons.consorziato.tipoCliente.tipoCliente,
        consorziatoId: cons.consorziatoId,
        data_cons: cons.consorziato,
      });
    }

    var listClientiFiltrata = [];
    for (var i in listClienti) {
      var cli = listClienti[i];
      var gia_associato = list.find((obj) => {
        return obj.clienteId == cli.clienteId;
      });
      if (!gia_associato) listClientiFiltrata.push(cli);
    }
    setConsorziati(list);
    setClientiFiltrati(listClientiFiltrata);
  };

  const handleChangeConsorziato = (e) => {
    setSelectedConsorziato(e.target.value);
  };

  const closeModalConfirm = () => {
    setModalConfirm(false);
  };
  const openModalConfirm = (e) => {
    setState({ consorziatoToDelete: e.consorziatoId });
    setModalConfirm(true);
  };

  const handleEditClickConsorziato = () => {
    setReadOnlyConsorziato(!readOnly);
  };
  const handlePostConsorziato = async () => {
    if (!selectedConsorziato) return;
    var api_path = Constants.Compose([
      Constants.PATH_CLIENTI,
      props.clienteId,
      "consorzio",
    ]);
    const response_data = await Constants.postData(
      api_path,
      JSON.stringify({ clienteConsorziatoId: selectedConsorziato })
    );

    forceUpdate();
  };
  const handleDeleteConsorziato = async () => {
    var api_path = Constants.Compose([
      Constants.PATH_CLIENTI,
      props.clienteId,
      "consorzio",
      state.consorziatoToDelete,
    ]);
    console.log(api_path);
    const response_data = await Constants.deleteData(api_path);
    forceUpdate();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Acronimo",
        accessor: "acronimo",
      },
      {
        Header: "Nome",
        accessor: "nome",
      },
      {
        Header: "Tipologia",
        accessor: "tipoCliente",
      },
      {
        Header: "Cancella",
        delete: 1,
      },
    ],
    []
  );

  return (
    <>
      <div class="border-0 top_commandi" style={{ textAlign: "right" }}>
        {readOnly ? (
          <MyButtons.MyModifica handleClick={handleEditClickConsorziato} />
        ) : (
          <MyButtons.MyAnnulla handleClick={handleEditClickConsorziato} />
        )}
      </div>
      {!readOnly && (
        <div class="form-group row">
          <div class="col-lg-10">
            <select
              name="clienteId"
              class="form-control"
              onChange={handleChangeConsorziato}
            >
              <option value="" selected="selected">
                Seleziona
              </option>
              {listClientiFiltrata.map((obj) => (
                <option value={obj["clienteId"]}>{obj["acronimo"]}</option>
              ))}
            </select>
          </div>
          <div class="col-lg-2">
            <MyButtons.MyAggiungi
              handleClick={handlePostConsorziato}
              label=" "
            />
          </div>
        </div>
      )}
      <ContentTable
        key={state.key}
        columns={columns}
        title="Consorziati"
        dataToDisplay={listConsorziati}
        consorziatiData={true}
        changeRoute={props.changeRoute}
        execToDelete={openModalConfirm}
        defaultOrderBy={"acronimo"}
        easy={1}
        editMode={!readOnly}
      />
      <ModalConfirm
        isModalConfirmOpen={isModalConfirmOpen}
        message="Il cliente sta per essere rimosso da questo consorzio."
        closeModalConfirm={closeModalConfirm}
        handleConfirm={handleDeleteConsorziato}
      />
    </>
  );
}

export default withRouter(Consorziati);
