import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as MyForms from "./MyForms";
import axios from "axios";
import Modal from "react-modal";
import SediOperativeTab from "./SediOperativeTab";
import * as Constants from "../../constants";

export default function SediOperativeStack(props) {
  const [sediOperative, setSediOperative] = useState([]);
  const [newTab, setNewTab] = useState(false);
  const [idObject, setIdObject] = useState(null);
  const [state, setState] = useState();

  const forceUpdate = React.useCallback(() => {
    setState({});
  }, []);

  useEffect(() => {
    const fetchSediOperative = async () => {
      const url =
        Constants.GETSEDICLIENTIURL +
        JSON.stringify({ where: { clienteId: props.clienteId } });
      const response_data = await Constants.getData(url);

      const sedi = response_data.filter((element) => {
        if (element.tipoSedeId === 2) {
          return element;
        } else {
          return "";
        }
      });
      setSediOperative(sedi);
    };
    fetchSediOperative();
    setIdObject(props.clienteId);
  }, [props.clienteId, state]);

  const handleDelete = async (id) => {
    const deleteUrl = Constants.PUTSEDIURL + id;
    await Constants.deleteData(deleteUrl);
    forceUpdate();
  };

  const handleEdit = async (id, dataToSend) => {
    const fetchUrl = Constants.PUTSEDIURL + id;
    await Constants.putData(fetchUrl, dataToSend);
  };

  const handlePostSede = async (dataToSend) => {
    const postNewSede = async () => {
      const fetchurl = Constants.POSTSEDIURL;
      const response_data = await Constants.postData(fetchurl, dataToSend);
      console.log(response_data);
    };

    //La sede operativa è una sede di tipo 2 legata al cliente chiamante
    dataToSend = { ...dataToSend, tipoSedeId: "2", clienteId: props.clienteId };

    console.log(dataToSend);
    await postNewSede(JSON.stringify(dataToSend, null, 2));
    forceUpdate();
    setNewTab(false);
  };

  const handleNewClick = () => {
    setNewTab(true);
  };

  return (
    <>
      <div class="border-0 top_commandi" style={{ textAlign: "right" }}>
        <div class="btn btn-outline-primary font-size-sm mb-5" onClick={handleNewClick} >
          <i class="icon fa fa-plus"></i> Nuovo
        </div>
      </div>
      <div class="accordion accordion-solid accordion-toggle-plus" id="accordionSediOperative" >
        {sediOperative.map((sede, i) => (
          <SediOperativeTab
            setNewTab={setNewTab}
            handleEdit={handleEdit}
            handlePost={handlePostSede}
            handleDelete={handleDelete}
            sede={sede}
            nuovaTab={false}
            dataId={i}
            readOnly={true}
          />
        ))}
        {newTab && (
          <SediOperativeTab
            setNewTab={setNewTab}
            handleEdit={handleEdit}
            handlePost={handlePostSede}
            handleDelete={handleDelete}
            sede={null}
            nuovaTab={true}
            dataId={sediOperative.length + 1}
            readOnly={false}
          />
        )}
      </div>
    </>
  );
}
