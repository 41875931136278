
import React, { useState, useEffect, useCallback } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import * as Constants from "../../constants";
import * as MyForms from "./MyForms";
import Modal from "react-modal";
import * as MyButtons from "./MyButtons";
import * as Utils from "./Utils";

export default function GdL(props) {
	const [state, setState] = useState({});
	const [nomeCliente, setNomeCliente] = useState();
	const [livelli, setLivelli] = useState({});
	const [listGdL, setlistGdL] = useState([]);
	const [listDipendenti, setlistDipendenti] = useState();
	const [selectedDip, setSelectedDip] = useState();
	const [selectedReverse, setSelectedReverse] = useState();
	const [selectedMeseI, setSelectedMeseI] = useState();
	const [selectedMeseF, setSelectedMeseF] = useState();
	const [selectedCostoMedioReale, setselectedCostoMedioReale] = useState();
	const [isAssociaOpen, setAssociaOpen] = useState(false);
	const [costoReale, setCostoReale] = useState(false);
	const [labelGestioneProgetto, setLabel] = useState(false);
	const [livelliAmmessi, setLivelliAmmessi] = useState([]);

	useEffect(() => {
		if(props.partner) fetchGdL();
	}, [props.partner, state]);

	useEffect(() => {
	}, [isAssociaOpen]);

	useEffect(() => {
		if(props.progetto) {
			setCostoReale(props.progetto.costiReali)
			var label = "Progetto gestito NON a Costi Reali";
			var liv = [1,2,3,4];
			if(props.progetto.costiReali) {
				label = "Progetto gestito a Costi Reali";
				liv = [4,5];
			}
			setLabel(label);
			setLivelliAmmessi(liv);
		}
	}, [props.progetto]);

	const forceUpdate = React.useCallback(() => {
		setState({ key: Math.random() });
	}, []);

	const fetchGdL = async () => {
		var api_path = Constants.Compose([Constants.PATH_LIVELLI]);
		const list_livelli = await Constants.getData(api_path);

		var api_path = Constants.Compose([Constants.PATH_PARTNERS, props.partner.partnerId]);
		api_path += "?filter=" + JSON.stringify({'include':['cliente', 'partnerLivelli']});
		const partner = await Constants.getData(api_path);
		const partnerLivelli = partner.partnerLivelli;
		setNomeCliente(props.partner.cliente.acronimo);

		var LIVELLI = {};
		// for(var i in partner.partnerLivelli) {
		list_livelli.map(livello => {
			var l = partnerLivelli.find(obj => { return obj.tipoLivelloId === livello.tipoLivelloId});
			var numero = l ? l.numero : 0;
			LIVELLI[livello.tipoLivelloId] = numero
		});
		setLivelli(LIVELLI);


		var api_path = Constants.Compose([Constants.PATH_PARTNERS, props.partner.partnerId, Constants.PATH_DIPENDENTI]);
		api_path += "?filter=" + JSON.stringify({'include':[{'dipendente':'persona'}, 'tipoLivello']});
		const partner_dip = await Constants.getData(api_path);
		setlistGdL(partner_dip);

		var DIPENDENTI = {
			"1": [],
			"2": [],
			"3": [],
			"4": [],
			"5": []
		};

		var api_path = Constants.Compose([Constants.PATH_CLIENTI, partner.clienteId, Constants.PATH_DIPENDENTI_INREL]);
		api_path += "?filter=" + JSON.stringify({'include':['cliente', 'persona']});
		const cliente_dip = await Constants.getData(api_path);

		if(cliente_dip.length) {
			cliente_dip.map(dip => {
				var test = partner_dip.filter(obj => {return obj.dipendenteId == dip.dipendenteId});
				if(dip.tipoLivelloId) {
					var nomeDip = dip.persona.cognome + " " + dip.persona.nome;
					let associated = !test.length ? 0 : 1;
					DIPENDENTI[dip.tipoLivelloId].push({"dipendenteId": dip.dipendenteId, "dipendente": nomeDip, "associated": associated});
					/// Creo un elenco di tutti i dipendenti per la gestione dei costi reali
					if(dip.tipoLivelloId != 4) DIPENDENTI[5].push({"dipendenteId": dip.dipendenteId, "dipendente": nomeDip});
				}
			});
		}
		
		setlistDipendenti(DIPENDENTI);
	};

	const handleEdit = async (dataToSend) => {
		var api_path = Constants.Compose([Constants.PATH_PARTNERS]);
		const response_data = await Constants.putData(api_path, JSON.stringify(dataToSend));
		forceUpdate();
	};

	const handleChange = (e) => {
		setSelectedDip(e.target.value);
	}
	const handleChangeReverse = (e) => {
		console.log(e.target.value)
		setSelectedReverse(e.target.value)
	}
	const handleChangeMeseI = (e) => {
		let mese_i = e.target.value;
		if(mese_i < 2) mese_i = null;
		if(mese_i && selectedMeseF && mese_i >= selectedMeseF) {
			alert('Il mese inizio non può essere più grande del mese fine')
			return;
		}
		setSelectedMeseI(mese_i)
	}
	const handleChangeMeseF = (e) => {
		let mese_f = e.target.value;
		if(mese_f < 1) mese_f = null;
		if(mese_f && selectedMeseI && mese_f <= selectedMeseI) {
			alert('Il mese fine non può essere più piccolo del mese inizio')
			return;
		}
		setSelectedMeseF(mese_f);
	}
	const handleChangeCosto = (e) => {
		setselectedCostoMedioReale(e.target.value);
	}
	const Associa = (partnerDipendenteId) => {
		setAssociaOpen(partnerDipendenteId)
	}
	const Modifica = (partnerDipendenteId) => {
		let item = listGdL.find(ele => ele.partnerDipendenteId == partnerDipendenteId)
		setAssociaOpen(partnerDipendenteId)
		setSelectedDip(item.dipendenteId)
		setSelectedMeseI(item.mese_i)
		setSelectedMeseF(item.mese_f)
		setselectedCostoMedioReale(item.costoMedioReale)
		let reverse = item.reverse ? 1 : 0;
		setSelectedReverse(reverse)
	}
	const Conferma = async (partnerDipendenteId) => {
		var api_path = Constants.Compose([Constants.PATH_PARTNERS, props.partner.partnerId, Constants.PATH_DIPENDENTI, partnerDipendenteId]);
		await Constants.putData(api_path, JSON.stringify({
			"dipendenteId":selectedDip, 
			"costoMedioReale": selectedCostoMedioReale, 
			"mese_i": selectedMeseI, 
			"mese_f": selectedMeseF,
			"reverse": selectedReverse
		}));
		setSelectedDip();
		setselectedCostoMedioReale();
		setSelectedReverse()
		setSelectedMeseI()
		setSelectedMeseF()
		forceUpdate();
		setAssociaOpen();
	}

	const Cancella = async (partnerDipendenteId) => {
		var api_path = Constants.Compose([Constants.PATH_PARTNERS, props.partner.partnerId, Constants.PATH_DIPENDENTI, partnerDipendenteId]);
		const response_data = await Constants.putData(api_path, JSON.stringify({"dipendenteId":null, "mese_i": null, "mese_f": null}));
		forceUpdate();
		setAssociaOpen();
	}

	const Annulla = (partnerDipendenteId) => {
		setAssociaOpen();
		setSelectedDip();
		setSelectedMeseI()
		setSelectedMeseF()
	}

	const closeAssocia = () => {
		setAssociaOpen(false)
	}

	const handlePost = async () =>{
	}

	const setZero = async (e) => {
		if(!e.target.value) e.target.value = 0;
	}

	return (
		<>
			<Modal
				isOpen={props.isModalGdLOpen}
				onRequestClose={props.onRequestClose}
				style={{
					overlay: {
						width: "35",
					},
					content: {
						top: "5%",
						left: "15%",
						right: "15%",
						bottom: "auto",
					},
					width: "30px",
				}}
			>
				<div className="modal-content">
					<div className="modal-header">
						<h5>GdL - {nomeCliente} </h5>
						<h6>{labelGestioneProgetto} </h6>
						<MyButtons.MyClose handleClick={props.onRequestClose}/>
					</div>
					<div className="modal-body">
						<Formik
							enableReinitialize={true}
							initialValues={
								costoReale ? {
									tipoLivelloId4: !props.nuovaTab ? livelli['4'] : "",
									tipoLivelloId5: !props.nuovaTab ? livelli['5'] : "",
								} : {
									tipoLivelloId1: !props.nuovaTab ? livelli['1'] : "",
									tipoLivelloId2: !props.nuovaTab ? livelli['2'] : "",
									tipoLivelloId3: !props.nuovaTab ? livelli['3'] : "",
									tipoLivelloId4: !props.nuovaTab ? livelli['4'] : "",
								}
							}
							validationSchema={Yup.object({
								tipoLivelloId1: Yup.number(),
								tipoLivelloId2: Yup.number(),
								tipoLivelloId3: Yup.number(),
								tipoLivelloId4: Yup.number(),
								tipoLivelloId5: Yup.number(),
							})}
							onSubmit={(values, { setSubmitting }) => {
								values['partnerLivelli'] = [];
								for(var j in livelliAmmessi) {
									var i  = livelliAmmessi[j];
									var numero = values['tipoLivelloId'+i] || 0;
									values['partnerLivelli'].push({tipoLivelloId: i, numero: parseInt(numero)})
								}
								delete values['tipoLivelloId1'];
								delete values['tipoLivelloId2'];
								delete values['tipoLivelloId3'];
								delete values['tipoLivelloId4'];
								delete values['tipoLivelloId5'];
								values['partnerId'] = props.partner.partnerId;
								handleEdit(values)
							}}
						>
							<Form class="form">
								<div class="form-group row">
									{ ! costoReale ? (
										<>
										<div class="col-lg-3">
											<MyForms.MyTextInput
												label="Livello Alto"
												type="text"
												name="tipoLivelloId1"
												class="form-control"
												disabled={false}
												onBlur={setZero}
											/>
										</div>
										<div class="col-lg-3">
											<MyForms.MyTextInput
												label="Livello Medio"
												type="text"
												name="tipoLivelloId2"
												class="form-control"
												disabled={false}
												onBlur={setZero}
											/>
										</div>
										<div class="col-lg-3">
											<MyForms.MyTextInput
												label="Livello Basso"
												type="text"
												name="tipoLivelloId3"
												class="form-control"
												disabled={false}
												onBlur={setZero}
											/>
										</div>
										</>
									) : (
										<div class="col-lg-3">
											<MyForms.MyTextInput
												label="Dipendenti a Costo Reale"
												type="text"
												name="tipoLivelloId5"
												class="form-control"
												disabled={false}
												onBlur={setZero}
											/>
										</div>

									)}
									<div class="col-lg-3">
										<MyForms.MyTextInput
											label="Non dipendenti"
											type="text"
											name="tipoLivelloId4"
											class="form-control"
											disabled={false}
											onBlur={setZero}
										/>
									</div>
									<div class="col-lg-3" style={{'paddingTop':'25px'}}>
										<MyButtons.MySalva label="Aggiorna l'elenco"/>
									</div>
								</div>
							</Form>
						</Formik>
						<div>
							<table className="table">
								<thead>
									<tr>
										<th width="5%">#</th>
										<th width="5%">Livello</th>
										<th width="5%">Dipendente</th>
										<th width="35%">Periodo di attivita' nel progetto</th>
										<th width="35%"></th>
									</tr>
								</thead>
								{listGdL.map((dip) => {
									var dipendente = dip.dipendenteId ? dip.dipendente.persona.cognome + ' ' + dip.dipendente.persona.nome : '';
									var mese_i_reverse = dip.mese_i ? parseInt(dip.mese_i-1) : ''
									var mese_f_reverse = dip.mese_f ? parseInt(dip.mese_f+1) : ''
									return (
										<tr>
											<td width="10%">{dip.etichetta}</td>
											<td width="20%">{dip.tipoLivello.tipoLivello}</td>
											<td width="20%">
												{isAssociaOpen == dip.partnerDipendenteId ? (
													<>
													<select name="dipendente_id" onChange={handleChange} >
														<option value="">Seleziona un dipendente</option>
														{listDipendenti[dip.tipoLivelloId].map((cliente_dip) => {
															if(cliente_dip.associated == 1 && selectedDip != cliente_dip.dipendenteId) return false

															let selected = (selectedDip == cliente_dip.dipendenteId ) ? 'selected' : ''
															return (
																<option value={cliente_dip.dipendenteId} selected={selected}>{cliente_dip.dipendente}</option>
															)
														})}
													</select>
													{(costoReale || dip.tipoLivelloId == 4) && (
														<>
														<br/>Costo Medio Reale: <input type="text" name="costoMedioReale" value={selectedCostoMedioReale} size="4" onChange={handleChangeCosto} autoComplete="off"/>
														</>
													)}
													</>
												) : (
													<>
													{dip.dipendenteId && (
														<>
															{dipendente}
															{dip.costoMedioReale && (
																<>
																<br/>(Costo Medio Reale: {Utils.Format(dip.costoMedioReale)})
																</>
															)}
														</>
													)}
													</>
												)}
											</td>
											<td width="10%">
												{isAssociaOpen == dip.partnerDipendenteId ? (
													<div>
														<div>
															<select name="reverse" onChange={handleChangeReverse} value={selectedReverse}>
																<option value="0">Seleziono i mesi di attivita'</option>
																<option value="1">Seleziono i mesi di sospensione</option>
															</select>
														</div>
														<br/>
														<div>
															<label>Mese inizio: </label> <input type="number" name="mese_i" style={{"width": "5em"}} value={selectedMeseI} onChange={handleChangeMeseI}/>
															&nbsp;<label> Mese fine: </label> <input type="number" name="mese_f" style={{"width": "5em"}} value={selectedMeseF} onChange={handleChangeMeseF}/>
														</div>
													</div>
												) : (
													<>
													{(dip.mese_i > 1 || dip.mese_f > 1) ? ( 
														<>
														{ dip.reverse == 1? (
															<>
															{dip.mese_i > 1 ? ("Da inizio progetto a mese " + parseInt(mese_i_reverse)) : ( " ")}
															<br/>{dip.mese_f > 1 ? ( "Da mese " + mese_f_reverse + " a fine progetto "): ( " ")}
															</>
														) : (
															<>
															Da  
															{dip.mese_i > 1 ? ( " mese " + dip.mese_i + " "): ( " inizio progetto ")}
															a {dip.mese_f > 1 ? ( " mese " + dip.mese_f + " "): ( " fine progetto")}
															</>
														)}
														</>
													): ( "Tutta la durata del progetto")}
													</>
												)}
											</td>
											<td width="20%">
												{isAssociaOpen == dip.partnerDipendenteId ? (
													<>
														<span class="label label-lg label-light-success label-inline pointer" onClick={() => Conferma(dip.partnerDipendenteId)}>Conferma</span>
														&nbsp;&nbsp;
														<span class="label label-lg label-light-warning label-inline pointer" onClick={() => Annulla(dip.partnerDipendenteId)}>Annulla</span>
													</>
												) : (
													<>
													{dip.dipendenteId ? (
														<>
														<span width="100px" class="label label-lg label-light-warning label-inline pointer" onClick={() => Modifica(dip.partnerDipendenteId)}>Modifica</span>&nbsp;
														<span width="100px" class="label label-lg label-light-danger label-inline pointer" onClick={() => Cancella(dip.partnerDipendenteId)}>Cancella</span>
														</>
													) : (
														<span width="100px" class="label label-lg label-light-success label-inline pointer" onClick={() => Associa(dip.partnerDipendenteId)}>Associa dipendente</span>
													)}
													</>
												)}
											</td>
										</tr>
									)
								})}
							</table>
						</div>
					</div>
				</div>
			</Modal>
		</>
	)
}