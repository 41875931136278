import React, { useState, useEffect, useCallback } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import * as MyForms from "./MyForms";
import * as MyButtons from "./MyButtons";
import Modal from "react-modal";
import ModalConfirm from "./ModalConfirm";
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import '../../../custom-datasheet.css';
import * as Utils from "./Utils";

Modal.setAppElement("#root");

export default function BudgetTab(props) {
	const [fields, updateFields] = useState({});
	const [state, setState] = useState({});
	const [isModalConfirmOpen, setModalConfirm] = useState(false);
	const [readOnly, setReadOnly] = useState(props.readOnly);
	const [RTotale, setRTotale] = useState();
	const [myGrid, setGrid] = useState([]);

	useEffect(() => {
		PrepareBudget(props.listVociCosto);
	}, [props.listVociCosto, props.budget, readOnly, state]);

	useEffect(() => {
		ChangeSheetReadOnly();
	}, [readOnly]);

	const handleEditClick = () => {
		setReadOnly(!readOnly);
	};

	const openModalConfirm = (e) => {
		setModalConfirm(true);
	};
	const closeModalConfirm = () => {
		setModalConfirm(false);
	};

	const ChangeSheetReadOnly = function() {
		if(! myGrid.length) return;
		for(var i=1;i<=myGrid.length-2; i++) {
			myGrid[i][1]['readOnly'] = readOnly;
			myGrid[i][2]['readOnly'] = readOnly;
		}
		setGrid(myGrid);
	}

	const calc = function(grid, RowTotale) {
		if(!RowTotale) RowTotale = RTotale;

		if(grid.length) {
			var sumRI = 0;
			var sumSS = 0;
			for(var i=1;i<=grid.length-2; i++) {
				var row = grid[i];
				var eleRI = row.find(obj => { return obj.tipoAttivitaId === 1});
				var eleSS = row.find(obj => { return obj.tipoAttivitaId === 2});
				sumRI += parseFloat(eleRI.valuePulito);
				sumSS += parseFloat(eleSS.valuePulito);
				row[3]['value'] = Utils.Format(parseFloat(eleRI.valuePulito + eleSS.valuePulito));
			}
			grid[RowTotale][1]['value'] = Utils.Format(sumRI);
			grid[RowTotale][2]['value'] = Utils.Format(sumSS);
			grid[RowTotale][3]['value'] = Utils.Format(parseFloat(sumRI + sumSS));

			setGrid(grid);
		}
	}

	const PrepareBudget = function (listVociCosto) {
		if (!listVociCosto) {
		return;
		}
		var grid = [];

		// INTESTAZIONE
		var rowGrid = [
			{"readOnly": true, className:"thcell"}, 
			{"value": "RI", "readOnly": true, className:"thcell"}, 
			{"value": "SS", "readOnly": true, className:"thcell"}, 
			{"value": "TOTALE", "readOnly": true, className:"thcell"}
		];
		grid.push(rowGrid)

		listVociCosto.map((voce) => {
			var eleRI = {importo: 0};
			var eleSS = {importo: 0};
			if(props.budget) {
				eleRI = props.budget.partnerCosti.find(obj => { return obj.tipoAttivitaId === 1 && obj.voceCostoId === voce.voceCostoId});
				eleSS = props.budget.partnerCosti.find(obj => { return obj.tipoAttivitaId === 2 && obj.voceCostoId === voce.voceCostoId});
				if(!eleRI) eleRI = {importo: 0}
				if(!eleSS) eleSS = {importo: 0}
			}
			var rowGrid = [
				{"value": voce.voceCosto, "readOnly": true}, 
				{"valuePulito": eleRI.importo, "value": Utils.Format(eleRI.importo), "readOnly": readOnly, "tipoAttivitaId": 1, "voceCostoId": voce.voceCostoId, className:"cifra"}, 
				{"valuePulito": eleSS.importo, "value": Utils.Format(eleSS.importo), "readOnly": readOnly, "tipoAttivitaId": 2, "voceCostoId": voce.voceCostoId, className:"cifra"}, 
				{"value": "", "readOnly": true, "className": "cifra"}
			];
			grid.push(rowGrid)
		});

		var rowGrid = [
			{"value": "TOTALE", "readOnly": true, "className": "thcell"}, 
			{"value": "", "readOnly": true, "className": "thcell cifra"}, 
			{"value": "", "readOnly": true, "className": "thcell cifra"}, 
			{"value": "", "readOnly": true, "className": "thcell cifra"}
		];
		grid.push(rowGrid)

		var RowTotale = grid.length-1;
		setRTotale(RowTotale);

		// Passo RowTotale perche il setRTotale e' asincrono
		calc(grid, RowTotale);
	}

	return (
		<>
		<div id={"tab_pane_budget_" + props.id} class={"tab-pane fade " + props.active}>
			<Formik
				enableReinitialize={true}
				initialValues={
					props.budget ? {
						tipoBudgetId: props.budget.tipoBudgetId
					} : {
						tipoBudgetId: ""
					}
				}
				validationSchema={Yup.object({
					tipoBudgetId: Yup.number().required("Campo obbligatorio"),
				})}
				onSubmit={(values, { setSubmitting }) => {
					values['partnerCosti'] = []
					for(var i=1;i<=myGrid.length-2; i++) {
						var row = myGrid[i];
						var eleRI = row.find(obj => { return obj.tipoAttivitaId === 1});
						var eleSS = row.find(obj => { return obj.tipoAttivitaId === 2});
						values.partnerCosti.push({tipoAttivitaId: eleRI.tipoAttivitaId, voceCostoId: eleRI.voceCostoId, importo: eleRI.valuePulito});
						values.partnerCosti.push({tipoAttivitaId: eleSS.tipoAttivitaId, voceCostoId: eleSS.voceCostoId, importo: eleSS.valuePulito});
					}
					if(props.budget) values['partnerBudgetId'] = props.budget.partnerBudgetId;
					values['partnerId'] = props.partnerId;
					props.budget
						? props.handleEdit( values)
						: props.handlePost(values);
					setReadOnly(true);
				}}
				onReset={() => {
					setReadOnly(true);
				}}
			>
				<Form class="form">
					{props.active && (
						<div class="border-0 top_commandi bp-5" style={{ textAlign: "right" }} >
							{readOnly ? (
								<div>
									<MyButtons.MyModifica handleClick={handleEditClick}/>
								</div>
							) : (
							<div>
								{!props.nuovoBudget ? (
									<>
										<MyButtons.MyAnnulla handleClick={handleEditClick}/>
										&nbsp;&nbsp;
										<MyButtons.MyCancella handleClick={openModalConfirm}/>
									</>
								) : (
									<MyButtons.MyAnnulla handleClick={props.handleCloseNuovoBudget}/>
								)}
								&nbsp;&nbsp;
								<MyButtons.MySalva/>
							</div>
							)}
						</div>
					)}
					<table class="table table-borderless">
						<tr>
							<td>
								<MyForms.MySelect
								label="Tipo Budget"
								list={props.listTipoBudget}
								name="tipoBudgetId"
								class="form-control"
								disabled={readOnly}
								nameLabel="tipoBudget"
							  />
						  </td>
						</tr>
					</table>
					{!props.listVociCosto.length && (
						<p style={{color:'red'}}><bold>Attenzione: non sono state inserite le voci di costo previste per il bando</bold></p>
					)}
				</Form>
			</Formik>
			<ReactDataSheet
				data={myGrid}
				valueRenderer={cell => cell.value}
				dataRenderer={cell => cell.valuePulito}
				className='custom-sheet'
				onCellsChanged={changes => {
					const grid = myGrid.map(row => [...row]);
					changes.forEach(({ cell, row, col, value }) => {
						if(!value) value = 0;

						// Formato "." come separatore di migliaia e "," separatore decimali
						value = Utils.pulisciImporto(value);
						///

						value = isNaN(value) ? 0 : value;
						value = parseFloat(value);
						grid[row][col]['valuePulito'] = value;
						grid[row][col]['value'] = Utils.Format(value);
					});
					calc(grid);
				}}
			/>
		</div>
		{!props.nuovoBudget && (
			<ModalConfirm
				isModalConfirmOpen={isModalConfirmOpen}
				closeModalConfirm={closeModalConfirm}
				handleConfirm={props.handleDelete}
				idToDelete={props.budget.partnerBudgetId}
  	      />
		)}
		</>
	)
}