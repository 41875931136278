import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as MyForms from "./MyForms";
import axios from "axios";
import Modal from "react-modal";
import RenderTab from "./RenderTab";
import { getToken } from "../../../App";

export default function Stack(props) {
  const [fields, setFields] = useState([]);
  const [newTab, setNewTab] = useState(false);
  const [idObject, setIdObject] = useState(null);
  const [state, setState] = useState();

  const forceUpdate = React.useCallback(() => {
    console.log("update");
    setState({});
  }, []);

  useEffect(() => {
    const fetchdata = async (url) => {
      console.log(url);
      await axios
        .get(url)
        .then(function (response) {
          // handle success
          console.log(response.data);
          setFields(response.data);
        })
        .catch(function (error) {
          // handle error
          alert(error);
        })
        .then(function () {
          // always executed
        });
    };

    //Si costruisce l'url
    const url =
      props.getAssociatedUrl +
      props.id +
      "/persone?access_token=" + getToken();

    fetchdata(url);
    setIdObject(props.id);
  }, [props.getAssociatedUrl, props.id, state]);

  const handlePost = async (dataToSend) => {
    const fetchdata = async (url, data) => {
      console.log("HANDLE POST:" + url);
      console.log(data);
      // url += "?access_token=" + getToken();
      await axios
        .post(url, data, { headers: { "Content-Type": "application/json" } })
        .then(function (response) {
          // handle success
          console.log(response.data);
          console.log(response.data.personaId);
        })
        .catch(function (error) {
          // handle error
          alert(error);
        });
    };
    const fetchurl =
      props.postAssociatedUrl +
      idObject +
      "/persone?access_token=" + getToken();
    await fetchdata(fetchurl, dataToSend);
    forceUpdate();
    setNewTab(false);
  };

  const handleDelete = async (id_persona) => {
    const fetchdata = async (url) => {
      console.log(url);
      await axios
        .delete(url, { headers: { "Content-Type": "application/json" } })
        .then(function (response) {
          // handle success
          console.log(response);
        })
        .catch(function (error) {
          // handle error
          alert(error);
        });
    };
    const fetchurl =
      props.putObjectUrl +
      idObject +
      "/persone/" +
      id_persona + "?access_token=" + getToken();
    await fetchdata(fetchurl);
    forceUpdate();
  };

  const handleEdit = (id_persona, dataToSend) => {
    const fetchdata = async (url, data) => {
      url += "?access_token=" + getToken();
      console.log(url);
      console.log(data);
      await axios
        .put(url, data, { headers: { "Content-Type": "application/json" } })
        .then(function (response) {
          // handle success
          console.log(response);
        })
        .catch(function (error) {
          // handle error
          alert(error);
        });
    };
    const fetchurl =
      props.putSubjectUrl + id_persona;
    fetchdata(fetchurl, dataToSend);
  };

  const handleNewClick = () => {
    setNewTab(true);
  };
  return (
    <div
      class="tab-pane fade"
      id={props.idPane}
      role="tabpanel"
      aria-labelledby="tab_pane_2"
    >
      <div class="border-0 top_commandi" style={{ textAlign: "right" }}>
        <div
          class="btn btn-outline-primary font-size-sm mb-5"
          onClick={handleNewClick}
        >
          <i class="icon fa fa-plus"></i> Nuovo
        </div>
      </div>

      <div
        class="accordion accordion-solid accordion-toggle-plus"
        id="accordionExample1"
      >
        {fields.map((field, i) => (
          <RenderTab
            setNewTab={setNewTab}
            handleEdit={handleEdit}
            handlePost={handlePost}
            handleDelete={handleDelete}
            persona={field}
            nuovaScheda={false}
            dataId={i}
            readOnly={true}
          />
        ))}
        {newTab && (
          <RenderTab
            setNewTab={setNewTab}
            handleEdit={handleEdit}
            handlePost={handlePost}
            handleDelete={handleDelete}
            persona={null}
            nuovaScheda={true}
            dataId={fields.length + 1}
            readOnly={false}
          />
        )}
      </div>
    </div>
  );
}
