
import React, { useState } from "react";
import * as Constants from "../../constants";
import ContentTable from "../Tables/ContentTable";
import PMeseModal from "./PMeseModal";
import * as MyButtons from "./MyButtons";
import * as Utils from './Utils';

export default function PMese(props) {
	const [state, setState] = useState({});
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedPMese,setDataPMese] = useState({});

	const openModal = (row_original) => {
		setDataPMese(row_original)
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
	};

	const columns = React.useMemo(
	  () => [
		{
		  Header: "Dipendente",
		  accessor: "dipendente",
		},
		{
		  Header: "Stato",
		  accessor: "stato",
		  Cell: row => {
			if(row.value) 
				return  <span class="label label-lg label-light-success label-inline"> Calcolato </span>
			else 
				return  <span class="label label-lg label-light-warning label-inline"> Da calcolare </span>
		  }
		},
	  ],
	  []
	);
    return (
    	<>
    		<ContentTable
    			key={state.key}
    			columns={columns}
    			title="pMese"
    			easy = "1"
    			dataToDisplay={props.PMese}
    			openModal={openModal}
    			defaultOrderBy={"dipendente"}
    		/>
            <PMeseModal
                isModalOpen={isModalOpen}
                selectedPMese={selectedPMese}
                onRequestClose={closeModal}
				forceUpdate={props.forceUpdate}
				partnerId={props.partnerId}
				progetto={props.progetto}
            />
        </>
    )
}