
import React, { useState, useEffect, useCallback } from "react";
import { Formik, Field, Form } from "formik";
import ContentTable from "../Tables/ContentTable";
import PDipendenteModal from "./PDipendenteModal";
import * as Constants from "../../constants";
import * as datiTest from "../../constants/datiTest";

export default function PDipendente(props) {
	const [state, setState] = useState({});
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedPDipendente,setDataPDipendente] = useState({});

	const openModal = (row_original) => {
		fetchPDipendente(row_original.progettoOrId);
	};

	const fetchPDipendente = async (progettoOrId) => {
		var api_path = Constants.Compose([Constants.PATH_PARTNERS, props.partnerId, 'pianificazioneDipendente']);
		api_path += "?orId=" + progettoOrId;
		const response_data = await Constants.getData(api_path);
		setDataPDipendente(response_data)

		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
	};

	const columns = React.useMemo(
	  () => [
		{
		  Header: "ID OR",
		  accessor: "progettoOrNumero",
		},
		{
		  Header: "OR",
		  accessor: "progettoOrDescrizione",
		},
		{
		  Header: "Stato",
		  accessor: "stato",
		  Cell: row => {
			if(row.value) 
				return  <span class="label label-lg label-light-success label-inline"> Calcolato </span>
			else 
				return  <span class="label label-lg label-light-warning label-inline"> Da calcolare </span>
		  }
		},
	  ],
	  []
	);
    return (
    	<>
    		<ContentTable
    			key={state.key}
    			columns={columns}
    			title="pDipendente"
    			easy = "1"
    			dataToDisplay={props.PDipendente}
    			openModal={openModal}
    			defaultOrderBy={"progettoOrNumero"}
    		/>
            <PDipendenteModal
                isModalOpen={isModalOpen}
                selectedPDipendente={selectedPDipendente}
				partnerId={props.partnerId}
				progetto={props.progetto}
                onRequestClose={closeModal}
				forceUpdate={props.forceUpdate}
            />
        </>
    )
}