import React, { useState, useEffect } from "react";

export const MyModifica = ({ ...props }) => {
    return (
		<button class="btn btn-primary font-size-sm font-weight-bolder" onClick={props.handleClick} >
			<i class="icon fa fa-edit"></i> {props.label ? props.label : "Modifica"}
		</button>
    )
}

export const MyUpload = ({ ...props }) => {
    return (
		<button class="btn btn-primary font-weight-bolder" onClick={props.handleClick} >
			<i class="icon far fa-save"></i> {props.label ? props.label : "Upload"}
		</button>
    )
}

export const MySimple = ({ ...props }) => {
    return (
		<button class="btn btn-primary font-size-sm font-weight-bolder" onClick={props.handleClick}>
			{props.label}
		</button>
	);
}
export const MyAnnulla = ({ ...props }) => {
    return (
		<button type="reset" class="btn btn-outline-primary font-size-sm font-weight-bolder" onClick={props.handleClick}>
			{props.label ? props.label : "Annulla"}
		</button>
	);
}

export const MyCancella = ({ ...props }) => {
    return (
		<button class="btn btn-danger font-size-sm font-weight-bolder" onClick={props.handleClick}>
			<i class="icon fa fa-trash-alt"></i> {props.label ? props.label : "Cancella"}
		</button>
	);
}

export const MySalva = ({ ...props }) => {
    return (
		<button class="btn btn-primary font-weight-bolder" type="submit" >
			<i class="icon fa fa-save"></i>  {props.label ? props.label : "Salva"}
		</button>
	)
}

export const MyAggiungi = ({ ...props }) => {
    return (
		<button class="btn btn-outline-primary font-size-sm font-weight-bolder" onClick={props.handleClick}>
			<i class="icon fa fa-plus"></i>  {props.label ? props.label : "Nuovo"}
		</button>
	)
}
export const MyClose = ({ ...props }) => {
    return (
		<button type="button" class="close" onClick={props.handleClick} >
			<i aria-hidden="true" class="ki ki-close"></i>
		</button>
	)
}
