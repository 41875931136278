import React, { useState, useEffect, useCallback } from "react";
import Modal from "react-modal";
import ReactDataSheet from 'react-datasheet';
import * as MyButtons from "./MyButtons";
import * as Constants from "../../constants";
import 'react-datasheet/lib/react-datasheet.css';
import '../../../custom-datasheet.css';
import * as Utils from './Utils';

Modal.setAppElement("#root");

export default function Monitoraggio(props) {
	const [myGrid, setGrid] = useState([]);

	useEffect(() => {
		setGrid(props.myGrid);
	}, [props.myGrid]);

	return (
		<>
			<Modal
				isOpen={props.isModalOpen}
				onRequestClose={props.onRequestClose}
				style={{
					overlay: {
						width: "350",
					},
					content: {
						top: "5%",
						left: "7%",
						right: "7%",
						bottom: "auto",
					},
					width: "1200px"
				}}
			>
				<div className="modal-content">
					<div className="modal-header">
						<h5>Monitoraggio Avanzamento Costi &nbsp; - {props.selectedPartnerName}</h5>
						<MyButtons.MyClose handleClick={props.onRequestClose}/>
					</div>
					<div className="modal-body">
						<div class="border-0 top_commandi bp-5" style={{textAlign:"center"}}>
							<ReactDataSheet
								data={myGrid}
								readOnly={true}
								valueRenderer={cell => { cell.readOnly = true; return cell.value; }}


								className='custom-sheet mx-auto'
							/>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
}