import React, { useState } from "react";
import useBreadcrumbs from "use-react-router-breadcrumbs";

export default function Breadcrumb(props) {
  const breadcrumbs = useBreadcrumbs();

  return (
    <div class="d-flex align-items-center font-weight-bold my-2">
      <a href="/" class="opacity-75 hover-opacity-100">
        <i class="flaticon2-shelter text-white icon-1x"></i>
      </a>

      {breadcrumbs.map(({ match, breadcrumb }) => (
        <a
          key={match.url}
          href={match.url}
          class="text-white text-hover-white opacity-75 hover-opacity-100"
        >
          <span class="label label-dot label-sm bg-white opacity-75 mx-3"></span>
          {breadcrumb}{" "}
        </a>
      ))}
    </div>
  );
}
