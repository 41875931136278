import React, { useState, useEffect, useCallback } from "react";
import * as Constants from "../../constants";
import {
  useHistory,
  withRouter,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import ContentTable from "./ContentTable";
import SchedaBanca from "../Forms/SchedaBanca";
import { PrivateRoute } from "../../../App";

function Banche(props) {
  const { url, path } = useRouteMatch();

  const history = useHistory();

  const changeRoute = useCallback(
    (row) => {
      history.push({ pathname: url + "/" + row.nome, state: row });
    },
    [url, history]
  );

  const changeState = useCallback(
    (row) => {
      history.push({pathname: "/"});
      history.replace({ pathname: url + "/" + row.nome, state: row });
    },
    [url, history]
  );

  const backFromScheda = useCallback(() => {
    history.push({ pathname: "/Banche" });
  }, [history]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "bancaId", // accessor è la chiave in data
      },
      {
        Header: "Nome",
        accessor: "nome",
      },
      {
        Header: "Tipologia",
        accessor: "tipoBanca.tipoBanca",
      },
    ],
    []
  );

  return (
    <Switch>
      <PrivateRoute exact path="/Banche">
        <ContentTable
          columns={columns}
          title="Banca"
          fetchUrl={Constants.GETBANCHEURL + '?filter=' + JSON.stringify({ include: ["tipoBanca", "utente"]})}
          changeRoute={changeRoute}
          defaultOrderBy={"nome"}
        />
      </PrivateRoute>
      <PrivateRoute exact path={"/Banche/Nuova Banca"}>
        <SchedaBanca
          backFromScheda={backFromScheda}
          nuovaScheda={true}
          changeState={changeState}
        />
      </PrivateRoute>
      <PrivateRoute exact path={"/Banche/:nome"}>
        <SchedaBanca
          backFromScheda={backFromScheda}
          getReferentiUrl={Constants.GETREFERENTIURL}
          postReferentiUrl={Constants.POSTREFERENTIURL}
          putPersoneUrl={Constants.PUTPERSONEURL}
          nuovaScheda={false}
        />
      </PrivateRoute>
    </Switch>
  );
}

export default withRouter(Banche);
