import React, { useState, useEffect } from "react";
import * as MyButtons from "./MyButtons";
import PartnerTab from "./PartnerTab";
import * as Constants from "../../constants";

export default function PartnerStack(props) {
	const [state, setState] = useState();
	const [listPartner, setlistPartner] = useState([]);
	const [listClienti, setListClienti] = useState([]);
	const [newTab, setNewTab] = useState(false);

	const forceUpdate = React.useCallback(() => {
		console.log("STACK FORCE UPDATE")
		setState({});
	}, []);

	useEffect(() => {
		if(props.displayTab == 7) {
			fetchPartners();
			fetchClienti();
		}
	}, [props.progettoId, props.displayTab, state]);

	const fetchClienti = async () => {
		let api_path = Constants.Compose([Constants.PATH_CLIENTI]);
		api_path += "?filter=" + JSON.stringify({order: ['nome']});
		const response_data = await Constants.getData(api_path);
		setListClienti(response_data);
	};


	const fetchPartners = async () => {
		var api_path = Constants.Compose([Constants.PATH_PROGETTI, props.progettoId, Constants.PATH_PARTNERS_INREL]);
		api_path += "?filter=" + JSON.stringify({include:[{'relation': 'cliente', 'scope': {order: ['nome']}}, 'partnerLivelli', 'referenti']});
		const response_data = await Constants.getData(api_path);
		setlistPartner(response_data);
	};

	const handleNewClick = () => {
		setNewTab(true);
	};

	return (
		<div>
			<div class="border-0 top_commandi" style={{ textAlign: "right" }}>
				<MyButtons.MyAggiungi handleClick={handleNewClick} label="Aggiungi Partner"/>
			</div>
			<div class="accordion accordion-solid accordion-toggle-plus" id="accordionPartners" >
				{newTab && (
					<PartnerTab
						key='new'
						setNewTab={setNewTab}
						forceUpdate={forceUpdate}
						progettoId={props.progettoId}
						nuovaTab={true}
						dataId={listPartner.length + 1}
						listClienti={listClienti}
						readOnly={false}
					/>
				)}
				{listPartner.map((partner, i) => (
					<PartnerTab
						key={i}
						setNewTab={setNewTab}
						partner={partner}
						bandoId={props.bandoId}
						forceUpdate={forceUpdate}
						listClienti={listClienti}
						nuovaTab={false}
						dataId={i}
						readOnly={true}
						progetto={props.progetto}
					/>
				))}
			</div>
		</div>
	);
}
