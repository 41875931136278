
import React, { useState, useEffect, useCallback } from "react";
import { Formik, Field, Form } from "formik";
import * as Constants from "../../constants";
import ContentTable from "../Tables/ContentTable";
import PLivelloModal from "./PLivelloModal";

export default function PLivello(props) {
	const [state, setState] = useState({});
    const [isModalOpen, setModalOpen] = useState(false);
	const [LastBudget, setLastBudget] = useState();
    const [partnerAttivitaId, setPartnerAttivitaId] = useState();
    const [statoPLivello, setStatoPLivello] = useState();

	useEffect(() => {
		if(! props.displayTab || props.displayTab == 'plivello') {
			fetchBudgets();
		}
	}, [props.displayTab]);

	const openModal = (row_original) => {
		setPartnerAttivitaId(row_original.partnerAttivitaId);
		setStatoPLivello(row_original.stato);  /// Brutto!!! Da cambiare ..
		setModalOpen(true);
	};

	const fetchBudgets = async () => {
		var api_path = Constants.Compose([Constants.PATH_PARTNERS, props.partnerId, Constants.PATH_BUDGET_INREL]);
		api_path += "?filter=" + JSON.stringify({'include':['tipoBudget', 'partnerCosti'], 'order': ['tipoBudgetId DESC']});
		const response_data = await Constants.getData(api_path);
		if(response_data[0]) setLastBudget(response_data[0]);
	};

	const closeModal = () => {
		setModalOpen(false);
	};

	const columns = React.useMemo(
	  () => [
		{
		  Header: "Numero Attivita",
		  accessor: "attivitaNumero",
		},
		{
		  Header: "Tipologia Attivita",
		  accessor: "tipoAttivita",
		},
		{
		  Header: "Descrizione",
		  accessor: "attivitaDescrizione",
		},
		{
		  Header: "OR",
		  accessor: "progettoOrDescrizione",
		},
		{
		  Header: "Durata",
		  accessor: "durata",
		},
		{
		  Header: "Stato",
		  accessor: "stato",
		  Cell: row => {
			if(row.value) 
				return  <span class="label label-lg label-light-success label-inline"> Calcolato </span>
			else 
				return  <span class="label label-lg label-light-warning label-inline"> Da calcolare </span>
		  }
		},
	  ],
	  []
	);
    return (
    	<>
    		<ContentTable
    			key={state.key}
    			columns={columns}
    			title="pLivello"
    			easy = "1"
    			dataToDisplay={props.pLivello}
    			openModal={openModal}
    			defaultOrderBy={"attivitaNumero"}
				message_nodata="Nessuna attività è stata assegnata a questo partner"
    		/>
            <PLivelloModal
                isModalOpen={isModalOpen}
				partnerAttivitaId={partnerAttivitaId}
				statoPLivello={statoPLivello}
				lastBudget={LastBudget}
				partnerId={props.partnerId}
				forceUpdate={props.forceUpdate}
                onRequestClose={closeModal}
            />
        </>
    )
}