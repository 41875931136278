import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as MyForms from "./MyForms";
import axios from "axios";
import Modal from "react-modal";
import ContrattiTab from "./ContrattiTab";
import * as Constants from "../../constants";

export default function ContrattiStack(props) {
	const [contratti, setContratti] = useState([]);
	const [newTab, setNewTab] = useState(false);
	const [progettoId, setProgettoId] = useState(false);
	const [clienteId, setClienteId] = useState(false);
	const [listProgetti, setListProgetti] = useState(false);
	const [listClienti, setListClienti] = useState(false);

	const [state, setState] = useState();

	const forceUpdate = React.useCallback(() => {
		setState({});
	}, []);

	useEffect(() => {
		if(props.displayTab == 5 || props.displayTab == 'cli_5') {
			fetch();
			setProgettoId(props.progettoId);
			setClienteId(props.clienteId);
		}
	}, [props.clienteId, props.progettoId, props.displayTab, state]);

	const fetch = async () => {
		var api_path = Constants.Compose([Constants.PATH_CONTRATTI]);
		if(props.clienteId) api_path += "?filter=" + JSON.stringify({'where': {clienteId: props.clienteId}, 'include': ['progetto', 'cliente', 'allegato'], 'order': ['createdAt DESC']});
		if(props.progettoId) api_path += "?filter=" + JSON.stringify({'where': {progettoId: props.progettoId}, 'include': ['progetto', 'cliente', 'allegato'], 'order': ['createdAt DESC']});
		const response_data = await Constants.getData(api_path);
		setContratti(response_data);

		if(props.clienteId) {
			var api_path = Constants.Compose([Constants.PATH_PROGETTI]);
			console.log(api_path)
			const response_data = await Constants.getData(api_path);
			for(var i in response_data) {
				var p = response_data[i];
				p['acronimo_titolo'] = p.acronimo + " - " + p.titolo;
			}
			setListProgetti(response_data);
		}

		if(props.progettoId) {
			// var api_path = Constants.Compose([Constants.PATH_CLIENTI]);
			var api_path = Constants.Compose([Constants.PATH_PROGETTI, props.progettoId, 'partners']);
			api_path += "?filter=" + JSON.stringify({'include': ['cliente']});
			const response_data = await Constants.getData(api_path);
			let list = [];
			for(var i in response_data) {
				list.push(response_data[i].cliente)
			}
			setListClienti(list);
		}
	};

	const handleDelete = async (id) => {
		const deleteUrl =
			Constants.PUTCONTRATTIURL + id;
		await Constants.deleteData(deleteUrl);
		forceUpdate();
	};

	const handleEdit = async (id, dataToSend) => {
		const fetchUrl = Constants.PUTCONTRATTIURL + id;
		await Constants.putData(fetchUrl, dataToSend);
		forceUpdate();
	};

	const handlePostContratto = async (dataToSend) => {
		const postNewContratto = async () => {
			const fetchurl = Constants.POSTCONTRATTIURL;
			const response_data = await Constants.postData(fetchurl, dataToSend);
		};

		delete dataToSend.allegatoId;
		await postNewContratto(JSON.stringify(dataToSend, null, 2));
		forceUpdate();
		setNewTab(false);
	};

	const handleNewClick = () => {
		setNewTab(true);
	};

	return (
		<>
			<div class="border-0 top_commandi" style={{ textAlign: "right" }}>
				<div class="btn btn-outline-primary font-size-sm mb-5" onClick={handleNewClick} >
					<i class="icon fa fa-plus"></i> Nuovo
				</div>
			</div>
			<div class="accordion accordion-solid accordion-toggle-plus" id="accordionContratti" >
				{newTab && (
					<ContrattiTab
						setNewTab={setNewTab}
						handleEdit={handleEdit}
						handlePost={handlePostContratto}
						handleDelete={handleDelete}
						contratto={null}
						nuovaTab={true}
						progettoId={progettoId}
						clienteId={clienteId}
						listProgetti={listProgetti}
						listClienti={listClienti}
						dataId={contratti.length + 1}
						readOnly={false}
					/>
				)}
				{contratti.map((contratto, i) => (
					<ContrattiTab
						setNewTab={setNewTab}
						handleEdit={handleEdit}
						handlePost={handlePostContratto}
						handleDelete={handleDelete}
						contratto={contratto}
						nuovaTab={false}
						progettoId={progettoId}
						clienteId={clienteId}
						listProgetti={listProgetti}
						listClienti={listClienti}
						dataId={i}
						readOnly={true}
					/>
				))}
			</div>
		</>
	);
}
