import React, { useState, useEffect, useCallback } from "react";
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import * as MyButtons from "./MyButtons";
import ReactDataSheet from 'react-datasheet';
import * as Constants from "../../constants";
import 'react-datasheet/lib/react-datasheet.css';
import '../../../custom-datasheet.css';
import * as Utils from './Utils';

Modal.setAppElement("#root");

export default function PMeseModal(props) {
	const [state, setState] = useState();
  	const [myGrid, setGrid] = useState([]);
	const [RTotale, setRTotale] = useState();
	const [visCosti, setVisCosti] = useState(false);
	const [visPianificazione, setVisPianificazione] = useState(false);
	const [effort_totale, setEffortTotale] = useState(false);
	const [costo_totale, setCostoTotale] = useState(false);
	const [visCalcolato, setVisCalcolato] = useState(false);
    const [rendicontazione, setRendicontazione] = useState({});
    const [PMese, setPMese] = useState([]);
	const [readOnly, setReadOnly] = useState(true);
	
	useEffect(() => {
		fetchPMese();
		if(props.rendicontato) {
			setVisPianificazione(false)
		} else {
			setVisPianificazione(true)
		}
	}, [props.partnerDipendente, state]);

	useEffect(() => {
		setVisCalcolato(false);
		var [grid, RTotale, alert, effort_totale_sal, costo_totale_sal] = Utils.PreparePMese(PMese, props.partnerDipendente.costoMedioOrario, visCalcolato, visCosti, readOnly, props.sal.numero, props.rendicontato, undefined, visPianificazione);
		setGrid(grid);
		setRTotale(RTotale);
		setEffortTotale(effort_totale_sal)
		setCostoTotale(costo_totale_sal)
	}, [readOnly, PMese, visCosti, visPianificazione]);

	const handleChangeView = () => {
		setVisCosti(!visCosti);
	}
	const handleChangeViewPianificazione = () => {
		setVisPianificazione(!visPianificazione);
	}

	const fetchPMese = async () => {
		var api_path = Constants.Compose([Constants.PATH_PMESE]);
		api_path += "?partnerDipendenteId="+ props.partnerDipendente.partnerDipendenteId+"&progettoId="+props.progettoId;
		const response_data = await Constants.getData(api_path);
		setPMese(response_data);
		setRendicontazione(props.partnerDipendente.rendicontazioni[0])
	};

	const handlePost = async () => {
		var d = {
			"effort": [],
			"salId": props.sal.salId
		};

		myGrid.map(row => {
			row.map(cell => {
				if(cell.pianificazioneDipendenteId) {
					var obj = {
						pianificazioneDipendenteId: cell.pianificazioneDipendenteId,
						mese: cell.idMese,
					}
					var value = cell.value || 0;
					var costoRendicontato = cell.costoRendicontato || 0;
					if(cell.getBis) {
						obj.effortRendicontatoBis = value
						obj.costoRendicontatoBis = costoRendicontato
					} else {
						obj.effortRendicontato = value
						obj.costoRendicontato = costoRendicontato
					}
					d.effort.push(obj);
				}
			});
		});
		var api_path = Constants.Compose([Constants.PATH_PMESE_POST]);
		const response_data = await Constants.postData(api_path, JSON.stringify(d));

		props.forceUpdate();
		fetchPMese();
	};

	const handleEditClick = () => {
		setReadOnly(false);
	}

	return (
		<>
			<div className="modal-content">
				<div className="modal-body">
					<Formik
						enableReinitialize={true}
						initialValues={{
						}}
						onSubmit={(values, { setSubmitting }) => {
							handlePost(values);
							setReadOnly(true);
						}}
						onReset={() => {
							setReadOnly(true);
						}}
					>
						<Form class="form">
							<div class="border-0 top_commandi" style={{ textAlign: "right", height: "40px" }} >
								{! props.SalReadOnly && (
									!visCosti && (
										readOnly ? (
											<MyButtons.MyModifica handleClick={handleEditClick}/>
										) : (
											<>
												<MyButtons.MyAnnulla/>
												&nbsp;&nbsp;
												<MyButtons.MySalva/>
											</>
										)
									)
								)}
							</div>
							<div class="pointer border-0 top_commandi bp-5">
								{readOnly && (
									visCosti ? (
										<span class="label label-lg label-light-primary label-inline" onClick={handleChangeView}> Visualizza Effort</span>
									) : (
										<span class="label label-lg label-light-primary label-inline" onClick={handleChangeView}> Visualizza Costi</span>
									)
								)}
							</div>
							<div>
								<span>Costo Medio Orario: {Utils.Format(props.partnerDipendente.costoMedioOrario)}</span>
							</div>
							<div>
								<span>Effort totale rendicontato: {effort_totale}</span>
							</div>
							<div>
								<span>Costo totale rendicontato: {Utils.Format(costo_totale)}</span>
							</div>
							{! readOnly && (
								visPianificazione ? (
										props.rendicontato ? (
											<h6 class="text-center">Stai visualizzando gli effort salvati nell'ultima pianificazione &nbsp; 
												<span class="pointer label label-lg label-light-primary label-inline" onClick={handleChangeViewPianificazione}> Visualizza Effort Rendicontati</span>
											</h6>
										) : (
											<h6 class="text-center">Stai visualizzando gli effort salvati nell'ultima pianificazione &nbsp; </h6>
										)
								) : (
									<h6 class="text-center">Stai visualizzando gli effort dell'ultimo salvataggio della rendicontazione &nbsp;
										<span class="pointer label label-lg label-light-primary label-inline" onClick={handleChangeViewPianificazione}> Visualizza Effort salvati nell'ultima pianificazione</span>
									</h6>
								)
							)}
							<div>
								<ReactDataSheet
									data={myGrid}
									valueRenderer={cell => cell.value}
									className='custom-sheet'
									onCellsChanged={changes => {
										const grid = myGrid.map(row => [...row]);
										changes.forEach(({ cell, row, col, value }) => {
											if(!value) value = 0;
											value = isNaN(value) ? 0 : value;
											value = parseInt(value);
											grid[row][col] = { ...grid[row][col], value };

											var costoRendicontato = parseFloat(value * props.partnerDipendente.costoMedioOrario);
											grid[row][col]['valuePulito'] = value;
											grid[row][col]['costoRendicontato'] = costoRendicontato;
										});
										var [gridUpdated] = Utils.calcSommePMese(grid, RTotale, props.partnerDipendente.costoMedioOrario, props.sal.numero);
										setGrid(gridUpdated);
									}}
								/>
							</div>
						</Form>
					</Formik>
				</div>
			</div>
        </>
    )
}