import React, { useState, useCallback, useEffect } from "react";
import {
  useHistory,
  Redirect,
  withRouter,
  useLocation,
} from "react-router-dom";
import * as MyForms from "./components/Content/Forms/MyForms";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import * as Constants from "./components/constants";
import PropTypes from "prop-types";

function Login(props) {
  const [loggedIn, setLoggedIn] = useState(false);
  const [personaId, setPersonaId] = useState(false);

  const history = useHistory();

  const toHome = useCallback(() => {
    history.push({ pathname: "/" });
  }, [history]);

	useEffect(() => {
    if(personaId) fetchPersona();
  }, [personaId]);

  const handleLogin = async (dataToSend) => {
    const fetchdata = async (url, data) => {
      await axios
        .post(url, data, { headers: { "Content-Type": "application/json" } })
        .then(function (response) {
          if (response.status === 200) {
            const token = response.data.id;
            props.setToken(token);
            if(response.data.user.personaId) {
              setPersonaId(response.data.user.personaId);
            } else {
              fetchPersona();
            }
          } else {
            console.log("Login respone error ", response)
            alert(response.error.message)
          }
        })
        .catch(function (error) {
          console.log("Login error ", error)
          alert(error);
        });
    };
    const fetchurl = Constants.LOGINURL + '?include=user';
    fetchdata(fetchurl, dataToSend);
    // props.setRoles("RUOLO FINTO");
  };

	const fetchPersona = async () => {
    let nome_utente = 'Amministratore';
    if(personaId) {
		  let api_path = Constants.Compose(['Persone', personaId]);
		  const response_data = await Constants.getData(api_path);
      nome_utente = response_data.nome + ' ' + response_data.cognome;
    }
    props.setUtente(nome_utente);
    setLoggedIn(true);
	};

  if (loggedIn) {
    toHome();
    window.location.reload();
  }


  return (
    <div className="login login-2 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white" id="kt_login" >
      <div className="login-aside order-2 order-lg-1 d-flex flex-column-fluid flex-lg-row-auto bgi-size-cover bgi-no-repeat p-7 p-lg-10">
        <div className="d-flex flex-row-fluid flex-column justify-content-between">
          <div className="d-flex flex-column-fluid flex-column flex-center mt-5 mt-lg-0">
            <a href="#" className="mb-15 text-center">
              <img src="assets/images/logo_riformed.png" className="max-h-75px" alt="" />
            </a>
            <div className="login-form login-signin">
              <div className="text-center mb-10 mb-lg-20">
                <h2 className="font-weight-bold">Entra nell'Area Riservata</h2>
                <p className="text-muted font-weight-bold"> Inserisci il tuo nome utente e la password </p>
              </div>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={Yup.object({
                  email: Yup.string().required("Required"),
                  password: Yup.string()
                    .min(6, "Password must be at least 6 characters")
                    .max(24, "Password can be maximum 24 characters")
                    .required("Required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  if(values['email'] == 'amministratore') {
                    values['username'] = values['email']
                    delete values['email']
                  }
                  handleLogin(JSON.stringify(values, null, 2));
                }}
              >
                <Form className="form" id="kt_login_signin_form">
                  <div className="form-group py-3 m-0">
                    <MyForms.MyTextInput
                      label="Email"
                      name="email"
                      //type="text"
                      class="form-control h-auto border-1 placeholder-dark-75"
                    />
                  </div>
                  <div className="form-group py-3 border-top m-0">
                    <MyForms.MyTextInput
                      label="Password"
                      name="password"
                      type="password"
                      class="form-control h-auto border-1 placeholder-dark-75"
                    />
                  </div>
                  <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-2">
                    <button className="btn btn-primary font-weight-bold px-9 py-4 my-3" type="submit" > Login </button>
                  </div>
                </Form>
              </Formik>
            </div>

            <div className="login-form login-signup">
              <div className="text-center mb-10 mb-lg-20">
                <h3 className="">LOG IN</h3>
                <p className="text-muted font-weight-bold"> Enter your details to create your account </p>
              </div>

              <form className="form" noValidate="novalidate" id="kt_login_signup_form" >
                <div className="form-group py-3 m-0">
                  <input className="form-control h-auto border-0 px-0 placeholder-dark-75" type="text" placeholder="Fullname" name="fullname" autocomplete="off" />
                </div>
                <div className="form-group py-3 border-top m-0">
                  <input className="form-control h-auto border-0 px-0 placeholder-dark-75" type="password" placeholder="Email" name="email" autocomplete="off" />
                </div>
                <div className="form-group py-3 border-top m-0">
                  <input className="form-control h-auto border-0 px-0 placeholder-dark-75" type="password" placeholder="Password" name="password" autocomplete="off" />
                </div>
                <div className="form-group py-3 border-top m-0">
                  <input className="form-control h-auto border-0 px-0 placeholder-dark-75" type="password" placeholder="Confirm password" name="cpassword" autocomplete="off" />
                </div>
                <div className="form-group mt-5">
                  <div className="checkbox-inline">
                    <label className="checkbox checkbox-outline">
                      <input type="checkbox" name="agree" />
                      <span></span>I Agree the{" "} <a href="#" className="ml-1"> terms and conditions </a> .
                    </label>
                  </div>
                </div>
                <div className="form-group d-flex flex-wrap flex-center">
                  <button id="kt_login_signup_submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-2" >
                    Submit
                  </button>
                  <button id="kt_login_signup_cancel" className="btn btn-outline-primary font-weight-bold px-9 py-4 my-3 mx-2" >
                    Cancel
                  </button>
                </div>
              </form>
            </div>

            <div className="login-form login-forgot">
              <div className="text-center mb-10 mb-lg-20">
                <h3 className="">Forgotten Password ?</h3>
                <p className="text-muted font-weight-bold">
                  Enter your email to reset your password
                </p>
              </div>

              <form className="form" noValidate="novalidate" id="kt_login_forgot_form" >
                <div className="form-group py-3 border-bottom mb-10">
                  <input className="form-control h-auto border-0 px-0 placeholder-dark-75" type="email" placeholder="Email" name="email" autocomplete="off" />
                </div>
                <div className="form-group d-flex flex-wrap flex-center">
                  <button id="kt_login_forgot_submit" className="btn btn-primary  font-weight-bold px-9 py-4 my-3 mx-2" >
                    Submit
                  </button>
                  <button id="kt_login_forgot_cancel" className="btn btn-light-primary  font-weight-bold px-9 py-4 my-3 mx-2" >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="d-flex flex-column-auto justify-content-between mt-15">
            <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
              &copy; 2022 Ri.For.Med. S.r.l.
            </div>
            <div className="d-flex order-1 order-sm-2 my-2">
              <a href="#" className="text-muted text-hover-primary"> Privacy </a>
              <a href="#" className="text-muted text-hover-primary ml-4"> Contatti </a>
            </div>
          </div>
        </div>
      </div>
      <div className="order-1 order-lg-2 flex-column-auto flex-lg-row-fluid d-flex flex-column p-7" style={{ backgroundImage: "url(assets/images/bg6.jpg )", backgroundSize: "cover", }} >
        <div className="d-flex flex-column-fluid flex-lg-center">
          <div className="d-flex flex-column justify-content-center">
            <h3 className="display-3 font-weight-bold my-7 text-white"> Ri.For.Med. S.r.l.  </h3>
            <p className="font-weight-bold font-size-lg text-white opacity-80">
              "Decidete che una cosa si può e si deve fare e troverete il modo"
              (Abramo Lincoln)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
  setUtente: PropTypes.func.isRequired,
};

export default withRouter(Login);
