import React, { useState, useEffect, useCallback } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as MyForms from "./MyForms";
import * as MyButtons from "./MyButtons";
import Modal from "react-modal";
import * as Constants from "../../constants";
import ContentTable from "../Tables/ContentTable";
import FormAllegato from "./FormAllegato";
import Moment from "react-moment";
import * as Utils from "./Utils";

Modal.setAppElement("#root");

export default function Allegati(props) {
	const [ApiPath, setApiPath] = useState();
	const [selectedAllegato, setDataAllegato] = useState({});
	const [nuovoAllegato, setNuovoAllegato] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);
	const [tipiAllegato, setTipiAllegato] = useState([]);
	const [state, setState] = useState({});

	const [readOnly, setReadOnly] = useState(props.readOnly);
	const [divStyle, setDivStyle] = useState(
		props.nuovaTab ? "collapse show" : "collapse"
	);

	useEffect(() => {
		if(props.displayTab == 6) {
			var api_path = Constants.Compose([Constants.PATH_PROGETTI, props.progettoId, Constants.PATH_ALLEGATI]);
			api_path += "?filter=" + JSON.stringify({include: ['tipoAllegato']}); 
			setApiPath(api_path);
			fetchTipiAllegato();
		}
	}, [props.displayTab]);

	const forceUpdate = React.useCallback(() => {
		setState({ key: Math.random() });
	}, []);

	const fetchTipiAllegato = async () => {
		var api_path = Constants.Compose([Constants.PATH_TIPOALLEGATO]);
		api_path += "?filter=" + JSON.stringify({order: ['tipoAllegato']}); 
		const response_data = await Constants.getData(api_path);
		setTipiAllegato(response_data);
	};


	const closeNewTab = () => {
		props.setNewTab(false);
	};

	const openModal = (row_original) => {
		if(row_original.allegatoId) {
			setDataAllegato(row_original)
			setNuovoAllegato(false);
		} else {
			setNuovoAllegato(true);
		}
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
	};

	const columns = React.useMemo(
	  () => [
		{
		  Header: "Tipo Documento",
		  accessor: "tipoAllegato.tipoAllegato",
		},
		{
		  Header: "Documento",
		  accessor: "fileName",
		  click: 1,
		  Cell: row => (
			<span className="link" onClick={() => {Utils.downloadAllegato(row.row.original.allegatoId, row.row.original.fileName)}}>{row.value}</span>
		  )
		},
		{
		  Header: "Note",
		  accessor: "note"
		},
		{
		  Header: "Data",
		  accessor: "data",
		  Cell: row => (
			<Moment format="DD/MM/YYYY">{row.value}</Moment>
		  )
		},
	  ],
	  []
	);

	return (
		<>
			<div class="card">
				<div class="card-body">
					<div class="border-0 top_commandi bp-5" style={{ textAlign: "right" }} >
						<div>
							<MyButtons.MyAggiungi handleClick={openModal} label="Nuovo Documento"/>
						</div>
					</div>
					<div>
						<ContentTable
							key={state.key}
							columns={columns}
							easy = "1"
							fetchUrl={ApiPath}
							openModal={openModal}
							defaultOrderBy={"numero"}
						/>
					</div>
				</div>
			</div>
			<FormAllegato
				isModalOpen={isModalOpen}
				allegato={selectedAllegato} 
				tipiAllegato={tipiAllegato}
				{...props}
				forceUpdate={forceUpdate}
				nuovoAllegato={nuovoAllegato}
				onRequestClose={closeModal}
				setNuovaAllegato={setNuovoAllegato}
			/>
		</>
	);
}
