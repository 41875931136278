import React, { useState, useEffect, useCallback } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import * as MyForms from "./MyForms";
import * as Constants from "../../constants";
import * as MyButtons from "./MyButtons";
import Modal from "react-modal";
import ContentTable from "../Tables/ContentTable";
import ModalConfirm from "./ModalConfirm";

export default function FormAttivita(props) {
  const [fields, updateFields] = useState(props.attivita);
  const [state, setState] = useState({});

  const [selectedPartner, setSelectedPartner] = useState();

  const [readOnlyAttivita, setReadOnlyAttivita] = useState(true);
  const [readOnlyPartnerAttivita, setReadOnlyPartnerAttivita] = useState(true);

  const [listProgettoPartners, setListProgettoPartners] = useState([]);
  const [listTipiAttivita, setListTipiAttivita] = useState([]);

  const [isModalConfirmOpen, setModalConfirm] = useState(false);
  const [isModalConfirmPartnerOpen, setModalPartnerConfirm] = useState(false);

  const [api_path_partnerattivita, setApiPathPartnerAttivita] = useState(false);

  const forceUpdate = React.useCallback(() => {
    setState({ key: Math.random() });
  }, []);

  const test = async (args) => {
    await updateFields(args);
  };

  useEffect(() => {
    setReadOnlyAttivita(!props.nuovaAttivita);
    setReadOnlyPartnerAttivita(true);
  }, [props.nuovaAttivita]);

  useEffect(() => {
    fetchTipoAttivita();
    fetchProgettoPartners();
  }, [state]);

  useEffect(() => {
    if(readOnlyPartnerAttivita) setSelectedPartner(undefined);
  }, [readOnlyPartnerAttivita]);

  useEffect(() => {
    var api_path;
    if (fields["attivitaId"]) {
      api_path = Constants.Compose([
        Constants.PATH_ATTIVITA,
        fields["attivitaId"],
        Constants.PATH_PARTNERS_INREL,
      ]);
      api_path += "?filter=" + JSON.stringify({ include: "cliente" });
    }
    setApiPathPartnerAttivita(api_path);
    forceUpdate();
  }, [fields]);

  const fetchProgettoPartners = async () => {
    var url = Constants.Compose([
      Constants.PATH_PROGETTI,
      props.progettoOr.progettoId,
      Constants.PATH_PARTNERS,
    ]);
    url += "?filter=" + JSON.stringify({ include: ["cliente"] });
    const response_data = await Constants.getData(url);
    var list = [];
    response_data.map((p) => {
      list.push({ partnerId: p.partnerId, nome: p.cliente.acronimo });
    });
    setListProgettoPartners(list);
  };

  const fetchTipoAttivita = async () => {
    const url = Constants.Compose([Constants.PATH_TIPOATTIVITA]);
    const response_data = await Constants.getData(url);
    setListTipiAttivita(response_data);
  };

  //// PROGETTO PARTNER ////
  const handleChangePartner = (e) => {
    setSelectedPartner(e.target.value);
  };
  const handlePostPartnerAttivita = async () => {
    if (!selectedPartner) return;
    var api_path = Constants.Compose([
      Constants.PATH_ATTIVITA,
      fields["attivitaId"],
      Constants.PATH_PARTNERS_INREL,
      "rel",
      selectedPartner,
    ]);
    const response_data = await Constants.putData(api_path);

    forceUpdate();
    props.forceUpdateTab();
  };
  /////////////////////////

  //// PARTNER ATTIVITA ////
  const handleEditClickPartnerAttivita = () => {
    setReadOnlyPartnerAttivita(!readOnlyPartnerAttivita);
  };
  const handleDeletePartnerAttivita = async (partnerToDelete) => {
    var api_path = Constants.Compose([
      Constants.PATH_ATTIVITA,
      fields["attivitaId"],
      Constants.PATH_PARTNERS_INREL,
      "rel",
      state.partnerToDelete,
    ]);
    const response_data = await Constants.deleteData(api_path);

    forceUpdate();
    props.forceUpdateTab();
  };
  ////////

  //// ATTIVITA ////
  const handleEditClickAttivita = () => {
    setReadOnlyAttivita(!readOnlyAttivita);
  };
  const handleEditAttivita = async (dataToSend) => {
    dataToSend.attivitaId = fields["attivitaId"];
    dataToSend.durata = dataToSend.meseFine - dataToSend.meseInizio + 1;
    // API custumizzata di MASSIMO
    var api_path = Constants.Compose([Constants.PATH_ATTIVITA]);
    const response_data = await Constants.putData(
      api_path,
      JSON.stringify(dataToSend)
    );
    setReadOnlyAttivita(true);
    props.forceUpdateTab();
  };

  const handlePostAttivita = async (dataToSend) => {
    dataToSend.durata = dataToSend.meseFine - dataToSend.meseInizio + 1;
    dataToSend.progettoOrId = props.progettoOr.progettoOrId;
    // API custumizzata di MASSIMO
    var api_path = Constants.Compose([Constants.PATH_ATTIVITA]);
    const response_data = await Constants.postData(
      api_path,
      JSON.stringify(dataToSend)
    );
    props.setNuovaAttivita(false);
    setReadOnlyAttivita(true);
    props.forceUpdateTab();
    updateFields(response_data);
  };

  const handleDeleteAttivita = async () => {
    var api_path = Constants.Compose([
      Constants.PATH_ATTIVITA,
      fields["attivitaId"],
    ]);
    const response_data = await Constants.deleteData(api_path);
    props.onRequestClose();
    props.forceUpdateTab();
  };

  const openModalConfirm = (e) => {
    setModalConfirm(true);
  };
  const closeModalConfirm = () => {
    setModalConfirm(false);
  };
  const openModalPartnerConfirm = (e) => {
    setState({ partnerToDelete: e.partnerId });
    setModalPartnerConfirm(true);
  };
  const closeModalPartnerConfirm = () => {
    setModalPartnerConfirm(false);
  };

  const handleCalc = (e) => {
    console.log(e);
    console.log("CALCOLO:" + e.target.value);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Partners associati all'attività",
        accessor: "cliente.acronimo",
      },
      {
        Header: "Cancella",
        delete: 1,
      },
    ],
    []
  );

  return (
    <>
      <div
        class="tab-pane fade active show"
        id="tab_att_pane_1"
        role="tabpanel"
      >
        <Formik
          enableReinitialize={true}
          initialValues={
            !props.nuovaAttivita
              ? {
                  numero: fields["numero"],
                  descrizione: fields["descrizione"],
                  tipoAttivitaId: fields["tipoAttivitaId"],
                  progettoOrId: fields["progettoOrId"],
                  meseInizio: fields["meseInizio"],
                  meseFine: fields["meseFine"],
                  durata: fields["durata"],
                }
              : {
                  numero: "",
                  descrizione: "",
                  tipoAttivitaId: "",
                  progettoOrId: props.progettoOr.progettoOrId,
                  meseInizio: "",
                  meseFine: "",
                  durata: "",
                }
          }
          validationSchema={Yup.object({
            numero: Yup.number().required("Numero attivita Obbligatorio"),
            meseInizio: Yup.number().required(
              "Mese Inizio attivita Obbligatorio"
            ),
            meseFine: Yup.number().required("Mese Fine attivita Obbligatorio"),
            descrizione: Yup.string().required(
              "Descrizione attivita Obbligatorio"
            ),
          })}
          // validateOnChange={true}
          onSubmit={(values, { setSubmitting }) => {
            !props.nuovaAttivita
              ? handleEditAttivita(values)
              : handlePostAttivita(values);
          }}
          onReset={() => {
            setReadOnlyAttivita(true);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form class="form">
              <div class="border-0 top_commandi" style={{ textAlign: "right" }}>
                {readOnlyAttivita ? (
                  <MyButtons.MyModifica
                    label="Modifica attivita"
                    handleClick={handleEditClickAttivita}
                  />
                ) : (
                  <div>
                    {/* Se si sta inserendo una nuova scheda il tasto Cancella è invisibile ed il tasto
								annulla riporta all'elenco. Se invece siamo in modifica il tasto cancella è visibile
								ed il tasto annulla disabilita l'editing rimanendo sulla scheda*/}
                    {!props.nuovaAttivita ? (
                      <>
                        <MyButtons.MyAnnulla
                          handleClick={handleEditClickAttivita}
                        />
                        &nbsp;&nbsp;
                        <MyButtons.MyCancella handleClick={openModalConfirm} />
                      </>
                    ) : (
                      <MyButtons.MyAnnulla handleClick={props.onRequestClose} />
                    )}
                    &nbsp;&nbsp;
                    <MyButtons.MySalva />
                  </div>
                )}
              </div>
              <div class="form-group row">
                <div class="col-lg-6">
                  <MyForms.MyTextInput
                    label="Numero"
                    name="numero"
                    class="form-control"
                    disabled={readOnlyAttivita}
                  />
                  <span class="form-text text-muted"></span>
                </div>
                <div class="col-lg-6">
                  <MyForms.MySelect
                    label="Tipo Attivita"
                    name="tipoAttivitaId"
                    class="form-control"
                    list={listTipiAttivita}
                    disabled={readOnlyAttivita}
                    nameLabel="tipoAttivita"
                  />
                  <span class="form-text text-muted"></span>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-lg-12">
                  <MyForms.MyTextarea
                    label="Descrizione"
                    class="form-control"
                    name="descrizione"
                    disabled={readOnlyAttivita}
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-lg-4">
                  <MyForms.MyTextInput
                    label="Mese inizio progetto"
                    name="meseInizio"
                    class="form-control"
                    disabled={readOnlyAttivita}
                    // onChange={handleCalc}
                  ></MyForms.MyTextInput>
                  <span class="form-text text-muted"></span>
                </div>
                <div class="col-lg-4">
                  <MyForms.MyTextInput
                    label="Mese fine progetto"
                    name="meseFine"
                    class="form-control"
                    disabled={readOnlyAttivita}
                  ></MyForms.MyTextInput>
                  <span class="form-text text-muted"></span>
                </div>
                <div class="col-lg-4">
                  <MyForms.MyTextInput
                    label="Durata Attivita"
                    name="durata"
                    class="form-control"
                    disabled="true"
                    value={values.meseFine - values.meseInizio + 1}
                  />
                  <span class="form-text text-muted"></span>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {!props.nuovaAttivita && (
        <div class="tab-pane fade" id="tab_att_pane_2" role="tabpanel">
          <div class="border-0 top_commandi" style={{ textAlign: "right" }}>
            {readOnlyPartnerAttivita ? (
              <MyButtons.MyModifica
                handleClick={handleEditClickPartnerAttivita}
              />
            ) : (
              <MyButtons.MyAnnulla
                handleClick={handleEditClickPartnerAttivita}
              />
            )}
          </div>
          {!readOnlyPartnerAttivita && (
            <div class="form-group row">
                <div class="col-lg-6">
                  <select name="partnerId" class="form-control" onChange={handleChangePartner} >
                    <option value="">Seleziona un partner</option>
                    {listProgettoPartners.map((obj) => (
                      <option value={obj["partnerId"]}>{obj["nome"]}</option>
                    ))}
                  </select>
                </div>
                { selectedPartner && (
                  <div class="col-lg-4">
                    <MyButtons.MyAggiungi handleClick={handlePostPartnerAttivita} label=" Aggiungi il partner al progetto" />
                  </div>
                )}
            </div>
          )}
          <div>
            <ContentTable
              key={state.key}
              columns={columns}
              easy="1"
              fetchUrl={api_path_partnerattivita}
              defaultOrderBy={"cliente.acronimo"}
              readOnly={readOnlyPartnerAttivita}
              exec={openModalPartnerConfirm}
            />
          </div>
        </div>
      )}
      <ModalConfirm
        isModalConfirmOpen={isModalConfirmOpen}
        message="L'attivita' sta per essere rimossa da questo progetto. Tutte le relative pianificazioni verranno cancellate"
        closeModalConfirm={closeModalConfirm}
        handleConfirm={handleDeleteAttivita}
      />
      <ModalConfirm
        isModalConfirmOpen={isModalConfirmPartnerOpen}
        message="Il partner sta per essere rimosso da questa attivita. Tutte le relative pianificazioni verranno cancellate"
        closeModalConfirm={closeModalPartnerConfirm}
        handleConfirm={handleDeletePartnerAttivita}
      />
    </>
  );
}
