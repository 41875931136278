/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useState, useEffect } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import ModalChangePassword from "./ModalChangePassword";

function Header(props) {
  let history = useHistory();

  const [state, setState] = useState();
  const [isModalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };
  const forceUpdate = React.useCallback(() => {
    console.log("update");
    setState({});
  }, []);

  return (
    <>
      <div id="kt_header" className="header  header-fixed ">
        <div className=" container  d-flex align-items-stretch justify-content-between">
          <div className="d-flex align-items-stretch mr-3">
            <div className="header-logo">
              <Link to="/Dashboard">
                <img alt="Logo" src="/assets/images/logo_riformed_bn.png" className="logo-default max-h-40px" style={{ width: "auto" }} />
                <img alt="Logo" src="/assets/images/logo_riformed.png" className="logo-sticky max-h-40px" style={{ width: "auto" }} />
              </Link>
            </div>
            <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper" >
              <div id="kt_header_menu" className="header-menu header-menu-left header-menu-mobile  header-menu-layout-default " >
                <ul className="menu-nav ">
                  <li className="menu-item  menu-item-open menu-item-here menu-item-submenu menu-item-rel" data-menu-toggle="click" aria-haspopup="true" >
                    <Link to="/Dashboard" className="menu-link ">
                      <span className="menu-text"> Dashboard </span>
                    </Link>
                  </li>
                  <li className="menu-item menu-item-open menu-item-here menu-item-submenu menu-item-rel" data-menu-toggle="click" aria-haspopup="true" >
                    <div className="menu-link menu-toggle ">
                      <span className="menu-text"> Anagrafiche </span>
                    </div>
                    <div className="menu-submenu menu-submenu-classic menu-submenu-left">
                      <ul className="menu-subnav">
                        <li className="menu-item" aria-haspopup="true">
                          <Link to="/Banche" className="menu-link ">
                            <span className="menu-text"> Banche </span>
                          </Link>
                        </li>
                        <li className="menu-item" aria-haspopup="true">
                          <Link to="/Bandi" className="menu-link ">
                            <span className="menu-text"> Bandi </span>
                          </Link>
                        </li>
                        <li className="menu-item" aria-haspopup="true">
                          <Link to="/Enti Erogatori" className="menu-link ">
                            <span className="menu-text"> Enti Erogatori </span>
                          </Link>
                        </li>
                        <li className="menu-item" aria-haspopup="true">
                          <Link to="/ETS" className="menu-link ">
                            <span className="menu-text"> ETS </span>
                          </Link>
                        </li>
                        <li className="menu-item" aria-haspopup="true">
                          <Link to="/Clienti" className="menu-link ">
                            <span className="menu-text"> Clienti/Partner </span>
                          </Link>
                        </li>
                        <li className="menu-item" aria-haspopup="true">
                          <Link to="/Responsabili commessa" className="menu-link ">
                            <span className="menu-text"> {" "} Responsabili Commessa / Principal Investigator{" "} </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="menu-item  menu-item-open menu-item-here menu-item-submenu menu-item-rel menu-item-open menu-item-here" data-menu-toggle="click" aria-haspopup="true" >
                    <Link to="/Progetti" className="menu-link ">
                      <span className="menu-text"> Progetti </span>
                    </Link>
                  </li>
                  {1==0 && (
                  <li className="menu-item  menu-item-open menu-item-here menu-item-submenu menu-item-rel menu-item-open menu-item-here" data-menu-toggle="click" aria-haspopup="true" >
                    <Link to="/Utenti" className="menu-link ">
                      <span className="menu-text"> Utenti </span>
                    </Link>
                  </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="topbar">
            <div className="topbar-item">
              <div className="btn btn-icon btn-hover-transparent-white btn-lg mr-1" id="kt_quick_panel_toggle" >
                <span className="svg-icon svg-icon-xl">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1" >
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" >
                      <rect x="0" y="0" width="24" height="24" />
                      <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5" />
                      <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z" fill="#000000" opacity="0.3" />
                    </g>
                  </svg>
                </span>
              </div>
            </div>

            <div className="dropdown">
              <div className="topbar-item" data-toggle="dropdown" data-offset="0px,0px" >
                <div className="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto">
                  <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-0">
                    {props.user}
                  </span>
                </div>
              </div>
              <div className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
                <div className="d-flex align-items-center p-8 rounded-top">
                  <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                  </div>
                  <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                    {props.user}
                    <br/>
                    <span onClick={() => { setModalOpen(true); }} className="small pointer">[cambia password]</span>
                  </div>
                  <div onClick={() => { props.logout(); history.push("/Login"); }} target="_blank" className="btn btn-light-primary font-weight-bold" >
                    Sign Out
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalChangePassword isModalOpen={isModalOpen} closeModal={closeModal} passwordChanged={false}/>
    </>
  );
}

export default withRouter(Header);
