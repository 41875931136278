import React, { useState, useEffect, useCallback } from "react";
import * as Constants from "../../constants";
import {
  useHistory,
  useLocation,
  withRouter,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import ContentTable from "./ContentTable";
import SchedaCliente from "../Forms/SchedaCliente";
import { PrivateRoute } from "../../../App";

function Clienti(props) {
  const { url, path } = useRouteMatch();
  const history = useHistory();
  // const [fields, setFields] = useState({});

  const changeRoute = useCallback(
    (row) => {
      console.log("CHANGE ROUTE:", row);
      history.push({ pathname: url + "/" + row.nome, state: row });
      // setFields(row);
      console.log("HISTORY ", history);
    },
    [url, history]
  );

  const changeState = useCallback(
    (row) => {
      history.push({ pathname: "/" });
      history.replace({ pathname: url + "/" + row.nome, state: row });
    },
    [url, history]
  );

  const backFromScheda = useCallback(() => {
    history.push({ pathname: "/Clienti" });
  }, [history]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Acronimo",
        accessor: "acronimo", // accessor è la chiave in data
      },
      {
        Header: "Nome",
        accessor: "nome",
      },
      {
        Header: "Tipologia",
        accessor: "tipoCliente.tipoCliente",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Pec",
        accessor: "pec",
      },
    ],
    []
  );

  return (
    <Switch>
      <PrivateRoute exact path="/Clienti">
        <ContentTable
          columns={columns}
          title="Clienti"
          fetchUrl={
            Constants.GETCLIENTIURL +
            "?filter=" +
            JSON.stringify({ include: ["sedi", "tipoCliente"] })
          }
          changeRoute={changeRoute}
          defaultOrderBy={"acronimo"}
        />
      </PrivateRoute>
      <PrivateRoute exact path={"/Clienti/Nuovo Cliente"}>
        <SchedaCliente
          backFromScheda={backFromScheda}
          nuovaScheda={true}
          changeState={changeState}
        />
      </PrivateRoute>

      <PrivateRoute exact path={"/Clienti/:nome"}>
        <SchedaCliente
          backFromScheda={backFromScheda}
          postReferentiUrl={Constants.POSTREFERENTIURL}
          putPersoneUrl={Constants.PUTPERSONEURL}
          changeRoute={changeRoute}
          // fields={fields}
          nuovaScheda={false}
        />
      </PrivateRoute>
    </Switch>
  );
}

export default withRouter(Clienti);
