import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as MyForms from "./MyForms";
import axios from "axios";
import Modal from "react-modal";
import RefClienteTab from "./RefClienteTab";
import * as Constants from "../../constants";

export default function RefClientiStack(props) {
  const [referenti, setReferenti] = useState([]);
  const [tipiContratti, setTipiContratti] = useState([]);
  const [newTab, setNewTab] = useState(false);
  const [idObject, setIdObject] = useState(null);
  const [state, setState] = useState();

  const forceUpdate = React.useCallback(() => {
    console.log("update");
    setState({});
  }, []);

  useEffect(() => {
    const fetchReferenti = async () => {
      const url =
        Constants.GETREFERENTICLIENTIURL +
        JSON.stringify({
          include: ["persona"],
          where: { clienteId: props.clienteId },
        });
      const response_data = await Constants.getData(url);
      setReferenti(response_data);
    };

    fetchReferenti();
    setIdObject(props.clienteId);
  }, [props.clienteId, state]);

  const handleDelete = async (idPersona, idRefCliente) => {
    const deletePersonaUrl =
      Constants.PUTPERSONEURL + idPersona;
    const personaDeleteResponse = await Constants.deleteData(deletePersonaUrl);

    const deleteRefClienteUrl =
      Constants.PUTREFERENTECLIENTEURL +
      idRefCliente;
    const refClienteDeleteResponse = await Constants.deleteData(
      deleteRefClienteUrl
    );
    forceUpdate();
  };

  const handleEdit = async (idPersona, idDipendente, dataToSend) => {
    const dataToPersone = {
      personaId: idPersona,
      nome: dataToSend.nome,
      cognome: dataToSend.cognome,
      telefono: dataToSend.telefono,
      telefono2: dataToSend.telefono2,
      email: dataToSend.email,
      email2: dataToSend.email2
    };

    const editPersoneUrl =
      Constants.PUTPERSONEURL + idPersona;
      await Constants.putData(
        editPersoneUrl,
        JSON.stringify(dataToPersone, null, 2)
    );

    forceUpdate();
  };

  const handlePostDipendente = async (dataToSend) => {
    const dataToPersone = {
      nome: dataToSend.nome,
      cognome: dataToSend.cognome,
      telefono: dataToSend.telefono,
      telefono2: dataToSend.telefono2,
      email: dataToSend.email,
      email2: dataToSend.email2,
    };

    const postPersoneUrl = Constants.POSTPERSONEURL;
    const response_data1 = await Constants.postData(
      postPersoneUrl,
      JSON.stringify(dataToPersone, null, 2)
    );

    const dataToRefClienti = {
      clienteId: props.clienteId,
      personaId: response_data1.personaId,
    };

    const postRefClientiUrl = Constants.POSTREFERENTICLIENTIURL;
    const response_data2 = await Constants.postData(
      postRefClientiUrl,
      JSON.stringify(dataToRefClienti, null, 2)
    );

    forceUpdate();
    setNewTab(false);
  };

  const handleNewClick = () => {
    setNewTab(true);
  };

  return (
    <>
      <div class="border-0 top_commandi" style={{ textAlign: "right" }}>
        <div class="btn btn-outline-primary font-size-sm mb-5" onClick={handleNewClick} >
          <i class="icon fa fa-plus"></i> Nuovo
        </div>
      </div>
      <div class="accordion accordion-solid accordion-toggle-plus" id="accordionRefClienti" >
        {referenti.map((referente, i) => (
          <RefClienteTab
            setNewTab={setNewTab}
            handleEdit={handleEdit}
            handlePost={handlePostDipendente}
            handleDelete={handleDelete}
            referente={referente}
            nuovaTab={false}
            dataId={i}
            readOnly={true}
          />
        ))}
        {newTab && (
          <RefClienteTab
            setNewTab={setNewTab}
            handleEdit={handleEdit}
            handlePost={handlePostDipendente}
            handleDelete={handleDelete}
            nuovaTab={true}
            dataId={referenti.length + 1}
            readOnly={false}
          />
        )}
      </div>
    </>
  );
}
