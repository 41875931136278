import React, { useState, useEffect, useCallback } from "react";
import * as Constants from "../../constants";
import {
  useHistory,
  withRouter,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import ContentTable from "./ContentTable";
import SchedaUtente from "../Forms/SchedaUtente";
import { PrivateRoute } from "../../../App";

function Utenti(props) {
  const { url, path } = useRouteMatch();
	const [listUtenti, setListUtenti] = useState([]);

  const history = useHistory();

  const changeRoute = useCallback(
    (row) => {
      history.push({ pathname: url + "/" + row.nome + " - " + row.cognome , state: row });
    },
    [url, history]
  );

  const changeState = useCallback(
    (row) => {
      history.push({ pathname: "/" });
      history.replace({ pathname: url + "/" + row.cognome, state: row });
    },
    [url, history]
  );

  const backFromScheda = useCallback(() => {
    history.push({ pathname: "/Utenti" });
  }, [history]);

	useEffect(() => {
    fetchUtenti();
	}, []);

	const fetchUtenti = async () => {
		var api_path = Constants.Compose([Constants.PATH_UTENTI, 'findByRole']);
		api_path += "?role=responsabileCommessa";
		const utenti = await Constants.getData(api_path);
    let list = [];
    utenti.forEach(utente => {
      let persona = {};
      if(utente.persona) persona = utente.persona;
      list.push({
        nome: persona.nome,
        cognome: persona.cognome,
      })
    })
    console.log(utenti)
		setListUtenti(list)
	};
  const columns = React.useMemo(
    () => [
      {
        Header: "Cognome",
        accessor: "cognome",
      },
      {
        Header: "Nome",
        accessor: "nome",
      },
    ],
    []
  );
  return (
    <Switch>
      <PrivateRoute exact path="/Utenti">
        <ContentTable
          columns={columns}
          title="Utenti"
          dataToDisplay={listUtenti}
          changeRoute={changeRoute}
          defaultOrderBy={"nome"}
        />
      </PrivateRoute>
      <PrivateRoute exact path={"/Utenti/Nuovo Utente"}>
        <SchedaUtente
          backFromScheda={backFromScheda}
          nuovaScheda={true}
          changeState={changeState}
        />
      </PrivateRoute>
      <PrivateRoute exact path={"/Utenti/:nome"}>
        <SchedaUtente
          backFromScheda={backFromScheda}
          nuovaScheda={false}
        />
      </PrivateRoute>
    </Switch>
  );
}

export default withRouter(Utenti);
