import React, { useState, useEffect } from "react";
import FatturaTab from "./FatturaTab";
import * as Constants from "../../constants";

export default function FatturaStack(props) {
	const [listSal, setlistSal] = useState([]);
	const [state, setState] = useState();
	const [tipiStatoFattura, setListTipoStatoFattura] = useState([]);
	const [listPartners, setListPartners] = useState([]);
	const [listSals, setListSals] = useState([]);

	const acconto = {salId: 'acconto', numero: 'Acconto', acconto: 1}
	const anticipo = {salId: 'anticipo', numero: 'Anticipo', anticipo: 1}
	const forceUpdate = React.useCallback(() => {
		setState({});
	}, []);

	useEffect(() => {
		if(props.displayTab == 10) {
			fetchSal();
		}
	}, [props.displayTab, state]);

	const fetchSal = async () => {
		var api_path = Constants.Compose([Constants.PATH_PROGETTI, props.progettoId, Constants.PATH_SAL]);
		api_path += "?filter=" + JSON.stringify({order:['numero']});
		const response_data = await Constants.getData(api_path);

		setlistSal(response_data);

		// List partners
		var api_path = Constants.Compose([Constants.PATH_PROGETTI, props.progettoId, Constants.PATH_PARTNERS_INREL])
		api_path += "?filter=" + JSON.stringify({include: [{'relation': 'cliente', order: ['acronimo']}]});
		const response_dataP = await Constants.getData(api_path);
		var list = [];
		for(var i in response_dataP) {
			let ele = response_dataP[i]
			list.push({partnerId: ele.partnerId, partner: ele.cliente.acronimo})
		}
		setListPartners(list);

		// List sals
		var api_path = Constants.Compose([Constants.PATH_PROGETTI, props.progettoId, Constants.PATH_SAL_INREL])
		const response_dataS = await Constants.getData(api_path);
		var list = [];
		list.push({salId: 'anticipo', sal: 'Anticipo'})
		list.push({salId: 'acconto', sal: 'Acconto'})
		for(var i in response_dataS) {
			let ele = response_dataS[i]
			list.push({salId: ele.salId, sal: "Sal nr." + ele.numero})
		}
		setListSals(list);

		var api_path = Constants.Compose([Constants.PATH_TIPOSTATOFATTURA]);
		const response_dataF = await Constants.getData(api_path);
		setListTipoStatoFattura(response_dataF);
	};

	return (
		<div>
			<div class="accordion accordion-solid accordion-toggle-plus" id="accordionFatturazione" >
				<FatturaTab
					key={'a_0'}
					sal={acconto}
					progettoId={props.progettoId}
					tipiStatoFattura={tipiStatoFattura}
					listPartners={listPartners}
					listSals={listSals}
					dataId={'a_0'}
					forceUpdate={forceUpdate}
				/>
				<FatturaTab
					key={'a_1'}
					sal={anticipo}
					progettoId={props.progettoId}
					tipiStatoFattura={tipiStatoFattura}
					listPartners={listPartners}
					listSals={listSals}
					dataId={'a_1'}
					forceUpdate={forceUpdate}
				/>
				{listSal.length > 0 && (
					listSal.map((mySal, i) => (
						<FatturaTab
							key={i}
							sal={mySal}
							progettoId={props.progettoId}
							tipiStatoFattura={tipiStatoFattura}
							listPartners={listPartners}
							listSals={listSals}
							dataId={i}
							forceUpdate={forceUpdate}
						/>
					))
				)}
			</div>
		</div>
	);
}
