//FILE CHE CONTIENTE LE URL DELLE API E LE FUNZIONI DI FETCH
import axios from "axios";
import { useField } from "formik";
import {getToken} from "../../App";

//API URL
const domain = process.env.REACT_APP_API_PATH;

// PATH 
export const PATH_ALLEGATI = 'allegati';
export const PATH_ATTIVITA = 'Attivita';
export const PATH_ATTIVITA_INREL = 'attivita';
export const PATH_BANDI = 'Bandi';
export const PATH_BUDGET_INREL = 'partnerBudgets';
export const PATH_CLIENTI = 'Clienti';
export const PATH_CONTRATTI = 'Contratti';
export const PATH_COSTILIVELLO = 'CostiLivello';
export const PATH_COSTIBANDO_INREL = 'costiBando';
export const PATH_DASHBOARD = 'Dashboards/dashboard';
export const PATH_DIPENDENTI = 'partnerDipendenti';
export const PATH_DIPENDENTI_INREL = 'dipendenti';
export const PATH_FATTURA = 'Fatture';
export const PATH_LIVELLI = 'TipoLivelli';
export const PATH_PARTNERS = 'Partners';
export const PATH_PARTNERBUDGET = 'PartnerBudgets';
export const PATH_PARTNERS_INREL = 'partners';
export const PATH_PARTNERSATTIVITA = 'PartnerAttivita';
export const PATH_PMESE = 'PianificazioniMese/pianificazione';
export const PATH_PMESE_POST = 'PianificazioniMese';
export const PATH_PROGETTI = 'Progetti';
export const PATH_PROGETTIALLEGATI = 'ProgettoAllegati';
export const PATH_PROGETTOOR = 'ProgettiOr';
export const PATH_RENDICONTAZIONE = 'Rendicontazioni';
export const PATH_RENDICONTAZIONE_INREL = 'rendicontazioni';
export const PATH_SAL = 'Sals';
export const PATH_SAL_INREL = 'sals';
export const PATH_TIPOALLEGATO = 'TipoAllegati';
export const PATH_TIPOATTIVITA = 'TipoAttivita';
export const PATH_TIPOBUDGET = 'TipoBudgets';
export const PATH_TIPOISTRUTTORIA = 'TipoStatoIstruttorie';
export const PATH_TIPOSTATOFATTURA = 'TipoStatoFatture';
export const PATH_UPLOADALLEGATO = 'Allegati/upload';
export const PATH_UTENTI = 'Utenti';
///////

// COMPONI URL API 
export const Compose = function(list) {
	return domain + list.join('/');
}
//////

//GET
export const GETETSURL = domain + "Persone?filter=" + JSON.stringify({ where: { tipoPersonaId: "1" } });
export const GETRESPCOMMURL = domain + "Persone?filter=" + JSON.stringify({ where: { tipoPersonaId: "2" } });

export const GETCLIENTIURL = domain + "Clienti";
export const GETBANDIURL = domain + "Bandi";
export const GETBANCHEURL = domain + 'Banche';
export const GETCONTRATTIURL = domain + "Contratti?filter=";
export const GETDIPENDENTIURL = domain + "Dipendenti?filter=";
export const GETSEDICLIENTIURL = domain + "Sedi?filter=";
export const GETTIPOBANCHEURL = domain + "TipoBanche";
export const GETTIPOENTEURL = domain + "TipoEnti";
export const GETVOCICOSTOURL = domain + "VoceCosti";
export const GETENTIURL = domain + "Enti";
export const GETREFERENTIURL = domain + "Banche/";
export const GETCOSTIBANDOURL = domain + "Bandi/";
export const GETREFERENTIENTIURL = domain + "Enti/";
export const GETREFERENTICLIENTIURL = domain + "RefClienti?filter=";
export const GETTIPOCLIENTEURL = domain + "TipoClienti/";
export const GETTIPICONTRATTI = domain + "TipoContratti/";
export const GETALLEGATOURL = domain + "Allegati/";
export const GETPROGETTIURL = domain + "Progetti/";
export const GETPROGETTIORURL = domain + "ProgettiOr";

//POST
export const LOGINURL = domain + "Utenti/login";
export const POSTBANCHEURL = domain + "Banche/";
export const POSTPERSONEURL = domain + "Persone/";
export const POSTREFERENTIURL = domain + "Banche/";
export const POSTCOSTOBANDOURL = domain + "Bandi/";
export const POSTBANDIURL = domain + "Bandi/";
export const POSTREFERENTIENTIURL = domain + "Enti/";
export const POSTREFERENTICLIENTIURL = domain + "RefClienti/";
export const POSTENTIURL = domain + "Enti/";
export const POSTCLIENTIURL = domain + "Clienti/?";
export const POSTSEDIURL = domain + "Sedi/";
export const POSTDIPENDENTIURL = domain + "Dipendenti/";
export const POSTCONTRATTIURL = domain + "Contratti/";
// export const POSTALLEGATOURL = domain + "Allegati/upload";
export const POSTPROGETTIURL = domain + "Progetti";
export const POSTPROGETTIORURL = domain + "ProgettoOr";

//PUT
export const PUTBANCHEURL = domain + "Banche/";
export const PUTPERSONEURL = domain + "Persone/";
export const PUTBANDIURL = domain + "Bandi/";
export const PUTENTIURL = domain + "Enti/";
export const PUTCLIENTIURL = domain + "Clienti/";
export const PUTREFERENTECLIENTEURL = domain + "RefClienti/";
export const PUTSEDIURL = domain + "Sedi/";
export const PUTDIPENDENTIURL = domain + "Dipendenti/";
export const PUTCONTRATTIURL = domain + "Contratti/";
export const PUTPROGETTIURL = domain + "Progetti/";

//GET REQUEST
export const getData = async (fetchUrl, config) => {
	if(!fetchUrl) return [];
	fetchUrl += fetchUrl.match(/\?/) ? '' : '?';
	fetchUrl += "&access_token=" + getToken();
	let data;
	await axios
		.get(fetchUrl, config)
		.then(function (response) {
			// handle success
			if (response.status === 200) {
				data = response.data;
			}
		})
		.catch(function (error, tmp) {
			alert(error.response.data.error.message);
			if (error.response.status === 401) window.location = "/login";
		})
		.then(function () {
			// always executed
		});
	return data;
};

//PUT REQUEST
export const putData = async (fetchUrl, dataToSend) => {
	fetchUrl += fetchUrl.match(/\?/) ? '' : '?';
	fetchUrl += "&access_token=" + getToken();
	// console.log("CONSTANT PUT: " + fetchUrl, dataToSend);
	let data;
	await axios
		.put(fetchUrl, dataToSend, {
			headers: { "Content-Type": "application/json" },
		})
		.then(function (response) {
			if (response.status === 200) {
				data = response.data;
			}
		})
		.catch(function (error) {
			console.log(error.response.data.error.message);
			alert(error.response.data.error.message);
			if (error.response.status === 401) window.location = "/login";
		});
	return data;
};

//POST REQUEST
export const postData = async (fetchUrl, dataToSend) => {
	fetchUrl += fetchUrl.match(/\?/) ? '' : '?';
	fetchUrl += "&access_token=" + getToken();
	// console.log("CONSTANT POST: " + fetchUrl);
	let data;
	await axios
		.post(fetchUrl, dataToSend, {
			headers: { "Content-Type": "application/json" },
		})
		.then(function (response) {
			// handle success
			// console.log(response.data);
			if (response.status === 200) {
				data = response.data;
			}
		})
		.catch(function (error) {
			console.log(error.response.data.error.message);
			alert(error.response.data.error.message);
			data = {error: error.response.data.error.message}
			if (error.response.status === 401) window.location = "/login";
		});
	return data;
};

//GET DOWNLOAD
export const getDownload = async (fetchUrl, config) => {
	if(!fetchUrl) return [];
	fetchUrl += fetchUrl.match(/\?/) ? '' : '?';
	fetchUrl += "&access_token=" + getToken();
	let data;
	await axios
		.get(fetchUrl, {responseType: 'blob'})
		.then(function (response) {
			// handle success
			if (response.status === 200) {
				data = response.data;
			}
		})
		.catch(function (error, tmp) {
			alert(error.response.data.error.message);
			if (error.response.status === 401) window.location = "/login";
		})
		.then(function () {
			// always executed
		});
	return data;
};

//POST DOWNLOAD
export const postDownload = async (fetchUrl, dataToSend) => {
	fetchUrl += fetchUrl.match(/\?/) ? '' : '?';
	fetchUrl += "&access_token=" + getToken();
	let data;
	await axios
		.post(fetchUrl, dataToSend, {
			headers: { "Content-Type": "application/json" },
			responseType: 'blob'
		})
		.then(function (response) {
			if (response.status === 200) {
				data = response.data;
			}
		})
		.catch(function (error) {
			console.log("POST DOWNLOAD:", error);
			// alert(error.response.data.error.message);
			if (error.response.status === 401) window.location = "/login";
		});
	return data;
};

//DELETE REQUEST
export const deleteData = async (fetchUrl) => {
	fetchUrl += fetchUrl.match(/\?/) ? '' : '?';
	fetchUrl += "&access_token=" + getToken();
	// console.log("CONSTANT DELETE: " + fetchUrl);
	await axios
		.delete(fetchUrl, {
			headers: { "Content-Type": "application/json" },
		})
		.then(function (response) {
			// handle success
			// console.log(response.data);
		})
		.catch(function (error) {
			console.log(error.response.data.error.message);
			alert(error.response.data.error.message);
			if (error.response.status === 401) window.location = "/login";
		});
};
