import React, { useState, useEffect } from "react";

import FormProgetto from "./FormProgetto";
import * as Constants from "../../constants";
import SalStack from "./SalStack.js.SAVE";
import OrAttivitaStack from "./OrAttivitaStack";
import PartnerStack from "./PartnerStack";
import PianificazioneStack from "./PianificazioneStack";
import RendicontazioneStack from "./RendicontazioneStack";
import Allegati from "./Allegati";

import { useLocation, withRouter } from "react-router-dom";
import ContrattiStack from "./ContrattiStack";
import Monitoraggio from "./Monitoraggio";
import FatturaStack from "./FatturaStack";

function SchedaProgetto(props) {
	const [fields, updateFields] = useState(useLocation().state); //I campi vengono inizializzati con l'oggetto state passato fra le props del router
	const [state, setState] = useState({});

	const updateProjectData = (data) => {
		updateFields(data);
	}

	const displayTab  = (tab) => {
		setState({ displayTab: tab });
	};
	return (
		<div class="card-body">
			<ul class="nav nav-tabs nav-tabs-line mb-5">
				{!props.nuovaScheda && (
					<li class="nav-item" onClick={() => displayTab(2)}>
						<a class="nav-link" data-toggle="tab" href="#tab_pane_2">
							<span class="nav-text">Monitoraggio avanzamento costi</span>
						</a>
					</li>
				)}
				<li class="nav-item">
					<a class="nav-link active" data-toggle="tab" href="#tab_pane_1">
						<span class="nav-text">Dettagli Progetto</span>
					</a>
				</li>
				{!props.nuovaScheda && (
					<>
						<li class="nav-item" onClick={() => displayTab(7)}>
							<a class="nav-link" data-toggle="tab" href="#tab_pane_7">
								<span class="nav-text">Clienti/Partners</span>
							</a>
						</li>
						<li class="nav-item" onClick={() => displayTab(3)}>
							<a class="nav-link" data-toggle="tab" href="#tab_pane_3">
								<span class="nav-text">OR/Attività</span>
							</a>
						</li>
						{ 0==1 && (
							<li class="nav-item">
								<a class="nav-link" data-toggle="tab" href="#tab_pane_4">
									<span class="nav-text">SAL</span>
								</a>
							</li> 
						)}
						<li class="nav-item" onClick={() => displayTab(9)}>
							<a class="nav-link" data-toggle="tab" href="#tab_pane_9">
								<span class="nav-text">SAL/Rendicontazione</span>
							</a>
						</li>
						<li class="nav-item" onClick={() => displayTab(8)}>
							<a class="nav-link" data-toggle="tab" href="#tab_pane_8">
								<span class="nav-text">Pianificazione</span>
							</a>
						</li>
						{process.env.REACT_APP_TYPE == 'advisor' && (
							<li class="nav-item" onClick={() => displayTab(5)}>
								<a class="nav-link" data-toggle="tab" href="#tab_pane_5">
									<span class="nav-text">Contratti</span>
								</a>
							</li>
						)}
						<li class="nav-item" onClick={() => displayTab(6)}>
							<a class="nav-link" data-toggle="tab" href="#tab_pane_6">
								<span class="nav-text">Documenti</span>
							</a>
						</li>
						{process.env.REACT_APP_TYPE == 'advisor' && (
							<li class="nav-item" onClick={() => displayTab(10)}>
								<a class="nav-link" data-toggle="tab" href="#tab_pane_10">
									<span class="nav-text">Fatturazione</span>
								</a>
							</li>
						)}
					</>
				)}
			</ul>
			<div class="tab-content" id="TabContent">
				<div id="tab_pane_1" class="tab-pane fade show active">
					<FormProgetto
						backFromScheda={props.backFromScheda}
						// getReferentiUrl={props.getReferentiUrl}
						// postReferentiUrl={props.postReferentiUrl}
						// putPersoneUrl={props.putPersoneUrl}
						nuovaScheda={props.nuovaScheda}
						changeState={props.changeState}
						updateProjectData={updateProjectData}
					/>
				</div>
				{!props.nuovaScheda && (
					<>
					<div id="tab_pane_3" class="tab-pane fade">
						<OrAttivitaStack displayTab={state.displayTab} progettoId={fields['progettoId']} />
					</div>
					{ 0==1 && (
						<div id="tab_pane_4" class="tab-pane fade">
							<SalStack progettoId={fields['progettoId']} />
						</div>
					)} 
					<div id="tab_pane_5" class="tab-pane fade">
						<ContrattiStack displayTab={state.displayTab} progettoId={fields['progettoId']} />
					</div>
					<div id="tab_pane_6" class="tab-pane fade">
						<Allegati displayTab={state.displayTab} progettoId={fields['progettoId']} />
					</div>
					<div id="tab_pane_7" class="tab-pane fade">
						<PartnerStack displayTab={state.displayTab} progettoId={fields['progettoId']} progetto={fields} bandoId={fields['bandoId']} />
					</div>
					<div id="tab_pane_9" class="tab-pane fade">
						<RendicontazioneStack displayTab={state.displayTab} progettoId={fields['progettoId']} bandoId={fields['bandoId']}/>
					</div>
					<div id="tab_pane_8" class="tab-pane fade">
						<PianificazioneStack displayTab={state.displayTab} progetto={fields} progettoId={fields['progettoId']} bandoId={fields['bandoId']}/>
					</div>
					<div id="tab_pane_10" class="tab-pane fade">
						<FatturaStack displayTab={state.displayTab} progettoId={fields['progettoId']}/>
					</div>
					<div id="tab_pane_2" class="tab-pane fade">
						<Monitoraggio displayTab={state.displayTab} progettoId={fields['progettoId']}/>
					</div>
					</>
				)}
			</div>
		</div>
	);
}

export default withRouter(SchedaProgetto);
