import React, { useState, useEffect } from "react";
import * as Constants from "../../constants";
import * as MyButtons from "./MyButtons";

export default function AttachedFile(props) {
	const [selectedFile, setSelectedFile] = useState(null);
	const [doUpload, setDoUpload] = useState(false);

	useEffect(() => {
		setDoUpload(props.doUpload)
	}, [props.doUpload]);

	useEffect(() => {
		if(doUpload) Upload();
	}, [doUpload]);

	const onChangeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
	};


	const onClickHandler = () => {
		setDoUpload(true);
	};

	const Upload = async () => {
		const data = new FormData();
		data.append("allegato", selectedFile);
		// data.append(props.dettagliAllegato);
		var api_path = Constants.Compose([Constants.PATH_UPLOADALLEGATO]);
		const response_data = await Constants.postData(api_path, data);

		props.setAllegato(response_data.fileName);

		if(props.api_path_toupdate) {
			const allegatoId = { allegatoId: response_data.allegatoId };
			var tmp = await Constants.putData(props.api_path_toupdate, JSON.stringify(allegatoId, null, 2));
		}
	}

	return (
		<>
			<div class="form-group files">
				<label><strong>Carica nuovo allegato</strong></label>
				<div class="row">
					<div class="col-lg-8">
						<form method="post" action="#" id="#">
							<input type="file" class="form-control" multiple="" onChange={onChangeHandler} />
						</form>
					</div>
					<div class="col-lg-4">
						<MyButtons.MyUpload handleClick={onClickHandler} />
					</div>
				</div>
			</div>
		</>
	);
}
