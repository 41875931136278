import React from "react";
import Moment from "react-moment";
import { useField, Field, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import CurrencyFormat from 'react-currency-format';
import * as Utils from './Utils';

export const MyCurrencyInput = ({ label, ...props }) => {
	const [field, meta] = useField(props);

	return (
		<CurrencyFormat 
			class="text-input"
			{...field}
			{...props}
			className={props.class}
			disabled={props.disabled}
			displayType={'input'} 
			thousandSeparator={'.'} 
			fixedDecimalScale={true}
			decimalSeparator={','}
			decimalScale={2}
		/>
	)
}

export const MyTextInput = ({ label, ...props }) => {
	const [field, meta] = useField(props);

	var myValue = field.value;

	if (props.link) {
		if (!myValue.match(/http\:\:/)) myValue = "http://" + myValue;
	}
	var mailto;
	if (props.type == "email") {
		mailto = "mailto:" + myValue;
	}
	if (props.currency) {
		myValue = Utils.Format(myValue);
	}
	if (props.type == "perc") {
		myValue = Utils.FormatPerc(parseFloat(myValue));
		props.type = 'number';
		props.min = 0.00;
		props.max = 100.00;
		if(!props.step) props.step = 0.01
	}
	return (
		<>
			{label ? (
				<label htmlFor={props.id || props.name}>
					<strong>{label}</strong>
				</label>
			) : (
				<></>
			)}
			{props.disabled ? (
				<>
					{props.link ? (
						<div>
							<a href={myValue} target="_blank">
								{field.value}
							</a>
						</div>
					) : (
						<>
							{props.type == "email" ? (
								<div>
									<a href={mailto}>{field.value}</a>
								</div>
							) : (
								<>
									{props.name == "durata" && props.value ? (
										<div>{props.value}</div>
									) : (
										<div>{myValue}</div>
									)}
								</>
							)}
						</>
					)}
				</>
			) : (
				<input
					className="text-input"
					{...field}
					{...props}
					// className={props.class}
					disabled={props.disabled}
					// onChange={props.onChange}
				/>
			)}
			{meta.touched && meta.error ? (
				<div className="error">{meta.error}</div>
			) : null}
		</>
	);
};

//NON UTILIZZARE MYCHECKBOX PER LA CREAZIONE DI CHECKBOX. C'E UN PROBLEMA
//SULLA RILEVAZIONE DELL'EVENTO CHE DOVREBBE CAMBIARE IL CONTENUTO
export const MyCheckbox = ({ children, ...props }) => {
	const [field, meta] = useField({ ...props, type: "checkbox" });
	return (
		<>
			<label className="checkbox">
				<input name="check" type="checkbox" {...field} {...props} />
				{children}
			</label>
			{meta.touched && meta.error ? (
				<div className="error">{meta.error}</div>
			) : null}
		</>
	);
};

//USARE QUESTA --- PRIMA VERSIONE
export function CheckboxOLD(props) {
	return (
		<Field name={props.name}>
			{({ field, form }) => (
				<label className="checkbox">
					<input
						type="checkbox"
						disabled={props.disabled}
						{...props}
						checked={field.value.includes(props.value)}
						onChange={() => {
							if (field.value.includes(props.value)) {
								const nextValue = field.value.filter(
									(value) => value !== props.value
								);
								form.setFieldValue(props.name, nextValue);
							} else {
								const nextValue = field.value.concat(props.value);
								form.setFieldValue(props.name, nextValue);
							}
						}}
					/>
					<span></span>
					{props.label}
				</label>
			)}
		</Field>
	);
}

//USARE QUESTA --- SECONDA VERSIONE
export const Checkbox = ({ label, ...props }) => {
	const [field, meta] = useField(props);
	if (!props.list) return;

	return (
		<div>
			<label htmlFor={props.id || props.name}>
				<strong>{label}</strong>
			</label>
			{props.disabled ? (
				<div _className="checkbox-inline">
					{props.list.map((obj) => (
						<Field name={props.name}>
							{({ field, form }) => (
								<>
									{field.value.includes(obj[props.id]) && (
										<label>
											{obj[props.nameLabel]}
											<span>&nbsp;</span>
										</label>
									)}
								</>
							)}
						</Field>
					))}
				</div>
			) : (
				<div className="checkbox-inline">
					{props.list.map((obj) => (
						<Field name={props.name}>
							{({ field, form }) => (
								<label className="checkbox">
									<input
										type="checkbox"
										disabled={props.disabled}
										{...props}
										checked={field.value.includes(obj[props.id])}
										onChange={() => {
											if (field.value.includes(obj[props.id])) {
												const nextValue = field.value.filter(
													(value) => value !== obj[props.id]
												);
												form.setFieldValue(props.name, nextValue);
											} else {
												const nextValue = field.value.concat(obj[props.id]);
												form.setFieldValue(props.name, nextValue);
											}
										}}
									/>
									<span></span>
									{obj[props.nameLabel]}
								</label>
							)}
						</Field>
					))}
				</div>
			)}
			{meta.touched && meta.error ? (
				<div className="error">{meta.error}</div>
			) : null}
		</div>
	);
};

export const MySelect = ({ label, ...props }) => {
	const [field, meta] = useField(props);

	var nameLabel = "-";
	var name = props.name;
	if (props.list) {
		// console.log("QUESTO VA RISOLTO QUANDO SI AGGIORNA IL CAMPO SI PERDE LA VISUALIZZAZIONE : VALUE:", field.value + " PROPS NAME:", props.name + " PROPS LIST:", props.list)
		var getObj = props.list.filter((obj) => {
			// console.log("TEST " +name + " " + obj[name] + " === " + field.value)
			return obj[name] === field.value;
		});
		// console.log("ENTRO QUI:", getObj)
		if (getObj[0]) nameLabel = getObj[0][props.nameLabel];
	}
	return (
		<div>
			<label htmlFor={props.id || props.name}>
				<strong>{label}</strong>
			</label>
			{props.disabled ? (
				<div>{nameLabel}</div>
			) : (
				<select
					name={props.name}
					value={field.value}
					{...field}
					// onClick={e => props.onClick(e.target.value)} // Perche l'ho dovuto commentare?
					className={props.class}
					disabled={props.disabled}
				>
					<option value="">Seleziona</option>
					{props.list.map((obj) => (
						<option value={obj[props.name]}>{obj[props.nameLabel]}</option>
					))}
				</select>
			)}
			{meta.touched && meta.error ? (
				<div className="error">{meta.error}</div>
			) : null}
		</div>
	);
};

export const MyDatePickerField = ({ label, ...props }) => {
	const { setFieldValue } = useFormikContext();
	const [field, meta] = useField(props);

	/*
	const CustomInput = ({ value, onClick }) => (
		<input
			class={props.class}
			disabled={props.disabled}
			value={value}
			onClick={onClick}
		/>
	);
	*/
	return props.disabled ? (
		<div>
			{field.value ? (
				<Moment format="DD/MM/YYYY">{field.value}</Moment>
			) : (
				<>-</>
			)}
		</div>
	) : (
		<div className="input-group date">
			<label htmlFor={props.id || props.name}>
				<strong>{label}</strong>
			</label>
			<DatePicker
				dateFormat={props.dateFormat || "dd/MM/yyyy"}
				className={props.class}
				// {...field}
				// {...props}
				selected={(field.value && new Date(field.value)) || null}
				disabled={props.disabled}
				onChange={(val) => {
					val.setHours( val.getHours() +12 );
					setFieldValue(field.name, val);
				}}
				// customInput={<CustomInput />}
			/>
			<div className="input-group-append">
				<span className="input-group-text">
					<i className="la la-calendar"></i>
				</span>
			</div>
			{meta.touched && meta.error ? (
				<div className="error">{meta.error}</div>
			) : null}
		</div>
	);
};
export const MyTextarea = ({ label, ...props }) => {
	const [field, meta] = useField(props);
	var myValue = field.value || '-';
	return (
		<>
			{label ? (
				<label htmlFor={props.id || props.name}>
					<strong>{label}</strong>
				</label>
			) : (
				<></>
			)}
			{props.disabled ? (
				<div>{myValue}</div>
			) : (
				<textarea
					{...field}
					{...props}
					className={props.class}
					disabled={props.disabled}
				></textarea>
			)}
			{meta.touched && meta.error ? (
				<div className="error">{meta.error}</div>
			) : null}
		</>
	);
};
