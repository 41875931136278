import React, { useState, useEffect, useCallback } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import * as MyForms from "./MyForms";
import * as MyButtons from "./MyButtons";
import * as Utils from "./Utils";
// import Modal from "react-modal";
import ModalConfirm from "./ModalConfirm";
import ModalGdL from "./GdL";
import ModalReferenti from "./Referenti";
import BudgetStack from './BudgetStack';
import ContentTable from '../Tables/ContentTable';
import * as Constants from "../../constants";

export default function Partner(props) {
	const [fields, updateFields] = useState({});
	const [state, setState] = useState({});

	const [readOnly, setReadOnly] = useState(props.readOnly);
	const [nuovoBudget, setNuovoBudget] = useState(false);
	const [isModalConfirmOpen, setModalConfirm] = useState(false);
	const [isModalGdLOpen, setModalGdl] = useState(false);
	const [isModalReferentiOpen, setModalReferenti] = useState(false);
	// const [isTabBudgetOpen, setTabBudgetOpen] = useState(false);
	const [divStyle, setDivStyle] = useState(
		props.nuovaTab ? "collapse show" : "collapse"
	);

	var tipoLivelloId1;
	var tipoLivelloId2;
	var tipoLivelloId3;
	var tipoLivelloId4;
	var tipoLivelloId5;
	if(props.partner) {
		var livello = props.partner.partnerLivelli.find(liv => {return liv.tipoLivelloId == 1});
		if(livello) tipoLivelloId1 = livello.numero;
		var livello = props.partner.partnerLivelli.find(liv => {return liv.tipoLivelloId == 2});
		if(livello) tipoLivelloId2 = livello.numero;
		var livello = props.partner.partnerLivelli.find(liv => {return liv.tipoLivelloId == 3});
		if(livello) tipoLivelloId3 = livello.numero;
		var livello = props.partner.partnerLivelli.find(liv => {return liv.tipoLivelloId == 4});
		if(livello) tipoLivelloId4 = livello.numero;
		var livello = props.partner.partnerLivelli.find(liv => {return liv.tipoLivelloId == 5});
		if(livello) tipoLivelloId5 = livello.numero;
	}

	const handleDelete = async (partnerId) => {
		var api_path = Constants.Compose([Constants.PATH_PARTNERS, partnerId]);
		const response_data = await Constants.deleteData(api_path);
		props.forceUpdate();
	};

	const handleEdit = async (partnerId, dataToSend) => {
		var api_path = Constants.Compose([Constants.PATH_PARTNERS]);
		const response_data = await Constants.putData(api_path, JSON.stringify(dataToSend));
		props.forceUpdate();
	};

	const handlePost = async (dataToSend) => {
		dataToSend['progettoId'] = props.progettoId;
		var api_path = Constants.Compose([Constants.PATH_PARTNERS]);
		const response_data = await Constants.postData(api_path, dataToSend);

		props.forceUpdate();
		props.setNewTab(false);
	};

	const handleEditClick = () => {
		setReadOnly(!readOnly);
	};

	const openModalConfirm = (e) => {
		setModalConfirm(true);
	};
	const closeModalConfirm = () => {
		setModalConfirm(false);
	};

	const closeNewTab = () => {
		props.setNewTab(false);
	};

	const openTabBudget = () => {
		setNuovoBudget(true);
		// setTabBudgetOpen(true);
	};

	const openModalGdL = () => {
		setModalGdl(true);
	};
	const openModalReferenti = () => {
		setModalReferenti(true);
	};
	const closeModalGdL = () => {
		setModalGdl(false);
		props.forceUpdate();
	};
	const closeModalReferenti = () => {
		setModalReferenti(false);
		props.forceUpdate();
	};

	const handleCloseNuovoBudget = () => {
		setNuovoBudget(false);
		// setTabBudgetOpen(false);
	};

	if(!props.nuovaTab) {
		var api_path_attivita;
		var partnerId = props.partner.partnerId;
		// var api_path_attivita = Constants.Compose([Constants.PATH_PROGETTOOR, progettoOrId, Constants.PATH_ATTIVITA]);
		// api_path_attivita += "?filter=" + JSON.stringify({include:['tipoAttivita', {'partners':'clienti'}]});
	}

	/// Referenti
	const columns = React.useMemo(
    () => [
      {
        Header: "Cognome",
        accessor: "cognome",
      },
      {
        Header: "Nome",
        accessor: "nome",
      },
      {
        Header: "Telefono",
        accessor: "telefono",
      },
      {
        Header: "Telefono 2",
        accessor: "telefono2",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Email 2",
        accessor: "email2",
      },
    ],
    []
  );
	///
	return (
		<>
			<div class="card">
				<div class="card-header">
					{props.nuovaTab ? (
						<div class="card-title" data-toggle="collapse" data-target={"#collapsepartner" + props.dataId} >
							<i class=" flaticon2-start-up"></i> Nuovo Partner
						</div>
					) : (
						<div class="card-title collapsed" data-toggle="collapse" data-target={"#collapsepartner" + props.dataId} >
							<i class=" flaticon2-start-up"></i> {props.partner.cliente.acronimo}
						</div>
					)}
				</div>
				<div id={"collapsepartner" + props.dataId} class={divStyle} data-parent="#accordionPartners" >
					<div class="card-body">
						<Formik
							enableReinitialize={true}
							initialValues={
								!props.nuovaTab ? {
									clienteId: props.partner.clienteId,
									percRI: props.partner.percRI,
									percSS: props.partner.percSS,
									percRIF: props.partner.percRIF,
									cup: props.partner.cup,
									anticipazione: String(props.partner.anticipazione),
									tipoLivelloId1: tipoLivelloId1,
									tipoLivelloId2: tipoLivelloId2,
									tipoLivelloId3: tipoLivelloId3,
									tipoLivelloId4: tipoLivelloId4,
									tipoLivelloId5: tipoLivelloId5
								} : {
									clienteId: "",
									percRI: "",
									percSS: "",
									percRIF: "",
									cup: "",
									anticipazione: "",
									tipoLivelloId1: "0",	
									tipoLivelloId2: "0",	
									tipoLivelloId3: "0",	
									tipoLivelloId4: "0",	
									tipoLivelloId5: "0",	
								}
							}
							validationSchema={Yup.object({
								clienteId: Yup.number().required("Campo obbligatorio"),
								descrizione: Yup.string(),
							})}
							onSubmit={(values, { setSubmitting }) => {
								values['partnerLivelli'] = [
									{tipoLivelloId: 1, numero: parseInt(values['tipoLivelloId1'] || 0)},
									{tipoLivelloId: 2, numero: parseInt(values['tipoLivelloId2'] || 0)},
									{tipoLivelloId: 3, numero: parseInt(values['tipoLivelloId3'] || 0)},
									{tipoLivelloId: 4, numero: parseInt(values['tipoLivelloId4'] || 0)},
									{tipoLivelloId: 5, numero: parseInt(values['tipoLivelloId5'] || 0)}
								];
								delete values['tipoLivelloId1'];
								delete values['tipoLivelloId2'];
								delete values['tipoLivelloId3'];
								delete values['tipoLivelloId4'];
								delete values['tipoLivelloId5'];
								if(props.partner) values['partnerId'] = props.partner.partnerId;
								!props.nuovaTab
									? handleEdit(
											props.partner.partnerId,
											values
										)
									: handlePost(values);

								//setReadOnly(true);
							}}
							onReset={() => {
								setReadOnly(true);
							}}
						>
							<Form class="form">
								<div class="border-0 top_commandi pb-5" style={{ textAlign: "right" }} >
									{readOnly ? (
										<div>
											<MyButtons.MyModifica handleClick={handleEditClick}/>
											&nbsp;&nbsp;
											<MyButtons.MyModifica handleClick={openModalGdL} label="Gestisci GdL"/>
											&nbsp;&nbsp;
											<MyButtons.MyModifica handleClick={openModalReferenti} label="Gestisci Referenti"/>
											&nbsp;&nbsp;
											<MyButtons.MyAggiungi handleClick={openTabBudget} label="Nuovo Budget"/>
										</div>
									) : (
										<div>
											{!props.nuovaTab ? (
												<>
													<MyButtons.MyAnnulla/>
													&nbsp;&nbsp;
													<MyButtons.MyCancella handleClick={openModalConfirm}/>
												</>
											) : (
												<MyButtons.MyAnnulla handleClick={closeNewTab}/>
											)}
											&nbsp;&nbsp;
											<MyButtons.MySalva/>
										</div>
									)}
								</div>
								<div class="form-group row">
									<div class="col-lg-4">
										<MyForms.MySelect
											label="Cliente"
											name="clienteId"
											class="form-control"
											list={props.listClienti}
											disabled={readOnly}
											nameLabel="acronimo"
										/>
										<span class="form-text text-muted"></span>
									</div>
									<div class="col-lg-4">
										<MyForms.MyTextInput
											label="Cup"
											name="cup"
											class="form-control"
											disabled={readOnly}
										/>
										<span class="form-text text-muted"></span>
									</div>
									<div class="col-lg-4">
										<label><strong>Usufruisce dell'anticipazione:</strong></label>
										<div class="radio-inline">
											<label class="radio radio-solid">
												<Field
													type="radio"
													name="anticipazione"
													value="true"
													disabled={readOnly}
												/>
												<span></span>
												Sì
											</label>
											<label class="radio radio-solid">
												<Field
													type="radio"
													name="anticipazione"
													value="false"
													disabled={readOnly}
												/>
												<span></span>
												No
											</label>
										</div>
									</div>
									<span class="form-text text-muted"></span>
								</div>
								<div class="form-group row">
									<div class="col-lg-4">
										<label><strong>% Contributo RI:</strong></label>
										<div class="input-group">
											<MyForms.MyTextInput
												type="perc"
												name="percRI"
												class="form-control"
												disabled={readOnly}
											/> 
											{readOnly === false ? (
												<div class="input-group-prepend">
													<span class="input-group-text">%</span>
												</div>
											) : (
												<span>&nbsp; ({Utils.Format(props.partner.contributoRI)})</span>
											)}
										</div>
									</div>
									<div class="col-lg-4">
										<label><strong>% Contributo SS:</strong></label>
										<div class="input-group">
											<MyForms.MyTextInput
												type="perc"
												name="percSS"
												class="form-control"
												disabled={readOnly}
											/>
											{readOnly === false ? (
												<div class="input-group-prepend">
													<span class="input-group-text">%</span>
												</div>
											) : (
												<span>&nbsp; ({Utils.Format(props.partner.contributoSS)})</span>
											)}
										</div>
									</div>
									<div class="col-lg-4">
										<label><strong>% Contratto società di consulenza:</strong></label>
										<div class="input-group">
											<MyForms.MyTextInput
												type="perc"
												name="percRIF"
												step="0.0001"
												class="form-control"
												disabled={readOnly}
											/>
											{readOnly === false ? (
												<div class="input-group-prepend">
													<span class="input-group-text">%</span>
												</div>
											) : (
												<span>&nbsp; ({Utils.Format(props.partner.onorario)})</span>
											)}
										</div>
									</div>
								</div>
								{readOnly === true && (
									<>
										<div class="form-group row">
											<div class="col-lg-12">
												<h4>Referenti per il progetto</h4>
											</div>
										</div>
										<div class="form-group row">
											<ContentTable
											columns={columns}
											easy="1"
											noLink="1"
											title="Referenti"
											dataToDisplay={props.partner.referenti}
											defaultOrderBy={"cognome"}
											/>
										</div>
										<div class="form-group row">
											<div class="col-lg-12">
												<h4>Numero di dipendenti per livello che parteciperanno al progetto</h4>
											</div>
										</div>
										<div class="form-group row">
										{props.progetto.costiReali ? (
											<div class="col-lg-3">
												<MyForms.MyTextInput
													label="Dipendenti a Costo Reale"
													type="text"
													name="tipoLivelloId5"
													class="form-control"
													disabled={false}
												/>
											</div>
										) : (
											<>
												<div class="col-lg-3">
													<MyForms.MyTextInput
														label="Livello Alto"
														type="text"
														name="tipoLivelloId1"
														class="form-control"
														disabled={readOnly}
													/>
												</div>
												<div class="col-lg-3">
													<MyForms.MyTextInput
														label="Livello Medio"
														type="text"
														name="tipoLivelloId2"
														class="form-control"
														disabled={readOnly}
													/>
												</div>
												<div class="col-lg-3">
													<MyForms.MyTextInput
														label="Livello Basso"
														type="text"
														name="tipoLivelloId3"
														class="form-control"
														disabled={readOnly}
													/>
												</div>
											</>
										)}
											<div class="col-lg-3">
												<MyForms.MyTextInput
													label="Non dipendenti"
													type="text"
													name="tipoLivelloId4"
													class="form-control"
													disabled={readOnly}
												/>
											</div>
										</div>
									</>
								)}
							</Form>
						</Formik>
						<div>
						{(!props.nuovaTab && readOnly === true) && (
							<BudgetStack 
								partnerId={props.partner.partnerId} 
								bandoId={props.bandoId}
								nuovoBudget={nuovoBudget}
								setNuovoBudget={setNuovoBudget}
								handleCloseNuovoBudget={handleCloseNuovoBudget}
								forceUpdatePartner={props.forceUpdate}
							/>
						)}
						</div>
					</div>
				</div>
			</div>
			<ModalConfirm
                isModalConfirmOpen={isModalConfirmOpen}
                closeModalConfirm={closeModalConfirm}
                handleConfirm={handleDelete}
				idToDelete={partnerId}
            />
			{!props.nuovaTab && (
				<>
				<ModalGdL
					isModalGdLOpen={isModalGdLOpen}
					onRequestClose={closeModalGdL}
					partner={props.partner}
					progetto={props.progetto}
				/>
				<ModalReferenti
					isModalReferentiOpen={isModalReferentiOpen}
					onRequestClose={closeModalReferenti}
					partner={props.partner}
					progetto={props.progetto}
					forceUpdate={props.forceUpdate}
				/>
				</>
			)}
		</>
	);
}
