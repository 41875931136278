import React, { useState, useEffect, useCallback } from "react";
import Modal from "react-modal";
import Moment from "react-moment";
import * as MyButtons from "./MyButtons";
import ReactDataSheet from 'react-datasheet';
import * as Constants from "../../constants";
import 'react-datasheet/lib/react-datasheet.css';
import '../../../custom-datasheet.css';
import * as Utils from './Utils';

Modal.setAppElement("#root");

export default function PMeseModal(props) {
  	const [myGrid, setGrid] = useState([]);
	const [RTotale, setRTotale] = useState();
	const [alertOreMese, setAlertOreMese] = useState(false);
	const [readOnly, setReadOnly] = useState(true);
	const [TotaleReadOnly, setTotaleReadOnly] = useState(true);
	const [lastUpdate, setLastUpdate] = useState();
	const [visCalcolato, setVisCalcolato] = useState(false);
	const [visCosti, setVisCosti] = useState(false);
	const [effort_totale_progetto, setEffortTotaleProgetto] = useState(false);
	const [costo_totale_progetto, setCostoTotaleProgetto] = useState(false);
	const [isNew, setNew] = useState(false);
    const [selectedPMese,setDataPMese] = useState({});
	
	useEffect(() => {
		setVisCosti(false);
		setTotaleReadOnly(true);
		fetchPMese()
	}, [props.selectedPMese]);


	const fetchPMese = async () => {
		let data = props.selectedPMese;
		if(!data.partnerDipendenteId) return;
		var partnerDipendenteId = data.partnerDipendenteId;
		var api_path = Constants.Compose([Constants.PATH_PMESE]);
		api_path += "?partnerDipendenteId="+ partnerDipendenteId;
		const response_data = await Constants.getData(api_path);
		response_data['costoMedioOrario'] = data.costoMedioOrario;
		response_data['partnerDipendenteId'] = partnerDipendenteId

		if(response_data.sommaEffortConfermato > 0) {
			setVisCalcolato(false);
			setReadOnly(true);
			setNew(false);
			setLastUpdate(response_data.attivita[0].calendarioAttivita[0].updatedAt);
		} else {
			setVisCalcolato(true);
			setReadOnly(false);
			setNew(true);
		}
		setDataPMese(response_data)
	};

	useEffect(() => {
		var [grid, RTotale, alert, effort_totale_progetto, costo_totale_progetto] = Utils.PreparePMese(selectedPMese, props.selectedPMese.costoMedioOrario, visCalcolato, visCosti, readOnly, undefined, undefined, TotaleReadOnly);
		setGrid(grid);
		setRTotale(RTotale);
		setAlertOreMese(alert);
		setEffortTotaleProgetto(effort_totale_progetto);
		setCostoTotaleProgetto(costo_totale_progetto);
	}, [readOnly, visCalcolato, visCosti, selectedPMese, TotaleReadOnly]);

	const handleTotalePost = async () => {
		let dataToSend = [];
		myGrid.map(row => {
			row.map(cell => {
				if(cell.partnerDipendenteId && cell.attivitaId) {
					let costoConfermato = parseFloat(cell.value * props.selectedPMese.costoMedioOrario);
					dataToSend.push({
						attivitaId: cell.attivitaId,
						partnerDipendenteId: cell.partnerDipendenteId,
						effortConfermato: cell.value,
						costoConfermato: costoConfermato
					});
				}
			});
		});
		var api_path = Constants.Compose([Constants.PATH_PARTNERS, props.partnerId, 'pianificazioneDipendente']);
		const response_data = await Constants.postData(api_path, JSON.stringify(dataToSend));
		// props.forceUpdate();
		setTotaleReadOnly(true);
		fetchPMese();
	}
	const handlePost = async () => {
		var d = {
			"effort": [],
			"rendicontazioneId": props.rendicontazioneId
		};

		myGrid.map(row => {
			row.map(cell => {
				if(cell.pianificazioneDipendenteId && cell.idMese) {
					var costoConfermato = parseFloat(cell.value * props.selectedPMese.costoMedioOrario);
					d.effort.push({
						pianificazioneDipendenteId: cell.pianificazioneDipendenteId,
						mese: cell.idMese,
						effortCalcolato: cell.effortCalcolato,
						effortConfermato: cell.value,
						costoConfermato: costoConfermato
					});
				}
			});
		});
		var api_path = Constants.Compose([Constants.PATH_PMESE_POST]);
		const response_data = await Constants.postData(api_path, JSON.stringify(d));
		props.forceUpdate();
		props.onRequestClose();
	};

	const handleEditClick = () => {
		if(!readOnly) {
			setVisCalcolato(false);
		}
		setReadOnly(!readOnly);
	}
	const handleEditTotaleClick = () => {
		if(!readOnly) {
			setVisCalcolato(false);
		}
		setTotaleReadOnly(!readOnly);
	}
	const handleChangeView = () => {
		setVisCalcolato(!visCalcolato);
	}
	const handleChangeViewCosti = () => {
		setVisCosti(!visCosti);
	}

	return (
		<>
			<Modal
				isOpen={props.isModalOpen}
				onRequestClose={props.onRequestClose}
				style={{
					overlay: {
						width: "350",
					},
					content: {
						top: "5%",
						left: "7%",
						right: "7%",
						bottom: "auto",
					},
					width: "1200px"
				}}
			>
				<div className="modal-content">
					<div className="modal-header">
						<h5>{props.selectedPMese.dipendente}</h5>
						<h6>Pianificazione effort personale per mese &nbsp;
							{isNew ? ( 
								<span class="label label-lg label-light-warning label-inline"> Da calcolare </span>
							) : (
								<span class="label label-lg label-light-success label-inline"> Calcolato </span>
							)}
						</h6>
						<MyButtons.MyClose handleClick={props.onRequestClose}/>
					</div>
					<div className="modal-body">
						<div class="border-0 top_commandi" style={{ textAlign: "right" }} >
							{(readOnly && TotaleReadOnly) ? (
								<>
									<MyButtons.MyModifica label="Modifica totale ore pianificazione" handleClick={handleEditTotaleClick}/>&nbsp;
									<MyButtons.MyModifica handleClick={handleEditClick}/>
								</>
							) : (
								!readOnly ? (
								<>
									<MyButtons.MyAnnulla handleClick={handleEditClick}/>
									&nbsp;&nbsp;
									<MyButtons.MyModifica label="Salva" handleClick={handlePost}/>
								</>
								) : (
								<>
									<MyButtons.MyAnnulla handleClick={handleEditTotaleClick}/>
									&nbsp;&nbsp;
									<MyButtons.MyModifica label="Salva" handleClick={handleTotalePost}/>
								</>

								)
							)}
						</div>
						<div class="border-0 _top_commandi bp-10" >
							<table class="table table-borderless mx-auto" style={{maxWidth:"80%"}}>
								<tr>
									<td style={{ textAlign: "right", _width: "50%" }}><strong>Data inizio progetto</strong></td>
									<td style={{_width: "50%"}}><Moment format="DD/MM/YYYY">{props.progetto.dataInizioEff}</Moment></td>
									<td style={{ textAlign: "right" }}><strong>Monte ore mensili per il dipendente</strong></td>
									<td>{props.selectedPMese.oreMese}</td>
									<td style={{ textAlign: "right" }}><strong>Costo Medio Orario</strong></td>
									<td>{Utils.Format(props.selectedPMese.costoMedioOrario)}</td>
								</tr>
								<tr>
									<td style={{ textAlign: "right" }}><strong>Effort totale pianificato</strong></td>
									<td>{effort_totale_progetto}</td>
									<td style={{ textAlign: "right" }}><strong>Costo totale pianificato</strong></td>
									<td>{Utils.Format(costo_totale_progetto)}</td>
									<td style={{ textAlign: "right" }}><strong>Ultimo aggiornamento pianificazione</strong></td>
									<td><Moment format="DD/MM/YYYY">{lastUpdate}</Moment></td>
								</tr>
							</table>
						</div>
						<div class="border-0 top_commandi mx-auto" style={{height:"30px"}}>
							{! readOnly && (
								visCalcolato ? (
									!isNew && (
										<h6 class="text-center">Stai visualizzando gli effort suggeriti dall'applicazione &nbsp; 
											<span class="pointer label label-lg label-light-primary label-inline" onClick={handleChangeView}> Visualizza Pianificazione Salvata</span>
										</h6>
									)
								) : (
									<h6 class="text-center">Stai visualizzando gli effort dell'ultimo salvataggio &nbsp;
										<span class="pointer label label-lg label-light-primary label-inline" onClick={handleChangeView}> Visualizza effort suggeriti</span>
									</h6>
								)
							)}
							{readOnly && (
								<div class="pointer border-0 top_commandi bp-5">
								{visCosti ? (
									<h6 class="text-center">
										<span class="label label-lg label-light-primary label-inline" onClick={handleChangeViewCosti}> Visualizza Effort</span>
									</h6>
								) : (
									<h6 class="text-center">
										<span class="label label-lg label-light-primary label-inline" onClick={handleChangeViewCosti}> Visualizza Costi</span>
									</h6>
								)}
								</div>
							)}
						</div>
						<div class="border-0 top_commandi bp-5" style={{textAlign:"center"}}>
							<ReactDataSheet
								data={myGrid}
								valueRenderer={cell => cell.value}
								className='custom-sheet mx-auto'
								onCellsChanged={changes => {
									const grid = myGrid.map(row => [...row]);
									changes.forEach(({ cell, row, col, value }) => {
										if(!value) value = 0;
										value = isNaN(value) ? 0 : value;
										value = parseInt(value);
										var effortRendicontato = grid[row][col].effortRendicontato;
										if(effortRendicontato && value < effortRendicontato)  {
											alert("ATTENZIONE\nPer questo mese sono già state rendicontate " + effortRendicontato + " ore nel SAL precedente.\nInserire un valore uguale o maggiore");
											return false;
										}
										grid[row][col] = { ...grid[row][col], value };
										grid[row][col] = { ...grid[row][col], valuePulito: value };
									});
									var [gridUpdated] = Utils.calcSommePMese(grid, RTotale, selectedPMese.oreMese);
									setGrid(gridUpdated);
								}}
							/>
						</div>
						{alertOreMese && (
							<div class="border-0 top_commandi mx-auto" style={{height:"30px", textAlign:"center"}}>
								<span class="label label-lg label-light-danger label-inline"> Attenzione: per le mensilità evidenziate in rosso viene superato il limite massimo mensile di ore</span>
							</div>
						)}
					</div>
				</div>
			</Modal>
        </>
    )
}