import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useLocation, withRouter } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import * as MyForms from "./MyForms";
import * as Constants from "../../constants";

Modal.setAppElement("#root");

function FormBando(props) {
  const [fields, updateFields] = useState(useLocation().state); //I campi vengono inizializzati con l'oggetto state passato fra le props del router
  const [readOnly, setReadOnly] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [enti, setEnti] = useState([]);
  const [vociDiCosto, setVociDiCosto] = useState([]);
  const [costiBando, setCostiBando] = useState([]);

  useEffect(() => {
    const fetchVociDiCosto = async () => {
      const url = Constants.GETVOCICOSTOURL;
      console.log(url);
      const response_data = await Constants.getData(url);
      console.log(response_data);
      setVociDiCosto(response_data);
    };

    fetchVociDiCosto();

    const fetchEnti = async () => {
      const url = Constants.GETENTIURL;
      console.log(url);
      const response_data = await Constants.getData(url);
      console.log(response_data);
      setEnti(response_data);
    };

    fetchEnti();

    const fetchCostiBando = async () => {
      const url =
        Constants.GETCOSTIBANDOURL +
        fields["bandoId"] +
        "/costiBando";
      const response_data = await Constants.getData(url);
      setCostiBando(response_data);
    };

    if (!props.nuovaScheda) {
      fetchCostiBando();
    } else {
      updateFields(null);
      setReadOnly(false);
    }
  }, [props.nuovaScheda, fields]);

  const handleEditClick = () => {
    setReadOnly(!readOnly);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const backToList = () => {
    props.backFromScheda();
  };

  const handleDelete = async (id) => {
    const deleteCostoUrl =
      Constants.POSTCOSTOBANDOURL +
      id +
      "/costiBando";
    await Constants.deleteData(deleteCostoUrl);

    const deleteBandoUrl =
      Constants.PUTBANDIURL + id;
    await Constants.deleteData(deleteBandoUrl);
    props.backFromScheda();
  };

  const handleEdit = async (id, dataToSend) => {
    var vociCosto = dataToSend.vociDC.map((e) => ({ voceCostoId: e }));
    var dataBandi = {
      titolo: dataToSend.titolo,
      anticipazione: dataToSend.anticipazione,
      creditoImposta: dataToSend.creditoImposta,
      enteId: dataToSend.enteId,
      link: dataToSend.link,
      percAnticipazione: dataToSend.percAnticipazione,
    };

    var fetchUrl = Constants.PUTBANDIURL + id;
    await Constants.putData(fetchUrl, dataBandi);

    fetchUrl =
      Constants.POSTCOSTOBANDOURL +
      id +
      "/costiBando";
    await Constants.deleteData(fetchUrl);
    await Constants.postData(fetchUrl, JSON.stringify(vociCosto, null, 2));
    setReadOnly(true);
  };

  const handlePost = async (dataToSend) => {
    var vociCosto = dataToSend.vociDC.map((e) => ({ voceCostoId: e }));
    var dataBandi = {
      titolo: dataToSend.titolo,
      anticipazione: dataToSend.anticipazione,
      creditoImposta: dataToSend.creditoImposta,
      enteId: dataToSend.enteId,
      link: dataToSend.link,
      percAnticipazione: dataToSend.percAnticipazione,
    };

    const fetchurl = Constants.POSTBANDIURL;
    const bando = await Constants.postData(
      fetchurl,
      JSON.stringify(dataBandi, null, 2)
    );

    const fetchUrl =
      Constants.POSTCOSTOBANDOURL +
      bando.bandoId +
      "/costiBando";
    await Constants.postData(fetchUrl, JSON.stringify(vociCosto, null, 2));
    setReadOnly(true);
    props.changeState(bando);
  };

  return (
    <>
      <div
        class="tab-pane fade show active"
        id="tab_pane_1"
        role="tabpanel"
        aria-labelledby="tab_pane_2"
      >
        <Formik
          enableReinitialize={true}
          initialValues={
            !props.nuovaScheda
              ? {
                  titolo: fields["titolo"],
                  link: fields["link"],
                  enteId: fields["enteId"],
                  anticipazione: String(fields["anticipazione"]),
                  creditoImposta: String(fields["creditoImposta"]),
                  percAnticipazione: fields["percAnticipazione"],
                  vociDC: costiBando.map(({ voceCostoId }) => voceCostoId),
                }
              : {
                  titolo: "",
                  link: "",
                  enteId: "",
                  anticipazione: "",
                  creditoImposta: "",
                  percAnticipazione: "",
                  vociDC: [],
                }
          }
          validationSchema={Yup.object({
            titolo: Yup.string().required("Titolo Bando Obbilgatorio"),
            enteId: Yup.string().required("Ente obbligatorio"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            !props.nuovaScheda
              ? handleEdit(fields["bandoId"], values)
              : handlePost(values);
          }}
          onReset={() => {
            setReadOnly(true);
          }}
        >
          <Form class="form pt-5">
            <div class="border-0 top_commandi" style={{ textAlign: "right" }}>
              {readOnly ? (
                <div>
                  <button
                    class="btn btn-outline-primary font-size-sm"
                    onClick={backToList}
                  >
                    <i class="icon fas fa-arrow-left"></i>
                    Indietro
                  </button>
                  &nbsp;&nbsp;
                  <div
                    class="btn btn-primary font-weight-bolder"
                    onClick={handleEditClick}
                  >
                    <span class="svg-icon svg-icon-md">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24" />
                          <circle fill="#000000" cx="9" cy="15" r="6" />
                          <path
                            d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z"
                            fill="#000000"
                            opacity="0.3"
                          />
                        </g>
                      </svg>
                    </span>
                    Edit
                  </div>
                </div>
              ) : (
                <div>
                  {/* Se si sta inserendo una nuova scheda il tasto Cancella è invisibile ed il tasto
                    annulla riporta all'elenco. Se invece siamo in modifica il tasto cancella è visibile
                    ed il tasto annulla disabilita l'editing rimanendo sulla scheda*/}
                  {!props.nuovaScheda ? (
                    <>
                      <button
                        type="reset"
                        class="btn btn-outline-primary font-size-sm"
                      >
                        <i class="icon fas fa-arrow-left"></i>
                        Annulla
                      </button>
                      &nbsp;&nbsp;
                      <div
                        class="btn btn-danger font-size-sm"
                        onClick={openModal}
                      >
                        <i class="icon far fa-trash-alt"></i>
                        Cancella
                      </div>
                    </>
                  ) : (
                    <button
                      onClick={backToList}
                      class="btn btn-outline-primary font-size-sm"
                    >
                      <i class="icon fas fa-arrow-left"></i>
                      Annulla
                    </button>
                  )}
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-primary font-weight-bolder"
                    type="submit"
                  >
                    <i class="icon far fa-save"></i> Salva
                  </button>
                </div>
              )}
            </div>
            {/* Si istanziano le nostre Form. */}
            <br />
            <div class="form-group row">
              <div class="col-lg-4">
                <MyForms.MyTextInput
                  label="Titolo"
                  name="titolo"
                  //type="text"
                  class="form-control"
                  disabled={readOnly}
                />
                <span class="form-text text-muted"></span>
              </div>
              <div class="col-lg-4">
                <MyForms.MyTextInput
                  label="Link"
                  name="link"
                  //type="text"
                  class="form-control"
                  link="1"
                  disabled={readOnly}
                />
                <span class="form-text text-muted"></span>
              </div>
              <div class="col-lg-4">
                <MyForms.MySelect
                  label="Ente erogatore"
                  name="enteId"
                  class="form-control"
                  list={enti}
                  nameLabel="nome"
                  disabled={readOnly}
                >
                </MyForms.MySelect>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-4">
                <label>Prevista anticipazione:</label>
                <div class="radio-inline">
                  <label class="radio radio-solid">
                    <Field
                      type="radio"
                      name="anticipazione"
                      value="true"
                      disabled={readOnly}
                    />
                    <span></span>
                    Sì
                  </label>
                  <label class="radio radio-solid">
                    <Field
                      type="radio"
                      name="anticipazione"
                      value="false"
                      disabled={readOnly}
                    />
                    <span></span>
                    No
                  </label>
                </div>
                <span class="form-text text-muted"></span>
              </div>
              <div class="col-lg-4">
                <label>Previsto credito di imposta:</label>
                <div class="radio-inline">
                  <label class="radio radio-solid">
                    <Field
                      type="radio"
                      name="creditoImposta"
                      value="true"
                      disabled={readOnly}
                    />
                    <span></span>
                    Sì
                  </label>
                  <label class="radio radio-solid">
                    <Field
                      type="radio"
                      name="creditoImposta"
                      value="false"
                      disabled={readOnly}
                    />
                    <span></span>
                    No
                  </label>
                </div>
                <span class="form-text text-muted"></span>
              </div>
              <div class="col-lg-4">
					<label><strong>% Anticipazione:</strong></label>
					<div class="input-group">
                		<MyForms.MyTextInput
                		  name="percAnticipazione"
                		  type="perc"
                		  class="form-control"
                		  disabled={readOnly}
                		/>
						{readOnly === false ? (
							<div class="input-group-prepend">
								<span class="input-group-text">%</span>
							</div>
						) : (
							<span>&nbsp; ({props.percAnticipazione})</span>
						)}
					</div>
				</div>
            </div>
            <div class="form-group row">
              <div class="col-lg-12">
                    <MyForms.Checkbox
                      label="Voci di costo previste"
                      name="vociDC"
                      class=""
                      disabled={readOnly}
                      list={vociDiCosto}
                      id="voceCostoId"
                      nameLabel="voceCosto"
                    />
                <span class="form-text text-muted"></span>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-lg-6">
                <span class="form-text text-muted"></span>
              </div>
              <div class="col-lg-6">
                <span class="form-text text-muted"></span>
              </div>
            </div>
          </Form>
        </Formik>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            width: "150",
          },
          content: {
            color: "lightsteelblue",
            top: "5%",
            left: "30%",
            right: "30%",
            bottom: "auto",
          },
        }}
      >
        <h2 align="center">L'oggetto sta per essere eliminato</h2>
        <br />
        <br />
        <br />
        <div align="center">
          <div
            class="btn btn-outline-primary font-size-sm"
            onClick={closeModal}
            align="center"
          >
            Annulla
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div
            class="btn btn-outline-primary font-size-sm"
            onClick={() => {
              handleDelete(fields["bandoId"]);
            }}
            align="center"
          >
            Conferma
          </div>
        </div>
      </Modal>
    </>
  );
}

export default withRouter(FormBando);
