import { FormikContext } from "formik";
import React, { useState, useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";

import FormCliente from "./FormCliente";

function SchedaCliente(props) {
  const [fields, setFields] = useState(useLocation().state || {}); //I campi vengono inizializzati con l'oggetto state passato fra le props del router
	const [state, setState] = useState({});
  // 	const [fields, setFields] = useState(props.fields || {});

  // useEffect(() => {
  // 	console.log("PASSO")
  // 	setFields(props.fields);
  // }, [props.fields]);

	const displayTab  = (tab) => {
		setState({ displayTab: tab });
	};
  return (
    <div class="card-body">
      <ul class="nav nav-tabs nav-tabs-line mb-5">
        <li class="nav-item" onClick={() => displayTab('cli_1')}>
          <a class="nav-link active" data-toggle="tab" href="#tab_pane_1">
            <span class="nav-icon">
              <i class="flaticon2-chat-1"></i>
            </span>
            <span class="nav-text">Dettagli</span>
          </a>
        </li>
        {!props.nuovaScheda && (
          <>
            <li class="nav-item" onClick={() => displayTab('cli_2')}>
              <a class="nav-link" data-toggle="tab" href="#tab_pane_2">
                <span class="nav-icon">
                  <i class="flaticon2-pie-chart-4"></i>
                </span>
                <span class="nav-text">Sede legale</span>
              </a>
            </li>
            <li class="nav-item" onClick={() => displayTab('cli_3')}>
              <a class="nav-link" data-toggle="tab" href="#tab_pane_3">
                <span class="nav-icon">
                  <i class="flaticon2-pie-chart-4"></i>
                </span>
                <span class="nav-text">Sedi operative</span>
              </a>
            </li>
            {fields.tipoClienteId == 7 ? (
              <li class="nav-item" onClick={() => displayTab('cli_7')}>
                <a class="nav-link" data-toggle="tab" href="#tab_pane_7">
                  <span class="nav-icon">
                    <i class="flaticon2-pie-chart-4"></i>
                  </span>
                  <span class="nav-text">Consorziati</span>
                </a>
              </li>
            ) : (
              <li class="nav-item" onClick={() => displayTab('cli_4')}>
                <a class="nav-link" data-toggle="tab" href="#tab_pane_4">
                  <span class="nav-icon">
                    <i class="flaticon2-pie-chart-4"></i>
                  </span>
                  <span class="nav-text">Dipendenti</span>
                </a>
              </li>
            )}
            <li class="nav-item" onClick={() => displayTab('cli_5')}>
              <a class="nav-link" data-toggle="tab" href="#tab_pane_5">
                <span class="nav-icon">
                  <i class="flaticon2-pie-chart-4"></i>
                </span>
                <span class="nav-text">Contratti</span>
              </a>
            </li>
            <li class="nav-item" onClick={() => displayTab('cli_6')}>
              <a class="nav-link" data-toggle="tab" href="#tab_pane_6">
                <span class="nav-icon">
                  <i class="flaticon2-pie-chart-4"></i>
                </span>
                <span class="nav-text">Referenti</span>
              </a>
            </li>
            {fields.tipoClienteId != 7 && (
              <li class="nav-item" onClick={() => displayTab('cli_8')}>
                <a class="nav-link" data-toggle="tab" href="#tab_pane_8">
                  <span class="nav-icon">
                    <i class="flaticon2-pie-chart-4"></i>
                  </span>
                  <span class="nav-text">Consorzi</span>
                </a>
              </li>
            )}
          </>
        )}
      </ul>
      <div class="tab-content" id="TabContent">
        <FormCliente
          backFromScheda={props.backFromScheda}
          postReferentiUrl={props.postReferentiUrl}
          putPersoneUrl={props.putPersoneUrl}
          nuovaScheda={props.nuovaScheda}
          changeRoute={props.changeRoute}
          changeState={props.changeState}
          fields={fields}
          displayTab={state.displayTab}
        />
      </div>
    </div>
  );
}

export default withRouter(SchedaCliente);
