import * as Constants from "../../constants";

const ExcelFormatValuta = "#,##0.00 \"€\" ";
const ExcelFormatPerc = "0% ";

const formatter = new Intl.NumberFormat("it-IT", {
	style: "currency",
	currency: "EUR",
	minimumFractionDigits: 2,
});
const formatter_nodecimal = new Intl.NumberFormat("it-IT", {
	style: "currency",
	currency: "EUR",
	minimumFractionDigits: 0,
});

const formatterNumber = new Intl.NumberFormat("it-IT", {
	minimumFractionDigits: 2,
});

export function Format(v, nodecimal) {
	if(!v) v = 0;
	if(nodecimal) return formatter_nodecimal.format(v);
	return formatter.format(v);
}

export function FormatNumber(v) {
	if(!v) v = 0;
	return formatterNumber.format(v);
}

export function FormatPerc(v) {
	if(!v) v = 0;
	return formatterNumber.format(v) + "%";
}

export function pulisciImporto(importo) {
	if(typeof importo == 'number') return importo;

	// Significa che importo e' stato copiato dall'excel
	importo = importo.replace(".", "");
	importo = importo.replace(",", ".");
	return importo;
}

export async function downloadAllegato(allegatoId, fileName) {
	if(!allegatoId) return;
	const url = Constants.GETALLEGATOURL + allegatoId + "/getAllegato";
	const response_data = await Constants.getDownload(url);

	var blob = new Blob([response_data], { type: "application/octet-stream" });
	var link = document.createElement("a");
	link.style = "display: none;";
	var burl = window.URL.createObjectURL(blob);
	link.href = burl;
	link.download = fileName;
	link.click();
	window.URL.revokeObjectURL(burl);
};

export function PreparePMese(data, costoMedioOrario, visCalcolato, visCosti, readOnly, salNumero, rendicontato, TotaleReadOnly, visPianificazione) {
	if(!data) return;
	var grid = [];

	let effort_totale_progetto = 0;
	let effort_totale_sal = 0;

	// Row SAL
	var rowGrid = [];
	var label_effort_pianificazione_attivita = "Ore imputate a pianificazione";                                                       
	var label_effort_pianificazione = "Totale effort inserito";                                                       
	if(visCosti) {
		label_effort_pianificazione_attivita = "Costo imputato a pianificazione";  
		label_effort_pianificazione = "Totale costo inserito";  
	}
	rowGrid.push(
		{value:"", readOnly: true, rowSpan: 3}, 
		{value: label_effort_pianificazione_attivita, rowSpan:3, readOnly: true, className:"thcell", width:"15px"}, 
		{value: "Controllo DELTA", rowSpan:3, readOnly: true, className:"thcell", width:"15px"},
		{value: label_effort_pianificazione, rowSpan:3, readOnly: true, className:"thcell", width:"15px"}
	);

	var SalMesi = {};
	var inizio = 0;
	let durata_progetto = 0;
	for (var i in data.sal) {
		var ele = data.sal[i];
		var durata = ele.durata;
		durata_progetto += ele.durata
		durata = durata * 2;
		// Fare il test se il progetto non inizia il primo giorno del mese 
		// if(1) {
			if(i == 0 || i == data.sal.length-1) durata++;
		// }
		if(salNumero) durata++;
		//// 

		inizio++;
		var fine = parseInt(inizio + ele.durata -1);
		if(i == data.sal.length-1) fine++;

		SalMesi[ele.numero] = {'inizio': inizio, 'fine': fine, 'mesi': ele.mesi};

		inizio = fine;
		if(!salNumero || (salNumero && salNumero == ele.numero)) {
			rowGrid.push({"value": "SAL " + ele.numero, "colSpan":durata, "readOnly": true, className:"thcell"});
		}
	}
	grid.push(rowGrid);

	// Rows Calendario
	var rowGridIdMese = [];
	var rowGridMeseProgetto = [];
	var maxIdMese = 1;
	if(data.calendario) {
		data.calendario.map(ele => {
			if(1) {
				if(!salNumero || (salNumero && SalMesi[salNumero].mesi.indexOf(ele.idMese) != -1)) {
					rowGridIdMese.push({"value": ele.idMese, "readOnly": true, "colSpan": 2});
					rowGridMeseProgetto.push({"value": ele.meseProgetto, "readOnly": true, "colSpan": 2});
					maxIdMese = ele.idMese;
				}
			}
			if(0) { // Versione mesi allineati con i SAL
				let colSpanCalendario = 2;
				if(Object.keys(data.calendario).length != durata_progetto) { // Il progetto non inizia il primo giorno del mese
					if(ele.idMese == 1 || ele.idMese == Object.keys(data.calendario).length || ele.intercalare == true) colSpanCalendario = 1;
				}
				if(!salNumero || (salNumero && SalMesi[salNumero].mesi.indexOf(ele.idMese) != -1)) {
					if(ele.idMese <= durata_progetto) {
						rowGridIdMese.push({"value": ele.idMese, "readOnly": true, "colSpan": 2});
						maxIdMese = ele.idMese;
					}
					rowGridMeseProgetto.push({"value": ele.meseProgetto, "readOnly": true, "colSpan": colSpanCalendario});
				}
			}
		});
	}
	grid.push(rowGridIdMese);
	grid.push(rowGridMeseProgetto);

	// Rows Attivita
	if(data.attivita) {
		// data.attivita.map(ele => {
		let list = data.attivita.sort((a,b) => {return a.orNumero - b.orNumero})
		list.map(ele => {
			var totEffortSal = 0;
			var totCostoSal = 0;
			var rowGrid = [];
			var valueToDisplay = ele.effortConfermato;
			var valueToDisplayPulito = ele.effortConfermato;
			var myFormat;
			if(visCosti) {
				valueToDisplayPulito = ele.costoConfermato;
				valueToDisplay = Format(ele.costoConfermato);
				myFormat = ExcelFormatValuta; 
			}
			rowGrid.push(
				{"value": "Att. " + ele.orNumero + "." + ele.numero + " (" + ele.tipoAttivita + ")", "readOnly": true}, 
				{"value": valueToDisplay, "valuePulito": valueToDisplayPulito, "format": myFormat, "readOnly": TotaleReadOnly, "partnerDipendenteId": data.partnerDipendenteId, "attivitaId": ele.attivitaId}, 
				{"value": "", "readOnly": true},
				{"value": "", "readOnly": true}
			);
			ele.calendarioAttivita.map(eleCal => {
				var value;
				var valuePulito;
				var cellReadOnly = false;
				var className = "";
				var effortRendicontato;
				var costoRendicontato;
				var getBis;

				// if(eleCal.idMese > durata_progetto) return true; // Correzione mese in piu'

				if(salNumero) { // Rendicontazione
					if(SalMesi[salNumero].mesi.indexOf(eleCal.idMese) == -1) return true; // Visualizzo solo le mensilita' relative al SAL che sto rendicontando

					if(eleCal.attivo == 1) {
						if(eleCal.dpAttivo == 1) {
							value = eleCal.effortRendicontato;
							valuePulito = eleCal.effortRendicontato;
							effortRendicontato = eleCal.effortRendicontato;
							costoRendicontato = parseFloat(eleCal.effortRendicontato * costoMedioOrario);
							cellReadOnly = readOnly;

							if(eleCal.intercalare && eleCal.idMese != 1 && SalMesi[salNumero].mesi[0] == eleCal.idMese) {
								// Se il mese e' a cavallo tra due sal, la prima mensilita' viene rendicontata nel campo RendicontazioneBis, fatta eccezione per il primo mese di progetto
								value = eleCal.effortRendicontatoBis;
								valuePulito = eleCal.effortRendicontatoBis;
								effortRendicontato = eleCal.effortRendicontatoBis;
								costoRendicontato = parseFloat(eleCal.effortRendicontatoBis * costoMedioOrario);
								getBis = 1;
							}

							if((!rendicontato || visPianificazione) && !readOnly) { // Se il dipendente non e' stato ancora rendicontato e sono in modalita' 'edit'
								// propongo i valori della pianificazione.
								value = eleCal.effortConfermato;
								valuePulito = eleCal.effortConfermato;
								effortRendicontato = eleCal.effortConfermato;
								costoRendicontato = parseFloat(eleCal.effortConfermato * costoMedioOrario);
							}
							effort_totale_sal += value;
							if(visCosti) {
								valuePulito = parseFloat(eleCal.effortRendicontato * costoMedioOrario);
								value = Format(valuePulito);
							}

							totEffortSal += parseInt(eleCal.effortConfermato)
							totCostoSal += parseFloat(eleCal.effortConfermato * costoMedioOrario)
						} else {
							cellReadOnly = true;
							className = "empty_dip_month"; /// METTERE UNA CLASSE DIVERSA 
						}
					} else {
						if(eleCal.intercalare && eleCal.idMese != 1 && SalMesi[salNumero].mesi[0] == eleCal.idMese) getBis = 1
						cellReadOnly = true;
						className = "empty_month";
					}
				} else { // Pianificazione
					if(eleCal.attivo == 1) {
						if(eleCal.dpAttivo == 1) {
							effortRendicontato = eleCal.effortRendicontato;
							if(eleCal.locked) { // Se locked == true l'effortCalcolato corrisponde all'effortRendicontato
								let bis = eleCal.effortRendicontatoBis ? eleCal.effortRendicontatoBis : 0;
								value = parseInt(eleCal.effortRendicontato + bis);
								valuePulito = value;
								cellReadOnly = true;
							} else {
								value = visCalcolato ? eleCal.effortCalcolato : eleCal.effortConfermato;
								valuePulito = value;
								cellReadOnly = readOnly;
							}
							if(value) effort_totale_progetto += value;
							if(visCosti) {
								valuePulito = parseFloat(value * costoMedioOrario);
								value = Format(valuePulito);
							}
						} else {
							cellReadOnly = true;
							className = 'empty_dip_month'; // Mettere una classe diversa
						}
					} else {
						cellReadOnly = true;
						className = 'empty_month';
					}
				}

				var myEle = {
					'colSpan': 2,
					'value': value,
					'valuePulito': valuePulito,
					'pianificazioneDipendenteId': ele.pianificazioneDipendenteId,
					'idMese': eleCal.idMese,
					'readOnly': cellReadOnly,
					'className': className,
					'effortCalcolato': eleCal.effortCalcolato,
					'costoCalcolato': eleCal.costoCalcolato,
					'effortConfermato': eleCal.effortConfermato,
					'costoConfermato': eleCal.costoConfermato,
					'effortRendicontato': effortRendicontato,
					'costoRendicontato': costoRendicontato,
					'getBis': getBis
				}
				if(visCosti) myEle['format'] = ExcelFormatValuta;

		  		rowGrid.push(myEle);
			});
			grid.push(rowGrid);
		});
	}

	// Rows Totali
	var rowGrid = [{"value": "Totale progetto", "readOnly": true, className:"thcell"}, {"readOnly": true}, {"readOnly": true}, {"readOnly": true}];
	if(data.calendario) {
		data.calendario.map(ele => {
			// if(ele.idMese > durata_progetto) return true; // Correzione mese in piu'
			if(!salNumero || (salNumero && SalMesi[salNumero].mesi.indexOf(ele.idMese) != -1)) {
				rowGrid.push({"readOnly": true, "colSpan": 2});
			}
		});
	}
	grid.push(rowGrid);
	var RTotale = grid.length-1;

	if(!salNumero) {
		if(!visCosti) {
			// Effort Usato in altri progetti
			if(data.effortAltri) {
				var rowGrid = [{"value": "Ore già utilizzate per altri progetti", "readOnly": true, className:"thcell"}, {"readOnly": true}, {"readOnly": true}, {"readOnly": true}];
				for (var i=1;i<=maxIdMese;i++) {
					var v = data.effortAltri[i] ? data.effortAltri[i] : 0; 
					rowGrid.push({"value": v, "valuePulito": v, "readOnly": true, "colSpan":2});
				}
				grid.push(rowGrid);
			}

		}
	}

	let costo_totale_progetto = effort_totale_progetto * costoMedioOrario;
	let costo_totale_sal = effort_totale_sal * costoMedioOrario;

	let effort_totale = salNumero ? effort_totale_sal : effort_totale_progetto;
	let costo_totale = salNumero ? costo_totale_sal : costo_totale_progetto;

	return calcSommePMese(grid, RTotale, data.oreMese, salNumero, visCosti, effort_totale, costo_totale);
}

export function calcSommePMese(grid, RowTotale, oreMese, salNumero, visCosti, effort_totale, costo_totale) {
	if(grid.length) {
		// Controllo Delta
		grid.map((row, i) => {
			if(i < 3) return true;
			if(i >= RowTotale) return true;

			var sum = 0;
			grid[i].map((col, j) => {
				if (! col.pianificazioneDipendenteId) return true;
				if (! col.value) return true;
				if(visCosti) {
					sum += parseFloat(col.valuePulito);
				} else {
					sum += parseInt(col.value);
				}
			});

			var delta = parseInt(grid[i][1].value - sum);
			grid[i][2]['valuePulito'] = delta;
			grid[i][3]['valuePulito'] = sum;
			if(visCosti) {
				delta = Format(parseFloat(grid[i][1].valuePulito - sum));
				grid[i][2]['format'] = ExcelFormatValuta;

				sum = Format(parseFloat(sum));
				grid[i][3]['format'] = ExcelFormatValuta;
			}
			grid[i][2]['value'] = delta;
			grid[i][2]['className'] = sum < 0 ? '' : '';
			
			grid[i][3]['value'] = sum;
		});

		// Totali mese
		if(grid[3]) {
			var numCols = grid[3].length;
			var alert = false;
			for(var i=4;i<=grid[3].length-1; i++) {
				if(!grid[RowTotale][i]) continue;

			 	var sum = 0;
				for(var j=3;j<=RowTotale-1; j++) {
					var row = grid[j];
			 		if (!row[i]) return true;
					var v = isNaN(parseInt(row[i].valuePulito)) ? 0 : row[i].valuePulito;
			  		sum += parseFloat(v);
			 	}

				grid[RowTotale][i]['valuePulito'] = sum;
				if(visCosti) {
					sum = Format(sum);
					grid[RowTotale][i]['format'] = ExcelFormatValuta;
				}
				grid[RowTotale][i]['value'] = sum;
				grid[RowTotale][i]['className'] = sum < 0 ? '' : '';

				if(!salNumero && !visCosti) {
					// Controllo limite oreMese
					var totComplessivo = parseFloat(sum + grid[RowTotale+1][i].value);
					if(totComplessivo > oreMese) {
						grid[RowTotale][i]['className'] = 'red';
						grid[RowTotale+1][i]['className'] = 'red';
						alert = true;
					} else {
						grid[RowTotale][i]['className'] = '';
						grid[RowTotale+1][i]['className'] = '';
					}
				}
			}
		}
	}
	return [grid, RowTotale, alert, effort_totale, costo_totale];
}

export async function Export(progettoId, partnerId) {
	var api_path = Constants.Compose([Constants.PATH_PROGETTI, progettoId]);
	const Progetto = await Constants.getData(api_path);

	var api_path = Constants.Compose([Constants.PATH_PROGETTI, progettoId, 'pianificazione']);
	const Pianificazioni = await Constants.getData(api_path);
	var PPartner = Pianificazioni.find(obj => { return obj.partnerId == partnerId});

	if(partnerId > 0) {
		var excel = {
			"progettoNome": Progetto.titolo,
			"partnerNome": PPartner.partnerNome,
			"sheets": [
				{
					"name": "Pianificazione Effort",
					"formato": "pianificazione_effort",
					"sheet":[]
				},
				{
					"name": "Pianificazione Costi",
					"formato": "pianificazione_costi",
					"sheet":[]
				},
				{
					"name": "Rendicontazione Effort",
					"formato": "rendicontazioni_effort",
					"sheet":[]
				},
				{
					"name": "Rendicontazione Costi",
					"formato": "rendicontazioni_costi",
					"sheet":[]
				},
				{
					"name": "Monitoraggio Costi",
					"formato": "monitoraggio_costi",
					"sheet":[]
				}
			]
		};

		for(var i in PPartner.pMese) {
			var dip = PPartner.pMese[i];

			var api_path = Constants.Compose([Constants.PATH_PMESE]);
			var myApiPath = api_path + "?partnerDipendenteId="+ dip.partnerDipendenteId+"&progettoId="+progettoId;
			const response_data = await Constants.getData(myApiPath);
			var costoMedioOrario = dip.costoMedioOrario;
			var oreMese = response_data.oreMese;
			var ultimoAggiornamento = response_data.attivita[0] ? response_data.attivita[0].calendarioAttivita[0].updatedAt : "" ;

			// Pianificazione Effort
			var [PianDip] = PreparePMese(response_data, costoMedioOrario, undefined, false, true, undefined, undefined);
			excel['sheets'][0]['sheet'].push({
				"dipendente": dip.dipendente, 
				"monteOreMensili": oreMese,
				"costoMedioOrario": costoMedioOrario,
				"ultimoAggiornamento": ultimoAggiornamento,
				"pianificazione": PianDip
			});

			// Pianificazione Costi
			var [PianDip] = PreparePMese(response_data, costoMedioOrario, undefined, true, true, undefined, undefined);
			excel['sheets'][1]['sheet'].push({
				"dipendente": dip.dipendente, 
				"monteOreMensili": oreMese,
				"costoMedioOrario": costoMedioOrario,
				"ultimoAggiornamento": ultimoAggiornamento,
				"pianificazione": PianDip
			});

			var api_path_sal = Constants.Compose([Constants.PATH_PROGETTI, progettoId, Constants.PATH_SAL]);
			api_path_sal += "?filter=" + JSON.stringify({include: ['tipoStatoRendicontazione'], order:['numero']});
			const SALS = await Constants.getData(api_path_sal);
			// Rendicontazione Effort
			var rendicontazioni = [];
			for(var j in SALS) {
				var SAL = SALS[j];

				var [PianDip] = PreparePMese(response_data, costoMedioOrario, undefined, false, true, SAL.numero, undefined);
				rendicontazioni.push(PianDip);
			}
			excel['sheets'][2]['sheet'].push({
				"dipendente": dip.dipendente, 
				"monteOreMensili": oreMese,
				"costoMedioOrario": costoMedioOrario,
				"ultimoAggiornamento": ultimoAggiornamento,
				"rendicontazioni": rendicontazioni
			});

			// Rendicontazione Costi
			var rendicontazioni = [];
			for(var j in SALS) {
				var SAL = SALS[j];

				var [PianDip] = PreparePMese(response_data, costoMedioOrario, undefined, true, true, SAL.numero, undefined);
				rendicontazioni.push(PianDip);
			}
			excel['sheets'][3]['sheet'].push({
				"dipendente": dip.dipendente, 
				"monteOreMensili": oreMese,
				"costoMedioOrario": costoMedioOrario,
				"ultimoAggiornamento": ultimoAggiornamento,
				"rendicontazioni": rendicontazioni
			});
		}

		// Monitoraggio Costi
		var monitoraggio = await GetMonitoraggio(progettoId, partnerId);
		excel['sheets'][4]['sheet'] = monitoraggio[0].sheet;
	} else { // Export Monitoraggio Intero progetto
		var sheets = await GetMonitoraggio(progettoId);
		var excel = {
			"progettoNome": Progetto.titolo,
			"sheets": sheets
		};
	}

	// console.log(JSON.stringify(excel))
	return excel;
}

export async function GetMonitoraggio(progettoId, partnerId) {
	var VociSal = ['Costi', 'Contributi', '% avanzam. Costi', '% avanza. Contributi' ];
	var TipoAttivita = [{tipoAttivitaId: 1, "tipoAttivita": "ATTIVITA DI RICERCA"}, {tipoAttivitaId: 2, tipoAttivita: "ATTIVITA DI SVILUPPO"}];
	
	var api_path = Constants.Compose([Constants.PATH_PROGETTI, progettoId, Constants.PATH_SAL_INREL]);
	const SALS = await Constants.getData(api_path);

	var grids = [];

	var api_path = Constants.Compose([Constants.PATH_PROGETTI, progettoId, Constants.PATH_PARTNERS_INREL]);
	api_path += "?filter=" + JSON.stringify({include:['cliente', 'partnerLivelli']});

	const PARTNERS = await Constants.getData(api_path);
	for(var i in PARTNERS) {
		var PARTNER = PARTNERS[i];

		if(partnerId > 0 && partnerId != PARTNER.partnerId) continue;

		var partnerNome = PARTNER.cliente.acronimo;
		var grid = [];

		var myPartnerCosti = {
			"1": [],
			"2": []
		};

		var TOTALI_GENERALE = {
			"decreto": 0,
			"pianificato": 0
		};
		var TOTALI = {};

		
		// GET COSTI DA DECRETO
		var api_path = Constants.Compose([Constants.PATH_PARTNERS, PARTNER.partnerId, Constants.PATH_BUDGET_INREL]);
		api_path += "?filter=" + JSON.stringify({'include':['tipoBudget', {'partnerCosti':'voceCosto'}], 'order': ['tipoBudgetId DESC'], 'limit': [1]});
		const BUDGET = await Constants.getData(api_path);
		var myListVociCosto = {}
		BUDGET.map(B => {
			B.partnerCosti.map(costo => {
				myListVociCosto[costo.voceCostoId] = costo.voceCosto.voceCosto;
				myPartnerCosti[costo.tipoAttivitaId][costo.voceCostoId] = {'decreto': costo.importo};
			});
		});
		var countVociCosto = Object.keys(myListVociCosto).length;

		// GET RENDICONTAZIONI
		var api_path = Constants.Compose([Constants.PATH_PARTNERS, PARTNER.partnerId, Constants.PATH_RENDICONTAZIONE_INREL]);
		api_path += "?filter=" + JSON.stringify({'include':['rendicontazioneCosti']});
		const RENDICONTAZIONI = await Constants.getData(api_path);
		var ListRendicontazioni = {};
		RENDICONTAZIONI.map(rendicontazione => {
			ListRendicontazioni[rendicontazione.salId] = rendicontazione.rendicontazioneCosti;

		});

		// Intestazione SAL
		var rowGrid = [
			{"rowSpan":2}, 
			{"className": "thcell", "value": "Costi da decreto", "rowSpan": 2}, 
			{"className": "thcell", "value": "Ultima pianificazione", "rowSpan": 2},
			{"className": "thcell", "value": "Contributo decretato (base + 10% magg.)", "rowSpan": 2}
		];
		SALS.map(sal => {
			rowGrid.push({"className": "thcell", "value": "SAL " + sal.numero, "colSpan": 4})
			if(!TOTALI_GENERALE["SAL"+sal.numero]) TOTALI_GENERALE["SAL"+sal.numero] = {'costi':0, 'contributo':0};
		});
		grid.push(rowGrid)

		// Intestazione secondo livello
		var rowGrid = [];
		SALS.map(sal => {
			VociSal.map(voce => {
				rowGrid.push({"className": "thcell", "value": voce})
			});
		});
		grid.push(rowGrid)

		// CICLO SUL TIPO ATTIVITA
		TipoAttivita.map(tipoattivita => {
			var tipoAttivitaId = tipoattivita.tipoAttivitaId;
			var tipoAttivita = tipoattivita.tipoAttivita;
			var percContributo = tipoAttivitaId == 1 ? PARTNER.percRI : PARTNER.percSS;

			TOTALI[tipoAttivitaId] = {
				"decreto": 0,
				"pianificato": 0
			};


			// Etichetta tipo attivita
			var rowGrid = [{"value": tipoAttivita, "width": "500px", "className": "thcell"}, {}, {}, {}];
			SALS.map(sal => {
				VociSal.map(voce => {
					rowGrid.push(
						{"className": "thcell"},
					);
				});
			});
			grid.push(rowGrid)

			// Voci costo
			var countRiga = 0;
			for (const [voceCostoId, voceCosto] of Object.entries(myListVociCosto)) {
				countRiga++;
				var value = myPartnerCosti[tipoAttivitaId][voceCostoId]['decreto'];
				var valuePianificato = value; /// DA CAMBIARE E CHIEDERE COME DEFINIRLO
				var rowGrid = [
					{"value": voceCosto, "className": "thcell"}, // Etichetta di voce costo
					{"value": Format(value), "valuePulito": value, "format": ExcelFormatValuta, "className": "cifra"}, // Costo da decreto
					{"value": Format(valuePianificato), "valuePulito": valuePianificato, "format": ExcelFormatValuta, "className": "cifra"}, // Costo da ultima pianificazione
				];
				TOTALI[tipoAttivitaId]['decreto'] += parseFloat(value);
				TOTALI[tipoAttivitaId]['pianificato'] += parseFloat(valuePianificato);
				TOTALI_GENERALE['decreto'] += parseFloat(value);
				TOTALI_GENERALE['pianificato'] += parseFloat(valuePianificato);

				if(countRiga == 1) rowGrid.push({ // Contributo decretato
					"value": percContributo + " %", 
					"valuePulito": percContributo, 
					"className": "", 
					"rowSpan": countVociCosto 
				});

				SALS.map(sal => {
					var importo = 0;
					if(ListRendicontazioni[sal.salId]) {
						var ele = ListRendicontazioni[sal.salId].find(obj => { return obj.tipoAttivitaId == tipoAttivitaId && obj.voceCostoId == voceCostoId });
						if(ele) importo = ele.importo
					}
					rowGrid.push({"value": Format(importo), "valuePulito": importo, "format": ExcelFormatValuta}); //COSTO RENDICONTATO

					if(!TOTALI[tipoAttivitaId][sal.numero]) TOTALI[tipoAttivitaId][sal.numero] = 0
					TOTALI[tipoAttivitaId][sal.numero] += parseFloat(importo);

					if(!TOTALI_GENERALE["SAL"+sal.numero]) TOTALI_GENERALE["SAL"+sal.numero] = {'costi':0, 'contributo':0};
					TOTALI_GENERALE["SAL"+sal.numero]['costi'] += parseFloat(importo);

					if(countRiga == 1) {
						rowGrid.push({"rowSpan": countVociCosto});
						rowGrid.push({"rowSpan": countVociCosto}); //% AVANZAMENTO COSTI
						rowGrid.push({"rowSpan": countVociCosto}); // % AVANZAMENTO CONTRIBUTI
					}
				});

				grid.push(rowGrid)
			}

			// Totali 
			var contributoDecretato = parseFloat(TOTALI[tipoAttivitaId]['pianificato'] * percContributo / 100);
			var rowGrid = [
				{"value": "TOTALE", "className": "thcell"},
				{"value": Format(TOTALI[tipoAttivitaId]['decreto']), "valuePulito": TOTALI[tipoAttivitaId]['decreto'], "format": ExcelFormatValuta, "className": "thcell"},
				{"value": Format(TOTALI[tipoAttivitaId]['pianificato']),"valuePulito": TOTALI[tipoAttivitaId]['pianificato'], "format": ExcelFormatValuta, "className": "thcell"},
				{"value": Format(contributoDecretato), "valuePulito": contributoDecretato, "format": ExcelFormatValuta, "className": "thcell"},
			];
			if(!TOTALI_GENERALE['contributo']) TOTALI_GENERALE['contributo'] = 0;
			TOTALI_GENERALE['contributo'] += contributoDecretato;

			SALS.map(sal => {
				var SalCosto = TOTALI[tipoAttivitaId][sal.numero];
				var SalContributo = parseFloat( SalCosto * percContributo / 100);
				var SalPercCosti = parseFloat(SalCosto / TOTALI[tipoAttivitaId]['pianificato'] * 100);
				var SalPercContr = parseFloat(SalContributo / contributoDecretato * 100);
				rowGrid.push(
					{"value": Format(SalCosto),"valuePulito": SalCosto, "format": ExcelFormatValuta, "className": "thcell"},
					{"value": Format(SalContributo),"valuePulito": SalContributo, "format": ExcelFormatValuta, "className": "thcell"}, // Contributi
					{"value": FormatPerc(SalPercCosti),"valuePulito": SalPercCosti, "format": ExcelFormatPerc, "className": "thcell"}, // % avanzam costi
					{"value": FormatPerc(SalPercContr),"valuePulito": SalPercContr, "format": ExcelFormatPerc, "className": "thcell"}, // % avanzam contributi
				);
				TOTALI_GENERALE["SAL"+sal.numero]['contributo'] += parseFloat(SalContributo);
			});
			grid.push(rowGrid)

		});
		// Totali generale
		var TotContributoDecretato = TOTALI_GENERALE['contributo'];
		var rowGrid = [
			{"value": "TOTALE GENERALE", "className": "thcell"},
			{"value": Format(TOTALI_GENERALE['decreto']), "valuePulito": TOTALI_GENERALE['decreto'], "format": ExcelFormatValuta, "className": "thcell"},
			{"value": Format(TOTALI_GENERALE['pianificato']),"valuePulito": TOTALI_GENERALE['pianificato'], "format": ExcelFormatValuta, "className": "thcell"},
			{"value": Format(TotContributoDecretato), "valuePulito": TotContributoDecretato, "format": ExcelFormatValuta, "className": "thcell"},
		];

		SALS.map(sal => {
			var SalCosto = TOTALI_GENERALE["SAL"+sal.numero]['costi'];
			var SalContributo = TOTALI_GENERALE["SAL"+sal.numero]['contributo'];
			var SalPercCosti = parseFloat(SalCosto / TOTALI_GENERALE['pianificato'] * 100);
			var SalPercContr = parseFloat(SalContributo / TotContributoDecretato * 100);
			rowGrid.push(
				{"value": Format(SalCosto),"valuePulito": SalCosto, "format": ExcelFormatValuta, "className": "thcell"},
				{"value": Format(SalContributo),"valuePulito": SalContributo, "format": ExcelFormatValuta, "className": "thcell"}, // Contributi
				{"value": FormatPerc(SalPercCosti),"valuePulito": SalPercCosti, "format": ExcelFormatPerc, "className": "thcell"}, // % avanzam costi
				{"value": FormatPerc(SalPercContr),"valuePulito": SalPercContr, "format": ExcelFormatPerc, "className": "thcell"}, // % avanzam contributi
			);
		});
		grid.push(rowGrid)

		grids.push({
			"name": partnerNome,
			"formato": "monitoraggio_costi",
			"sheet": grid
		})
	}
	
	return grids;
}