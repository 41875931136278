import React, { useState, useEffect } from "react";

import FormBanca from "./FormBanca";
import { withRouter } from "react-router-dom";

function SchedaBanca(props) {
  return (
    <div class="card-body">
      <ul class="nav nav-tabs nav-tabs-line mb-5">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#tab_pane_1">
            <span class="nav-icon">
              <i class="flaticon2-chat-1"></i>
            </span>
            <span class="nav-text">Dettagli</span>
          </a>
        </li>
        {!props.nuovaScheda && (
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#tab_pane_2">
              <span class="nav-icon">
                <i class="flaticon2-pie-chart-4"></i>
              </span>
              <span class="nav-text">Referenti</span>
            </a>
          </li>
        )}
      </ul>
      <div class="tab-content" id="TabContent">
        <FormBanca
          backFromScheda={props.backFromScheda}
          getReferentiUrl={props.getReferentiUrl}
          postReferentiUrl={props.postReferentiUrl}
          putPersoneUrl={props.putPersoneUrl}
          nuovaScheda={props.nuovaScheda}
          changeState={props.changeState}
        />
      </div>
    </div>
  );
}

export default withRouter(SchedaBanca);
