import React, { useState, useEffect, useCallback } from "react";
import Modal from "react-modal";
import Moment from "react-moment";
import * as MyButtons from "./MyButtons";
import ReactDataSheet from 'react-datasheet';
import * as Constants from "../../constants";
import 'react-datasheet/lib/react-datasheet.css';
import '../../../custom-datasheet.css';
import * as Utils from './Utils';

Modal.setAppElement("#root");

export default function PMonitoraggio(props) {
  	const [myGrid, setGrid] = useState([]);

	useEffect(() => {
		if(props.displayTab == 'monitoraggio') {
			fetchPMonitoraggio(props.partnerId);
		}
	}, [props.displayTab]);

	const fetchPMonitoraggio = async (partnerId) => {
		var api_path = Constants.Compose([Constants.PATH_PARTNERS, partnerId, 'monitoraggioPianificazione']);
		const response_data = await Constants.getData(api_path);

		var grid = [];

		var rowGrid = [
			{value:"", readOnly: true}, 
			{value: "RI", readOnly: true, colSpan:2, className:"thcell", width:"15px"}, 
			{value: "SS", readOnly: true, colSpan:2, className:"thcell", width:"15px"}, 
			{value: "", readOnly: true, className:"thcell", width:"15px"}
		];
		grid.push(rowGrid);
		var rowGrid = [
			{value:"", readOnly: true}, 
			{value: "Costo Dipendenti", readOnly: true, className:"thcell", width:"15px"}, 
			{value: "Costo Non Dipendenti", readOnly: true, className:"thcell", width:"15px"},
			{value: "Costo Dipendenti", readOnly: true, className:"thcell", width:"15px"}, 
			{value: "Costo Non Dipendenti", readOnly: true, className:"thcell", width:"15px"},
			{value: "TOTALE", readOnly: true, className:"thcell", width:"15px"}
		];
		grid.push(rowGrid);

		let TOT_COL = {
			'1_1': 0,
			'2_1': 0,
			'1_2': 0,
			'2_2': 0,
		};
		for (let i in response_data.Sal) {
			let sal = response_data.Sal[i];
			let label_sal = 'SAL ' + sal.numero;
			if(sal.rendicontato) label_sal += ' (rendicontato)';

			let TOT_SAL = parseFloat(sal['1_1'] || 0) + parseFloat(sal['1_2'] || 0) + parseFloat(sal['2_1'] || 0) + parseFloat(sal['2_2'] || 0);
			TOT_COL['1_1'] += parseFloat(sal['1_1'] || 0);
			TOT_COL['2_1'] += parseFloat(sal['2_1'] || 0);
			TOT_COL['1_2'] += parseFloat(sal['1_2'] || 0);
			TOT_COL['2_2'] += parseFloat(sal['2_2'] || 0);

			rowGrid = [
				{value: label_sal, readOnly: true}, 
				{value: Utils.Format(sal['1_1']), readOnly: true, width:"15px", className: "cifra"}, 
				{value: Utils.Format(sal['2_1']), readOnly: true, width:"15px", className: "cifra"},
				{value: Utils.Format(sal['1_2']), readOnly: true, width:"15px", className: "cifra"},
				{value: Utils.Format(sal['2_2']), readOnly: true, width:"15px", className: "cifra"},
				{value: Utils.Format(TOT_SAL), readOnly: true, className:"thcell cifra", width:"15px"}
			];
			grid.push(rowGrid);
		}

		let TOT_TOTALE = parseFloat(TOT_COL['1_1']) + parseFloat(TOT_COL['2_1']) + parseFloat(TOT_COL['1_2']) + parseFloat(TOT_COL['2_2']);
		rowGrid = [
			{value: "TOTALE ", readOnly: true, className: "thcell"}, 
			{value: Utils.Format(TOT_COL['1_1']), readOnly: true, className:"thcell cifra", width:"15px"}, 
			{value: Utils.Format(TOT_COL['2_1']), readOnly: true, className:"thcell cifra", width:"15px"},
			{value: Utils.Format(TOT_COL['1_2']), readOnly: true, className:"thcell cifra", width:"15px"},
			{value: Utils.Format(TOT_COL['2_2']), readOnly: true, className:"thcell cifra", width:"15px"},
			{value: Utils.Format(TOT_TOTALE), readOnly: true, className:"thcell cifra", width:"15px"}
		];
		grid.push(rowGrid);

		if(response_data.lastBudget[0]) {
			let budgetDipRI = response_data.lastBudget.find(obj => {return obj.tipoAttivitaId == 1 && obj.voceCostoId == 1});
			let budgetDipSS = response_data.lastBudget.find(obj => {return obj.tipoAttivitaId == 2 && obj.voceCostoId == 1});
			let budgetNonDipRI = response_data.lastBudget.find(obj => {return obj.tipoAttivitaId == 1 && obj.voceCostoId == 2});
			let budgetNonDipSS = response_data.lastBudget.find(obj => {return obj.tipoAttivitaId == 2 && obj.voceCostoId == 2});
			let TOT_BUDGET = parseFloat(budgetDipRI.importo) + parseFloat(budgetDipSS.importo) + parseFloat(budgetNonDipRI.importo) + parseFloat(budgetNonDipSS.importo);
			rowGrid = [
				{value: "Importi da budget ", readOnly: true, className: "thcell"}, 
				{value: Utils.Format(budgetDipRI.importo), readOnly: true, className:"thcell cifra", width:"15px"}, 
				{value: Utils.Format(budgetNonDipRI.importo), readOnly: true, className:"thcell cifra", width:"15px"},
				{value: Utils.Format(budgetDipSS.importo), readOnly: true, className:"thcell cifra", width:"15px"},
				{value: Utils.Format(budgetNonDipSS.importo), readOnly: true, className:"thcell cifra", width:"15px"},
				{value: Utils.Format(TOT_BUDGET), readOnly: true, className:"thcell cifra", width:"15px"}
			];
			grid.push(rowGrid);
			let delta_1_1 = parseFloat(TOT_COL['1_1']) - parseFloat(budgetDipRI.importo);
			let delta_1_2 = parseFloat(TOT_COL['1_2']) - parseFloat(budgetDipSS.importo);
			let delta_2_1 = parseFloat(TOT_COL['2_1']) - parseFloat(budgetNonDipRI.importo);
			let delta_2_2 = parseFloat(TOT_COL['2_2']) - parseFloat(budgetNonDipSS.importo);
			let TOT_DELTA = parseFloat(delta_1_1) + parseFloat(delta_1_2) + parseFloat(delta_2_1) + parseFloat(delta_2_2)
			rowGrid = [
				{value: "Delta ", readOnly: true, className: "thcell"}, 
				{value: Utils.Format(delta_1_1), readOnly: true, className:"thcell cifra", width:"15px"}, 
				{value: Utils.Format(delta_2_1), readOnly: true, className:"thcell cifra", width:"15px"},
				{value: Utils.Format(delta_1_2), readOnly: true, className:"thcell cifra", width:"15px"},
				{value: Utils.Format(delta_2_2), readOnly: true, className:"thcell cifra", width:"15px"},
				{value: Utils.Format(TOT_DELTA), readOnly: true, className:"thcell cifra", width:"15px"}
			];
			grid.push(rowGrid);

		}


		setGrid(grid);
	};
    return (
    	<>
			<ReactDataSheet
				data={myGrid}
				valueRenderer={cell => cell.value}
				dataRenderer={(cell) => cell.expr}
				className='custom-sheet mx-auto'
			/>
		</>
	)
}