import React, { useState, useEffect, useCallback } from "react";
import Modal from "react-modal";
import FormAttivita from "./FormAttivita";
import * as MyButtons from "./MyButtons";

export default function SchedaAttivita(props) {
	const [fields, updateFields] = useState({});
	const [state, setState] = useState({});

	const [nuovaAttivita, setNuovaAttivita] = useState(props.nuovaAttivita);
	const [isModalAttivitaOpen, setModalAttivitaOpen] = useState();

	const forceUpdate = React.useCallback(() => {
		console.log("SCHEDA ATTIVITA FORCE UPDATER")
		setState({ key: Math.random() });
	}, []);

	useEffect(() => {
		setModalAttivitaOpen(props.isModalAttivitaOpen);
		updateFields(props.attivita);
		setNuovaAttivita(props.nuovaAttivita);
	}, [props.isModalAttivitaOpen, props.nuovaAttivita]);

	return (
		<>
			<Modal
				isOpen={isModalAttivitaOpen}
				onRequestClose={props.onRequestClose}
				style={{
					overlay: {
						width: "150",
					},
					content: {
						top: "5%",
						left: "30%",
						right: "30%",
						bottom: "auto",
					},
				}}
			>
				<div className="modal-content">
					<div className="modal-header">
						<h5>OR {props.progettoOr.numero} - {props.progettoOr.descrizione}</h5>
						{!nuovaAttivita ? (
							<h6>
							Attività {props.progettoOr.numero}.{fields["numero"]} - {fields["descrizione"]}
							</h6>
						) : (
							<h6>
							Nuova Attività
							</h6>
						)}
						<MyButtons.MyClose handleClick={props.onRequestClose}/>
					</div>
					<div className="modal-body">
						<ul class="nav nav-tabs nav-tabs-line mb-5">
							<li class="nav-item">
								<a class="nav-link active" data-toggle="tab" href="#tab_att_pane_1">
									<span class="nav-icon"><i class="flaticon2-chat-1"></i></span>
									<span class="nav-text">Dettagli</span>
								</a>
							</li>
   		    				{! nuovaAttivita && (
   		    					<li class="nav-item">
   		    						<a class="nav-link" data-toggle="tab" href="#tab_att_pane_2">
   		    							<span class="nav-icon"><i class="flaticon2-pie-chart-4"></i></span>
   		    							<span class="nav-text">Partner coinvolti</span>
   		    						</a>
   		    					</li>
   		    				)}
						</ul>
   		  				<div class="tab-content">
								<FormAttivita
									key={state.key}
									setModalAttivitaOpen={setModalAttivitaOpen}
									setNuovaAttivita={props.setNuovaAttivita}
									{...props}
									nuovaAttivita={nuovaAttivita}
									attivita={props.attivita}
									forceUpdateTab={props.forceUpdate}
									forceUpdate={forceUpdate}
								/>
						</div>
					</div>
				</div>
			</Modal>
        </>
    )
}