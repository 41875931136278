import { setNestedObjectValues } from "formik";
import React, { useState, useEffect, useCallback } from "react";
import { Formik, Field, Form } from "formik";
import Modal from "react-modal";
import RendicontazioneDipendente from "./RendicontazioneDipendente";
import * as Constants from "../../constants";
import * as MyButtons from "./MyButtons";
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import '../../../custom-datasheet.css';
import * as Utils from './Utils';

Modal.setAppElement("#root");

export default function RendicontazionePartner(props) {
	const [state, setState] = useState({});
    const [salNumero, setSal] = useState();
    const [partnerNome, setPartner] = useState();
    const [rendicontazione, setRendicontazione] = useState({});
    const [rendicontazioneId, setRendicontazioneId] = useState(false);
    const [rendicontazioneCosti, setRendicontazioneCosti] = useState([]);
    const [rendicontati, setRendicontati] = useState([]);
	const [listDipendenti, setDipendenti] = useState([]);
	const [RTotale, setRTotale] = useState();
	const [myGrid, setGrid] = useState([]);
	const [readOnly, setReadOnly] = useState(true);
	const [isNew, setNew] = useState(true);

	useEffect(() => {
		setSal(props.sal.numero);
		if(props.partner && props.partner.partnerId) {
			fetchPartner();
			fetchDipendenti();
		}
	}, [props.sal, props.partner, state]);

	useEffect(() => {
		if(rendicontazioneId) {
			fetchCosti();
			fetchRendicontati();
		}
	}, [rendicontazioneId]);

	useEffect(() => {
		if(props.vocicosto) PrepareBudget();
	}, [props.vocicosto, readOnly, rendicontazioneCosti, rendicontazioneId]);

	const fetchPartner = async () => {
		var api_path = Constants.Compose([Constants.PATH_PARTNERS, props.partner.partnerId]);
		api_path += "?filter=" + JSON.stringify({'include':['cliente', 'partnerDipendenti', {'relation': 'rendicontazioni', 'scope': {'where': {'salId': props.sal.salId}, 'include': 'tipoStatoRendicontazione'}}]});
		const partner = await Constants.getData(api_path);
		setPartner(partner.cliente.acronimo);
		if(partner.rendicontazioni[0]) {
			setRendicontazioneId(partner.rendicontazioni[0].rendicontazioneId);
			setNew(false);
			setReadOnly(true);
		} else {
			setRendicontazioneId();
		 	setRendicontazioneCosti([]);
		 	setRendicontazione({
				'tipoStatoRendicontazioneId': 1,
				'tipoStatoRendicontazione': "Da rendicontare",
			})
			setNew(true);
			setReadOnly(false);
		}
	}
	const fetchRendicontati = async () => {
		var api_path = Constants.Compose([Constants.PATH_RENDICONTAZIONE, rendicontazioneId, 'rendicontati']);
		const response_data = await Constants.getData(api_path);
		setRendicontati(response_data);
	}
	const fetchCosti = async () => {
		var api_path = Constants.Compose([Constants.PATH_RENDICONTAZIONE, rendicontazioneId]);
		api_path += "?filter=" + JSON.stringify({'include':['rendicontazioneCosti', 'tipoStatoRendicontazione']});
		const response_data = await Constants.getData(api_path);
		if(response_data) {
			setRendicontazione({
				'tipoStatoRendicontazioneId': response_data.tipoStatoRendicontazioneId,
				'tipoStatoRendicontazione': response_data.tipoStatoRendicontazione.tipoStatoRendicontazione,
			})
			setRendicontazioneCosti(response_data.rendicontazioneCosti);
		}
	}
	const fetchDipendenti = async () => {
		var api_path = Constants.Compose([Constants.PATH_PARTNERS, props.partner.partnerId, Constants.PATH_DIPENDENTI]);
		api_path += "?filter=" + JSON.stringify({'include':{'dipendente':'persona'}});
		const response_data = await Constants.getData(api_path);

		var api_path = Constants.Compose([Constants.PATH_COSTILIVELLO]);
		api_path += "?filter=" + JSON.stringify({'where': {'tipoClienteId': props.partner.cliente.tipoClienteId}});
		const costiLivello = await Constants.getData(api_path);

		response_data.map((dip) => {
			var nome = dip.etichetta;
			var CMO = costiLivello.find(obj => { return obj.tipoLivelloId === dip.tipoLivelloId});
			var costoOrarioLivello = CMO ? CMO.costoMedioOrario : 0;
			var stato = 0;
			// var costoMedioOrario = dip.tipoLivelloId != 4 ? CMO.costoMedioOrario : dip.costoMedioReale;
			var costoMedioOrario = dip.costoMedioReale ? dip.costoMedioReale : costoOrarioLivello; // Anche i dipendenti posso essere a costoReale

			if(dip.dipendente) nome = dip.dipendente.persona.cognome + " " + dip.dipendente.persona.nome;
			dip.nome = nome;
			dip.stato = stato;
			dip.costoMedioOrario = costoMedioOrario;
			dip.rendicontazioni = [];
		});
		setDipendenti(response_data);
	};

	const handlePost = async (dataToSend) => {
		var api_path = Constants.Compose([Constants.PATH_RENDICONTAZIONE]);
		const response_data = await Constants.postData(api_path, JSON.stringify(dataToSend));
		setRendicontazioneId(response_data.rendicontazioneId);
		setNew(false);
		setReadOnly(true);
		fetchPartner();
		if(!listDipendenti.length) { // Se il partner non ha dipendenti la rendicontazione si chiude con la rendicontazione costi
			var api_path = Constants.Compose([Constants.PATH_PMESE_POST, 'createEmpty']);
			const response_data = await Constants.postData(api_path, JSON.stringify({"salId": props.sal.salId, "partnerId": props.partner.partnerId}));
			props.forceUpdate();
		}
		// props.forceUpdate(); // Se scommentato, il modal rimane in edit e non si visualizzano i tab dei dipendenti
	}

	const handleEdit = async (dataToSend) => {
		var api_path = Constants.Compose([Constants.PATH_RENDICONTAZIONE]);
		const response_data = await Constants.putData(api_path, JSON.stringify(dataToSend));
		fetchCosti();
		if(!listDipendenti.length) { // Se il partner non ha dipendenti la rendicontazione si chiude con la rendicontazione costi
			var api_path = Constants.Compose([Constants.PATH_PMESE_POST, 'createEmpty']);
			const response_data = await Constants.postData(api_path, JSON.stringify({"salId": props.sal.salId, "partnerId": props.partner.partnerId}));
			props.forceUpdate();
		}
		// props.forceUpdate();
	}

	const handleEditClick = () => {
		setReadOnly(false);
	}

	const calc = function(grid, RowTotale) {
		if(!RowTotale) RowTotale = RTotale;

		if(grid.length) {
			var sumRI = 0;
			var sumSS = 0;
			for(var i=1;i<=grid.length-2; i++) {
				var row = grid[i];
				var eleRI = row.find(obj => { return obj.tipoAttivitaId === 1});
				var eleSS = row.find(obj => { return obj.tipoAttivitaId === 2});
				sumRI += parseFloat(eleRI.valuePulito);
				sumSS += parseFloat(eleSS.valuePulito);
				row[3]['value'] = Utils.Format(parseFloat(eleRI.valuePulito + eleSS.valuePulito));
			}
			grid[RowTotale][1]['value'] = Utils.Format(sumRI);
			grid[RowTotale][2]['value'] = Utils.Format(sumSS);
			grid[RowTotale][3]['value'] = Utils.Format(parseFloat(sumRI + sumSS));

			setGrid(grid);
		}
	}

	const PrepareBudget = function () {
		var grid = [];

		// INTESTAZIONE
		var rowGrid = [
			{"readOnly": true, className:"thcell"}, 
			{"value": "RI", "readOnly": true, className:"thcell"}, 
			{"value": "SS", "readOnly": true, className:"thcell"}, 
			{"value": "TOTALE", "readOnly": true, className:"thcell"}
		];
		grid.push(rowGrid)

		props.vocicosto.map((voce) => {
			var eleRI = {importo: 0};
			var eleSS = {importo: 0};
			if(rendicontazioneCosti.length) {
				eleRI = rendicontazioneCosti.find(obj => { return obj.tipoAttivitaId === 1 && obj.voceCostoId === voce.voceCostoId});
				eleSS = rendicontazioneCosti.find(obj => { return obj.tipoAttivitaId === 2 && obj.voceCostoId === voce.voceCostoId});
			}
			var thisReadOnly = readOnly;
			if(voce.voceCostoId == 1 || voce.voceCostoId == 2) {
				thisReadOnly = true;
				// if (isNew) return true;
			}
			var rowGrid = [
				{"value": voce.voceCosto, "readOnly": true}, 
				{"valuePulito": eleRI.importo, "value": Utils.Format(eleRI.importo), "readOnly": thisReadOnly, "tipoAttivitaId": 1, "voceCostoId": voce.voceCostoId, className:"cifra"}, 
				{"valuePulito": eleSS.importo, "value": Utils.Format(eleSS.importo), "readOnly": thisReadOnly, "tipoAttivitaId": 2, "voceCostoId": voce.voceCostoId, className:"cifra"}, 
				{"value": "", "readOnly": true, className: "cifra"}
			];
			grid.push(rowGrid)
		});

		var rowGrid = [
			{"value": "TOTALE", "readOnly": true, "className": "thcell"}, 
			{"value": "", "readOnly": true, "className": "thcell"}, 
			{"value": "", "readOnly": true, "className": "thcell"}, 
			{"value": "", "readOnly": true, "className": "thcell"}
		];
		grid.push(rowGrid)

		var RowTotale = grid.length-1;
		setRTotale(RowTotale);

		// Passo RowTotale perche il setRTotale e' asincrono
		calc(grid, RowTotale);
	}

	return (
		<>
			<Modal
				isOpen={props.isModalOpen}
				onRequestClose={props.onRequestClose}
				style={{
					overlay: {
						width: "350",
					},
					content: {
						top: "5%",
						left: "7%",
						right: "7%",
						bottom: "auto",
					},
				}}
			>
				<div className="modal-content">
					<div className="modal-header">
						<h5>Rendicontazione SAL {salNumero} - {partnerNome} &nbsp;
							{ rendicontazione.tipoStatoRendicontazioneId < 2 ? (
								<span class="label label-lg label-light-success label-inline"> {rendicontazione.tipoStatoRendicontazione} </span>
							) : (
								<span class="label label-lg label-light-success label-inline"> {rendicontazione.tipoStatoRendicontazione} </span>
							)
						}
						</h5>
						<MyButtons.MyClose handleClick={props.onRequestClose} />
					</div>
					<div className="modal-body">
						<Formik
							enableReinitialize={true}
							initialValues={{
								salId: props.sal.salId,
								partnerId: props.partner.partnerId,
								rendicontazioneId: rendicontazioneId
							}}
							onSubmit={(values, { setSubmitting }) => {
								values['rendicontazioneCosti'] = []
								for(var i=1;i<=myGrid.length-2; i++) {
									var row = myGrid[i];
									var eleRI = row.find(obj => { return obj.tipoAttivitaId === 1});
									var eleSS = row.find(obj => { return obj.tipoAttivitaId === 2});
									values.rendicontazioneCosti.push({tipoAttivitaId: eleRI.tipoAttivitaId, voceCostoId: eleRI.voceCostoId, importo: eleRI.valuePulito});
									values.rendicontazioneCosti.push({tipoAttivitaId: eleSS.tipoAttivitaId, voceCostoId: eleSS.voceCostoId, importo: eleSS.valuePulito});
								}
								rendicontazioneId
									? handleEdit(values)
									: handlePost(values);
								setReadOnly(true);
							}}
							onReset={() => {
								setReadOnly(true);
							}}
						>
							<Form class="form">
								<div class="border-0 top_commandi bp-5" style={{ textAlign: "right" }} >
									{! props.SalReadOnly && (
										readOnly ? (
											<div>
												<MyButtons.MyModifica handleClick={handleEditClick}/>
											</div>
										) : (
										<div>
											{!isNew && (
												<>
													<MyButtons.MyAnnulla handleClick={props.handleEditClick}/>
												</>
											)}
											&nbsp;&nbsp;
											<MyButtons.MySalva/>
										</div>
										)
									)}
								</div>
								<div class="form-group row">
									<div class="col-lg-4">
										<ReactDataSheet
											data={myGrid}
											valueRenderer={cell => cell.value}
											dataRenderer={cell => cell.valuePulito}
											className='custom-sheet'
											onCellsChanged={changes => {
												const grid = myGrid.map(row => [...row]);
												changes.forEach(({ cell, row, col, value }) => {
													if(!value) value = 0;
													value = Utils.pulisciImporto(value);
													value = isNaN(value) ? 0 : value;
													value = parseFloat(value);
													grid[row][col]['valuePulito'] = value;
													grid[row][col]['value'] = Utils.Format(value);
												});
												calc(grid);
											}}
										/>
									</div>
									<div class="col-lg-8"><span style={{'fontColor':'red'}}>La rendicontazione per le voci costo <b>Personale</b> e <b>Personale non dipendente</b> viene effettuata inserendo le rendicontazioni dei singoli dipendenti</span></div>
								</div>

							</Form>
						</Formik>
						{readOnly && (
							<>
							<ul class="nav nav-tabs nav-tabs-line mb-5">
								{listDipendenti.map((dip, i) => {
									var act = (i == 0) ? 'active' : '';
									var test;
									if(dip.dipendente) test = rendicontati.find(obj => { return obj == dip.dipendente.dipendenteId });

									return (
										<li class="nav-item">
											<a class={"nav-link " + act} data-toggle="tab" href={"#tab_pane_dip_" + dip.partnerDipendenteId}>
												{test ? (
													<span class="nav-text label label-lg label-light-success label-inline">{dip.nome}</span>
												) : (
													<span class="nav-text label label-lg label-light-warning label-inline">{dip.nome}</span>
												)}
											</a>
										</li>
									)
								})} 
							</ul>
							<div class="tab-content" id="TabContent">
								{listDipendenti.map((dip, i) => {
									var act = (i == 0) ? 'active' : '';
									var test;
									if(dip.dipendente) test = rendicontati.find(obj => { return obj == dip.dipendente.dipendenteId });
									return (
										<div id={"tab_pane_dip_" + dip.partnerDipendenteId} class={"tab-pane fade show " + act}>
											<RendicontazioneDipendente
												key={state.key}
												partnerDipendente={dip}
												progettoId={props.sal.progettoId}
												rendicontazioneId={rendicontazioneId}
												rendicontato={test}
												{...props}
											/>
										</div>
									)
								})}
							</div>
							</>
						)}
						</div>
				</div>
			</Modal>
		</>
	)
}