import React, { useState, useEffect } from "react";
import * as Constants from "../../constants";
import ContentTable from "../Tables/ContentTable";
import Moment from "react-moment";
import FormFattura from "./FormFattura";
import * as MyButtons from "./MyButtons";
import * as Utils from "./Utils";

export default function FatturaTab(props) {
	const [state, setState] = useState({});
    const [isModalOpen, setModalOpen] = useState(false);
	const [list, setList] = useState([]);
	const [label, setLabel] = useState([]);
	const [selectedFattura, setFattura] = useState({});
	const [divStyle, setDivStyle] = useState(
		props.nuovaTab ? "collapse show" : "collapse"
	);

	useEffect(() => {
		fetch();
		if(props.sal.anticipo || props.sal.acconto) {
			setLabel(props.sal.numero)
		} else {
			setLabel('SAL ' + props.sal.numero)
		}
	}, [props.sal, state]);

	const fetch = async () => {
		var api_path;
		if(props.sal.salId == 'acconto') {
			api_path = Constants.Compose([Constants.PATH_PROGETTI, props.progettoId, 'partners']);
			api_path += "?filter=" + JSON.stringify({
				include: [
					{ relation: 'cliente'},
					{ relation: 'fatture', scope: {where: {acconto: 1}, include: [{relation: 'allegato'}, {relation: 'tipoStatoFattura'}]} }
				]
			});
		} else if(props.sal.salId == 'anticipo') {
			api_path = Constants.Compose([Constants.PATH_PROGETTI, props.progettoId, 'partners']);
			api_path += "?filter=" + JSON.stringify({
				include: [
					{ relation: 'cliente'},
					{ relation: 'fatture', scope: {where: {anticipo: 1}, include: [{relation: 'allegato'}, {relation: 'tipoStatoFattura'}]} }
				]
			});
		} else {
			api_path = Constants.Compose([Constants.PATH_PROGETTI, props.progettoId, 'partners']);
			api_path += "?filter=" + JSON.stringify({
				include: [
				 	{ relation: 'cliente'},
					{ relation: 'fatture', scope: {where: {salId: props.sal.salId}, include: [{relation: 'allegato'}, {relation: 'tipoStatoFattura'}]} }
				]
			});
		}
		const response_data = await Constants.getData(api_path);
		var list = [];
		for(var i in response_data) {
			var progetto = response_data[i];
			for (var j in progetto['fatture']) {
				var fattura = progetto['fatture'][j]
				var fileName = fattura && fattura.allegato ? fattura.allegato.fileName : '';
				list.push({
					"partner": progetto.cliente.acronimo,
					"partnerId": fattura.partnerId,
					"salId": fattura.salId,
					"acconto": fattura.acconto,
					"anticipo": fattura.anticipo,
					"numero": fattura.numero,
					"dataFattura": fattura.dataFattura,
					"importo": fattura.importo,
					"fileName": fileName,
					"allegatoId": fattura.allegatoId,
					"fatturaId": fattura.fatturaId,
					"rendicontazioneId": fattura.rendicontazioneId,
					"tipoStatoFattura": fattura.tipoStatoFattura,
					"tipoStatoFatturaId": fattura.tipoStatoFatturaId
				});
			}
		}
		setList(list);
	};

	const openModal = (row_original) => {
		if(!row_original.fatturaId) {
			row_original = {salId: props.sal.salId}
		}
		if(row_original.acconto == 1) row_original.salId = 'acconto'
		if(row_original.anticipo == 1) row_original.salId = 'anticipo'
		setFattura(row_original);
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
	};
	const columns = React.useMemo(
		() => [
			{
				Header: "Partner",
				accessor: "partner",
			},
			{
				Header: "Numero",
				accessor: "numero",
			},
			{
				Header: "Data",
				accessor: "dataFattura",
				Cell: row => {
					if(row.value) {
						return	<Moment format="DD/MM/YYYY">{row.value}</Moment>
					}
					return "-";
				}
			},
			{
				Header: "Importo",
				accessor: "importo",
				Cell: row => {
					var importo = row.value;
					return Utils.Format(importo);
				}
			},
			{
				Header: "Allegato",
				accessor: "fileName",
				click: 1,
				Cell: row => (
					<span className="link" onClick={() => {Utils.downloadAllegato(row.row.original.allegatoId, row.row.original.fileName)}}>{row.value}</span>
				)
			},
			{
				Header: "Stato Fattura",
				accessor: "tipoStatoFattura",
				Cell: row => {
					var statoFattura = row.value;
					if(!statoFattura) return '';
					if(statoFattura.tipoStatoFatturaId < 3) 
						return  <span class="label label-lg label-light-warning label-inline"> {statoFattura.tipoStatoFattura} </span>
					else 
						return  <span class="label label-lg label-light-success label-inline"> {statoFattura.tipoStatoFattura} </span>
				}
			},
		]);
	return (
		<>
			<div class="card">
				<div class="card-header">
					<div class="card-title collapsed" data-toggle="collapse" data-target={"#collapsefattura" + props.dataId} >
						<i class=" flaticon2-start-up"></i> Fatture {label}
					</div>
				</div>
				<div id={"collapsefattura" + props.dataId} class={divStyle} data-parent="#accordionFatturazione" >
					<div class="border-0 top_commandi bp-5" style={{ textAlign: "right", marginTop: "10px" }} >
						<div>
							<MyButtons.MyAggiungi handleClick={openModal} label="Nuova Fattura"/>
						</div>
					</div>
					<div class="card-body">
    					<ContentTable
    						key={state.key}
    						columns={columns}
    						easy = "1"
    						dataToDisplay={list}
    						openModal={openModal}
    						defaultOrderBy={"attivitaNumero"}
							message_nodata="Nessuna fattura inserita"
    					/>
					</div>
				</div>
			</div>
			<FormFattura
				isModalOpen={isModalOpen}
				fattura={selectedFattura} 
				// tipiStatoFattura={props.tipiStatoFattura}
				// listPartners={props.listPartners}
				// listSals={props.listSals}
				{...props}
				forceUpdate={props.forceUpdate}
				onRequestClose={closeModal}
			/>
		</>
	);
}
